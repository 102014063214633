import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { arrayToMap } from '@finclusive/utils';
import {
  toggleEditDefaultConfigurationOverlayVisible,
  toggleEditCustomerConfigurationOverlayVisible,
  setGlobalLimitsSelectedBank,
  setGlobalLimitsData,
  setCustomerLimitsSelectedBank,
  setCustomerLimitsData,
  setCustomersFilter,
  setCustomersFilterInput,
  getCustomersStart,
  getCustomersSuccess,
  getCustomersFail,
  getCustomerConfigStart,
  getCustomerConfigSuccess,
  getCustomerConfigFail,
  setCustomerConfigStart,
  setCustomerConfigSuccess,
  setCustomerConfigFail,
  getGlobalConfigStart,
  getGlobalConfigSuccess,
  getGlobalConfigFail,
  setGlobalConfigStart,
  setGlobalConfigSuccess,
  setGlobalConfigFail,
} from '../actions';
import updateBankLimitConfigs from './helpers';
import initialState from './initialState';

const toggleEditDefaultConfigurationOverlayVisibleHandler = [
  toggleEditDefaultConfigurationOverlayVisible,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      editDefaultConfigurationOverlayVisible: payload,
    };
  },
];

const toggleEditCustomerConfigurationOverlayVisibleHandler = [
  toggleEditCustomerConfigurationOverlayVisible,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      editCustomerConfigurationOverlayVisible: payload,
    };
  },
];

const setGlobalLimitsSelectedBankHandler = [
  setGlobalLimitsSelectedBank,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      globalLimits: {
        ...state.globalLimits,
        selectedBank: payload,
      },
    };
  },
];

const setGlobalLimitsDataHandler = [
  setGlobalLimitsData,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      globalLimits: {
        ...state.globalLimits,
        configEdit: updateBankLimitConfigs(state.globalLimits, payload),
      },
    };
  },
];

const setCustomerLimitsSelectedBankHandler = [
  setCustomerLimitsSelectedBank,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customerLimits: {
        ...state.customerLimits,
        selectedBank: payload,
      },
    };
  },
];

const setCustomerLimitsDataHandler = [
  setCustomerLimitsData,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customerLimits: {
        ...state.customerLimits,
        configEdit: updateBankLimitConfigs(state.customerLimits, payload),
      },
    };
  },
];

const setCustomersFilterHandler = [
  setCustomersFilter,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customersFilter: {
        ...state.customersFilter,
        filterBy: payload,
      },
    };
  },
];

const setCustomersFilterInputHandler = [
  setCustomersFilterInput,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customersFilter: {
        ...state.customersFilter,
        input: payload,
      },
    };
  },
];

const getCustomersStartHandler = [
  getCustomersStart,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomers: true,
      },
    };
  },
];

const getCustomersSuccessHandler = [
  getCustomersSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomers: false,
      },
      customers: payload,
    };
  },
];

const getCustomersFailHandler = [
  getCustomersFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomers: false,
      },
      error: payload,
    };
  },
];

const getCustomerConfigStartHandler = [
  getCustomerConfigStart,
  (state, action) => {
    const { payload } = action;
    const { customerFinClusiveID, customerName } = payload;

    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomerConfig: true,
      },
      customerLimits: {
        ...state.customerLimits,
        customerFinClusiveID,
        customerName,
      },
    };
  },
];

const getCustomerConfigSuccessHandler = [
  getCustomerConfigSuccess,
  (state, action) => {
    const { id, bankLimitConfigs } = action.payload;

    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomerConfig: false,
      },
      customerLimits: {
        ...state.customerLimits,
        id: id || '',
        selectedBank: R.path(['0', 'bankName'], bankLimitConfigs),
        configEdit: arrayToMap('bankName', bankLimitConfigs),
      },
    };
  },
];

const getCustomerConfigFailHandler = [
  getCustomerConfigFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingCustomerConfig: false,
      },
      error: payload,
    };
  },
];

const setCustomerConfigStartHandler = [
  setCustomerConfigStart,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingCustomerConfig: true,
      },
    };
  },
];

const setCustomerConfigSuccessHandler = [
  setCustomerConfigSuccess,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingCustomerConfig: false,
      },
    };
  },
];

const setCustomerConfigFailHandler = [
  setCustomerConfigFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingCustomerConfig: false,
      },
      error: payload,
    };
  },
];

const getGlobalConfigStartHandler = [
  getGlobalConfigStart,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingGlobalConfig: true,
      },
    };
  },
];

const getGlobalConfigSuccessHandler = [
  getGlobalConfigSuccess,
  (state, action) => {
    const { id, bankLimitConfigs } = action.payload;

    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingGlobalConfig: false,
      },
      globalLimits: {
        ...state.globalLimits,
        id: id || '',
        selectedBank: R.path(['0', 'bankName'], bankLimitConfigs),
        configEdit: arrayToMap('bankName', bankLimitConfigs),
      },
    };
  },
];

const getGlobalConfigFailHandler = [
  getGlobalConfigFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingGlobalConfig: false,
      },
      error: payload,
    };
  },
];

const setGlobalConfigStartHandler = [
  setGlobalConfigStart,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingGlobalConfig: true,
      },
    };
  },
];

const setGlobalConfigSuccessHandler = [
  setGlobalConfigSuccess,
  (state) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingGlobalConfig: false,
      },
    };
  },
];

const setGlobalConfigFailHandler = [
  setGlobalConfigFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isSubmittingGlobalConfig: false,
      },
      error: payload,
    };
  },
];

const reducer = handleActions(
  new Map([
    toggleEditDefaultConfigurationOverlayVisibleHandler,
    toggleEditCustomerConfigurationOverlayVisibleHandler,
    setGlobalLimitsSelectedBankHandler,
    setGlobalLimitsDataHandler,
    setCustomerLimitsSelectedBankHandler,
    setCustomerLimitsDataHandler,
    setCustomersFilterHandler,
    setCustomersFilterInputHandler,
    getCustomersStartHandler,
    getCustomersSuccessHandler,
    getCustomersFailHandler,
    getCustomerConfigStartHandler,
    getCustomerConfigSuccessHandler,
    getCustomerConfigFailHandler,
    setCustomerConfigStartHandler,
    setCustomerConfigSuccessHandler,
    setCustomerConfigFailHandler,
    getGlobalConfigStartHandler,
    getGlobalConfigSuccessHandler,
    getGlobalConfigFailHandler,
    setGlobalConfigStartHandler,
    setGlobalConfigSuccessHandler,
    setGlobalConfigFailHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
