import { call, put, takeLatest } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_WORKFLOW_ITEM_PACKAGE as ENDPOINT } from 'env-config';
import { get } from 'modules/utils';
import {
  getWorkItemPackageFail,
  getWorkItemPackageStart,
  getWorkItemPackageSuccess,
} from '../actions/get-work-item-package';
import types from '../types';

export const httpCall = (data, workItemPackageID) => get({ route: `${ENDPOINT}/${workItemPackageID}`, data });

export function* getWorkItemPackage({ workItemPackageID }) {
  try {
    yield put(getWorkItemPackageStart());

    const payload = {};
    const result = yield call(httpCall, payload, workItemPackageID);

    yield put(getWorkItemPackageSuccess(result));
  } catch (error) {
    yield put(getWorkItemPackageFail(error));
    ToastNotification('error', error.message || 'There was an error trying to get work item package');
  }
}

export default function* getWorkItemPackageSaga() {
  yield takeLatest(types.GET_WORK_ITEM_PACKAGE, getWorkItemPackage);
}
