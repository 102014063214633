import types from '../types';

export const updateOtcDataStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: true,
  },
});

export const updateOtcDataSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
});

export const updateOtcDataFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
  error,
});

export default {
  [types.UPDATE_OTC_DATA_START]: updateOtcDataStart,
  [types.UPDATE_OTC_DATA_SUCCESS]: updateOtcDataSuccess,
  [types.UPDATE_OTC_DATA_FAIL]: updateOtcDataFail,
};
