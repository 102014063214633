import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from '@finclusive/library';

const BackButton = ({ history }) => {
  return (
    <Button
      className="back-button"
      type="link"
      onClick={() => {
        history.goBack();
      }}
    >
      Go Back
    </Button>
  );
};

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(BackButton);
