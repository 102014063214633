/* eslint-disable no-param-reassign */
import assignWorkItemReducer from './reducer/assign-work-item';
import assignWorkItemPackageReducer from './reducer/assign-work-item-package';
import finalizeWorkItemPackageReducer from './reducer/finalize-work-item-package';
import getWorkItemReducer from './reducer/get-work-item';
import getWorkItemPackageReducer from './reducer/get-work-item-package';
import getWorkItemPackagesReducer from './reducer/get-work-item-packages';
import updateActionItemReducer from './reducer/update-action-item';
import updateWorkItemPackageStatusReducer from './reducer/update-work-item-package-status';
import getDocumentReducer from './reducer/get-document';
import getEDDReportReducer from './reducer/get-edd-report';

import types from './types';

const initialState = {
  loaders: {
    isLoadingWorkItem: 0,
    isLoadingWorkItemPackage: true,
    isLoadingWorkItems: true,
    isUpdatingActionItem: false,
    isLoadingDocument: false,
    isFinalizingWorkItemPackage: false,
  },
  selectedRow: {
    index: null,
    page: null,
    rowDetails: {},
  },
  currentWorkItems: [],
  currentClient: null,
  currentPage: 1,
  eddOverlayVisible: false,
  overlays: {},
  query: '',
  workItems: {},
  sortOrder: {
    by: 'CreatedAt',
    direction: 1,
  },
  exportUrl: '',
  currentTab: 'new',
  statuses: [],
  assignedTo: [],
};

export const cancelActionItems = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isUpdatingActionItem: false,
  },
});

const changeActionItem = (state, { actionItem }) => {
  const workItemIndex = state.currentWorkItems.findIndex(
    (workItem) => workItem.id === actionItem.workItemID
  );
  const actionItemIndex = state.currentWorkItems[workItemIndex].actions.findIndex(
    (action) => action.id === actionItem.id
  );
  const workItemisDone = state.currentWorkItems[workItemIndex].actions.every((e) => e.isCompleted);
  state.currentWorkItems[workItemIndex].actions[actionItemIndex] = actionItem;
  state.currentWorkItems[workItemIndex].isDone = workItemisDone;
  const workItemPackageisDone = state.currentWorkItems.every((e) => e.isDone);
  state.currentClient.workItems[workItemIndex].isDone = workItemisDone;
  return {
    ...state,
    currentClient: {
      ...state.currentClient,
      workItems: [...state.currentClient.workItems],
      isDone: workItemPackageisDone,
    },
    currentWorkItems: [...state.currentWorkItems],
  };
};

const setSelectedRow = (state, { index }) => ({
  ...state,
  selectedRow: {
    ...state.selectedRow,
    index,
  },
});

const setSelectedPage = (state, { page }) => ({
  ...state,
  selectedRow: {
    ...state.selectedRow,
    page,
  },
});

const setCurrentPage = (state, { page }) => ({
  ...state,
  currentPage: page,
});

const setSortOrder = (state, { sortOrder }) => ({ ...state, sortOrder });

const removeCurrentClient = (state) => ({
  ...state,
  currentClient: null,
});

const removeCurrentWorkItems = (state) => ({
  ...state,
  currentWorkItems: [],
});

const toggleEDDOverlay = (state, { isVisible }) => ({
  ...state,
  eddOverlayVisible: isVisible,
});

const selectWorkItemsTab = (state, { status }) => ({ ...state, status });

export const clearDocument = (state) => ({
  ...state,
  exportUrl: '',
});

const reducer = {
  [types.CANCEL_ACTION_ITEMS_SUCCESS]: cancelActionItems,
  [types.CHANGE_ACTION_ITEM]: changeActionItem,
  [types.SET_SELECTED_ROW]: setSelectedRow,
  [types.SET_SELECTED_PAGE]: setSelectedPage,
  [types.SET_CURRENT_PAGE]: setCurrentPage,
  [types.REMOVE_CURRENT_CLIENT]: removeCurrentClient,
  [types.REMOVE_CURRENT_WORK_ITEMS]: removeCurrentWorkItems,
  [types.SET_SORT_ORDER]: setSortOrder,
  [types.TOGGLE_EDD_OVERLAY]: toggleEDDOverlay,
  [types.SELECT_WORK_ITEMS_TAB]: selectWorkItemsTab,
  [types.CLEAR_DOCUMENT]: clearDocument,
  ...assignWorkItemReducer,
  ...assignWorkItemPackageReducer,
  ...finalizeWorkItemPackageReducer,
  ...getWorkItemReducer,
  ...getWorkItemPackageReducer,
  ...getWorkItemPackagesReducer,
  ...updateActionItemReducer,
  ...updateWorkItemPackageStatusReducer,
  ...getDocumentReducer,
  ...getEDDReportReducer,
};

export default (state = initialState, action = {}) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
