import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const toggleVendorsOverlay = createPrefixedAction(actionTypes.TOGGLE_VENDORS_OVERLAY);
export const toggleVendorPricingOverlay = createPrefixedAction(
  actionTypes.TOGGLE_VENDOR_PRICING_OVERLAY
);
export const updateVendorSkuList = createPrefixedAction(actionTypes.UDPATE_VENDOR_SKU_LIST);

export const getDefaultVendors = createPrefixedAction(actionTypes.GET_DEFAULT_VENDORS_SAGA);
export const getDefaultVendorsStore = createPrefixedAction(actionTypes.GET_DEFAULT_VENDORS);

export const getVendorSkus = createPrefixedAction(actionTypes.GET_VENDOR_SKUS_SAGA);
export const getVendorSkusStore = createPrefixedAction(actionTypes.GET_VENDOR_SKUS);

export const getDefaultVendorPlan = createPrefixedAction(actionTypes.GET_DEFAULT_VENDOR_PLAN_SAGA);
export const getDefaultVendorPlanStore = createPrefixedAction(actionTypes.GET_DEFAULT_VENDOR_PLAN);

export const saveDefaultVendorPlan = createPrefixedAction(
  actionTypes.SAVE_DEFAULT_VENDOR_PLAN_SAGA
);
export const saveDefaultVendorPlanStore = createPrefixedAction(
  actionTypes.SAVE_DEFAULT_VENDOR_PLAN
);

export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
