import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ToastNotification } from 'components/library';
import API from 'config/api';
import { get, post, showErrorMessage } from 'modules/utils';
import { addPrefix } from '../config';
import { formToChannels } from './helpers';
import actionTypes from './types';
import {
  toggleChannelOverlay,
  resetNotificationsForm,
  getAllCustomers,
  getAllCustomersStore,
  getConfigurationOptionsStore,
  getConfigurationGroupsStore,
  saveCustomerConfigurationStore,
} from '.';

function* getAllCustomersSagaCallback() {
  try {
    yield put(getAllCustomersStore({ loading: true }));
    const payload = yield call(get, API.customerNotifications.getAll);
    yield put(getAllCustomersStore({ loading: false, payload }));
  } catch (error) {
    showErrorMessage(error);
    yield put(getAllCustomersStore({ loading: false, error }));
  }
}

function* getAllCustomersSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_ALL_CUSTOMERS_SAGA), getAllCustomersSagaCallback);
}

function* getConfigurationOptionsCallback() {
  try {
    yield put(getConfigurationOptionsStore({ loading: true }));
    const payload = yield call(get, API.customerNotifications.getOptions);
    yield put(getConfigurationOptionsStore({ loading: false, payload }));
  } catch (error) {
    showErrorMessage(error);
    yield put(getConfigurationOptionsStore({ loading: false, error }));
  }
}

function* getConfigurationOptionsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CONFIGURATION_OPTIONS_SAGA), getConfigurationOptionsCallback);
}

function* getConfigurationGroupsCallback() {
  try {
    yield put(getConfigurationGroupsStore({ loading: true }));
    const payload = yield call(get, API.customerNotifications.getGroups);
    yield put(getConfigurationGroupsStore({ loading: false, payload }));
  } catch (error) {
    showErrorMessage(error);
    yield put(getConfigurationGroupsStore({ loading: false, error }));
  }
}

function* getConfigurationGroupsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CONFIGURATION_GROUPS_SAGA), getConfigurationGroupsCallback);
}

function* saveCustomerConfigurationSagaCallback({ payload }) {
  try {
    const { isDeleteContext, selectedChannel } = payload;
    yield put(saveCustomerConfigurationStore({ loading: true }));

    const { selectedCustomer, groups } = yield select(({ customerNotifications }) => customerNotifications);
    const updatedChannels = formToChannels(selectedChannel, {
      channels: selectedCustomer.channels,
      groups: groups.data,
    });

    yield call(post, {
      route: API.customerNotifications.saveNotifications,
      data: { ...selectedCustomer, channels: updatedChannels },
    });
    ToastNotification('success', `Configuration ${isDeleteContext ? 'deleted' : 'saved'} successfully.`);

    yield put(saveCustomerConfigurationStore({ loading: false }));
    yield put(toggleChannelOverlay(false));
    yield put(resetNotificationsForm());
    yield put(getAllCustomers());
  } catch (error) {
    showErrorMessage(error);
    yield put(saveCustomerConfigurationStore({ loading: false, error }));
  }
}

function* saveCustomerConfigurationSaga() {
  yield takeLatest(addPrefix(actionTypes.SAVE_CUSTOMER_CONFIGURATION_SAGA), saveCustomerConfigurationSagaCallback);
}

const sagas = [
  call(getAllCustomersSaga),
  call(getConfigurationOptionsSaga),
  call(getConfigurationGroupsSaga),
  call(saveCustomerConfigurationSaga),
];

export default sagas;
