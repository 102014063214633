import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isBlank, noop, stringSanitize } from '@finclusive/utils';
import * as R from 'ramda';
import { PageOverlay, Tabs, Row, Column, Input, Checkbox, ToastNotification } from 'components/library';
import { toggleGlobalRuleFormOverlay, updateGlobalRule } from '../../../../actions';
import { getTabsConfig, validateRule } from './helpers';
import Field from '../../../Field';
import { PARTIES, ALERT_SEVERITIES } from '../../../../../../constants';
import StyledRuleForm from './RuleForm.style';

const RuleForm = ({ onSave, actions, isVisible, rule, isLoading, formDisabled, triggerConfigs }) => {
  const tabsConfig = getTabsConfig(rule.alerts);
  const [activeTriggerConfigIndex, setActiveTriggerConfigIndex] = useState(0);

  useEffect(() => {
    setActiveTriggerConfigIndex(0);
  }, [rule.id]);

  const onTabClick = (activeTabIndex) => {
    const { alerts } = rule;

    if (isBlank(alerts) || isBlank(activeTabIndex)) return;
    const index = R.findIndex(({ entityType }) => `${entityType}` === tabsConfig[activeTabIndex].key)(alerts);
    setActiveTriggerConfigIndex(index);
  };

  const onChange = ({ name, value, alertId }) =>
    actions.updateGlobalRule({ key: name, value: stringSanitize(value), alertId });

  const onSubmit = (event, fields) => {
    event && event.preventDefault();
    const validationErrors = validateRule(rule, fields);
    if (!isBlank(validationErrors)) {
      ToastNotification('error', validationErrors);
      return;
    }
    onSave();
  };

  const getFieldConfiguration = (triggerConfig) => {
    if (isBlank(triggerConfig) || isBlank(triggerConfigs)) return {};
    const fieldsConfig = R.find(R.propEq('triggerTypeDiscriminator', triggerConfig.typeDiscriminator))(triggerConfigs);
    return R.path(['fields'], fieldsConfig);
  };

  const activeAlert = R.path(['alerts'], rule)[activeTriggerConfigIndex];
  const [activeValues, fields] = isBlank(activeAlert)
    ? [{}, {}]
    : [activeAlert.triggerConfig, getFieldConfiguration(activeAlert.triggerConfig)];
  const areConfigFieldsVisible = !isBlank(fields);

  return (
    <PageOverlay
      title="Edit AML Rule"
      visible={isVisible}
      togglePageOverlay={actions.toggleGlobalRuleFormOverlay}
      primaryButton={{
        label: 'Save',
        disabled: formDisabled,
        action: (event) => onSubmit(event, fields),
      }}
      form="ruleForm"
      spinnerVisible={isLoading}
    >
      <StyledRuleForm>
        <Row>
          <Column width={7} leftOffset={1} rightOffset={3}>
            <Input key="rule-name" name="name" label="AML Rule" value={rule.name} tooltip={rule.description} disabled />
            <Input
              type="select"
              key="rule-alert-severity"
              name="severity"
              label="Alert Severity"
              disabled={formDisabled}
              tooltip="Indicates the level of risk associated with the alert"
              value={`${rule.severity}`}
              onChange={({ target }) => onChange({ name: 'severity', value: parseFloat(target.value) })}
              required
            >
              {R.map((key) => {
                const { label } = ALERT_SEVERITIES[key];
                return (
                  <option key={key} value={key}>
                    {label}
                  </option>
                );
              }, R.keys(ALERT_SEVERITIES))}
            </Input>
            <Input
              key="rule-alert-message"
              name="alertMessage"
              label="Alert Message"
              disabled={formDisabled}
              tooltip="Displays additional information associated with the alert"
              value={rule.alertMessage}
              onChange={({ target }) => onChange({ name: 'alertMessage', value: target.value })}
              required
            />
            <Input type="checkboxGroup" label="Parties" tooltip="Sender and recipient for the transaction">
              <Checkbox
                label="Sender"
                name="isSender"
                onChange={noop}
                checked={R.includes(rule.parties, [PARTIES.sender.value, PARTIES.both.value])}
                disabled
              />
              <Checkbox
                label="Receiver"
                name="isReceiver"
                onChange={noop}
                checked={R.includes(rule.parties, [PARTIES.receiver.value, PARTIES.both.value])}
                disabled
              />
            </Input>
            {areConfigFieldsVisible && (
              <>
                {!isBlank(tabsConfig) && (
                  <Tabs className="rule-form__tabs" tabs={tabsConfig} setSelectedTab={onTabClick} />
                )}
                {fields.map((field) => {
                  const { name } = field;
                  return (
                    <Field
                      config={{
                        ...field,
                        required: true,
                        disabled: formDisabled,
                        tooltipIdentifier: rule.id,
                      }}
                      entityType={activeAlert.entityType}
                      value={activeValues[name]}
                      key={name}
                      onChange={(params) => onChange({ ...params, alertId: activeAlert.id })}
                    />
                  );
                })}
              </>
            )}
          </Column>
        </Row>
      </StyledRuleForm>
    </PageOverlay>
  );
};

RuleForm.defaultProps = {
  ruleId: '',
  rule: {
    name: '',
    description: '',
    parties: 0,
    alerts: [],
  },
  isVisible: false,
  formDisabled: true,
};

RuleForm.propTypes = {
  actions: PropTypes.shape({
    toggleGlobalRuleFormOverlay: PropTypes.func,
    updateGlobalRule: PropTypes.func,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ruleId: PropTypes.string,
  rule: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    parties: PropTypes.number.isRequired,
    alerts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  transactionMonitoring: {
    amlRules: {
      global: { form },
      triggerConfigs,
    },
  },
}) => {
  const { isOverlayVisible, rule, isLoading, ruleId } = form;

  return {
    isVisible: isOverlayVisible,
    rule,
    isLoading,
    ruleId,
    triggerConfigs: triggerConfigs.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      toggleGlobalRuleFormOverlay,
      updateGlobalRule,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);
