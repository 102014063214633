import { isBlank } from '@finclusive/utils';
import { isValidPhoneNumber } from 'react-phone-number-input';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const isNaturalNumber = (str) => /^(0|([1-9]\d*))$/.test(str);

export const emailCheck = (val) => !!val.match(emailRegex);

export const integerCheck = (value, min, max) => {
  const isMinEmpty = isBlank(min);
  const isMaxEmty = isBlank(max);
  const isInteger = Number.isInteger(parseFloat(value));
  const integerValue = parseInt(value, 10);
  if (!isInteger) return false;
  if (!isMinEmpty && integerValue < min) return false;
  if (!isMaxEmty && integerValue > max) return false;
  return true;
};

export const amountCheck = (value) => {
  if (isBlank(value)) return false;
  if (value === '$ ' || value.includes('-')) return false;
  return true;
};

export const urlCheck = (value) => !!value.match(urlRegex);

export const phoneCheck = (val) => !isBlank(val) && isValidPhoneNumber(val);
