import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from '@finclusive/utils';
import StyledSidebarSection from './SidebarSection.style';

const SidebarSection = ({ children, isCollapsed, bottomFloat, title, dataTest }) => {
  const withTitle = !isBlank(title);

  return (
    <StyledSidebarSection
      withTitle={withTitle}
      isCollapsed={isCollapsed}
      bottomFloat={bottomFloat}
      data-test={dataTest}
    >
      {withTitle && (
        <div className="section-title" data-test="sidebar-section-title">
          {title}
        </div>
      )}
      <div className="section-content">{children}</div>
    </StyledSidebarSection>
  );
};

SidebarSection.defaultProps = {
  children: null,
  isCollapsed: false,
  bottomFloat: false,
  title: '',
  dataTest: '',
};

SidebarSection.propTypes = {
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  bottomFloat: PropTypes.bool,
  title: PropTypes.string,
  dataTest: PropTypes.string,
};

export default SidebarSection;
