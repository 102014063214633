import { put, takeLatest, call, select } from 'redux-saga/effects';
import qs from 'qs';
import * as R from 'ramda';
import { filterEmptyKeys, isBlank } from '@finclusive/utils';
import { ToastNotification } from 'components/library';
import API from 'config/api';
import { get, putRequest, post, deleteRequest, showErrorMessage } from 'modules/utils';
import { ENTITIES } from 'modules/transactionMonitoring/constants';
import {
  getTriggerConfigsStart,
  getTriggerConfigsSuccess,
  getTriggerConfigsFail,
  getGlobalRules,
  getGlobalRulesFail,
  getGlobalRulesStart,
  getGlobalRulesSuccess,
  editGlobalRuleFail,
  editGlobalRuleStart,
  editGlobalRuleSuccess,
  getGlobalRuleDetailsFail,
  getGlobalRuleDetailsStart,
  getGlobalRuleDetailsSuccess,
  toggleGlobalRuleFormOverlay,
  getOverridenRulesStart,
  getOverridenRulesSuccess,
  getOverridenRulesFail,
  getOverridenEntityRulesStart,
  getOverridenEntityRulesSuccess,
  getOverridenEntityRulesFail,
  addOverridenEntityRuleStart,
  addOverridenEntityRuleSuccess,
  toggleApplyOverridenRulesFormOverlay,
  getOverridenRules,
  addOverridenEntityRuleFail,
  deleteOverridenEntityRuleStart,
  deleteOverridenEntityRuleSuccess,
  deleteOverridenEntityRuleFail,
  setActiveTab,
} from '.';
import { addPrefix } from '../config';
import actionTypes from './types';

function* getTriggerConfigsSagaCallback() {
  try {
    yield put(getTriggerConfigsStart());

    const result = yield call(get, API.transactionMonitoring.triggerConfig);

    yield put(getTriggerConfigsSuccess(result));
  } catch (error) {
    yield put(getTriggerConfigsFail());
    showErrorMessage(error);
  }
}

function* getTriggerConfigsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_TRIGGER_CONFIGS), getTriggerConfigsSagaCallback);
}

function* getGlobalRulesSagaCallback() {
  try {
    yield put(getGlobalRulesStart());

    const result = yield call(get, API.transactionMonitoring.globalRules);

    yield put(getGlobalRulesSuccess(result));
  } catch (error) {
    yield put(getGlobalRulesFail());
    showErrorMessage(error);
  }
}

function* getGlobalRulesSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_GLOBAL_RULES), getGlobalRulesSagaCallback);
}

function* editGlobalRuleSagaCallback() {
  try {
    yield put(editGlobalRuleStart());

    const rule = yield select((state) =>
      R.path(['transactionMonitoring', 'amlRules', 'global', 'form', 'rule'], state)
    );

    yield call(putRequest, {
      route: `${API.transactionMonitoring.globalRules}/${rule.id}`,
      data: rule,
    });

    yield put(editGlobalRuleSuccess());
    yield put(toggleGlobalRuleFormOverlay(false));
    yield put(getGlobalRules());
    ToastNotification('success', 'Rule updated successfully.');
  } catch (error) {
    yield put(editGlobalRuleFail());
    showErrorMessage(error);
  }
}

function* editGlobalRuleSaga() {
  yield takeLatest(addPrefix(actionTypes.EDIT_GLOBAL_RULE), editGlobalRuleSagaCallback);
}

function* getGlobalRuleDetailsSagaCallback({ payload: ruleId }) {
  try {
    yield put(getGlobalRuleDetailsStart());

    const result = yield call(get, `${API.transactionMonitoring.globalRules}/${ruleId}`);

    yield put(getGlobalRuleDetailsSuccess(result));
    yield put(toggleGlobalRuleFormOverlay(true));
  } catch (error) {
    yield put(getGlobalRuleDetailsFail());
    showErrorMessage(error);
  }
}

function* getGlobalRuleDetailsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_GLOBAL_RULE_DETAILS), getGlobalRuleDetailsSagaCallback);
}

function* getOverridenRulesSagaCallback({ payload: entityType }) {
  try {
    yield put(getOverridenRulesStart());

    const pageFilters = yield select((state) =>
      R.path(['transactionMonitoring', 'amlRules', 'overriden', 'list', 'pageFilters'], state)
    );
    const params = qs.stringify(
      filterEmptyKeys({ pageNo: pageFilters.pageNo, pageSize: pageFilters.pageSize, entityType })
    );

    const result = yield call(get, `${API.transactionMonitoring.overridenRulesPreview}?${params}`);

    yield put(getOverridenRulesSuccess(result));
  } catch (error) {
    yield put(getOverridenRulesFail());
  }
}

function* getOverridenRulesSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_OVERRIDEN_RULES), getOverridenRulesSagaCallback);
}

function* getOverridenEntityRulesSagaCallback({
  payload: {
    rule: { entityID, channel },
    entityType,
  },
}) {
  try {
    yield put(getOverridenEntityRulesStart());

    const params = qs.stringify(filterEmptyKeys({ entityId: entityID, channel, entityType }));

    const result = yield call(get, `${API.transactionMonitoring.overridenRulesConfigs}?${params}`);

    yield put(getOverridenEntityRulesSuccess(result.results));
  } catch (error) {
    yield put(getOverridenEntityRulesFail());
    showErrorMessage(error);
  }
}

function* getOverridenEntityRulesSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_OVERRIDEN_ENTITY_RULES), getOverridenEntityRulesSagaCallback);
}

function* addOverridenEntityRulesSagaCallback({ payload: isEdit }) {
  try {
    yield put(addOverridenEntityRuleStart());
    const ruleState = yield select((state) => R.path(['transactionMonitoring', 'amlRules'], state));
    const formData = R.path(['overriden', 'form'], ruleState);

    const { entityType, entityId, channel } = isEdit ? formData.configDetails : formData.data;

    const alertsToPush = R.map((rule) => {
      const alert = R.find((alert) => `${alert.entityType}` === `${entityType}`)(rule.alerts);
      return {
        ...alert,
        id: R.prop('id', alert) || undefined,
        entityType: parseFloat(entityType),
        entityID: entityId,
        channel: parseFloat(channel),
        ruleID: rule.id,
      };
    }, formData.rules);
    if (isEdit && !isBlank(formData.removedRules)) {
      yield call(deleteRequest, {
        route: API.transactionMonitoring.overridenRules,
        data: { overriddenAlertConfigs: formData.removedRules.map((alertConfigId) => ({ id: alertConfigId })) },
      });
    }

    yield call(post, {
      route: API.transactionMonitoring.overridenRules,
      data: { overriddenAlertConfigs: alertsToPush },
    });
    yield put(addOverridenEntityRuleSuccess());
    yield put(toggleApplyOverridenRulesFormOverlay(false));
    yield put(setActiveTab(ENTITIES[entityType].value));
    yield put(getOverridenRules(entityType));
    ToastNotification('success', `Custom Rule Config ${isEdit ? 'updated' : 'created'} successfully.`);
  } catch (error) {
    yield put(addOverridenEntityRuleFail());
    showErrorMessage(error);
  }
}

function* addOverridenEntityRulesSaga() {
  yield takeLatest(addPrefix(actionTypes.ADD_OVERRIDEN_ENTITY_RULES), addOverridenEntityRulesSagaCallback);
}

function* deleteOverridenEntityRulesSagaCallback({ payload: alertConfigIDs }) {
  try {
    yield put(deleteOverridenEntityRuleStart());
    const activeTab = yield select((state) => R.path(['transactionMonitoring', 'amlRules', 'activeTab'], state));
    const entityType = R.find((key) => ENTITIES[key].value === activeTab)(R.keys(ENTITIES));

    yield call(deleteRequest, {
      route: API.transactionMonitoring.overridenRules,
      data: { overriddenAlertConfigs: alertConfigIDs.map((alertConfigId) => ({ id: alertConfigId })) },
    });

    yield put(toggleApplyOverridenRulesFormOverlay(false));
    if (entityType) {
      yield put(getOverridenRules(entityType));
    }
    yield put(deleteOverridenEntityRuleSuccess());
    ToastNotification('success', 'Custom Rule Config deleted successfully.');
  } catch (error) {
    yield put(deleteOverridenEntityRuleFail());
    showErrorMessage(error);
  }
}

function* deleteOverridenEntityRulesSaga() {
  yield takeLatest(addPrefix(actionTypes.DELETE_OVERRIDEN_ENTITY_RULES), deleteOverridenEntityRulesSagaCallback);
}

const sagas = [
  call(getTriggerConfigsSaga),
  call(getGlobalRulesSaga),
  call(editGlobalRuleSaga),
  call(getGlobalRuleDetailsSaga),
  call(getOverridenRulesSaga),
  call(getOverridenEntityRulesSaga),
  call(addOverridenEntityRulesSaga),
  call(deleteOverridenEntityRulesSaga),
];

export default sagas;
