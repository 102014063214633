import types from '../types';

export const finalizeWorkItemPackageStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isFinalizingWorkItemPackage: true,
  },
});

export const finalizeWorkItemPackageSuccess = (state, { success }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isFinalizingWorkItemPackage: false,
  },
  success,
});

export const finalizeWorkItemPackageFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isFinalizingWorkItemPackage: false,
  },
  error,
});

export default {
  [types.FINALIZE_WORK_ITEM_PACKAGE_START]: finalizeWorkItemPackageStart,
  [types.FINALIZE_WORK_ITEM_PACKAGE_SUCCESS]: finalizeWorkItemPackageSuccess,
  [types.FINALIZE_WORK_ITEM_PACKAGE_FAIL]: finalizeWorkItemPackageFail,
};
