const initialState = {
  isLoading: false,
  isDetailsLoading: false,
  list: [],
  filter: {
    searchValue: '',
    pageNo: 1,
    pageSize: 10,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
  },
  selectedRow: {
    index: null,
    rowDetails: {},
  },
  internalUserDetails: {},
  isInternalPermissionsLoading: false,
  internalPermissions: [],
};

export default initialState;
