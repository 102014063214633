import React from 'react';
import PropTypes from 'prop-types';

const DetailHighlightItem = ({ className, name, value }) => (
  <div className={`detail-view__highlight-item ${className}`}>
    <div className="detail-view__highlight-name">{name}</div>
    <div className="detail-view__highlight-value">{value}</div>
  </div>
);

DetailHighlightItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.node,
  value: PropTypes.node,
};
DetailHighlightItem.defaultProps = {
  className: '',
};

export default DetailHighlightItem;
