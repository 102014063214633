import * as actions from './actions';
import eddReducer from './reducer';
import assignWorkItemSaga from './sagas/assign-work-item';
import assignWorkItemPackageSaga from './sagas/assign-work-item-package';
import finalizeWorkItemPackageSaga from './sagas/finalize-work-item-package';
import getWorkItemSaga from './sagas/get-work-item';
import getWorkItemPackageSaga from './sagas/get-work-item-package';
import getWorkItemPackagesSaga from './sagas/get-work-item-packages';
import updateActionItemSaga from './sagas/update-action-item';
import updateWorkItemPackageStatusSaga from './sagas/update-work-item-package-status';
import uploadDocumentSaga from './sagas/upload-document';
import getDocumentSaga from './sagas/get-document';
import getEDDReportSaga from './sagas/get-edd-report';

export default eddReducer;
export {
  actions,
  assignWorkItemSaga,
  assignWorkItemPackageSaga,
  finalizeWorkItemPackageSaga,
  getWorkItemSaga,
  getWorkItemPackageSaga,
  getWorkItemPackagesSaga,
  updateActionItemSaga,
  updateWorkItemPackageStatusSaga,
  uploadDocumentSaga,
  getDocumentSaga,
  getEDDReportSaga,
};
