import 'babel-polyfill';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.min.css';
import '@microsoft/applicationinsights-shims';
import 'assets/styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { ConnectedRouter } from 'connected-react-router';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ThemeProvider from 'modules/themeManager/components';
import userManager from 'modules/userManager/components/UserManager';
import {
  REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
  REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
} from 'env-config';
import store, { history } from 'config/store';
import App from './App';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    connectionString: REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    enableDebug: false,
    enableCorsCorrelation: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history,
      },
    },
  },
});

appInsights.loadAppInsights();

const telemetryInitializer = (envelope) => {
  envelope.tags['ai.cloud.role'] = 'Internal-Portal'; // eslint-disable-line no-param-reassign
};

appInsights.addTelemetryInitializer(telemetryInitializer);

smoothscroll.polyfill();

ReactDOM.render(
  <MsalProvider instance={userManager.msalInstance}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <Provider store={store}>
        <ThemeProvider>
          <ConnectedRouter basename="/" history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </Provider>
    </AppInsightsContext.Provider>
  </MsalProvider>,
  document.getElementById('root')
);
