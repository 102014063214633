const DATE_UTC_FORMAT = 'MMMM DD, YYYY HH:mm A UTC';
const PENDING_PAY_OPS_APPROVAL = 'PendingPayOpsApproval';
const SUBMIT_TO_BANK = 'Submit to bank';
const SUBMITTED_TO_BANK = 'SubmittedToBank';
const NOT_AVAIL = 'N/A';
const ROLL_BACK = 'Rollback';
const ROLLED_BACK = 'Rolledback';
const COMPLETED = 'Completed';
const ACCEPT = 'Accept';
const FAILED = 'Failed';

const INTERNATIONAL_WIRE = 'InternationalWire';
const DOMESTIC_WIRE = 'DomesticWire';
const TAB_DETAILS_TYPES = {
  transactionDetails: 'Transaction details',
  [DOMESTIC_WIRE]: 'US Wire details',
  [INTERNATIONAL_WIRE]: 'International Wire details',
};
const ORANGE_STATUS_TYPES = ['SubmittedToBank', 'PendingPayOpsApproval', 'Pending'];

export {
  DATE_UTC_FORMAT,
  PENDING_PAY_OPS_APPROVAL,
  DOMESTIC_WIRE,
  INTERNATIONAL_WIRE,
  TAB_DETAILS_TYPES,
  NOT_AVAIL,
  SUBMIT_TO_BANK,
  SUBMITTED_TO_BANK,
  ROLL_BACK,
  ROLLED_BACK,
  COMPLETED,
  ACCEPT,
  FAILED,
  ORANGE_STATUS_TYPES,
};
