import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isBlank } from '@finclusive/utils';
import { setItem, SSTORAGE_KEYS } from '../modules/utils';
import userManager from '../modules/userManager/components/UserManager';
import NotFound from './static/NotFound';
import ROUTE_PATHS from './routePaths';

const PrivateRoute = ({ component: Component, componentProps, userPermissions, permissions, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { location } = props;

      if (!userManager.isAuthenticated()) {
        const fromLocation = location.pathname;
        setItem(SSTORAGE_KEYS.fincPersistedRoutePath, fromLocation);
        return <Redirect to={{ pathname: ROUTE_PATHS.LOGIN, state: { from: fromLocation } }} />;
      }

      if (!isBlank(permissions)) {
        const isUnauthorized = !R.any(({ name }) => permissions.includes(name))(userPermissions);
        if (isUnauthorized) {
          return <NotFound {...props} {...componentProps} />;
        }
      }

      if (location.pathname === ROUTE_PATHS.LANDING) {
        return <Redirect exact to={ROUTE_PATHS.DASHBOARD} />;
      }

      return <Component {...props} {...componentProps} />;
    }}
  />
);

PrivateRoute.defaultProps = {
  componentProps: {},
};

PrivateRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  componentProps: PropTypes.shape({}),
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = ({ userManager }) => ({
  userPermissions: userManager.permissions,
});

export default connect(mapStateToProps)(PrivateRoute);
