import { createPrefixedAction } from '../config';
import actionTypes from './types';

const {
  GET_CUSTOMERS,
  GET_CUSTOMERS_STORE,
  SET_CUSTOMERS_PAGE_FILTERS,
  GET_CUSTOMER_ACUANT_PROFILES,
  GET_CUSTOMER_ACUANT_PROFILES_STORE,
  GET_CUSTOMER_CONFIGURATION,
  GET_CUSTOMER_CONFIGURATION_STORE,
  RESET_CUSTOMER_CONFIGURATION_STORE,
  SET_CUSTOMER_CONFIGURATION,
  SAVE_CUSTOMER_CONFIGURATION,
  SAVE_CUSTOMER_CONFIGURATION_STORE,
} = actionTypes;

export const getCustomers = createPrefixedAction(GET_CUSTOMERS);
export const getCustomersStore = createPrefixedAction(GET_CUSTOMERS_STORE);

export const setCustomersPageFilters = createPrefixedAction(SET_CUSTOMERS_PAGE_FILTERS);

export const getCustomerAcuantProfiles = createPrefixedAction(GET_CUSTOMER_ACUANT_PROFILES);
export const getCustomerAcuantProfilesStore = createPrefixedAction(
  GET_CUSTOMER_ACUANT_PROFILES_STORE
);

export const getCustomerConfiguration = createPrefixedAction(GET_CUSTOMER_CONFIGURATION);
export const getCustomerConfigurationStore = createPrefixedAction(GET_CUSTOMER_CONFIGURATION_STORE);

export const resetCustomerConfigurationStore = createPrefixedAction(
  RESET_CUSTOMER_CONFIGURATION_STORE
);
export const setCustomerConfiguration = createPrefixedAction(SET_CUSTOMER_CONFIGURATION);

export const saveCustomerConfiguration = createPrefixedAction(SAVE_CUSTOMER_CONFIGURATION);
export const saveCustomerConfigurationStore = createPrefixedAction(
  SAVE_CUSTOMER_CONFIGURATION_STORE
);
