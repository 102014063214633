import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import initialState from './initialState';
import {
  getCustomerAcuantProfilesStore,
  getCustomerConfigurationStore,
  getCustomersStore,
  resetCustomerConfigurationStore,
  saveCustomerConfigurationStore,
  setCustomerConfiguration,
  setCustomersPageFilters,
} from '../actions';

const setCustomersPageFiltersHandler = [
  setCustomersPageFilters,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      list: {
        ...state.list,
        pageFilters: payload,
      },
    };
  },
];

const getCustomersStoreHandler = [
  getCustomersStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      list: {
        ...state.list,
        isLoading: false,
        data: R.omit(['results'], payload),
        results: R.path(['results'], payload),
      },
    };
  },
];

const getCustomerAcuantProfilesStoreHandler = [
  getCustomerAcuantProfilesStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        customer: {
          ...state.customer,
          acuantProfiles: {
            ...R.clone(initialState.customer.acuantProfiles),
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        customer: {
          ...state.customer,
          acuantProfiles: {
            ...R.clone(initialState.customer.acuantProfiles),
            isLoading: true,
            error,
          },
        },
      };
    }

    return {
      ...state,
      customer: {
        ...state.customer,
        acuantProfiles: {
          ...state.customer.acuantProfiles,
          isLoading: false,
          data: payload,
        },
      },
    };
  },
];

const getCustomerConfigurationStoreHandler = [
  getCustomerConfigurationStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        customer: {
          ...state.customer,
          configuration: {
            ...R.clone(initialState.customer.configuration),
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        customer: {
          ...state.customer,
          configuration: {
            ...R.clone(initialState.customer.configuration),
            isLoading: true,
            error,
          },
        },
      };
    }
    const configuration = R.prop('profiles', payload);
    return {
      ...state,
      customer: {
        ...state.customer,
        customerName: R.prop('customerName', payload),
        configuration: {
          ...state.customer.configuration,
          isLoading: false,
          data: configuration,
          initialConfiguration: configuration,
        },
      },
    };
  },
];

const resetCustomerConfigurationStoreHandler = [
  resetCustomerConfigurationStore,
  (state) => {
    return {
      ...state,
      customer: R.clone(initialState.customer),
    };
  },
];

const setCustomerConfigurationHandler = [
  setCustomerConfiguration,
  (state, action) => {
    const { payload: profile } = action;
    const updatedConfiguration = [
      ...state.customer.configuration.data.filter((it) => it.profileType !== profile.profileType),
      { ...profile },
    ];
    return {
      ...state,
      customer: {
        ...state.customer,
        configuration: {
          ...state.customer.configuration,
          data: updatedConfiguration,
        },
      },
    };
  },
];

const saveCustomerConfigurationStoreHandler = [
  saveCustomerConfigurationStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        customer: {
          ...state.customer,
          configuration: {
            ...state.customer.configuration,
            isSaveLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        customer: {
          ...state.customer,
          configuration: {
            ...state.customer.configuration,
            isSaveLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      customer: {
        ...state.customer,
        configuration: {
          ...state.customer.configuration,
          isSaveLoading: false,
        },
      },
    };
  },
];

const reducer = handleActions(
  new Map([
    setCustomersPageFiltersHandler,
    getCustomersStoreHandler,
    getCustomerAcuantProfilesStoreHandler,
    getCustomerConfigurationStoreHandler,
    resetCustomerConfigurationStoreHandler,
    setCustomerConfigurationHandler,
    saveCustomerConfigurationStoreHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
