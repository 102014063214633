import types from '../types';

export const updateWireStart = () => ({
  type: types.UPDATE_WIRE_START,
});

export const updateWire = (payload, transactionMethod) => ({
  type: types.UPDATE_WIRE,
  payload,
  transactionMethod,
});

export const updateWireSuccess = (data) => ({
  type: types.UPDATE_WIRE_SUCCESS,
  data,
});

export const updateWireFail = (err) => ({
  type: types.UPDATE_WIRE_FAIL,
  err,
});
