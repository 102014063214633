const EMPTY_DESTINATION = {
  address: {
    name: '',
    address: '',
  },
  isPrivate: false,
  isOptional: false,
};

export default EMPTY_DESTINATION;
