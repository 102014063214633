export const PAGE_LIMITS = [10, 25, 50];

export const COLUMNS_KEYS = {
  customerName: 'customerName',
  finClusiveId: 'finClusiveId',
  amount: 'amount',
  appliedAMLRule: 'appliedAMLRule',
  channel: 'channel',
  edit: 'edit',
  delete: 'delete',
};

export const COLUMNS_CONFIG = [
  {
    columnKey: COLUMNS_KEYS.appliedAMLRule,
    label: 'Applied AML Rule',
  },
  {
    columnKey: COLUMNS_KEYS.channel,
    label: 'Channel',
  },
  {
    columnKey: COLUMNS_KEYS.edit,
    label: '',
  },
  {
    columnKey: COLUMNS_KEYS.edit,
    label: '',
    className: 'rule-table-icon',
  },
  {
    columnKey: COLUMNS_KEYS.delete,
    label: '',
    className: 'rule-table-icon',
  },
];
