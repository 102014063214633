import { call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_EDD_REPORT } from 'env-config';
import { get, showErrorMessage } from 'modules/utils';
import { getEDDReportFail, getEDDReportStart, getEDDReportSuccess } from '../actions/get-edd-report';
import types from '../types';

export const httpCall = (workItemPackageId) =>
  get({
    route: `${REACT_APP_EDD_REPORT}/${workItemPackageId}`,
    responseType: 'blob',
  });

export function* getEDDReport({ workItemPackageId, name }) {
  try {
    yield put(getEDDReportStart());

    const result = yield call(httpCall, workItemPackageId);

    /* Open the save file window */
    const fileName = `Enhanced Due Diligence Report - ${name}.docx`;
    const docUrl = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement('a');
    link.href = docUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    yield put(getEDDReportSuccess({ result }));
  } catch (error) {
    yield put(getEDDReportFail(error));
    showErrorMessage(error);
  }
}

export default function* getEDDReportSaga() {
  yield takeLatest(types.GET_EDD_REPORT, getEDDReport);
}
