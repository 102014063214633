import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import qs from 'qs';
import { filterEmptyKeys } from '@finclusive/utils';
import { get, post, showErrorMessage } from 'modules/utils';
import API from 'config/api';
import { ToastNotification } from 'components/library';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  getCustomerAcuantProfilesStore,
  getCustomerConfigurationStore,
  getCustomersStore,
  saveCustomerConfigurationStore,
  getCustomerConfiguration,
} from '.';
import { getProfilesToSave } from '../components/CDDMonitoringProfile/helpers';

function* getCustomersSagaCallback({ payload: filterValue }) {
  try {
    yield put(getCustomersStore({ loading: true }));

    const { pageNo, pageSize } = yield select((state) => R.path(['cddMonitoring', 'list', 'pageFilters'], state));

    const params = qs.stringify(
      filterEmptyKeys({
        filterValue,
        pageNo,
        pageSize,
      })
    );

    const result = yield call(get, API.cddMonitoring.customersList(params));
    yield put(getCustomersStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getCustomersStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* getCddMonitoringCustomersSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMERS), getCustomersSagaCallback);
}

function* getCustomerAcuantProfilesSagaCallback({ payload: customerFinClusiveId }) {
  try {
    yield put(getCustomerAcuantProfilesStore({ loading: true }));
    const result = yield call(get, API.cddMonitoring.acuantProfiles(customerFinClusiveId));
    yield put(getCustomerAcuantProfilesStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getCustomerAcuantProfilesStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* getCustomerAcuantProfilesSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_ACUANT_PROFILES), getCustomerAcuantProfilesSagaCallback);
}

function* getCustomerCddConfigurationSagaCallback({ payload: customerFinClusiveId }) {
  try {
    yield put(getCustomerConfigurationStore({ loading: true }));
    const result = yield call(get, API.cddMonitoring.customerConfiguration(customerFinClusiveId));
    yield put(getCustomerConfigurationStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getCustomerConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* getCustomerCddConfigurationSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_CONFIGURATION), getCustomerCddConfigurationSagaCallback);
}

function* saveCustomerCddConfigurationSagaCallback({ payload: customerFinClusiveId }) {
  try {
    yield put(saveCustomerConfigurationStore({ loading: true }));

    const configurationState = yield select((state) => R.path(['cddMonitoring', 'customer', 'configuration'], state));
    const profiles = getProfilesToSave(
      R.prop('data', configurationState),
      R.prop('initialConfiguration', configurationState)
    );
    yield call(post, {
      route: API.cddMonitoring.customerConfiguration(customerFinClusiveId),
      data: {
        profiles,
      },
    });

    yield put(saveCustomerConfigurationStore({ loading: false }));
    ToastNotification('success', 'Customer Configuration saved successfully.');
    yield put(getCustomerConfiguration(customerFinClusiveId));
  } catch (error) {
    yield put(saveCustomerConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* saveCustomerCddConfigurationSaga() {
  yield takeLatest(addPrefix(actionTypes.SAVE_CUSTOMER_CONFIGURATION), saveCustomerCddConfigurationSagaCallback);
}

const sagas = [
  call(getCddMonitoringCustomersSaga),
  call(getCustomerAcuantProfilesSaga),
  call(getCustomerCddConfigurationSaga),
  call(saveCustomerCddConfigurationSaga),
];

export default sagas;
