import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ id, className, name, defaultValue, placeholder, rows, onChange }) => (
  <textarea
    className={`input--textarea ${className}`}
    onChange={onChange}
    value={defaultValue}
    id={id}
    name={name}
    rows={rows}
    placeholder={placeholder}
  />
);

TextArea.defaultProps = {
  id: '',
  className: '',
  name: '',
  defaultValue: '',
  placeholder: '',
  rows: '',
  onChange: () => {},
};

TextArea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
};

export default TextArea;
