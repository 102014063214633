import { combineReducers } from 'redux';

import vendorsReducer from '../components/Vendors/reducer';
import defaultPlansReducer from '../components/DefaultPlans/reducer';
import customerPlansReducer from '../components/CustomerPlans/reducer';

export default combineReducers({
  vendors: vendorsReducer,
  defaultPlans: defaultPlansReducer,
  customerPlans: customerPlansReducer,
});
