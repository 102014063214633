import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ type, name, className, onClick }) => (
  <i
    className={`${type === 'fa' ? 'fa fa-' : 'mdi mdi-'}${name} icon ${className}`}
    onClick={onClick || undefined}
  />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  type: '',
};

export default Icon;
