import { call, put, takeLatest } from 'redux-saga/effects';
import { ToastNotification } from 'components/library';
import { REACT_APP_ASSIGN_WORK_ITEM_PACKAGE as ENDPOINT } from 'env-config';
import { post } from 'modules/utils';
import {
  assignWorkItemPackageFail,
  assignWorkItemPackageStart,
  assignWorkItemPackageSuccess,
} from '../actions/assign-work-item-package';
import types from '../types';
import { getWorkItemPackages } from './get-work-item-packages';
import { getWorkItemPackage } from './get-work-item-package';

export const httpCall = (data) => post({ route: ENDPOINT, data });

export function* assignWorkItemPackage({ workItemPackageID, dataAnalystID, paginationInfo }) {
  try {
    yield put(assignWorkItemPackageStart());

    const result = yield call(httpCall, { workItemPackageID, dataAnalystID });

    yield put(assignWorkItemPackageSuccess(result));
    yield getWorkItemPackage({ workItemPackageID });
    yield getWorkItemPackages(paginationInfo);
    ToastNotification('success', 'You have successfully claimed the work item');
  } catch (error) {
    yield put(assignWorkItemPackageFail(error));
    ToastNotification('error', error.message || 'There was an error trying to assign work item');
  }
}

export default function* assignWorkItemPackageSaga() {
  yield takeLatest(types.ASSIGN_WORK_ITEM_PACKAGE, assignWorkItemPackage);
}
