import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNotificationConfigurationStore, saveNotificationConfigurationStore } from '.';
import { hideDialog } from '../../../../../actions/global';
import { ToastNotification } from '../../../../../components/library';
import API from '../../../../../config/api';
import { get, patch } from '../../../../utils/axios-wrapper';
import { showErrorMessage } from '../../../../utils/saga-errors';
import { addPrefix } from '../config';
import actionTypes from './types';

function* getNotificationConfigurationSagaCallback() {
  try {
    yield put(getNotificationConfigurationStore({ loading: true }));

    const result = yield call(get, API.transactionMonitoring.notification.internalConfig);

    yield put(getNotificationConfigurationStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getNotificationConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* getTMInternalNotificationConfigurationSaga() {
  yield takeLatest(
    addPrefix(actionTypes.GET_NOTIFICATION_CONFIGURATION_SAGA),
    getNotificationConfigurationSagaCallback
  );
}

function* saveNotificationConfigurationSagaCallback() {
  try {
    yield put(saveNotificationConfigurationStore({ loading: true }));

    const configuration = yield select((state) =>
      R.path(['transactionMonitoring', 'internalNotificationConfigurator', 'configuration'], state)
    );

    yield call(patch, { route: API.transactionMonitoring.notification.internalConfig, data: configuration });

    yield put(saveNotificationConfigurationStore({ loading: false }));
    yield put(hideDialog());
    ToastNotification(
      'success',
      'The notification configuration was updated successfully.',
      'Transaction Monitoring - Internal Notifications'
    );
  } catch (error) {
    yield put(saveNotificationConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* saveTMInternalNotificationConfigurationSaga() {
  yield takeLatest(
    addPrefix(actionTypes.SAVE_NOTIFICATION_CONFIGURATION_SAGA),
    saveNotificationConfigurationSagaCallback
  );
}

const sagas = [call(getTMInternalNotificationConfigurationSaga), call(saveTMInternalNotificationConfigurationSaga)];

export default sagas;
