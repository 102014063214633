import types from '../types';

export const updateWorkItemPackageStatusStart = () => ({
  type: types.UPDATE_WORK_ITEM_PACKAGE_STATUS_START,
});

export const updateWorkItemPackageStatus = (workItemPackageID, desiredStatus, paginationInfo) => ({
  type: types.UPDATE_WORK_ITEM_PACKAGE_STATUS,
  workItemPackageID,
  desiredStatus,
  paginationInfo,
});

export const updateWorkItemPackageStatusSuccess = (data) => ({
  type: types.UPDATE_WORK_ITEM_PACKAGE_STATUS_SUCCESS,
  data,
});

export const updateWorkItemPackageStatusFail = (error) => ({
  type: types.UPDATE_WORK_ITEM_PACKAGE_STATUS_FAIL,
  error,
});
