import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../index';

export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inFocus: false,
    };
  }

  onFocus = () => this.setState({ inFocus: true });

  onBlur = () => this.setState({ inFocus: false });

  render() {
    const { className, label, checked, onChange, required, name, alignLeft, tooltip, disabled } = this.props;
    const { inFocus } = this.state;

    return (
      <div className={`checkbox ${className} ${disabled ? 'checkbox--disabled' : ''}`}>
        <label className="checkbox__label-container">
          {!alignLeft && label && <div className="checkbox__label">{label}</div>}
          <div className="checkbox__input-container">
            <input
              className="checkbox__input"
              type="checkbox"
              checked={checked}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              onChange={onChange}
              required={required}
              name={name}
              id={name}
            />
            <span
              className={`checkbox__input-fake-checkbox 
                ${checked ? 'checkbox__input-fake-checkbox--checked' : ''} 
                ${inFocus ? 'checkbox__input-fake-checkbox--focus' : ''}`}
            />
            {tooltip && <Tooltip tooltip={tooltip} identifier={name} />}
          </div>
          {alignLeft && label && <div className="checkbox__label checkbox__label--right">{label}</div>}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  alignLeft: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Checkbox.defaultProps = {
  className: '',
  required: false,
  alignLeft: false,
  checked: false,
  disabled: false,
};
