import types from '../types';

export const settleTransactionStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: true,
  },
});

export const settleTransactionSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
});

export const settleTransactionFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
  error,
});

export default {
  [types.SETTLE_TRANSACTION_START]: settleTransactionStart,
  [types.SETTLE_TRANSACTION_SUCCESS]: settleTransactionSuccess,
  [types.SETTLE_TRANSACTION_FAIL]: settleTransactionFail,
};
