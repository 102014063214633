const initialState = {
  list: {
    isLoading: false,
    data: {},
    results: [],
    pageFilters: {
      pageNo: 1,
      pageSize: 10,
    },
  },
  customer: {
    customerName: '',
    acuantProfiles: {
      isLoading: false,
      data: [],
      error: null,
    },
    configuration: {
      isLoading: false,
      isSaveLoading: false,
      data: [],
      initialConfiguration: [],
      error: null,
    },
  },
};

export default initialState;
