import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TableWithPagination } from 'components/library';
import { PAGE_LIMITS } from './constants';
import StyledOverridenRules from './OverridenRules.style';
import { getColumnConfig, getTableData } from './helpers';
import { setOverridenRulesPageData, getOverridenRules, getOverridenEntityRules } from '../../actions';

const OverridenRules = ({ entityType, pageData, rules, actions, isLoading, onRuleDelete, canEditAMLRules }) => {
  const getPagedData = useCallback(
    (pageNo, pageSize) => {
      actions.setOverridenRulesPageData({ pageNo, pageSize });
      actions.getOverridenRules(entityType);
    },
    [entityType, actions]
  );

  useEffect(() => {
    getPagedData(1, PAGE_LIMITS[0]);
  }, [getPagedData]);

  const onRuleClick = (rule) => {
    actions.getOverridenEntityRules({
      entityType,
      rule,
    });
  };
  const columnConfig = getColumnConfig(entityType, canEditAMLRules);
  const tableData = getTableData(pageData, rules, onRuleClick, onRuleDelete);
  return (
    <StyledOverridenRules>
      <TableWithPagination
        data={tableData}
        columns={columnConfig}
        pageLimits={PAGE_LIMITS}
        getPagedData={getPagedData}
        selectedPage={pageData.currentPage}
        isLoading={isLoading}
      />
    </StyledOverridenRules>
  );
};

OverridenRules.defaultProps = {
  rules: [],
  pageData: {},
};

OverridenRules.propTypes = {
  entityType: PropTypes.number.isRequired,
  pageData: PropTypes.shape({}),
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      entityName: PropTypes.string,
      entityID: PropTypes.string,
      ruleNames: PropTypes.arrayOf(PropTypes.string),
      channel: PropTypes.number,
    })
  ),
  actions: PropTypes.shape({
    setOverridenRulesPageData: PropTypes.func,
    getOverridenRules: PropTypes.func,
    getOverridenEntityRules: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRuleDelete: PropTypes.func.isRequired,
  canEditAMLRules: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  transactionMonitoring: {
    amlRules: { overriden },
  },
}) => {
  return {
    rules: overriden.list.data,
    pageData: overriden.list.pageData,
    isLoading: overriden.list.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setOverridenRulesPageData,
      getOverridenRules,
      getOverridenEntityRules,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverridenRules);
