import * as R from 'ramda';
import qs from 'qs';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { filterEmptyKeys } from '@finclusive/utils';
import { ToastNotification } from 'components/library';
import API from 'config/api';
import { get, post, putRequest, deleteRequest } from 'modules/utils';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  toggleCustomerPlansOverlay,
  toggleVendorPricingOverlay,
  getCustomersPlans,
  getCustomersPlansStore,
  getCustomerPlanStore,
  createCustomerPlanStore,
  updateCustomerPlanStore,
  getCustomerVendors,
  getCustomerVendorsStore,
  getCustomerVendorPlanStore,
  saveCustomerVendorPlanStore,
  deleteCustomerVendorPlanStore,
} from '.';

function* getCustomersPlansSagaCallback() {
  try {
    yield put(getCustomersPlansStore({ loading: true }));
    const customerPlans = yield select((state) => R.path(['billing', 'customerPlans'], state));
    const pageFilters = R.path(['pageFilters'], customerPlans);
    const searchValue = R.path(['searchFilters', 'searchValue'], customerPlans);
    const filterBy = R.path(['searchFilters', 'filterOption', 'value'], customerPlans);
    const params = qs.stringify(
      filterEmptyKeys({
        ...pageFilters,
        filterValue: searchValue,
        filterBy,
      })
    );
    const response = yield call(get, `${API.billing.customerPlans.customers}?${params}`);
    yield put(getCustomersPlansStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getCustomersPlansStore({ loading: true, error }));
    ToastNotification('error', error || 'There was an error trying to get the customers plans.');
  }
}

function* getCustomersPlansSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMERS_PLANS_SAGA), getCustomersPlansSagaCallback);
}

function* getCustomerPlanSagaCallback({ payload }) {
  try {
    yield put(getCustomerPlanStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    const result = yield call(get, {
      route: API.billing.customerPlans.customer(customerFinClusiveID),
      data: payload,
    });

    yield put(getCustomerPlanStore({ loading: false, payload: result }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getCustomerPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to get the customer plan.');
  }
}

function* getCustomerPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_PLAN_SAGA), getCustomerPlanSagaCallback);
}

function* createCustomerPlanSagaCallback({ payload }) {
  try {
    yield put(createCustomerPlanStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    yield call(post, {
      route: API.billing.customerPlans.customer(customerFinClusiveID),
      data: payload,
    });

    yield put(createCustomerPlanStore({ loading: false }));
    yield put(getCustomersPlans());
    ToastNotification('success', 'Customer plan successfully created');
    yield put(toggleCustomerPlansOverlay(false));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(createCustomerPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to create the customer plan.');
  }
}

function* createCustomerPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.SET_CUSTOMER_PLAN_SAGA), createCustomerPlanSagaCallback);
}

function* updateCustomerPlanSagaCallback({ payload }) {
  try {
    yield put(updateCustomerPlanStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    yield call(putRequest, {
      route: API.billing.customerPlans.customer(customerFinClusiveID),
      data: payload,
    });

    yield put(updateCustomerPlanStore({ loading: false }));
    ToastNotification('success', 'Customer plan successfully updated');
    yield put(toggleCustomerPlansOverlay(false));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(updateCustomerPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to update the customer plan.');
  }
}

function* updateCustomerPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.UPDATE_CUSTOMER_PLAN_SAGA), updateCustomerPlanSagaCallback);
}

function* getCustomerVendorsSagaCallback() {
  try {
    yield put(getCustomerVendorsStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    const response = yield call(get, API.billing.customerPlans.thirdParties(customerFinClusiveID));

    yield put(getCustomerVendorsStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getCustomerVendorsStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to get the customer vendors.');
  }
}

function* getCustomerVendorsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_VENDORS_SAGA), getCustomerVendorsSagaCallback);
}

function* getCustomerVendorPlanSagaCallback({ payload: vendor }) {
  try {
    yield put(getCustomerVendorPlanStore({ loading: true, payload: vendor }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    const { hasPlanAssigned, planType } = vendor;
    const defaultPath = API.billing.defaultPlans.thirdParty(planType);
    const customerPath = API.billing.customerPlans.thirdParty(planType, customerFinClusiveID);
    const response = yield call(get, {
      route: hasPlanAssigned ? customerPath : defaultPath,
    });

    yield put(getCustomerVendorPlanStore({ loading: false, payload: { data: response, hasPlanAssigned } }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getCustomerVendorPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to retrieve the details for this vendor.');
  }
}

function* getCustomerVendorPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_VENDOR_PLAN_SAGA), getCustomerVendorPlanSagaCallback);
}

function* saveCustomerVendorPlanSagaCallback() {
  try {
    yield put(saveCustomerVendorPlanStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    const { hasPlanAssigned, planType, thirdPartySKUsMetadata } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'vendors', 'details', 'data'], state)
    );
    const method = hasPlanAssigned ? putRequest : post;
    yield call(method, {
      route: API.billing.customerPlans.thirdParty(planType, customerFinClusiveID),
      data: {
        thirdPartySKUs: thirdPartySKUsMetadata,
      },
    });

    yield put(saveCustomerVendorPlanStore({ loading: false }));
    yield put(toggleVendorPricingOverlay(false));
    yield put(getCustomerVendors());
    ToastNotification('success', 'Customer vendor skus updated successfully.');
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(saveCustomerVendorPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to update the skus for this customer vendor.');
  }
}

function* saveCustomerVendorPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.SAVE_CUSTOMER_VENDOR_PLAN_SAGA), saveCustomerVendorPlanSagaCallback);
}

function* deleteCustomerVendorPlanSagaCallback({ payload: thirdPartyId }) {
  try {
    yield put(deleteCustomerVendorPlanStore({ loading: true }));

    const { customerFinClusiveID } = yield select((state) =>
      R.path(['billing', 'customerPlans', 'planOverlay', 'customer'], state)
    );
    const response = yield call(deleteRequest, {
      route: API.billing.customerPlans.thirdParty(thirdPartyId, customerFinClusiveID),
    });

    yield put(deleteCustomerVendorPlanStore({ loading: false, payload: response }));
    ToastNotification('success', 'Customer vendor plan successfully deleted');
    yield put(toggleVendorPricingOverlay(false));
    yield put(getCustomerVendors());
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(deleteCustomerVendorPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to delete the customer vendor plan.');
  }
}

function* deleteCustomerVendorPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.DELETE_CUSTOMER_VENDOR_PLAN_SAGA), deleteCustomerVendorPlanSagaCallback);
}

export const customerPlansSagas = [
  call(getCustomersPlansSaga),
  call(getCustomerPlanSaga),
  call(createCustomerPlanSaga),
  call(updateCustomerPlanSaga),
  call(getCustomerVendorsSaga),
  call(getCustomerVendorPlanSaga),
  call(saveCustomerVendorPlanSaga),
  call(deleteCustomerVendorPlanSaga),
];
