const ROUTE_PATHS = {
  LOGIN: '/',
  LOGIN_REDIRECT: '/aad-callback',
  LOGOUT: '/logout',

  LANDING: '/',
  DASHBOARD: '/dashboard',
  REGISTRATION: '/registration',
  RESOURCES: '/resources',
  FEEDBACK: '/feedback',
  WIRED_TRANSACTIONS: '/payOps/transactions',
  STELLAR_TRANSACTIONS: '/tradeOps/stellar/transactions',
  MANAGEMENT_LIMITS: '/tradeOps/management/limits',
  STELLAR_REPORT: '/tradeOps/stellar/report',
  TRANSACTION_MONITORING_TRANSACTIONS: '/transaction-monitoring/transactions',
  TRANSACTION_MONITORING_AML_RULES: '/transaction-monitoring/aml-rules',
  CDD_MONITORING: '/cdd-monitoring',
  CDD_MONITORING_CONFIGURATION: '/cdd-monitoring/configuration',
  BILLING_CUSTOMERS: '/billing/customers',
  BILLING_DEFAULT_PLANS: '/billing/default-plans',
  BILLING_ACUANT_DEFAULT: '/billing/acuant-default',
  CUSTOMER_NOTIFICATIONS: '/customer-notifications',
  CREATE_TRANSACTION: '/create-transaction',
  INTERNAL_USER_MANAGEMENT: '/internal-users',
  INTERNAL_USER_MANAGEMENT_EDIT: '/internal-users/:objectId',

  ERROR: '/error',
};

export default ROUTE_PATHS;
