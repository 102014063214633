import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isBlank } from '@finclusive/utils';
import { getUserData } from 'modules/userManager/actions';
import userManager from 'modules/userManager/components/UserManager';
import { Spinner } from 'components/library';

const withAuthentication = (WrappedComponent) => {
  if (isBlank(WrappedComponent)) {
    throw new Error('No component provided to HOC');
  }

  const AuthenticationWrapper = ({ isLoading, actions, ...restProps }) => {
    const handleGetUserData = useCallback(async () => {
      await userManager.handleRedirectPromise();
      const isAuthenticated = userManager.isAuthenticated();
      if (isAuthenticated) {
        actions.getUserData();
      }
    }, [actions]);

    useEffect(() => {
      handleGetUserData();
    }, [handleGetUserData]);

    if (isLoading) {
      return <Spinner visible subLoader={false} />;
    }

    return <WrappedComponent {...restProps} />;
  };

  AuthenticationWrapper.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      getUserData: PropTypes.func,
    }).isRequired,
  };

  const mapStateToProps = ({ userManager }) => ({
    isLoading: userManager.isLoading,
  });

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        getUserData,
      },
      dispatch
    ),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper);
};

export default withAuthentication;
