const AML_RULES_PERMISSIONS = {
  VIEW_AML_RULES: 'internal-amlrulesconfig-read',
  EDIT_AML_RULES: 'internal-amlrulesconfig-edit',
  VIEW_AML_TRANSACTIONS: 'internal-amltransaction-read',
  EDIT_AML_TRANSACTIONS: 'internal-amltransaction-edit',
  VIEW_AML_ALERTS: 'internal-amlalert-edit',
  EDIT_AML_ALERTS: 'internal-amlalert-read',
};

const TM_NOTIFICATIONS_PERMISSIONS = {
  VIEW_TM_NOTIFICATIONS: 'tm-internal-notificationconfig-read',
  EDIT_TM_NOTIFICATIONS: 'tm-internal-notificationconfig-edit',
};

const BILLING_PERMISSIONS = {
  VIEW_BILLING: 'internal-billingsku-read',
  EDIT_BILLING: 'internal-billingsku-edit',
};

const CDD_MONITORING_PERMISSIONS = {
  VIEW_CDD_MONITORING: 'internal-cddprofileconfiguration-read',
  EDIT_CDD_MONITORING: 'internal-cddprofileconfiguration-edit',
};

const CUSTOMER_NOTIFICATIONS_PERMISSIONS = {
  VIEW_CUSTOMER_NOTIFICATIONS: 'notification-configuration-admin-read',
  EDIT_CUSTOMER_NOTIFICATIONS: 'notification-configuration-admin-write',
  VIEW_CUSTOMER_NOTIFICATIONS_TEMPLATE_OPTIONS: 'notification-template-options-read',
  EDIT_CUSTOMER_NOTIFICATIONS_TEMPLATE_OPTIONS: 'notification-template-write',
};

const OTC_TRADE_TRANSACTION_PERMISSIONS = {
  VIEW_OTC_TRADE_TRANSACTION: 'tradeops-otctrade-transaction-read',
  EDIT_OTC_TRADE_TRANSACTION: 'tradeops-otctrade-transaction-otctradedata-edit',
  ROLLBACK_OTC_TRADE_TRANSACTION: 'tradeops-otctrade-transaction-rollback',
  CONTINUE_OTC_TRADE_TRANSACTION: 'tradeops-otctrade-transaction-completetrade',
};

const REGISTRATION_PERMISSIONS = {
  VIEW_APPLICATION_REGISTRATION: 'registration-application-read',
  EDIT_APPLICATION_REGISTRATION: 'registration-application-write',
  APPROVE_APPLICATION_REGISTRATION: 'registration-application-approve',
  VIEW_PROSPECT_REGISTRATION: 'registration-prospect-read',
  EDIT_PROSPECT_REGISTRATION: 'registration-prospect-write',
};

const TRANSACTIONS_LIMITS_PERMISSIONS = {
  VIEW_TRANSACTION_LIMITS_CONFIG: 'tradeops-overalltransactionlimitsconfig-read',
  EDIT_TRANSACTION_LIMITS_CONFIG: 'tradeops-overalltransactionlimitsconfig-edit',
};

const US_WIRE_TRANSACTIONS_PERMISSIONS = {
  VIEW_US_WIRES_TRANSACTIONS: 'payops-uswires-transaction-read',
  EDIT_US_WIRES_TRANSACTIONS: 'payops-uswires-transaction-edit',
};

const STELLAR_TRANSACTIONS_PERMISSIONS = {
  VIEW_STELLAR_TRANSACTIONS_ONLY: 'tradeops-multidaysettlement-transaction-read',
  ROLLBACK_STELLAR_TRANSACTIONS_ONLY: 'tradeops-multidaysettlement-transaction-rollback',
  CONTINUE_STELLAR_TRANSACTIONS_ONLY: 'tradeops-multidaysettlement-transaction-continue',
  VIEW_GENERATE_REPORT_VIEW: 'tradeops-stellar-report-generate',
};

const PAYOPS_CREATE_TRANSACTION_PERMISSIONS = {
  VIEW_PAYOPS_ACCOUNT: 'payops-account-read',
  EDIT_PAYOPS_TRANSACTION: 'payops-transaction-external-create',
};

export const PERMISSION_TYPES = {
  ADMINISTRATIVE_ACCESS: 'ui-administrativeaccess',
  VIEW_INTERNAL_USERS: 'internal-user-read',
  EDIT_INTERNAL_USERS: 'internal-user-edit',
  ...AML_RULES_PERMISSIONS,
  ...BILLING_PERMISSIONS,
  ...CDD_MONITORING_PERMISSIONS,
  ...CUSTOMER_NOTIFICATIONS_PERMISSIONS,
  ...OTC_TRADE_TRANSACTION_PERMISSIONS,
  ...REGISTRATION_PERMISSIONS,
  ...TRANSACTIONS_LIMITS_PERMISSIONS,
  ...US_WIRE_TRANSACTIONS_PERMISSIONS,
  ...STELLAR_TRANSACTIONS_PERMISSIONS,
  ...PAYOPS_CREATE_TRANSACTION_PERMISSIONS,
  ...TM_NOTIFICATIONS_PERMISSIONS,
};

export const PERMISSION_NAMES = {};
