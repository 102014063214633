import React from 'react';
import { Icon } from '@finclusive/library';
import { isBlank } from '@finclusive/utils';
import * as R from 'ramda';
import { CHANNELS, EMPTY_VALUE, ENTITIES, ENTITY_ID_LABEL } from '../../../../constants';
import { COLUMNS_CONFIG, COLUMNS_KEYS } from './constants';

const getTriggeredRuleNames = (ruleNames) => {
  if (isBlank(ruleNames)) {
    return EMPTY_VALUE;
  }
  if (ruleNames.length <= 3) {
    return ruleNames.join(', ');
  }
  const othersCount = ruleNames.length - 2;
  return `${ruleNames.slice(0, 2).join(', ')} and ${othersCount} others`;
};

export const getTableData = (pageFilters, rules, onRuleClick, onRuleDelete) => ({
  ...pageFilters,
  results: !isBlank(rules)
    ? rules.map((rule) => {
        const channelKey = R.find((key) => CHANNELS[key].value === rule.channel)(R.keys(CHANNELS));
        return {
          onClick: () => onRuleClick(rule),
          cells: {
            [COLUMNS_KEYS.customerName]: rule.entityName || EMPTY_VALUE,
            [COLUMNS_KEYS.finClusiveId]: rule.entityID || EMPTY_VALUE,
            [COLUMNS_KEYS.appliedAMLRule]: getTriggeredRuleNames(rule.ruleNames),
            [COLUMNS_KEYS.channel]: R.path([channelKey, 'label'], CHANNELS) || EMPTY_VALUE,
            [COLUMNS_KEYS.edit]: (
              <div className="table-icon">
                <Icon name="pencil" />
              </div>
            ),
            [COLUMNS_KEYS.delete]: (
              <div
                className="table-icon"
                onClick={(evt) => {
                  evt.stopPropagation();
                  onRuleDelete(rule);
                }}
              >
                <Icon name="bin" />
              </div>
            ),
          },
        };
      })
    : [],
});

export const getColumnConfig = (entityType, canEditAMLRules) => {
  const columns = [
    {
      columnKey: COLUMNS_KEYS.customerName,
      label: `${ENTITIES[entityType].label} name`,
    },
    {
      columnKey: COLUMNS_KEYS.finClusiveId,
      label: ENTITY_ID_LABEL[entityType] || EMPTY_VALUE,
    },
    ...COLUMNS_CONFIG,
  ];
  return canEditAMLRules
    ? columns
    : R.filter((col) => col.columnKey !== COLUMNS_KEYS.delete, columns);
};
