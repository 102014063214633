import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { post } from 'modules/utils';
import { REACT_APP_POST_STELLAR_ROLLBACK_TRANSACTION_URL } from 'env-config';
import { ToastNotification } from 'components/library';
import types from '../types';
import {
  rollbackTransactionStart,
  rollbackTransactionSuccess,
  rollbackTransactionFail,
} from '../actions/rollback-transaction';
import { getTransactions } from '../actions/get-transactions';
import { toggleDetailView } from '../../browser/actions';
import { setSelectedRow } from '../actions';

export const callToRollbackTransaction = ({ transactionID }) =>
  post({ route: REACT_APP_POST_STELLAR_ROLLBACK_TRANSACTION_URL, data: { transactionID } });

export function* rollbackTransaction() {
  try {
    yield put(rollbackTransactionStart());

    const { transactionID } = yield select((state) => R.path(['transactions', 'selectedRow', 'rowDetails'], state));

    yield call(callToRollbackTransaction, { transactionID });

    yield put(getTransactions());

    ToastNotification('success', 'Transaction has been successfully rolled back');

    yield put(toggleDetailView(false));

    yield put(setSelectedRow(null, {}, null));

    yield put(rollbackTransactionSuccess());
  } catch (error) {
    yield put(rollbackTransactionFail(error));
    if (R.path(['response', 'data', 'errors'], error) && !isBlank(error.response.data.errors)) {
      error.response.data.errors.forEach((item) => ToastNotification('error', item));
      return;
    }

    if (R.path(['response', 'data', 'message'], error)) {
      ToastNotification('error', error.response.data.message);
      return;
    }

    yield put(getTransactions());

    yield put(toggleDetailView(false));

    yield put(setSelectedRow(null, {}, null));

    ToastNotification('error', 'Something went wrong when we were trying to process the action');
  }
}

export default function* rollbackTransactionSaga() {
  yield takeLatest(types.ROLLBACK_TRANSACTION, rollbackTransaction);
}
