import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { hideDialog, showDialog } from '../actions/global';

const initialState = {
  dialog: {
    isVisible: false,
  },
};

const showDialogHandler = [
  showDialog,
  (state, { payload }) => ({
    ...state,
    dialog: {
      ...state.dialog,
      ...payload,
      isVisible: true,
    },
  }),
];

const hideDialogHandler = [
  hideDialog,
  (state) => ({
    ...state,
    dialog: {
      isVisible: false,
    },
  }),
];

const reducer = handleActions(
  new Map([showDialogHandler, hideDialogHandler]),
  R.clone(initialState)
);

export default reducer;
