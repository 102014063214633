import * as R from 'ramda';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { isBlank } from '@finclusive/utils';
import { ToastNotification } from 'components/library';
import { REACT_APP_LIST_CUSTOMERS_URL, REACT_APP_CUSTOMER_LIMITS_URL, REACT_APP_GLOBAL_LIMITS_URL } from 'env-config';
import { get, post, putRequest } from 'modules/utils';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  getCustomersStart,
  getCustomersSuccess,
  getCustomersFail,
  getCustomerConfig,
  getCustomerConfigStart,
  getCustomerConfigSuccess,
  getCustomerConfigFail,
  setCustomerConfigStart,
  setCustomerConfigSuccess,
  setCustomerConfigFail,
  getGlobalConfig,
  getGlobalConfigStart,
  getGlobalConfigSuccess,
  getGlobalConfigFail,
  setGlobalConfigStart,
  setGlobalConfigSuccess,
  setGlobalConfigFail,
  toggleEditCustomerConfigurationOverlayVisible,
} from '.';
import { showErrorMessage } from '../../utils/saga-errors';

function* getCustomersSagaCallback({ payload }) {
  try {
    yield put(getCustomersStart());
    const { page, pageSize } = payload;
    const filterValue = yield select((state) => R.path(['limits', 'customersFilter', 'input'], state));
    const filterBy = yield select((state) => R.path(['limits', 'customersFilter', 'filterBy'], state));
    const clients = yield call(
      get,
      `${REACT_APP_LIST_CUSTOMERS_URL}?pageNo=${page}&pageSize=${pageSize}&filterValue=${filterValue}&filterBy=${filterBy}`
    );
    yield put(getCustomersSuccess(clients));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

export function* getCustomersSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMERS), getCustomersSagaCallback);
}

function* getCustomerConfigSagaCallback({ payload }) {
  try {
    const { customerFinClusiveID } = payload;
    yield put(getCustomerConfigStart(payload));
    const data = yield call(get, `${REACT_APP_CUSTOMER_LIMITS_URL}/${customerFinClusiveID}`);
    yield put(getCustomerConfigSuccess(data));
    yield put(toggleEditCustomerConfigurationOverlayVisible(true));
  } catch (error) {
    yield put(getCustomerConfigFail(error));
    showErrorMessage(error);
  }
}

export function* getCustomerConfigSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_CUSTOMER_CONFIG), getCustomerConfigSagaCallback);
}

function* setCustomerConfigSagaCallback() {
  try {
    yield put(setCustomerConfigStart());
    const customerLimits = yield select((state) => R.path(['limits', 'customerLimits'], state));
    const { id, customerName, customerFinClusiveID, configEdit } = customerLimits;
    const payload = {
      ...(!isBlank(id) && { id }),
      customerFinClusiveID,
      bankLimitConfigs: R.reduce(
        (acc, key) => {
          acc.push(configEdit[key]);
          return acc;
        },
        [],
        R.keys(configEdit)
      ),
    };

    const METHOD = isBlank(id) ? post : putRequest;

    yield call(METHOD, { route: REACT_APP_CUSTOMER_LIMITS_URL, data: payload });
    yield put(getCustomerConfig({ customerFinClusiveID, customerName }));
    yield put(setCustomerConfigSuccess());
    ToastNotification('success', 'Customer configuration has been successfully updated');
  } catch (error) {
    const message = R.path(['response', 'data', 'message'], error);
    if (message) {
      ToastNotification('error', message);
      return;
    }
    ToastNotification('error', 'Something went wrong when we were trying to process the action');
    yield put(setCustomerConfigFail(error));
  }
}

export function* setCustomerConfigSaga() {
  yield takeLatest(addPrefix(actionTypes.SET_CUSTOMER_CONFIG), setCustomerConfigSagaCallback);
}

function* getGlobalConfigSagaCallback() {
  try {
    yield put(getGlobalConfigStart());
    const data = yield call(get, REACT_APP_GLOBAL_LIMITS_URL);
    yield put(getGlobalConfigSuccess(data));
  } catch (error) {
    yield put(getGlobalConfigFail(error));
  }
}

export function* getGlobalConfigSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_GLOBAL_CONFIG), getGlobalConfigSagaCallback);
}

function* setGlobalConfigSagaCallback() {
  try {
    yield put(setGlobalConfigStart());
    const globalLimits = yield select((state) => R.path(['limits', 'globalLimits'], state));
    const { id, configEdit } = globalLimits;
    const payload = {
      id,
      bankLimitConfigs: R.reduce(
        (acc, key) => {
          acc.push(configEdit[key]);
          return acc;
        },
        [],
        R.keys(configEdit)
      ),
    };

    yield call(putRequest, { route: REACT_APP_GLOBAL_LIMITS_URL, data: payload });

    ToastNotification('success', 'Default configuration has been successfully updated');

    yield put(getGlobalConfig());
    yield put(setGlobalConfigSuccess());
  } catch (error) {
    yield put(setGlobalConfigFail(error));
    const errors = R.path(['response', 'data', 'errors'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => ToastNotification('error', item));
      return;
    }
    if (R.path(['response', 'data', 'message'], error)) {
      ToastNotification('error', error.response.data.message);
      return;
    }
    ToastNotification('error', 'Something went wrong when we were trying to process the action');
  }
}

export function* setGlobalConfigSaga() {
  yield takeLatest(addPrefix(actionTypes.SET_GLOBAL_CONFIG), setGlobalConfigSagaCallback);
}
