import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/library';
import ROUTE_PATHS from 'routes/routePaths';
import userManager from '../../UserManager';

const Logout = ({ history }) => {
  const isAuthenticated = userManager.isAuthenticated();
  if (isAuthenticated) {
    userManager.logout();
  } else {
    history.push(ROUTE_PATHS.LOGIN);
  }
  return <Spinner visible solidOverlay />;
};

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Logout;
