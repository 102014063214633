import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { isBlank } from '@finclusive/utils';
import compose, { withFeatureToggle, withPermissions, PERMISSION_TYPES } from 'hocs';
import { toggleSubmenu } from 'modules/browser';
import FEATURE_FLAGS from 'modules/featureToggle/config/flags';
import SidebarSection from '../common/sidebarSection';
import SidebarItem from '../common/sidebarItem';
import getMainMenuItems from './helpers';

const MainSection = ({ permissions, isCollapsed, currentOpenSubmenu, location, search, actions }) => {
  const mainMenu = getMainMenuItems({
    permissions,
    currentOpenSubmenu,
    toggleSubmenu: actions.toggleSubmenu,
  });

  return (
    <SidebarSection isCollapsed={isCollapsed} dataTest="sidebar-main-section">
      {R.map((key) => {
        const { label, icon, rightSideIcon, routePath, isVisible, subMenu, onClick } = R.prop(key, mainMenu);
        if (!isVisible) return null;

        return (
          <React.Fragment key={key}>
            <SidebarItem
              dataTest={`sidebar-${key}`}
              label={label}
              icon={icon}
              rightSideIcon={rightSideIcon}
              routePath={routePath}
              isCollapsed={isCollapsed}
              onClick={onClick}
              location={location}
              search={search}
              push={actions.push}
            />
            {!isBlank(subMenu) && currentOpenSubmenu === key && (
              <div className="sub-menu">
                {R.map((subMenuKey) => {
                  const { subMenuLabel, subMenuIcon, subMenuRoutePath, isSubMenuVisible } = R.prop(subMenuKey, subMenu);
                  if (!isSubMenuVisible) return null;

                  return (
                    <div key={subMenuKey}>
                      <SidebarItem
                        isChildItem
                        label={subMenuLabel}
                        icon={subMenuIcon}
                        routePath={subMenuRoutePath}
                        isCollapsed={isCollapsed}
                        location={location}
                        search={search}
                        push={actions.push}
                      />
                    </div>
                  );
                }, R.keys(subMenu))}
              </div>
            )}
          </React.Fragment>
        );
      }, R.keys(mainMenu))}
    </SidebarSection>
  );
};

MainSection.defaultProps = {};

MainSection.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func,
    toggleSubmenu: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ router, browser }) => {
  return {
    location: router.location.pathname,
    search: router.location.search,
    isCollapsed: browser.sidebarIsCollapsed,
    currentOpenSubmenu: browser.currentOpenSubmenu,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
      toggleSubmenu,
    },
    dispatch
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFeatureToggle([FEATURE_FLAGS.isBankingEnabled]),
  withPermissions({
    canViewRegistration: [PERMISSION_TYPES.VIEW_APPLICATION_REGISTRATION, PERMISSION_TYPES.VIEW_PROSPECT_REGISTRATION],
    canViewStellarTransactions: [PERMISSION_TYPES.VIEW_STELLAR_TRANSACTIONS_ONLY],
    canViewGenerateReportView: [PERMISSION_TYPES.VIEW_GENERATE_REPORT_VIEW],
    canViewTransactionLimits: [PERMISSION_TYPES.VIEW_TRANSACTION_LIMITS_CONFIG],
    canViewUsWiresTransactions: [PERMISSION_TYPES.VIEW_US_WIRES_TRANSACTIONS],
    canViewAMLTransactions: [PERMISSION_TYPES.VIEW_AML_TRANSACTIONS],
    canViewAMLRules: [PERMISSION_TYPES.VIEW_AML_RULES],
    canViewBilling: [PERMISSION_TYPES.VIEW_BILLING],
    canViewCustomerNotifications: [PERMISSION_TYPES.VIEW_CUSTOMER_NOTIFICATIONS],
    canViewPayOpsCreateTransaction: [PERMISSION_TYPES.VIEW_PAYOPS_ACCOUNT],
  })
)(MainSection);
