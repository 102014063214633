import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'components/library';
import { COLUMNS_CONFIG } from './constants';
import StyledGlobalRules from './GlobalRules.style';
import { getTableData } from './helpers';
import { editGlobalRule, getGlobalRuleDetails } from '../../actions';
import RuleForm from './components/RuleForm';

const GlobalRules = ({ actions, rules, canEditAMLRules }) => {
  const onDefaultRuleSave = () => actions.editGlobalRule();

  const onEditClick = (rule) => actions.getGlobalRuleDetails(rule.id);

  const tableData = getTableData(rules, onEditClick);

  return (
    <StyledGlobalRules>
      <Table data={tableData} columns={COLUMNS_CONFIG} />
      <RuleForm onSave={onDefaultRuleSave} formDisabled={!canEditAMLRules} />
    </StyledGlobalRules>
  );
};

GlobalRules.propTypes = {};

const mapStateToProps = ({
  transactionMonitoring: {
    amlRules: { global },
  },
}) => {
  return {
    rules: global.list.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      editGlobalRule,
      getGlobalRuleDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRules);
