import * as R from 'ramda';
import userManager from 'modules/userManager/components/UserManager';
import { isBlank } from '@finclusive/utils';

export const addAuthorizationHeaderRequestInterceptor = async (axiosInstance, options) => {
  const skipAccessToken = R.path(['data', 'skipAccessToken'], options);

  const token = await userManager.getToken();

  if (!skipAccessToken && !token) throw new Error('Invalid Access Token');

  axiosInstance.interceptors.request.use(
    (config) => {
      if (!skipAccessToken && token && !isBlank(token.accessToken))
        config.headers.Authorization = `Bearer ${token.accessToken}`;

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};
