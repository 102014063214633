import styled, { css } from 'styled-components';

const StyledGlobalRules = styled.div`
  ${() => css`
    .table {
      th:last-child,
      .table__selected-icon {
        display: none;
      }
    }
  `}
`;

StyledGlobalRules.displayName = 'StyledGlobalRules';
export default StyledGlobalRules;
