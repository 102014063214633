import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop } from '@finclusive/utils';
import TableHeaderCell from './components/TableHeaderCell';
import TableBody from './components/TableBody';

class Table extends Component {
  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    selected: props.selected,
  });

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  reorderCells = (cells) => {
    const { columns } = this.props;
    const reorderedCells = [];
    R.toPairs(cells).forEach(([key, value]) => {
      const index = columns.findIndex((obj) => obj.columnKey === key);

      if (index > -1) {
        const isDownload = !!columns[index].isDownload;
        const hasTooltip = !!columns[index].hasTooltip;

        reorderedCells[index] = {
          value,
          icon: columns[index].icon || '',
          className: columns[index].className || '',
          isDownload,
          size: isDownload ? cells.sizeInBytes : null,
          name: isDownload ? cells.name : null,
          tooltip: hasTooltip && cells.tooltipContent ? cells.tooltipContent : null,
        };
      }
    });

    return reorderedCells;
  };

  downloadDocument = ({ name, value, id, fileName }) => {
    const { customDownload, actions } = this.props;
    if (customDownload) {
      customDownload(id, fileName);
    } else {
      actions.getDocument(null, name, null, value);
    }
  };

  render() {
    const {
      isLoading,
      enableHover,
      className,
      dataTest,
      data,
      columns,
      selectedIcon,
      noDataFoundMessage,
      sortOrder,
      sort,
    } = this.props;
    const { selected } = this.state;

    return (
      <div className={`table ${className}`}>
        <div className="table__wrapper">
          <table className="table__table" data-test={dataTest}>
            <thead data-test={`${dataTest}-head`}>
              <tr className="table__header" data-test={`${dataTest}-head-row`}>
                {columns.map((columnProps, idx) => (
                  <TableHeaderCell
                    key={`table-header-cell-${idx}`}
                    dataTest={`${dataTest}-head-row-cell-${idx}`}
                    className={columnProps.className}
                    label={columnProps.label}
                    columnSort={columnProps.columnSort}
                    sortable={columnProps.sortable}
                    style={columnProps.style}
                    sortOrder={sortOrder}
                    sort={sort}
                  />
                ))}
                <th />
              </tr>
            </thead>
            <TableBody
              isLoading={isLoading}
              enableHover={enableHover}
              dataTest={dataTest}
              data={data}
              selected={selected}
              selectedIcon={selectedIcon}
              noDataFoundMessage={noDataFoundMessage}
              reorderCells={this.reorderCells}
              getDocument={this.downloadDocument}
            />
          </table>
        </div>
      </div>
    );
  }
}

Table.defaultProps = {
  isLoading: false,
  className: '',
  dataTest: '',
  data: [],
  enableHover: true,
  selected: null,
  selectedIcon: 'check',
  noDataFoundMessage: 'No data found.',
  sortOrder: '',
  sort: noop,
  customDownload: noop,
};

Table.propTypes = {
  isLoading: PropTypes.bool,
  enableHover: PropTypes.bool,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      isDownload: PropTypes.bool,
      className: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.string,
      columnKey: PropTypes.string,
      columnSort: PropTypes.string,
      sortable: PropTypes.bool,
      style: PropTypes.shape({}),
    })
  ).isRequired,
  selected: PropTypes.number,
  selectedIcon: PropTypes.string,
  noDataFoundMessage: PropTypes.string,
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  sort: PropTypes.func,
  customDownload: PropTypes.func,
  actions: PropTypes.shape({
    getDocument: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDocument: noop,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(Table);
