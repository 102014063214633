import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { getUsersStore, getUserDataStore } from '../actions';
import initialState from './initialState';

const getUsersStoreHandler = [
  getUsersStore,
  (state, actions) => {
    const { loading, payload, error } = actions.payload;

    if (loading) {
      return {
        ...state,
        isUsersLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isUsersLoading: false,
        error,
      };
    }

    return {
      ...state,
      isUsersLoading: false,
      users: payload,
    };
  },
];

const getUserDataStoreHandler = [
  getUserDataStore,
  (state, actions) => {
    const { loading, payload, error } = actions.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    return {
      ...state,
      isLoading: false,
      ...payload,
    };
  },
];

const reducer = handleActions(
  new Map([getUsersStoreHandler, getUserDataStoreHandler]),
  R.clone(initialState)
);

export default reducer;
