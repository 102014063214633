import types from '../types';

export const getEDDReportStart = () => ({
  type: types.GET_EDD_REPORT_START,
});

export const getEDDReport = (workItemPackageId, name) => ({
  type: types.GET_EDD_REPORT,
  workItemPackageId,
  name,
});

export const getEDDReportSuccess = (data) => ({
  type: types.GET_EDD_REPORT_SUCCESS,
  data,
});

export const getEDDReportFail = (error) => ({
  type: types.GET_EDD_REPORT_FAIL,
  error,
});
