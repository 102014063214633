import types from '../types';

export const updateWorkItemPackagesStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: true,
  },
});

export const updateWorkItemPackagesSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: false,
  },
});

export const updateWorkItemPackagesFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: false,
  },
  error,
});

export default {
  [types.UPDATE_WORK_ITEM_PACKAGE_STATUS_START]: updateWorkItemPackagesStart,
  [types.UPDATE_WORK_ITEM_PACKAGE_STATUS_SUCCESS]: updateWorkItemPackagesSuccess,
  [types.UPDATE_WORK_ITEM_PACKAGE_STATUS_FAIL]: updateWorkItemPackagesFail,
};
