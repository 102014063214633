import types from '../types';

export const getWiredTransactionsStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    IsLoadingWiredTransactions: true,
  },
});

export const getWiredTransactionsSuccess = (state, { wiredTransactions }) => ({
  ...state,
  wiredTransactions,
  loaders: {
    ...state.loaders,
    IsLoadingWiredTransactions: false,
  },
});

export const getWiredTransactionsFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    IsLoadingWiredTransactions: false,
  },
  error,
});

export default {
  [types.GET_WIRED_TRANSACTIONS_START]: getWiredTransactionsStart,
  [types.GET_WIRED_TRANSACTIONS_FAIL]: getWiredTransactionsFail,
  [types.GET_WIRED_TRANSACTIONS_SUCCESS]: getWiredTransactionsSuccess,
};
