import React from 'react';
import PropTypes from 'prop-types';

import ButtonComplex from '../../ButtonComplex';

const DetailSection = ({ className, children, title, buttons, type, sectionClassName }) => (
  <div className={`detail-view__section ${sectionClassName}`}>
    {title && (
      <h4 className={`detail-view__section-title--${type}`}>
        {title}
        {buttons &&
          buttons.map((button, i) => (
            <ButtonComplex
              key={`detail-view-button-${i}`}
              {...button}
              className={`detail-view__header-button ${button.className ? button.className : ''}`}
            >
              {button.label}
            </ButtonComplex>
          ))}
      </h4>
    )}
    <div className={`detail-view__section-content ${className}`}>{children}</div>
  </div>
);

DetailSection.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['standalone', 'profile']),
  children: PropTypes.node,
  title: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};
DetailSection.defaultProps = {
  className: '',
  sectionClassName: '',
  type: 'standalone',
  children: null,
};

export default DetailSection;
