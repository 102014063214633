import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { stringSanitize } from '@finclusive/utils';
import { Input as ReactInput } from 'reactstrap';
import { MAX_FILE_SIZE } from 'utilities';
import { ToastNotification, Tooltip } from 'components/library';

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: props.value,
      invalidFile: false,
      inputValue: '',
    };
  }

  onFileChange = (e) => {
    const { onChange } = this.props;
    if (e.target.files.length && e.target.files[0].size < MAX_FILE_SIZE) {
      this.setState({ fileName: stringSanitize(e.target.files[0].name), invalidFile: false });
      onChange && onChange(e);
    } else {
      this.setState({ fileName: '' });
    }
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      this.setState({ invalidFile: true });
      ToastNotification('error', 'File size must be under 10MB');
    }
    this.setState({ inputValue: stringSanitize(e.target.value) });
  };

  handleClick = () => {
    this.setState({ inputValue: '' });
  };

  render() {
    const { required, name, className, label, tooltip, value } = this.props;
    const { fileName, invalidFile, inputValue } = this.state;

    return (
      <div
        className={cls('input', 'input--file', {
          'input--is-invalid': invalidFile,
          'input--file-with-tooltip': tooltip,
        })}
      >
        <label className="input__fake-wrapper">
          <div
            className={cls('input__label', 'input__label--file', {
              'input__label--file-required': required,
            })}
          >
            {label || name}
          </div>
          <ReactInput
            value={inputValue}
            invalid={invalidFile}
            type="file"
            className={`input__field input__field--file ${className}`}
            onClick={this.handleClick}
            onChange={this.onFileChange}
            required={required && !fileName}
          />
          <span
            className={cls('input__file-label', {
              'input__file-label--required': required && fileName === name,
            })}
          >
            {fileName || value || 'Choose File'}
          </span>
        </label>
        {tooltip && <Tooltip position="bottom" tooltip={tooltip} className="tooltip--file" identifier={name} />}
      </div>
    );
  }
}

File.defaultProps = {
  className: '',
};

File.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.string,
};

export default File;
