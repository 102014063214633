import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFadingOut: false,
      isFadingIn: false,
      className: props.className,
      visible: props.visible,
      subLoader: props.subLoader,
      solidOverlay: props.solidOverlay,
      small: props.subLoader,
      tiny: props.tiny,
    };
  }

  componentDidUpdate = async (oldProps) => {
    if (!oldProps.visible && this.props.visible) {
      setTimeout(() => this.setState({ isFadingIn: true }), 0);
      this.setState({ isFadingIn: false, visible: true });
    }
    if (oldProps.visible && !this.props.visible) {
      this.setState({ isFadingOut: true }, () => {
        setTimeout(
          () =>
            // Timeout for the animation to finish
            this.setState({ isFadingOut: false, visible: false }),
          200
        );
      });
    }
  };

  render() {
    const { isFadingIn, isFadingOut, visible, small, tiny, solidOverlay, className } = this.state;
    const { overlay } = this.props;

    if (!visible) return null;

    return (
      <div
        className={cls('spinner', className, {
          'spinner--is-fading-in': isFadingIn,
          'spinner--is-fading-out': isFadingOut,
          'spinner--visible': visible,
          'spinner--small': small,
          'spinner--overlay': overlay,
          'spinner--solid-overlay': solidOverlay,
        })}
      >
        <div
          className={cls('spinner__line spinner__line--outer', {
            'spinner__line--small-outer': small,
            'spinner__line--tiny-outer': tiny,
          })}
        />
        <div
          className={cls('spinner__line spinner__line--middle', {
            'spinner__line--small-middle': small,
            'spinner__line--tiny-middle': tiny,
          })}
        />
        <div
          className={cls('spinner__line spinner__line--inner', {
            'spinner__line--small-inner': small,
            'spinner__line--tiny-inner': tiny,
          })}
        />
      </div>
    );
  }
}

Spinner.defaultProps = {
  className: '',
  visible: false,
  subLoader: true,
  overlay: false,
  solidOverlay: false,
};

Spinner.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  subLoader: PropTypes.bool,
  overlay: PropTypes.bool,
  solidOverlay: PropTypes.bool,
};

export default Spinner;
