import types from '../types';

export const updateOtcDataStart = () => ({
  type: types.UPDATE_OTC_DATA_START,
});

export const updateOtcData = () => ({
  type: types.UPDATE_OTC_DATA,
});

export const updateOtcDataSuccess = () => ({
  type: types.UPDATE_OTC_DATA_SUCCESS,
});

export const updateOtcDataFail = (error) => ({
  type: types.UPDATE_OTC_DATA_FAIL,
  error,
});
