const actionTypes = {
  GET_PENDING_REGISTRATIONS_SAGA: 'GET_PENDING_REGISTRATIONS_SAGA',
  GET_PENDING_REGISTRATIONS_STORE: 'GET_PENDING_REGISTRATIONS_STORE',

  SET_SELECTED_PENDING_REGISTRATION: 'SET_SELECTED_PENDING_REGISTRATION',
  CLEAR_SELECTED_PENDING_REGISTRATION: 'CLEAR_SELECTED_PENDING_REGISTRATION',

  SET_SELECTED_PENDING_REGISTRATION_FIELD: 'SET_SELECTED_PENDING_REGISTRATION_FIELD',
  SET_PENDING_REGISTRATION_VALIDATION_ERRORS: 'SET_PENDING_REGISTRATION_VALIDATION_ERRORS',

  GET_REGISTRATION_REPORT_SAGA: 'GET_REGISTRATION_REPORT_SAGA',
  GET_REGISTRATION_REPORT_STORE: 'GET_REGISTRATION_REPORT_STORE',

  EXPORT_REGISTRATION_REPORT_SAGA: 'EXPORT_REGISTRATION_REPORT_SAGA',
  EXPORT_REGISTRATION_REPORT_STORE: 'EXPORT_REGISTRATION_REPORT_STORE',

  UPDATE_PENDING_REGISTRATION_SAGA: 'UPDATE_PENDING_REGISTRATION_SAGA',
  UPDATE_PENDING_REGISTRATION_STORE: 'UPDATE_PENDING_REGISTRATION_STORE',

  APPROVE_PENDING_REGISTRATION_CURRENT_SAGA: 'APPROVE_PENDING_REGISTRATION_CURRENT_SAGA',
  APPROVE_PENDING_REGISTRATION_CURRENT_STORE: 'APPROVE_PENDING_REGISTRATION_CURRENT_STORE',

  APPROVE_PENDING_REGISTRATION_SECONDARY_SAGA: 'APPROVE_PENDING_REGISTRATION_SECONDARY_SAGA',
  APPROVE_PENDING_REGISTRATION_SECONDARY_STORE: 'APPROVE_PENDING_REGISTRATION_SECONDARY_STORE',

  ARCHIVE_REGISTRATION_SAGA: 'ARCHIVE_REGISTRATION_SAGA',
  ARCHIVE_REGISTRATION_STORE: 'ARCHIVE_REGISTRATION_STORE',
};

export default actionTypes;
