import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@finclusive/library';
import { isBlank } from '@finclusive/utils';
import StyledTools from './Tools.style';

const Tools = ({ isLoading, customTools, dataTest }) => {
  return (
    <StyledTools data-test={dataTest}>
      {!isBlank(customTools) &&
        customTools.map((tool, i) => (
          <Button
            key={`tool-${i}`}
            className="tool-actions"
            color={tool.color}
            variant={tool.variant}
            disabled={isLoading || tool.disabled}
            action={tool.action}
            iconName={tool.iconName}
            iconPosition={tool.iconPosition}
          >
            {tool.label}
          </Button>
        ))}
    </StyledTools>
  );
};

Tools.defaultProps = {
  isLoading: false,
};

Tools.propTypes = {
  isLoading: PropTypes.bool,
  customTools: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func,
      iconName: PropTypes.string,
      iconPosition: PropTypes.string,
    })
  ).isRequired,
  dataTest: PropTypes.string,
};

export default Tools;
