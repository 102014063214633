import types from '../types';

export const rollbackTransactionStart = () => ({
  type: types.ROLLBACK_TRANSACTION_START,
});

export const rollbackTransaction = ({ transactionID }) => ({
  type: types.ROLLBACK_TRANSACTION,
  transactionID,
});

export const rollbackTransactionSuccess = () => ({
  type: types.ROLLBACK_TRANSACTION_SUCCESS,
});

export const rollbackTransactionFail = (error) => ({
  type: types.ROLLBACK_TRANSACTION_FAIL,
  error,
});
