import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cls from 'classnames';
import { isBlank } from '@finclusive/utils';
import Select from './Select';
import CustomSelect from './CustomSelect';
import File from './File';
import FileGroup from './FileGroup';
import CheckboxGroup from './CheckboxGroup';
import Icon from '../Icon';
import { Tooltip } from '../index';
import TabularData from './TabularData';
import Text from './Text';
import TextArea from './TextArea';
import TextGroup from './TextGroup';
import DateField from './DateField';

class Input extends Component {
  render() {
    const { type, name, fullWidth, label, required, icon, tooltip, footNote, tooltipIdentifier } = this.props;
    const fieldIcon = type === 'date' ? 'calendar' : icon;
    const inputTypes = {
      date: DateField,
      customSelect: CustomSelect,
      select: Select,
      checkboxGroup: CheckboxGroup,
    };
    const RenderInput = inputTypes[type] ? inputTypes[type] : Text;

    if (type === 'file') return <File {...this.props} />;

    return (
      <div className={cls('input', { 'input--full-width': fullWidth })}>
        {label && (
          <label
            className={cls('input__label', {
              'input__label--required': required,
              'input__label--full-width': fullWidth,
            })}
          >
            {label}
          </label>
        )}
        <div
          className={cls('input__container', {
            'input__container--full-width': fullWidth,
          })}
        >
          <RenderInput {...R.omit(['fullWidth', 'tooltipIdentifier'], this.props)} key={name} />
        </div>
        {fieldIcon && <Icon name={fieldIcon} className="input__addon" />}
        {tooltip && <Tooltip tooltip={tooltip} identifier={isBlank(tooltipIdentifier) ? name : tooltipIdentifier} />}
        {footNote ? <span className="input__max-length">{footNote}</span> : ''}
      </div>
    );
  }
}

Input.defaultProps = {
  type: 'text',
  className: '',
  requiresValidation: true,
  placeholder: '',
  fullWidth: false,
  tooltipIdentifier: '',
};

Input.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  requiresValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footNote: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipIdentifier: PropTypes.string,
};

export default Input;
export { File, FileGroup, TabularData, TextArea, TextGroup };
