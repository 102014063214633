import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { post } from 'modules/utils';
import { toggleDetailView } from 'modules/browser/actions';
import { ToastNotification } from 'components/library';
import { REACT_APP_POST_STELLAR_SETTLE_TRANSACTION_URL } from 'env-config';
import types from '../types';
import { getTransactions } from '../actions/get-transactions';
import { setSelectedRow } from '../actions';
import { settleTransactionStart, settleTransactionSuccess, settleTransactionFail } from '../actions/settle-transaction';

export const callToSettleTransaction = ({ transactionID }) =>
  post({
    route: REACT_APP_POST_STELLAR_SETTLE_TRANSACTION_URL,
    data: { transactionID },
  });

export function* settleTransaction() {
  try {
    yield put(settleTransactionStart());
    const { transactionID } = yield select((state) => R.path(['transactions', 'selectedRow', 'rowDetails'], state));

    yield call(callToSettleTransaction, { transactionID });

    yield put(getTransactions());

    ToastNotification('success', 'Transaction has been successfully approved');

    yield put(toggleDetailView(false));

    yield put(setSelectedRow(null, {}, null));

    yield put(settleTransactionSuccess());
  } catch (error) {
    yield put(settleTransactionFail(error));
    if (R.path(['response', 'data', 'errors'], error) && !isBlank(error.response.data.errors)) {
      error.response.data.errors.forEach((item) => ToastNotification('error', item));
      return;
    }

    if (R.path(['response', 'data', 'message'], error)) {
      ToastNotification('error', error.response.data.message);
      return;
    }

    yield put(getTransactions());

    yield put(toggleDetailView(false));

    yield put(setSelectedRow(null, {}, null));

    ToastNotification('error', 'Something went wrong when we were trying to process the action');
  }
}

export default function* settleTransactionSaga() {
  yield takeLatest(types.SETTLE_TRANSACTION, settleTransaction);
}
