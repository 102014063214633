import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({ children, level, className, style }) => {
  const HeadingWithLevel = `h${level}`;

  return (
    <HeadingWithLevel className={`heading heading--${level} ${className}`} style={style}>
      {children}
    </HeadingWithLevel>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Heading.defaultProps = {
  level: 1,
  className: '',
};

export default Heading;

export const SubHeading = (props) => <Heading {...props} level={2} />;
export const SmallHeading = (props) => <Heading {...props} level={3} />;
