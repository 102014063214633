import types from '../types';

export const rollbackWireStart = () => ({
  type: types.ROLLBACK_WIRE_START,
});

export const rollbackWire = (payload, transactionMethod) => ({
  type: types.ROLLBACK_WIRE,
  payload,
  transactionMethod,
});

export const rollbackWireSuccess = (data) => ({
  type: types.ROLLBACK_WIRE_SUCCESS,
  data,
});

export const rollbackWireFail = (err) => ({
  type: types.ROLLBACK_WIRE_FAIL,
  err,
});
