import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  getInternalUsersStart,
  getInternalUsersSuccess,
  getInternalUsersFail,
  setFilter,
  setSelectedRow,
  getInternalUserDetailsStart,
  getInternalUserDetailsSuccess,
  getInternalUserDetailsFail,
  patchInternalUserDetailsStart,
  patchInternalUserDetailsSuccess,
  patchInternalUserDetailsFail,
  getInternalPermissionsStart,
  getInternalPermissionsSuccess,
  getInternalPermissionsFail,
} from '../actions';
import initialState from './initialState';

const getInternalUsersStartHandler = [
  getInternalUsersStart,
  (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
];

const getInternalUsersSuccessHandler = [
  getInternalUsersSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      list: R.prop('results', payload),
      filter: {
        ...state.filter,
        pageNo: R.prop('currentPage', payload),
        pageCount: R.prop('pageCount', payload),
        pageSize: R.prop('pageSize', payload),
        rowCount: R.prop('rowCount', payload),
        firstRowOnPage: R.prop('firstRowOnPage', payload),
        lastRowOnPage: R.prop('lastRowOnPage', payload),
      },
      selectedRow: {
        index: null,
        rowDetails: {},
      },
    };
  },
];

const getInternalUsersFailHandler = [
  getInternalUsersFail,
  (state, action) => {
    const { error } = action;
    return {
      ...state,
      isLoading: false,
      error,
    };
  },
];

const setFilterHandler = [
  setFilter,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      filter: {
        ...state.filter,
        ...payload,
      },
    };
  },
];

const setSelectedRowHandler = [
  setSelectedRow,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      selectedRow: {
        index: payload.index,
        rowDetails: payload.internalUser,
      },
    };
  },
];

const getInternalUserDetailsStartHandler = [
  getInternalUserDetailsStart,
  (state) => {
    return {
      ...state,
      isDetailsLoading: true,
    };
  },
];

const getInternalUserDetailsSuccessHandler = [
  getInternalUserDetailsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isDetailsLoading: false,
      internalUserDetails: payload,
    };
  },
];

const getInternalUserDetailsFailHandler = [
  getInternalUserDetailsFail,
  (state, action) => {
    const { error } = action;
    return {
      ...state,
      isDetailsLoading: false,
      error,
    };
  },
];

const patchInternalUserDetailsStartHandler = [
  patchInternalUserDetailsStart,
  (state) => {
    return {
      ...state,
      isDetailsLoading: true,
    };
  },
];

const patchInternalUserDetailsSuccessHandler = [
  patchInternalUserDetailsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isDetailsLoading: false,
      internalUserDetails: payload,
    };
  },
];

const patchInternalUserDetailsFailHandler = [
  patchInternalUserDetailsFail,
  (state, action) => {
    const { error } = action;
    return {
      ...state,
      isDetailsLoading: false,
      error,
    };
  },
];

const getInternalPermissionsStartHandler = [
  getInternalPermissionsStart,
  (state) => {
    return {
      ...state,
      isInternalPermissionsLoading: true,
    };
  },
];

const getInternalPermissionsSuccessHandler = [
  getInternalPermissionsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isInternalPermissionsLoading: false,
      internalPermissions: payload,
    };
  },
];

const getInternalPermissionsFailHandler = [
  getInternalPermissionsFail,
  (state, action) => {
    const { error } = action;
    return {
      ...state,
      isInternalPermissionsLoading: false,
      error,
    };
  },
];

const reducer = handleActions(
  new Map([
    getInternalUsersStartHandler,
    getInternalUsersSuccessHandler,
    getInternalUsersFailHandler,
    setFilterHandler,
    setSelectedRowHandler,
    getInternalUserDetailsStartHandler,
    getInternalUserDetailsSuccessHandler,
    getInternalUserDetailsFailHandler,
    patchInternalUserDetailsStartHandler,
    patchInternalUserDetailsSuccessHandler,
    patchInternalUserDetailsFailHandler,
    getInternalPermissionsStartHandler,
    getInternalPermissionsSuccessHandler,
    getInternalPermissionsFailHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
