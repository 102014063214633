import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cls from 'classnames';
import ReactSelect from 'react-select';
import { isBlank } from '@finclusive/utils';
import { Icon, Spinner } from 'components/library';

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: this.checkIfValid(props.value),
      dirty: false,
    };
  }

  onChange = (e) => {
    const { onChange, requiresValidation } = this.props;
    const valid = this.checkIfValid(e.value);
    if (requiresValidation) {
      this.setState({ dirty: true, valid });
    }
    if (onChange) {
      onChange(e);
    }
  };

  checkIfValid = (value) => {
    const { required, requiresValidation } = this.props;
    if (!requiresValidation) return null;
    if (value && R.isEmpty(value)) return !!value;
    if (required) return false;
    return null;
  };

  render() {
    const { className, value, disabled, label, selectIconClassName, isLoading, options } = this.props;
    const { valid, dirty } = this.state;

    return (
      <>
        <ReactSelect
          {...R.omit(['requiresValidation', 'fullWidth', 'selectIconClassName', 'isLoading'], this.props)}
          onChange={this.onChange}
          options={options}
          type="select"
          classNamePrefix="input__field--custom-select"
          className={cls('input__field', 'input__field--custom-select', 'form-control', className, {
            'input__field--valid': !isBlank(value),
            'input__field--valid-select': !isBlank(value),
            'input__field--invalid': (!valid && dirty) || (dirty && isBlank(value)),
            'input__field--disabled': disabled,
            'input__field--loading': isLoading,
            'input__field--full-width': !label,
          })}
          autoComplete="invalid"
        />
        {isLoading && <Spinner visible className="input__spinner" />}
        <Icon
          name="chevron-down"
          className={cls('input__selector', selectIconClassName, {
            'input__selector--validated': valid || (!valid && dirty),
            'input__selector--disabled': disabled,
          })}
        />
      </>
    );
  }
}

CustomSelect.propTypes = {
  className: PropTypes.string,
  requiresValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  selectIconClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
};

export default CustomSelect;
