import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as UUID } from 'uuid';
import { stringSanitize } from '@finclusive/utils';
import { ReactComponent as Upload } from './components/upload.svg';
import { ReactComponent as View } from './components/view.svg';
import { ReactComponent as Download } from './components/download.svg';
import { ReactComponent as Trash } from './components/trash.svg';
import ViewModal from './components/view-modal';
import DeleteModal from './components/delete-modal';
import ToastNotification from '../../ToastNotification';
import ButtonComplex from '../../ButtonComplex';

const FileGroup = ({
  currentClient,
  name,
  title,
  defaultValue,
  exportUrl,
  isLoadingDocument,
  allowedFileTypes,
  onChange,
  uploadFile,
  viewFile,
  downloadFile,
  isDisabled,
}) => {
  const hiddenFileInput = React.useRef(null);
  const [viewModalOpen, showViewModal] = useState(false);
  const [deleteModalOpen, showDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [index, setIndex] = useState(-1);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const remove = (i) => {
    defaultValue.splice(i, 1);

    onChange({ target: { name, value: defaultValue } });
  };

  const cleanupInvalid = (files) => {
    const validFiles = [];
    for (let i = 0; i < files.length; i += 1) {
      if (allowedFileTypes.includes(files[i].type)) {
        validFiles.push(files[i]);
      }
    }
    if (validFiles.length !== files.length) {
      ToastNotification(
        'error',
        'Please select only documents or images (PDF, Word, Excel, CSV, Text, Rich Text, JPG, PNG, GIF, BMP, TIFF)'
      );
    }

    return validFiles;
  };

  const fileUpload = async (e) => {
    const { files } = e.target;
    const value = defaultValue.concat();
    const validFiles = cleanupInvalid(files);

    for (let i = 0; i < validFiles.length; i += 1) {
      const currentFile = validFiles[i];
      const { name: fileName, type: mimeType } = currentFile;
      const lastModifiedAt = moment.utc(currentFile.lastModifiedDate).format();
      const createdAt = moment.utc().format();
      const id = UUID();

      const document = {
        id,
        customerID: currentClient.customer.id,
        customerFinClusiveID: currentClient.customer.finClusiveID,
        clientID: currentClient.client.id,
        clientFinClusiveID: currentClient.client.finClusiveID,
        significantPartyID: currentClient.significantParty?.id || null,
        title: stringSanitize(fileName),
        mimeType,
        createdAt,
        lastModifiedAt,
        encoding: 'utf-8',
      };
      value.push(document);
      uploadFile({
        file: currentFile,
        fileMeta: {
          ID: id,
          customerID: currentClient.customer.id,
          clientID: currentClient.client.id,
          significantPartyID: currentClient.significantParty?.id || null,
          customerFinClusiveID: currentClient.customer.finClusiveID,
          clientFinClusiveID: currentClient.client.finClusiveID,
          title: stringSanitize(title),
          mimeType,
          createdAt,
          lastModifiedAt,
        },
      });
    }

    onChange({ target: { name, value: stringSanitize(value) } });
  };

  const clickDelete = (defVal, i) => {
    showDeleteModal(true);
    setFile(defVal);
    setIndex(i);
  };

  return (
    <>
      <div className="flex-row space-between align-items-center">
        <label>{title}</label>
        <ButtonComplex type="tertiary" onClick={handleClick} disabled={isDisabled}>
          <Upload />
          &nbsp;Upload
        </ButtonComplex>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onClick={(e) => {
          e.target.value = null;
        }}
        onChange={(e) => fileUpload(e)}
        style={{ display: 'none' }}
        accept={allowedFileTypes || '*'}
        multiple
      />
      {defaultValue?.map((defVal, i) => (
        <div key={`${name}-${i}`}>
          <hr />
          <div className="flex-row flex-end align-items-center">
            {defVal.title}
            <div className="flex-row">
              <ButtonComplex
                type="tertiary"
                onClick={() => {
                  showViewModal(true);
                  setFile(defVal);
                  viewFile({ documentUniqueIdentifier: defVal.id, name: defVal.title });
                }}
              >
                <View />
              </ButtonComplex>
              <ButtonComplex
                type="tertiary"
                onClick={() => downloadFile({ documentUniqueIdentifier: defVal.id, name: defVal.title })}
              >
                <Download />
              </ButtonComplex>
              <ButtonComplex type="tertiary" onClick={() => clickDelete(defVal, i)} disabled={isDisabled}>
                <Trash />
              </ButtonComplex>
            </div>
          </div>
        </div>
      ))}
      <ViewModal
        isOpen={viewModalOpen}
        base64={exportUrl}
        isLoading={isLoadingDocument}
        toggle={() => showViewModal(false)}
        handleConfirm={() => showViewModal(false)}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={() => showDeleteModal(false)}
        handleConfirm={() => {
          remove(index);
          showDeleteModal(false);
        }}
        title={file?.title}
      />
    </>
  );
};

FileGroup.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  allowedFiles: PropTypes.array,
  viewFile: PropTypes.func,
  downloadFile: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default FileGroup;
