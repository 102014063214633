import types from '../types';

export const getWorkItemStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItem: state.loaders.isLoadingWorkItem + 1,
  },
});

export const getWorkItemSuccess = (state, { data }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItem: state.loaders.isLoadingWorkItem - 1,
  },
  currentWorkItems: [...state.currentWorkItems, data],
});

export const getWorkItemFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItem: 0,
  },
  error,
});

export default {
  [types.GET_WORK_ITEM_START]: getWorkItemStart,
  [types.GET_WORK_ITEM_SUCCESS]: getWorkItemSuccess,
  [types.GET_WORK_ITEM_FAIL]: getWorkItemFail,
};
