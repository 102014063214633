import types from '../types';

export const rollbackWireStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: true,
  },
});

export const rollbackWireSuccess = (state, { data }) => ({
  ...state,
  data,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
});

export const rollbackWireFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
  error,
});

export default {
  [types.ROLLBACK_WIRE_START]: rollbackWireStart,
  [types.ROLLBACK_WIRE_FAIL]: rollbackWireFail,
  [types.ROLLBACK_WIRE_SUCCESS]: rollbackWireSuccess,
};
