import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY } from '@finclusive/utils';

const decodeHTML = (text) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

const CurrencyText = ({
  className, negative, amount, colored, currency,
}) => {
  let currencySymbol = '$';
  const currencyDetails = CURRENCY[currency];
  const peddingStatus = amount === null;
  if (currencyDetails) currencySymbol = currencyDetails.symbol;

  const colourPedding = peddingStatus ? 'currency-text--pending-balance' : 'currency-text--positive';
  const colourClass = !peddingStatus && negative ? 'currency-text--negative' : colourPedding;
  const displayAmount = currency === 'USD' ? (Math.floor(amount * 100) / 100) : amount;
  return (
    <span className={`currency-text ${colored ? colourClass : ''} ${className}`}>
      {!peddingStatus && negative && '-'}
      {peddingStatus && 'Pending'}
      {!peddingStatus && currencySymbol && decodeHTML(currencySymbol)}
      {amount && displayAmount.toLocaleString(undefined, { minimumFractionDigits: 2, ...(currency === 'USD' && { maximumFractionDigits: 2 }) })}
      {!currencySymbol && currencyDetails.abbreviation}
    </span>
  );
};

CurrencyText.propTypes = {
  className: PropTypes.string,
  negative: PropTypes.bool,
  amount: PropTypes.number,
  colored: PropTypes.bool,
  currency: PropTypes.string,
};
CurrencyText.defaultProps = {
  className: '',
  colored: true,
  currency: 'USD',
};

export default CurrencyText;
