import styled, { css } from 'styled-components';
import { LAYOUT_PADDING } from '../Layout/Layout.style';

const StyledViewContainer = styled.div`
  ${({ theme }) => {
    const { palette } = theme;
    return css`
      display: flex;
      flex-direction: column;
      height: 100%;

      .view-container-heading {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 4rem;

        .heading-group {
          display: flex;
          flex-direction: column;
          .heading-crumb {
            margin: 0;
            color: ${palette.gray.grayDarkMedium};
            font-size: 1.2rem;
          }
          .heading-title {
            display: flex;
            flex-direction: column;
            margin: 0;
            font-weight: 300;
            .icon {
              margin-right: 0.5rem;
            }
            .heading-title-back-button {
              font-size: 2.4rem;
              cursor: pointer;
              margin-right: 0.8rem;
              color: #666666;
            }
          }
          .heading-subtitle {
            margin-top: 1rem;
          }
        }
      }

      .view-container-content {
        flex-grow: 1;
      }

      .view-container-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        bottom: -${LAYOUT_PADDING.bottom};
        padding: 2.6rem 0;
        margin: 0 -${LAYOUT_PADDING.right} 0 -${LAYOUT_PADDING.left};
        margin-bottom: -${LAYOUT_PADDING.bottom};
        border-top: 0.1rem solid ${palette.gray.xxGrayLightest};
        background: #fbfbfb;
        > button:not(:last-child) {
          margin-right: 2.4rem;
        }
      }
    `;
  }}
`;

StyledViewContainer.displayName = 'StyledViewContainer';
export default StyledViewContainer;
