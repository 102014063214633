const initialState = {
  isOverlayVisible: false,
  isLoading: false,
  list: [],
  details: {
    isOverlayVisible: false,
    isLoading: false,
    data: {},
  },
  error: null,
};

export default initialState;
