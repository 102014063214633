import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';

export const getNotificationGroupsObject = (data) => {
  if (isBlank(data)) return {};

  const typesObject = data.reduce((ac, cv) => {
    if (isBlank(R.path([cv.notificationTypeOwner], ac))) {
      ac[cv.notificationTypeOwner] = {};
    }

    ac[cv.notificationTypeOwner][cv.notificationType] = cv.id;
    return ac;
  }, {});

  return typesObject;
};

export const formToChannels = (payload, { channels, groups }) => {
  if (!payload) return channels.length ? channels : null;

  const notificationGroupsObject = getNotificationGroupsObject(groups);

  const notificationGroups = Object.keys(notificationGroupsObject).map((group) => {
    const newGroups = Object.keys(notificationGroupsObject[group]).map((types) => {
      return {
        id: notificationGroupsObject[group][types],
        title: types,
        notificationTypeOwner: group,
        notificationType: types,
        isEnabled: payload[types],
      };
    });

    return {
      title: group,
      notificationTypesOwner: group,
      notificationTypes: newGroups,
    };
  });

  const newChannel = {
    title: payload.title,
    channelType: payload.channelType,
    destinations: Object.values(payload.destinations),
    notificationGroups,
  };

  if (R.path(['id'], payload)) {
    newChannel.id = payload.id;
    const newChannels = channels.map((channel) =>
      channel.id === payload.id ? newChannel : channel
    );

    return newChannels;
  }

  return [...(channels || []), newChannel];
};
