import { call, cancel, put, fork, take } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_UPDATE_ACTION_ITEM as ENDPOINT } from 'env-config';
import { post } from 'modules/utils';
import { cancelActionItemsSuccess } from '../actions';
import { updateActionItemFail, updateActionItemStart, updateActionItemSuccess } from '../actions/update-action-item';
import types from '../types';

export const httpCall = (data) => post({ route: ENDPOINT, data });

export function* updateActionItem({ actionItem, workItemIndex, actionItemIndex }) {
  try {
    yield put(updateActionItemStart());

    const payload = {
      workItemID: actionItem.workItemID,
      actionItemID: actionItem.id,
      actionItem,
    };
    yield call(httpCall, payload);

    yield put(updateActionItemSuccess({ actionItem, workItemIndex, actionItemIndex }));
  } catch (error) {
    yield put(updateActionItemFail(error));
    ToastNotification('error', error.message || 'There was an error trying to update action item');
  }
}

function* cancelActionItem() {
  yield put(cancelActionItemsSuccess());
}

// eslint-disable-next-line func-names
const takeLatest = (patternOrChannel, saga, ...args) =>
  // eslint-disable-next-line func-names
  fork(function* () {
    let lastTask;

    while (true) {
      const action = yield take(patternOrChannel);
      const sagaIndex = patternOrChannel.indexOf(action.type);

      if (lastTask) {
        yield cancel(lastTask);
      }

      lastTask = yield fork(saga[sagaIndex], ...args.concat(action));
    }
  });

export default function* updateActionItemSaga() {
  yield takeLatest([types.UPDATE_ACTION_ITEM, types.CANCEL_ACTION_ITEMS], [updateActionItem, cancelActionItem]);
}
