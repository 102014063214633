import styled, { css } from 'styled-components';
import { sidebarSpacing } from '../Sidebar/Sidebar.style';

export const TOP_BAR_HEIGHT = '6rem';

export const LAYOUT_PADDING = {
  top: '4rem',
  right: '4rem',
  bottom: '4rem',
  left: '4rem',
};

const StyledSidebarOffset = styled.div`
  ${({ isSidebarCollapsed }) => {
    return css`
      padding-top: ${TOP_BAR_HEIGHT};
      padding-left: ${isSidebarCollapsed ? sidebarSpacing.collapsedWidth : sidebarSpacing.width};
      transition: padding 0.2s ease;
      height: 100%;
      .layout-header {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: ${TOP_BAR_HEIGHT};
        z-index: 1;
        .topbar {
          flex: 1;
          box-shadow: 0.6rem 0 0.6rem rgb(0 0 0 / 10%);
          z-index: 2;
        }
      }
    `;
  }}
`;

const StyledLayout = styled.div`
  ${() => {
    return css`
      display: flex;
      position: relative;
      height: 100%;
      padding: ${LAYOUT_PADDING.top} ${LAYOUT_PADDING.right} ${LAYOUT_PADDING.bottom} ${LAYOUT_PADDING.left}};
      overflow: auto;
      flex-direction: column;
      .layout-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        & > * {
          flex-basis: 100%;
        }
      }
    `;
  }}
`;

StyledSidebarOffset.displayName = 'StyledSidebarOffset';
StyledLayout.displayName = 'StyledLayout';
export { StyledSidebarOffset, StyledLayout };
