import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  togglePlanOverlay,
  setDefaultPlanName,
  setDefaultPlanSkus,
  getDefaultPlansStore,
  getDefaultPlanStore,
  createDefaultPlanStore,
  putDefaultPlanStore,
  deleteDefaultPlanStore,
  getPlanSkusStore,
  resetState,
} from '../actions';
import initialState from './initialState';

const togglePlanOverlayHandler = [
  togglePlanOverlay,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isVisible: action.payload,
        ...(!action.payload && R.clone(initialState.planOverlay)),
      },
    };
  },
];

const setDefaultPlanNameHandler = [
  setDefaultPlanName,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        data: {
          ...state.planOverlay.data,
          planName: action.payload,
        },
      },
    };
  },
];

const setDefaultPlanSkusHandler = [
  setDefaultPlanSkus,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        data: {
          ...state.planOverlay.data,
          finClusiveSKUs: action.payload,
        },
      },
    };
  },
];

const getDefaultPlansStoreHandler = [
  getDefaultPlansStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    return {
      ...state,
      isLoading: false,
      list: payload,
    };
  },
];

const getDefaultPlanStoreHandler = [
  getDefaultPlanStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isLoading: false,
        data: {
          ...R.omit(['finClusiveSKUsMetadata'], payload),
          finClusiveSKUs: R.path(['finClusiveSKUsMetadata'], payload),
        },
      },
    };
  },
];

const createDefaultPlanStoreHandler = [
  createDefaultPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isCreating: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isCreating: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isCreating: false,
      },
    };
  },
];

const putDefaultPlanStoreHandler = [
  putDefaultPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isUpdating: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isUpdating: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isUpdating: false,
      },
    };
  },
];

const deleteDefaultPlanStoreHandler = [
  deleteDefaultPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isDeleting: true,
      };
    }

    if (error) {
      return {
        ...state,
        isDeleting: false,
        error,
      };
    }

    return {
      ...state,
      isDeleting: false,
    };
  },
];

const getPlanSkusStoreHandler = [
  getPlanSkusStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isLoading: false,
        data: {
          finClusiveSKUs: payload,
        },
      },
    };
  },
];

const resetStateHandler = [resetState, () => R.clone(initialState)];

const reducer = handleActions(
  new Map([
    togglePlanOverlayHandler,
    setDefaultPlanNameHandler,
    setDefaultPlanSkusHandler,
    getDefaultPlansStoreHandler,
    getDefaultPlanStoreHandler,
    createDefaultPlanStoreHandler,
    putDefaultPlanStoreHandler,
    deleteDefaultPlanStoreHandler,
    getPlanSkusStoreHandler,
    resetStateHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
