import styled, { css } from 'styled-components';

const StyledTools = styled.div`
  ${() => {
    return css`
      display: flex;
      flex-direction: row-reverse;
      flex: 1 1;
      .tool-actions {
        &:not(:first-child) {
          margin-right: 1.6rem;
        }
      }
      }
    `;
  }}
`;

StyledTools.displayName = 'StyledTools';
export default StyledTools;
