import { ToastNotification } from 'components/library';
import * as R from 'ramda';

const VALIDATION_HTTP_RESPONSE_STATUS = 400;
const VALIDATION_ERROR_CODE = 422;

export const handleValidationErrors = (err) => {
  const status = R.path(['response', 'status'], err);
  const data = R.path(['response', 'data'], err);
  const errorCode = R.path(['errorCode'], data);

  let errorMessage = '';
  if (status === VALIDATION_HTTP_RESPONSE_STATUS && errorCode === VALIDATION_ERROR_CODE) {
    const errors = R.path(['errors'], data);

    if (errors) {
      if (Array.isArray(errors)) {
        errorMessage = errors.length === 1 ? errors[0] : errors;
      } else {
        errorMessage = errors;
      }
    } else {
      errorMessage = data.message;
    }

    if (errorMessage) {
      ToastNotification('error', errorMessage);
      return true;
    }
  }
  return false;
};
