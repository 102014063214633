const actionTypes = {
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  GET_TRIGGER_CONFIGS: 'GET_TRIGGER_CONFIGS',
  GET_TRIGGER_CONFIGS_START: 'GET_TRIGGER_CONFIGS_START',
  GET_TRIGGER_CONFIGS_SUCCESS: 'GET_TRIGGER_CONFIGS_SUCCESS',
  GET_TRIGGER_CONFIGS_FAIL: 'GET_TRIGGER_CONFIGS_FAIL',
  // GLOBAL RULES
  GET_GLOBAL_RULES: 'GET_GLOBAL_RULES',
  GET_GLOBAL_RULES_START: 'GET_GLOBAL_RULES_START',
  GET_GLOBAL_RULES_SUCCESS: 'GET_GLOBAL_RULES_SUCCESS',
  GET_GLOBAL_RULES_FAIL: 'GET_GLOBAL_RULES_FAIL',
  EDIT_GLOBAL_RULE: 'EDIT_GLOBAL_RULE',
  EDIT_GLOBAL_RULE_START: 'EDIT_GLOBAL_RULE_START',
  EDIT_GLOBAL_RULE_SUCCESS: 'EDIT_GLOBAL_RULE_SUCCESS',
  EDIT_GLOBAL_RULE_FAIL: 'EDIT_GLOBAL_RULE_FAIL',
  GET_GLOBAL_RULE_DETAILS: 'GET_GLOBAL_RULE_DETAILS',
  GET_GLOBAL_RULE_DETAILS_START: 'GET_GLOBAL_RULE_DETAILS_START',
  GET_GLOBAL_RULE_DETAILS_SUCCESS: 'GET_GLOBAL_RULE_DETAILS_SUCCESS',
  GET_GLOBAL_RULE_DETAILS_FAIL: 'GET_GLOBAL_RULE_DETAILS_FAIL',
  TOGGLE_GLOBAL_RULE_FORM_OVERLAY: 'TOGGLE_GLOBAL_RULE_FORM_OVERLAY',
  UPDATE_GLOBAL_RULE: 'UPDATE_GLOBAL_RULE',

  // OVERRIDEN RULES
  GET_OVERRIDEN_RULES: 'GET_OVERRIDEN_RULES',
  GET_OVERRIDEN_RULES_START: 'GET_OVERRIDEN_RULES_START',
  GET_OVERRIDEN_RULES_SUCCESS: 'GET_OVERRIDEN_RULES_SUCCESS',
  GET_OVERRIDEN_RULES_FAIL: 'GET_OVERRIDEN_RULES_FAIL',

  GET_OVERRIDEN_ENTITY_RULES: 'GET_OVERRIDEN_ENTITY_RULES',
  GET_OVERRIDEN_ENTITY_RULES_START: 'GET_OVERRIDEN_ENTITY_RULES_START',
  GET_OVERRIDEN_ENTITY_RULES_SUCCESS: 'GET_OVERRIDEN_ENTITY_RULES_SUCCESS',
  GET_OVERRIDEN_ENTITY_RULES_FAIL: 'GET_OVERRIDEN_ENTITY_RULES_FAIL',

  SET_OVERRIDEN_RULES_PAGE_DATA: 'SET_OVERRIDEN_RULES_PAGE_DATA',
  TOGGLE_APPLY_OVERRIDEN_RULES_FORM_OVERLAY: 'TOGGLE_APPLY_OVERRIDEN_RULES_FORM_OVERLAY',
  SET_OVERRIDE_FORM_DATA: 'SET_OVERRIDE_FORM_DATA',
  ADD_OVERRIDE_FORM_RULE: 'ADD_OVERRIDE_FORM_RULE',
  REMOVE_OVERRIDE_FORM_RULE: 'REMOVE_OVERRIDE_FORM_RULE',
  UPDATE_OVERRIDE_FORM_RULE: 'UPDATE_OVERRIDE_FORM_RULE',
  UPDATE_OVERRIDE_FORM_RULE_DATA: 'UPDATE_OVERRIDE_FORM_RULE_DATA',
  UPDATE_OVERRIDE_FORM_RULE_ALERT_DATA: 'UPDATE_OVERRIDE_FORM_RULE_ALERT_DATA',

  ADD_OVERRIDEN_ENTITY_RULES: 'ADD_OVERRIDEN_ENTITY_RULES',
  ADD_OVERRIDEN_ENTITY_RULES_START: 'ADD_OVERRIDEN_ENTITY_RULES_START',
  ADD_OVERRIDEN_ENTITY_RULES_SUCCESS: 'ADD_OVERRIDEN_ENTITY_RULES_SUCCESS',
  ADD_OVERRIDEN_ENTITY_RULES_FAIL: 'ADD_OVERRIDEN_ENTITY_RULES_FAIL',

  DELETE_OVERRIDEN_ENTITY_RULES: 'DELETE_OVERRIDEN_ENTITY_RULES',
  DELETE_OVERRIDEN_ENTITY_RULES_START: 'DELETE_OVERRIDEN_ENTITY_RULES_START',
  DELETE_OVERRIDEN_ENTITY_RULES_SUCCESS: 'DELETE_OVERRIDEN_ENTITY_RULES_SUCCESS',
  DELETE_OVERRIDEN_ENTITY_RULES_FAIL: 'DELETE_OVERRIDEN_ENTITY_RULES_FAIL',
};

export default actionTypes;
