import types from '../types';

export const updateActionItemStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isUpdatingActionItem: true,
  },
});

export const updateActionItemSuccess = (state, { data }) => {
  const { actionItem, workItemIndex, actionItemIndex } = data;
  const { currentWorkItems } = state;
  currentWorkItems[workItemIndex].actions[actionItemIndex] = actionItem;

  return {
    ...state,
    loaders: {
      ...state.loaders,
      isUpdatingActionItem: false,
    },
    currentWorkItems,
  };
};

export const updateActionItemFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isUpdatingActionItem: false,
  },
  error,
});

export default {
  [types.UPDATE_ACTION_ITEM_START]: updateActionItemStart,
  [types.UPDATE_ACTION_ITEM_SUCCESS]: updateActionItemSuccess,
  [types.UPDATE_ACTION_ITEM_FAIL]: updateActionItemFail,
};
