import types from './types';

export const cancelActionItems = () => ({
  type: types.CANCEL_ACTION_ITEMS,
});

export const cancelActionItemsSuccess = () => ({
  type: types.CANCEL_ACTION_ITEMS_SUCCESS,
});

export const changeActionItem = (actionItem) => ({
  type: types.CHANGE_ACTION_ITEM,
  actionItem,
});

export const setSearchValue = ({ query }) => ({
  type: types.SET_SEARCH_WORK_ITEM,
  query,
});

export const setSelectedRow = (index) => ({
  type: types.SET_SELECTED_ROW,
  index,
});

export const setSelectedPage = (page) => ({
  type: types.SET_SELECTED_PAGE,
  page,
});

export const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE,
  page,
});

export const removeCurrentClient = () => ({
  type: types.REMOVE_CURRENT_CLIENT,
});

export const removeCurrentWorkItems = () => ({
  type: types.REMOVE_CURRENT_WORK_ITEMS,
});

export const setSortOrder = ({ sortOrder }) => ({
  type: types.SET_SORT_ORDER,
  sortOrder,
});

export const toggleEDDOverlay = (isVisible) => ({
  type: types.TOGGLE_EDD_OVERLAY,
  isVisible,
});
