import { TAB_KEYS } from '../components/components/TransactionDetailView/constants';

const initialState = {
  list: {
    isLoading: false,
    data: {},
    results: [],
    pageFilters: {
      pageNo: 1,
      pageSize: 10,
    },
    selectedRow: {
      index: null,
    },
  },
  detailView: {
    isVisible: false,
    activeTab: TAB_KEYS.details,
    selectedTransaction: null,
    transactionDetail: {
      isLoading: false,
      isClaimTransactionLoading: false,
      isClearTransactionLoading: false,
      note: '',
      data: {},
    },
    transactionAlerts: {
      isLoading: false,
      loadingAlert: null,
      list: [],
      notes: {},
      expandedAlerts: [],
    },
  },
  filters: {},
};

export default initialState;
