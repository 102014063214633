const BANKING_FLAG_TYPES = {
  isSepaEnabled: 'isSepaEnabled',
};

const BANKING_GLOBAL_FEATURE_FLAGS = {
  [BANKING_FLAG_TYPES.isSepaEnabled]: 'CORE_SEPAEnabled',
};

const BANKING_CUSTOMER_FEATURE_FLAGS = {};

const FEATURE_FLAGS = {
  ...BANKING_FLAG_TYPES,
};

export const FEATURE_FLAGS_VALUES = {
  ...BANKING_GLOBAL_FEATURE_FLAGS,
  ...BANKING_CUSTOMER_FEATURE_FLAGS,
};

export default FEATURE_FLAGS;
