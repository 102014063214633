import React from 'react';
import PropTypes from 'prop-types';

const DetailItem = ({ className, name, value, onClick }) => (
  <div className={`detail-view__item ${className}`}>
    <div className="detail-view__name">{name}</div>
    <div
      className={`detail-view__value ${onClick ? 'detail-view__link' : ''}`}
      onClick={onClick || undefined}
    >
      {value}
    </div>
  </div>
);

DetailItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.node,
  value: PropTypes.node,
};
DetailItem.defaultProps = {
  className: '',
};

export default DetailItem;
