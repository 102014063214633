import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cls from 'classnames';
import { Loader } from '@finclusive/library';
import { isBlank, noop } from '@finclusive/utils';
import Icon from 'components/library/Icon';
import TableCell from '../TableCell';
import StyledTableBody from './TableBody.style';

const TableBody = ({
  isLoading,
  enableHover,
  dataTest,
  data,
  selected,
  selectedIcon,
  noDataFoundMessage,
  reorderCells,
  getDocument,
}) => {
  if (isLoading) {
    return (
      <StyledTableBody>
        <tr className="table__row table__row--no-border">
          <TableCell value={<Loader size="medium" />} />
        </tr>
        {[...Array(10).keys()].map((key) => (
          <tr key={key} className="table__row table__row--no-border" />
        ))}
      </StyledTableBody>
    );
  }

  if (isBlank(data)) {
    return (
      <StyledTableBody>
        <tr className="table__row table__row--no-border">
          <TableCell value={<div className="no-data">{noDataFoundMessage}</div>} />
        </tr>
        {[...Array(10).keys()].map((key) => (
          <tr key={key} className="table__row table__row--no-border" />
        ))}
      </StyledTableBody>
    );
  }

  return (
    <StyledTableBody data-test={`${dataTest}-body`}>
      {data.map(({ id, rowClassName, cells, fileName, onClick }, idx) => {
        const cellsMap = reorderCells(cells);
        const isRowClickable = R.is(Function, onClick);

        return (
          <tr
            key={`table-row-${idx}`}
            className={cls('table__row', rowClassName, {
              'table__row--selected': selected === idx,
              'table__row--hover-enabled': isRowClickable || enableHover,
            })}
            data-test={`${dataTest}-body-row-${idx}`}
            onClick={(event) => {
              if (isRowClickable) {
                onClick(event);
              }
            }}
          >
            {cellsMap.map((cellData, colIdx) => (
              <TableCell
                key={`table-row-${idx}-cell-${colIdx}`}
                className={cellData.className}
                dataTest={`${dataTest}-body-row-${idx}-cell-${colIdx}`}
                isDownload={cellData.isDownload}
                name={cellData.name}
                value={cellData.value}
                icon={cellData.icon}
                size={cellData.size}
                tooltip={cellData.size}
                id={id}
                fileName={fileName}
                rowIndex={idx}
                colIndex={colIdx}
                getDocument={getDocument}
              />
            ))}
            <td
              className={cls('table__selected-icon', {
                'table__selected-icon--selected': selected === idx,
              })}
              data-test={`${dataTest}-body-row-${idx}-cell-icon`}
            >
              <Icon name={selectedIcon} />
            </td>
          </tr>
        );
      })}
    </StyledTableBody>
  );
};

TableBody.defaultProps = {
  isLoading: false,
  enableHover: false,
  dataTest: '',
  selected: null,
  selectedIcon: '',
  noDataFoundMessage: '',
  reorderCells: noop,
  getDocument: noop,
};

TableBody.propTypes = {
  isLoading: PropTypes.bool,
  enableHover: PropTypes.bool,
  dataTest: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      rowClassName: PropTypes.string,
      cells: PropTypes.shape({
        className: PropTypes.string,
        isDownload: PropTypes.bool,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        icon: PropTypes.string,
        size: PropTypes.number,
        tooltip: PropTypes.string,
      }),
      fileName: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  selected: PropTypes.number,
  selectedIcon: PropTypes.string,
  noDataFoundMessage: PropTypes.string,
  reorderCells: PropTypes.func,
  getDocument: PropTypes.func,
};

export default TableBody;
