import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getItem, SSTORAGE_KEYS } from 'modules/utils';
import ROUTE_PATHS from 'routes/routePaths';
import userManager from '../../UserManager';

function LoginRedirect({ history }) {
  const redirect = useCallback(async () => {
    await userManager.handleRedirectPromise();
    const isAuthenticated = userManager.isAuthenticated();
    if (isAuthenticated) {
      const redirectPath = getItem(SSTORAGE_KEYS.fincPersistedRoutePath) || ROUTE_PATHS.DASHBOARD;
      history.push(redirectPath);
    } else {
      history.push(ROUTE_PATHS.LOGIN);
    }
  }, [history]);

  useEffect(() => {
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

LoginRedirect.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(LoginRedirect);
