const actionTypes = {
  SET_TRANSACTION_PAGE_FILTERS: 'SET_TRANSACTION_PAGE_FILTERS',
  SET_SELECTED_TRANSACTION: 'SET_SELECTED_TRANSACTION',
  CLEAR_SELECTED_TRANSACTION: 'CLEAR_SELECTED_TRANSACTION',
  SET_ALERT_NOTE: 'SET_ALERT_NOTE',
  SET_TRANSACTION_NOTE: 'SET_TRANSACTION_NOTE',
  SET_TRANSACTION_STATUS_FILTER: 'SET_TRANSACTION_STATUS_FILTER',
  SET_TRANSACTION_CLAIMED_FILTER: 'SET_TRANSACTION_CLAIMED_FILTER',
  SET_DETAIL_VIEW_ACTIVE_TAB: 'SET_DETAIL_VIEW_ACTIVE_TAB',
  TOGGLE_DETAIL_VIEW: 'TOGGLE_DETAIL_VIEW',
  SET_ALERT_EXPANDED: 'SET_ALERT_EXPANDED',
  SET_ALERT_COLLAPSED: 'SET_ALERT_COLLAPSED',

  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_STORE: 'GET_TRANSACTIONS_STORE',

  GET_TRANSACTION_DETAILS: 'GET_TRANSACTION_DETAILS',
  GET_TRANSACTION_DETAILS_STORE: 'GET_TRANSACTION_DETAILS_STORE',

  GET_TRANSACTION_ALERTS: 'GET_TRANSACTION_ALERTS',
  GET_TRANSACTION_ALERTS_STORE: 'GET_TRANSACTION_ALERTS_STORE',

  CLEAR_ALERT: 'CLEAR_ALERT',
  CLEAR_ALERT_STORE: 'CLEAR_ALERT_STORE',

  CLAIM_TRANSACTION: 'CLAIM_TRANSACTION',
  CLAIM_TRANSACTION_STORE: 'CLAIM_TRANSACTION_STORE',

  CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',
  CLEAR_TRANSACTION_STORE: 'CLEAR_TRANSACTION_STORE',

  GET_HISTORICAL_TRANSACTIONS: 'GET_HISTORICAL_TRANSACTIONS',
  GET_HISTORICAL_TRANSACTIONS_STORE: 'GET_HISTORICAL_TRANSACTIONS_STORE',

  SET_TRANSACTION_FILTERS: 'SET_TRANSACTION_FILTERS',
};

export default actionTypes;
