import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';

const updateCurrencyLimitConfigs = (currencyIndex, currencyLimitConfigsMap, actualValue) => {
  return R.update(
    currencyIndex,
    {
      ...currencyLimitConfigsMap[currencyIndex],
      ...actualValue,
    },
    currencyLimitConfigsMap
  );
};

const updateBankConfigTypeMap = (channelIndex, bankConfigTypeMap, currencyLimitConfigs) => {
  return R.update(
    channelIndex,
    {
      ...bankConfigTypeMap[channelIndex],
      currencyLimitConfigs,
    },
    bankConfigTypeMap
  );
};

const updateBankLimitConfigsMap = (selectedBank, configEdit, bankConfigType, configType) => {
  return {
    ...configEdit,
    [selectedBank]: {
      ...configEdit[selectedBank],
      [configType]: bankConfigType,
    },
  };
};

const updateBankLimitConfigs = (limits, data) => {
  const { selectedBank, configEdit } = limits;
  const { configType, channelName, currencyName, method, methodType, value } = data;
  const bankConfigTypeMap = R.path([selectedBank, configType], configEdit) || [];
  const channelIndex = R.findIndex(R.propEq('channelName', channelName))(bankConfigTypeMap);
  const currencyLimitConfigsMap =
    R.path([channelIndex, 'currencyLimitConfigs'], bankConfigTypeMap) || [];
  const currencyIndex = R.findIndex(R.propEq('currencyName', currencyName))(
    currencyLimitConfigsMap
  );

  const actualValue = isBlank(method)
    ? { isTradeAllowed: value }
    : {
        [method]: {
          ...currencyLimitConfigsMap[currencyIndex][method],
          [methodType]: value,
        },
      };

  const currencyLimitConfigs = updateCurrencyLimitConfigs(
    currencyIndex,
    currencyLimitConfigsMap,
    actualValue
  );
  const bankConfigType = updateBankConfigTypeMap(
    channelIndex,
    bankConfigTypeMap,
    currencyLimitConfigs
  );
  const bankLimitConfigs = updateBankLimitConfigsMap(
    selectedBank,
    configEdit,
    bankConfigType,
    configType
  );

  return bankLimitConfigs;
};

export default updateBankLimitConfigs;
