export const PARTIES_TYPE = {
  None: 0,
  Sender: 1,
  Receiver: 2,
  Both: 3,
};

export const PARTIES = {
  unset: {
    value: PARTIES_TYPE.None,
    label: 'None',
  },
  sender: {
    value: PARTIES_TYPE.Sender,
    label: 'Sender',
  },
  receiver: {
    value: PARTIES_TYPE.Receiver,
    label: 'Receiver',
  },
  both: {
    value: PARTIES_TYPE.Both,
    label: 'Both',
  },
};

export const AML_TRANSACTION_RISKS = {
  low: 0,
  medium: 1,
  high: 2,
};

export const AML_TRANSACTION_RISKS_LABELS = {
  [AML_TRANSACTION_RISKS.low]: 'Low',
  [AML_TRANSACTION_RISKS.medium]: 'Medium',
  [AML_TRANSACTION_RISKS.high]: 'High',
};

export const AML_NOTIFICATION_FREQUENCIES = {
  never: {
    value: 0,
    label: 'Never',
  },
  immediately: {
    value: 1,
    label: 'Immediately',
  },
  hourly: {
    value: 2,
    label: 'Hourly',
  },
  every4Hours: {
    value: 3,
    label: 'Every 4 Hours',
  },
  every8Hours: {
    value: 4,
    label: 'Every 8 Hours',
  },
  daily: {
    value: 5,
    label: 'Daily',
  },
};

export const ALERT_SEVERITIES = {
  [AML_TRANSACTION_RISKS.low]: {
    label: 'Low',
    type: 'success',
  },
  [AML_TRANSACTION_RISKS.medium]: {
    label: 'Medium',
    type: 'warning',
  },
  [AML_TRANSACTION_RISKS.high]: {
    label: 'High',
    type: 'error',
  },
};

export const ENTITY_TYPES = {
  CUSTOMER: 0,
  CLIENT: 1,
  ACCOUNT: 2,
};

export const ENTITIES = {
  [ENTITY_TYPES.CUSTOMER]: {
    value: 'customer',
    label: 'Customer',
  },
  [ENTITY_TYPES.CLIENT]: {
    value: 'client',
    label: 'Client',
  },
  [ENTITY_TYPES.ACCOUNT]: {
    value: 'account',
    label: 'Account',
  },
};

export const ENTITY_ID_LABEL = {
  [ENTITY_TYPES.ACCOUNT]: 'Account ID',
  [ENTITY_TYPES.CUSTOMER]: 'FinClusive Customer ID',
  [ENTITY_TYPES.CLIENT]: 'FinClusive Client ID',
};

export const CHANNELS = {
  AllChannels: {
    value: null,
    label: 'All Channels',
  },
  ACHCredit: {
    value: 0,
    label: 'ACH Credit',
  },
  ACHDebit: {
    value: 1,
    label: 'ACH Debit',
  },
  DomesticWires: {
    value: 2,
    label: 'Domestic Wires',
  },
  InternationalWires: {
    value: 3,
    label: 'International Wires',
  },
  Stellar: {
    value: 4,
    label: 'Stellar',
  },
  Celo: {
    value: 5,
    label: 'Celo',
  },
  SEPA: {
    value: 6,
    label: 'SEPA',
  },
};

export const TRANSACTION_MONITORING_HEADING = 'Transaction Monitoring';

export const BREADCRUMB_ITEMS = [
  {
    label: TRANSACTION_MONITORING_HEADING,
  },
];

export const EMPTY_VALUE = '-';
