const actionTypes = {
  GET_INTERNAL_USERS: 'GET_INTERNAL_USERS',
  GET_INTERNAL_USERS_START: 'GET_INTERNAL_USERS_START',
  GET_INTERNAL_USERS_SUCCESS: 'GET_INTERNAL_USERS_SUCCESS',
  GET_INTERNAL_USERS_FAIL: 'GET_INTERNAL_USERS_FAIL',

  GET_INTERNAL_USER_DETAILS: 'GET_INTERNAL_USER_DETAILS',
  GET_INTERNAL_USER_DETAILS_START: 'GET_INTERNAL_USER_DETAILS_START',
  GET_INTERNAL_USER_DETAILS_SUCCESS: 'GET_INTERNAL_USER_DETAILS_SUCCESS',
  GET_INTERNAL_USER_DETAILS_FAIL: 'GET_INTERNAL_USER_DETAILS_FAIL',

  GET_INTERNAL_PERMISSIONS: 'GET_INTERNAL_PERMISSIONS',
  GET_INTERNAL_PERMISSIONS_START: 'GET_INTERNAL_PERMISSIONS_START',
  GET_INTERNAL_PERMISSIONS_SUCCESS: 'GET_INTERNAL_PERMISSIONS_SUCCESS',
  GET_INTERNAL_PERMISSIONS_FAIL: 'GET_INTERNAL_PERMISSIONS_FAIL',

  PATCH_INTERNAL_USER_DETAILS: 'PATCH_INTERNAL_USER_DETAILS',
  PATCH_INTERNAL_USER_DETAILS_START: 'PATCH_INTERNAL_USER_DETAILS_START',
  PATCH_INTERNAL_USER_DETAILS_SUCCESS: 'PATCH_INTERNAL_USER_DETAILS_SUCCESS',
  PATCH_INTERNAL_USER_DETAILS_FAIL: 'PATCH_INTERNAL_USER_DETAILS_FAIL',

  SET_FILTER: 'SET_FILTER',
  SET_SELECTED_ROW: 'SET_SELECTED_ROW',
};

export default actionTypes;
