import React from 'react';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { Input, Tooltip } from 'components/library';
import { FIELD_TYPES } from '../GlobalRules/components/RuleForm/constants';
import { ALERT_SEVERITIES } from '../../../../constants';
import { getHigherSeverityArray } from './helpers';

const Field = ({ config, value, onChange, entityType }) => {
  const { displayLabel, name, type, disabled, required, description, tooltipIdentifier } = config;

  switch (type) {
    case FIELD_TYPES.number:
      return (
        <Input
          key={`${name}-${entityType}`}
          tooltipIdentifier={`${tooltipIdentifier}-${name}-${entityType}`}
          name="integerNumber"
          label={displayLabel}
          value={`${value}`}
          onChange={({ target: { value: inputValue } }) =>
            onChange({ name, value: isBlank(inputValue) ? '' : parseFloat(inputValue) })
          }
          type="number"
          tooltip={description}
          disabled={disabled}
          required={required}
          min={1}
        />
      );
    case FIELD_TYPES.currency:
      return (
        <Input
          key={name}
          tooltipIdentifier={`${tooltipIdentifier}-${name}-${entityType}`}
          name="amount"
          label={displayLabel}
          autoFormat
          value={`${value}`}
          tooltip={description}
          onChange={({ target: { rawValue } }) => {
            const inputValue = rawValue.split('$ ')[1];
            onChange({
              name,
              value: isBlank(inputValue) || inputValue.includes('-') ? '' : parseFloat(inputValue),
            });
          }}
          disabled={disabled}
          required={required}
        />
      );
    case FIELD_TYPES.riskMapping:
      return (
        <>
          <div className="rule-form__section-header">
            <span>{displayLabel}</span>
            <Tooltip tooltip={description} identifier={`${tooltipIdentifier}-${name}`} />
          </div>
          <div>
            {R.map((valueKey) => {
              return (
                <Input
                  key={valueKey}
                  type="select"
                  name="select"
                  label={valueKey}
                  value={value[valueKey]}
                  onChange={({ target }) => {
                    onChange({
                      name,
                      value: { ...value, [valueKey]: parseFloat(target.value) },
                    });
                  }}
                  disabled={disabled}
                  required={required}
                  requiresValidation={false}
                >
                  {R.map((key) => {
                    const { label } = ALERT_SEVERITIES[key];
                    return (
                      <option key={key} value={key}>
                        {`${label} - FinClusive Severity`}
                      </option>
                    );
                  }, R.keys(ALERT_SEVERITIES))}
                </Input>
              );
            }, R.keys(value))}
          </div>
        </>
      );
    case FIELD_TYPES.riskList:
      return (
        <>
          <div className="rule-form__section-header">
            <span>{displayLabel}</span>
            <Tooltip tooltip={description} identifier={`${tooltipIdentifier}-${name}`} />
          </div>
          <Input
            key={name}
            type="select"
            name="select"
            label="FinClusive Severity"
            value={R.prop(0, value)}
            onChange={({ target: { value: inputValue } }) => {
              onChange({
                name,
                value: getHigherSeverityArray(parseFloat(inputValue)),
              });
            }}
            disabled={disabled}
            required={required}
            requiresValidation={false}
          >
            {R.map((key) => {
              const { label } = ALERT_SEVERITIES[key];
              return (
                <option key={key} value={key}>
                  {label}
                </option>
              );
            }, R.keys(ALERT_SEVERITIES))}
          </Input>
        </>
      );
    default:
      return <></>;
  }
};

export default Field;
