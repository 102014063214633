import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  getGlobalFeatureFlagsStart,
  getGlobalFeatureFlagsSuccess,
  getGlobalFeatureFlagsFail,
  getEntityFeatureFlagsStart,
  getEntityFeatureFlagsSuccess,
  getEntityFeatureFlagsFail,
} from '../actions';
import initialState from './initialState';

const getGlobalFeatureFlagsStartHandler = [
  getGlobalFeatureFlagsStart,
  (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
];

const getGlobalFeatureFlagsSuccessHandler = [
  getGlobalFeatureFlagsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      featureFlags: R.concat(state.featureFlags, payload),
    };
  },
];

const getGlobalFeatureFlagsFailHandler = [
  getGlobalFeatureFlagsFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      error: payload,
    };
  },
];

const getEntityFeatureFlagsStartHandler = [
  getEntityFeatureFlagsStart,
  (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
];

const getEntityFeatureFlagsSuccessHandler = [
  getEntityFeatureFlagsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      featureFlags: R.concat(state.featureFlags, payload),
    };
  },
];

const getEntityFeatureFlagsFailHandler = [
  getEntityFeatureFlagsFail,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      error: payload,
    };
  },
];

const reducer = handleActions(
  new Map([
    getGlobalFeatureFlagsStartHandler,
    getGlobalFeatureFlagsSuccessHandler,
    getGlobalFeatureFlagsFailHandler,
    getEntityFeatureFlagsStartHandler,
    getEntityFeatureFlagsSuccessHandler,
    getEntityFeatureFlagsFailHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
