import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/library/Icon';

const Navigation = ({ className, dataTest, icon, pageNumber, isDisabled, onClick }) => (
  <div
    className={`pagination__navigation ${isDisabled ? 'pagination__navigation--disabled' : ''}`}
    data-test={dataTest}
  >
    <div className="pagination__item pagination__item--with-icon" onClick={onClick(pageNumber)}>
      <Icon className={`pagination__icon ${className}`} name={icon} />
    </div>
  </div>
);

Navigation.defaultProps = {
  className: '',
  isDisabled: false,
};

Navigation.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Navigation;
