import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const togglePlanOverlay = createPrefixedAction(actionTypes.TOGGLE_PLAN_OVERLAY);
export const setDefaultPlanName = createPrefixedAction(actionTypes.SET_DEFAULT_PLAN_NAME);
export const setDefaultPlanSkus = createPrefixedAction(actionTypes.SET_DEFAULT_PLAN_SKUS);

export const getDefaultPlans = createPrefixedAction(actionTypes.GET_DEFAULT_PLANS_SAGA);
export const getDefaultPlansStore = createPrefixedAction(actionTypes.GET_DEFAULT_PLANS);

export const getDefaultPlan = createPrefixedAction(actionTypes.GET_DEFAULT_PLAN_SAGA);
export const getDefaultPlanStore = createPrefixedAction(actionTypes.GET_DEFAULT_PLAN);

export const createDefaultPlan = createPrefixedAction(actionTypes.CREATE_DEFAULT_PLAN_SAGA);
export const createDefaultPlanStore = createPrefixedAction(actionTypes.CREATE_DEFAULT_PLAN);

export const putDefaultPlan = createPrefixedAction(actionTypes.PUT_DEFAULT_PLAN_SAGA);
export const putDefaultPlanStore = createPrefixedAction(actionTypes.PUT_DEFAULT_PLAN);

export const deleteDefaultPlan = createPrefixedAction(actionTypes.DELETE_DEFAULT_PLAN_SAGA);
export const deleteDefaultPlanStore = createPrefixedAction(actionTypes.DELETE_DEFAULT_PLAN);

export const getPlanSkus = createPrefixedAction(actionTypes.GET_PLAN_SKUSS_SAGA);
export const getPlanSkusStore = createPrefixedAction(actionTypes.GET_PLAN_SKUS);

export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
