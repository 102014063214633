import types from '../types';

export const generateReportStart = () => ({
  type: types.GENERATE_REPORT_START,
});

export const generateReport = () => ({
  type: types.GENERATE_REPORT,
});

export const generateReportSuccess = () => ({
  type: types.GENERATE_REPORT_SUCCESS,
});

export const generateReportFail = (error) => ({
  type: types.GENERATE_REPORT_FAIL,
  error,
});
