import React from 'react';
import PropTypes from 'prop-types';
import logoFinclusive from 'assets/img/finclusive-logo.png';
import Icon from 'components/library/Icon';
import StyledSidebarHeader from './SidebarHeader.style';

const SidebarHeader = ({ isCollapsed, isNarrow, onSidebarCollapse }) => {
  return (
    <StyledSidebarHeader isCollapsed={isCollapsed} isNarrow={isNarrow} data-test="sidebar-logo-container">
      {isNarrow || isCollapsed ? (
        <Icon className="top-icon-menu" dataTest="sidebar-icon-menu" name="menu" onClick={onSidebarCollapse} />
      ) : (
        <Icon
          className="top-icon-back"
          dataTest="sidebar-icon-chevron-left"
          name="chevron-left"
          onClick={onSidebarCollapse}
        />
      )}
      <img className="logo" data-test="sidebar-logo" src={logoFinclusive} alt="FinClusive" />
    </StyledSidebarHeader>
  );
};

SidebarHeader.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  isNarrow: PropTypes.bool.isRequired,
  onSidebarCollapse: PropTypes.func.isRequired,
};

export default SidebarHeader;
