import { stringSanitize } from '@finclusive/utils';
import types from './types';

export const toggleTransactionSidebar = (isOpen) => ({
  type: types.TOGGLE_TRANSACTION_SIDEBAR,
  isOpen,
});

export const setTransactionFilter = (filterBy) => ({
  type: types.SET_TRANSACTION_FILTER,
  filterBy,
});

export const setTransactionFilterInput = (input) => ({
  type: types.SET_TRANSACTION_FILTER_INPUT,
  input,
});

export const setSelectedRow = (index, rowDetails, otcData) => ({
  type: types.SET_SELECTED_ROW,
  index,
  rowDetails,
  otcData,
});

export const setOtcData = (key, value) => ({
  type: types.SET_OTC_DATA,
  key,
  value: stringSanitize(value),
});

export const setReportDateInterval = (dateInterval) => ({
  type: types.SET_REPORT_DATE_INTERVAL,
  dateInterval,
});

export const toggleWiresStatusModal = (isOpen) => ({
  type: types.TOGGLE_WIRES_STATUS_MODAL,
  isOpen,
});

export const resetWiresStatusInfo = () => ({
  type: types.RESET_WIRES_STATUS_INFO,
});

export const setWiresStatusInfo = (key, value) => ({
  type: types.SET_WIRES_STATUS_INFO,
  key,
  value: stringSanitize(value),
});
