import qs from 'qs';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { filterEmptyKeys } from '@finclusive/utils';
import API from 'config/api';
import ROUTE_PATHS from 'routes/routePaths';
import { get } from 'modules/utils';
import userManager from '../components/UserManager';
import { addPrefix } from '../config';
import actionTypes from './types';
import { getUsersStore, getUserDataStore } from '.';

function* getUsersSagaCallback() {
  try {
    yield put(getUsersStore({ loading: true }));

    const params = qs.stringify(
      filterEmptyKeys({
        CurrentPage: 1,
        PageSize: 1000,
        IsActiveOnlyIncluded: true,
      })
    );
    const users = yield call(get, API.internalUserManagement.getInternalUsers(params));

    yield put(getUsersStore({ loading: false, payload: users }));
  } catch (error) {
    yield put(getUsersStore({ loading: false, error }));
  }
}

function* getUsersSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_USERS_SAGA), getUsersSagaCallback);
}

function* getUserDataSagaCallback() {
  try {
    yield put(getUserDataStore({ loading: true }));

    // notSignedInFlow
    if (!userManager.isAuthenticated()) {
      yield put(getUserDataStore({ loading: false, error: 'User is not logged in' }));
      yield put(push(ROUTE_PATHS.LOGIN));
    }

    const user = yield call(get, API.user.getUserMe);

    yield put(getUserDataStore({ loading: false, payload: user }));
  } catch (error) {
    yield put(getUserDataStore({ loading: false, error }));
    yield put(push(ROUTE_PATHS.LOGIN));
  }
}

function* getUserDataSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_USER_DATA_SAGA), getUserDataSagaCallback);
}

const sagas = [call(getUsersSaga), call(getUserDataSaga)];

export default sagas;
