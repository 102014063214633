import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get, putRequest } from 'modules/utils';
import API from 'config/api';
import { ToastNotification } from 'components/library';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  toggleVendorPricingOverlay,
  getDefaultVendors,
  getDefaultVendorsStore,
  getVendorSkusStore,
  getDefaultVendorPlanStore,
  saveDefaultVendorPlanStore,
} from '.';

function* getDefaultVendorsSagaCallback() {
  try {
    yield put(getDefaultVendorsStore({ loading: true }));
    const response = yield call(get, {
      route: API.billing.defaultPlans.thirdParties,
    });
    yield put(getDefaultVendorsStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getDefaultVendorsStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to retrieve the vendors list.');
  }
}

function* getDefaultVendorsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_DEFAULT_VENDORS_SAGA), getDefaultVendorsSagaCallback);
}

function* getVendorSkusSagaCallback({ payload: vendor }) {
  try {
    yield put(getVendorSkusStore({ loading: true, payload: vendor }));
    const response = yield call(get, {
      route: API.billing.skus.thirdPartySkus(R.path(['planType'], vendor)),
    });
    yield put(getVendorSkusStore({ loading: false, payload: { thirdPartySKUsMetadata: response } }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getVendorSkusStore({ loading: false, error }));
    yield put(toggleVendorPricingOverlay(false));
    ToastNotification('error', error || 'There was an error trying to retrieve the details for this vendor.');
  }
}

function* getVendorSkusSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_VENDOR_SKUS_SAGA), getVendorSkusSagaCallback);
}

function* getDefaultVendorPlanSagaCallback({ payload: vendor }) {
  try {
    yield put(getDefaultVendorPlanStore({ loading: true, payload: vendor }));
    const response = yield call(get, {
      route: API.billing.defaultPlans.thirdParty(R.path(['planType'], vendor)),
    });
    yield put(getDefaultVendorPlanStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getDefaultVendorPlanStore({ loading: false, error }));
    yield put(toggleVendorPricingOverlay(false));
    ToastNotification('error', error || 'There was an error trying to retrieve the details for this vendor.');
  }
}

function* getDefaultVendorPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_DEFAULT_VENDOR_PLAN_SAGA), getDefaultVendorPlanSagaCallback);
}

function* saveDefaultVendorPlanSagaCallback() {
  try {
    yield put(saveDefaultVendorPlanStore({ loading: true }));

    const vendor = yield select((state) => R.path(['billing', 'vendors', 'details', 'data'], state));

    yield call(putRequest, {
      route: API.billing.defaultPlans.thirdParty(R.path(['planType'], vendor)),
      data: {
        thirdPartySKUs: R.path(['thirdPartySKUsMetadata'], vendor),
      },
    });

    yield put(saveDefaultVendorPlanStore({ loading: false }));
    yield put(toggleVendorPricingOverlay(false));
    yield put(getDefaultVendors());
    ToastNotification('success', 'Vendor skus updated successfully.');
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(saveDefaultVendorPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to update the skus for this vendor.');
  }
}

function* saveDefaultVendorPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.SAVE_DEFAULT_VENDOR_PLAN_SAGA), saveDefaultVendorPlanSagaCallback);
}

export const vendorsSagas = [
  call(getDefaultVendorsSaga),
  call(getVendorSkusSaga),
  call(getDefaultVendorPlanSaga),
  call(saveDefaultVendorPlanSaga),
];
