const actionTypes = {
  TOGGLE_VENDORS_OVERLAY: 'TOGGLE_VENDORS_OVERLAY',
  TOGGLE_VENDOR_PRICING_OVERLAY: 'TOGGLE_VENDOR_PRICING_OVERLAY',
  UDPATE_VENDOR_SKU_LIST: 'UDPATE_VENDOR_SKU_LIST',

  GET_DEFAULT_VENDORS_SAGA: 'GET_DEFAULT_VENDORS_SAGA',
  GET_DEFAULT_VENDORS: 'GET_DEFAULT_VENDORS',

  GET_VENDOR_SKUS_SAGA: 'GET_VENDOR_SKUS_SAGA',
  GET_VENDOR_SKUS: 'GET_VENDOR_SKUS',

  GET_DEFAULT_VENDOR_PLAN_SAGA: 'GET_DEFAULT_VENDOR_PLAN_SAGA',
  GET_DEFAULT_VENDOR_PLAN: 'GET_DEFAULT_VENDOR_PLAN',

  SAVE_DEFAULT_VENDOR_PLAN_SAGA: 'SAVE_DEFAULT_VENDOR_PLAN_SAGA',
  SAVE_DEFAULT_VENDOR_PLAN: 'SAVE_DEFAULT_VENDOR_PLAN',

  RESET_STATE: 'RESET_STATE',
};

export default actionTypes;
