import types from '../types';

export const getEDDReportStart = (state) => ({
  ...state,
});

export const getEDDReportSuccess = (state, { success }) => ({
  ...state,
  success,
});

export const getEDDReportFail = (state, { error }) => ({
  ...state,
  error,
});

export default {
  [types.GET_EDD_REPORT_START]: getEDDReportStart,
  [types.GET_EDD_REPORT_SUCCESS]: getEDDReportSuccess,
  [types.GET_EDD_REPORT_FAIL]: getEDDReportFail,
};
