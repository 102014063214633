export default {
  SET_BREAKPOINT: 'browser/SET_BREAKPOINT',
  TOGGLE_DETAIL_VIEW: 'browser/TOGGLE_DETAIL_VIEW',
  COLLAPSE_SIDEBAR: 'browser/COLLAPSE_SIDEBAR',
  SWITHC_TO_VIEW: 'browser/SWITHC_TO_VIEW',
  TOGGLE_SUBMENU: 'browser/TOGGLE_SUBMENU',

  DASHBOARD_VIEW: 'browser/DASHBOARD_VIEW',
  NEWS_VIEW: 'browser/NEWS_VIEW',
  RESOURCES_VIEW: 'browser/RESOURCES_VIEW',
  CUSTOMERS_VIEW: 'browser/CUSTOMERS_VIEW',
  FEEDBACK_VIEW: 'browser/FEEDBACK_VIEW',
  TOGGLE_CLIENT_VIEW: 'browser/TOGGLE_CLIENT_VIEW',
};
