const actionTypes = {
  GET_ACCOUNT_SAGA: 'GET_ACCOUNT_SAGA',
  GET_ACCOUNT_STORE: 'GET_ACCOUNT_STORE',
  SET_ACCOUNT_NUMBER: 'SET_ACCOUNT_NUMBER',
  CLEAR_ACCOUNT: 'CLEAR_ACCOUNT',
  SET_TRANSACTION_FIELD: 'SET_TRANSACTION_FIELD',
  SET_ORIGINATOR_FIELD: 'SET_ORIGINATOR_FIELD',
  SET_RECEIVER_FIELD: 'SET_RECEIVER_FIELD',
  CREATE_TRANSACTION_SAGA: 'CREATE_TRANSACTION_SAGA',
  CREATE_TRANSACTION_STORE: 'CREATE_TRANSACTION_STORE',
  SET_TRANSACTION_DETAIL_ERRORS: 'SET_TRANSACTION_DETAIL_ERRORS',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  GET_TRANSACTIONS_SAGA: 'GET_TRANSACTIONS_SAGA',
  GET_TRANSACTIONS_STORE: 'GET_TRANSACTIONS_STORE',
  SET_TRANSACTION_PAGE_FILTERS: 'SET_TRANSACTION_PAGE_FILTERS',
};

export default actionTypes;
