import { createPrefixedAction } from '../config';
import actionTypes from './types';

const {
  SET_ACTIVE_TAB,
  GET_TRIGGER_CONFIGS,
  GET_TRIGGER_CONFIGS_START,
  GET_TRIGGER_CONFIGS_SUCCESS,
  GET_TRIGGER_CONFIGS_FAIL,
  GET_GLOBAL_RULES,
  GET_GLOBAL_RULES_START,
  GET_GLOBAL_RULES_SUCCESS,
  GET_GLOBAL_RULES_FAIL,
  EDIT_GLOBAL_RULE,
  EDIT_GLOBAL_RULE_START,
  EDIT_GLOBAL_RULE_SUCCESS,
  EDIT_GLOBAL_RULE_FAIL,
  GET_GLOBAL_RULE_DETAILS,
  GET_GLOBAL_RULE_DETAILS_START,
  GET_GLOBAL_RULE_DETAILS_SUCCESS,
  GET_GLOBAL_RULE_DETAILS_FAIL,
  TOGGLE_GLOBAL_RULE_FORM_OVERLAY,
  UPDATE_GLOBAL_RULE,
  GET_OVERRIDEN_RULES,
  GET_OVERRIDEN_RULES_START,
  GET_OVERRIDEN_RULES_SUCCESS,
  GET_OVERRIDEN_RULES_FAIL,
  SET_OVERRIDEN_RULES_PAGE_DATA,
  TOGGLE_APPLY_OVERRIDEN_RULES_FORM_OVERLAY,
  SET_OVERRIDE_FORM_DATA,
  GET_OVERRIDEN_ENTITY_RULES,
  GET_OVERRIDEN_ENTITY_RULES_START,
  GET_OVERRIDEN_ENTITY_RULES_SUCCESS,
  GET_OVERRIDEN_ENTITY_RULES_FAIL,
  ADD_OVERRIDE_FORM_RULE,
  REMOVE_OVERRIDE_FORM_RULE,
  UPDATE_OVERRIDE_FORM_RULE,
  UPDATE_OVERRIDE_FORM_RULE_DATA,
  UPDATE_OVERRIDE_FORM_RULE_ALERT_DATA,
  ADD_OVERRIDEN_ENTITY_RULES,
  ADD_OVERRIDEN_ENTITY_RULES_START,
  ADD_OVERRIDEN_ENTITY_RULES_SUCCESS,
  ADD_OVERRIDEN_ENTITY_RULES_FAIL,
  DELETE_OVERRIDEN_ENTITY_RULES,
  DELETE_OVERRIDEN_ENTITY_RULES_START,
  DELETE_OVERRIDEN_ENTITY_RULES_SUCCESS,
  DELETE_OVERRIDEN_ENTITY_RULES_FAIL,
} = actionTypes;

export const setActiveTab = createPrefixedAction(SET_ACTIVE_TAB);
export const getTriggerConfigs = createPrefixedAction(GET_TRIGGER_CONFIGS);
export const getTriggerConfigsStart = createPrefixedAction(GET_TRIGGER_CONFIGS_START);
export const getTriggerConfigsSuccess = createPrefixedAction(GET_TRIGGER_CONFIGS_SUCCESS);
export const getTriggerConfigsFail = createPrefixedAction(GET_TRIGGER_CONFIGS_FAIL);
export const getGlobalRules = createPrefixedAction(GET_GLOBAL_RULES);
export const getGlobalRulesStart = createPrefixedAction(GET_GLOBAL_RULES_START);
export const getGlobalRulesSuccess = createPrefixedAction(GET_GLOBAL_RULES_SUCCESS);
export const getGlobalRulesFail = createPrefixedAction(GET_GLOBAL_RULES_FAIL);
export const editGlobalRule = createPrefixedAction(EDIT_GLOBAL_RULE);
export const editGlobalRuleStart = createPrefixedAction(EDIT_GLOBAL_RULE_START);
export const editGlobalRuleSuccess = createPrefixedAction(EDIT_GLOBAL_RULE_SUCCESS);
export const editGlobalRuleFail = createPrefixedAction(EDIT_GLOBAL_RULE_FAIL);
export const getGlobalRuleDetails = createPrefixedAction(GET_GLOBAL_RULE_DETAILS);
export const getGlobalRuleDetailsStart = createPrefixedAction(GET_GLOBAL_RULE_DETAILS_START);
export const getGlobalRuleDetailsSuccess = createPrefixedAction(GET_GLOBAL_RULE_DETAILS_SUCCESS);
export const getGlobalRuleDetailsFail = createPrefixedAction(GET_GLOBAL_RULE_DETAILS_FAIL);
export const toggleGlobalRuleFormOverlay = createPrefixedAction(TOGGLE_GLOBAL_RULE_FORM_OVERLAY);
export const updateGlobalRule = createPrefixedAction(UPDATE_GLOBAL_RULE);
export const getOverridenRules = createPrefixedAction(GET_OVERRIDEN_RULES);
export const getOverridenRulesStart = createPrefixedAction(GET_OVERRIDEN_RULES_START);
export const getOverridenRulesSuccess = createPrefixedAction(GET_OVERRIDEN_RULES_SUCCESS);
export const getOverridenRulesFail = createPrefixedAction(GET_OVERRIDEN_RULES_FAIL);
export const setOverridenRulesPageData = createPrefixedAction(SET_OVERRIDEN_RULES_PAGE_DATA);
export const toggleApplyOverridenRulesFormOverlay = createPrefixedAction(
  TOGGLE_APPLY_OVERRIDEN_RULES_FORM_OVERLAY
);
export const setOverrideFormData = createPrefixedAction(SET_OVERRIDE_FORM_DATA);
export const getOverridenEntityRules = createPrefixedAction(GET_OVERRIDEN_ENTITY_RULES);
export const getOverridenEntityRulesStart = createPrefixedAction(GET_OVERRIDEN_ENTITY_RULES_START);
export const getOverridenEntityRulesSuccess = createPrefixedAction(
  GET_OVERRIDEN_ENTITY_RULES_SUCCESS
);
export const getOverridenEntityRulesFail = createPrefixedAction(GET_OVERRIDEN_ENTITY_RULES_FAIL);
export const addOverrideFormRule = createPrefixedAction(ADD_OVERRIDE_FORM_RULE);
export const removeOverrideFormRule = createPrefixedAction(REMOVE_OVERRIDE_FORM_RULE);
export const updateOverrideFormRule = createPrefixedAction(UPDATE_OVERRIDE_FORM_RULE);
export const updateOverrideFormRuleData = createPrefixedAction(UPDATE_OVERRIDE_FORM_RULE_DATA);
export const updateOverrideFormRuleAlertData = createPrefixedAction(
  UPDATE_OVERRIDE_FORM_RULE_ALERT_DATA
);
export const addOverridenEntityRule = createPrefixedAction(ADD_OVERRIDEN_ENTITY_RULES);
export const addOverridenEntityRuleStart = createPrefixedAction(ADD_OVERRIDEN_ENTITY_RULES_START);
export const addOverridenEntityRuleSuccess = createPrefixedAction(
  ADD_OVERRIDEN_ENTITY_RULES_SUCCESS
);
export const addOverridenEntityRuleFail = createPrefixedAction(ADD_OVERRIDEN_ENTITY_RULES_FAIL);
export const deleteOverridenEntityRule = createPrefixedAction(DELETE_OVERRIDEN_ENTITY_RULES);
export const deleteOverridenEntityRuleStart = createPrefixedAction(
  DELETE_OVERRIDEN_ENTITY_RULES_START
);
export const deleteOverridenEntityRuleSuccess = createPrefixedAction(
  DELETE_OVERRIDEN_ENTITY_RULES_SUCCESS
);
export const deleteOverridenEntityRuleFail = createPrefixedAction(
  DELETE_OVERRIDEN_ENTITY_RULES_FAIL
);
