import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ToastNotification } from '../../../../../components/library';
import API from '../../../../../config/api';
import { get, patch } from '../../../../utils/axios-wrapper';
import { showErrorMessage } from '../../../../utils/saga-errors';
import { addPrefix } from '../config';
import actionTypes from './types';
import { getNotificationConfiguration, getNotificationConfigurationStore, saveNotificationConfigurationStore } from '.';

function* getNotificationConfigurationSagaCallback({ payload: customerFinClusiveId }) {
  try {
    yield put(getNotificationConfigurationStore({ loading: true }));

    const result = yield call(get, API.transactionMonitoring.notification.customerConfig(customerFinClusiveId));

    yield put(getNotificationConfigurationStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getNotificationConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* getTMCustomerNotificationConfigurationSaga() {
  yield takeLatest(
    addPrefix(actionTypes.GET_NOTIFICATION_CONFIGURATION_SAGA),
    getNotificationConfigurationSagaCallback
  );
}

function* saveNotificationConfigurationSagaCallback({ payload: customerFinClusiveId }) {
  try {
    yield put(saveNotificationConfigurationStore({ loading: true }));

    const configuration = yield select((state) =>
      R.path(['transactionMonitoring', 'customerNotificationConfigurator', 'configuration'], state)
    );

    yield call(patch, {
      route: API.transactionMonitoring.notification.customerConfig(customerFinClusiveId),
      data: configuration,
    });

    yield put(saveNotificationConfigurationStore({ loading: false }));
    yield put(getNotificationConfiguration(customerFinClusiveId));
    ToastNotification(
      'success',
      `The notification configuration for customer ${customerFinClusiveId} was updated successfully.`,
      'Customer Notifications - Transaction Monitoring'
    );
  } catch (error) {
    yield put(saveNotificationConfigurationStore({ loading: false, error }));
    showErrorMessage(error);
  }
}

function* saveTMCustomerNotificationConfigurationSaga() {
  yield takeLatest(
    addPrefix(actionTypes.SAVE_NOTIFICATION_CONFIGURATION_SAGA),
    saveNotificationConfigurationSagaCallback
  );
}

const sagas = [call(getTMCustomerNotificationConfigurationSaga), call(saveTMCustomerNotificationConfigurationSaga)];

export default sagas;
