import React from 'react';
import PropTypes from 'prop-types';
import { H1, H2, Paragraph } from '@finclusive/library';
import { isBlank } from '@finclusive/utils';
import { ReloadButton, LogoutButton } from '../index';
import StyledErrorPage from './ErrorPage.style';

const ErrorPage = ({ children, title, subtitle, description }) => {
  return (
    <StyledErrorPage>
      <div className="error-page-wrapper">
        <H1 className="page-error-title">{title}</H1>
        {subtitle && <H2 className="page-error-subtitle">{subtitle}</H2>}
        <Paragraph className="page-error-description">{description}</Paragraph>
        <div className="page-error-body">
          {!isBlank(children) && children}
          {isBlank(children) && (
            <>
              <ReloadButton />
              <LogoutButton />
            </>
          )}
        </div>
      </div>
    </StyledErrorPage>
  );
};

ErrorPage.defaultProps = {
  children: null,
  title: 'Error',
  subtitle: '',
  description: 'Something went wrong',
};

ErrorPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
};

export default ErrorPage;
