import styled, { css } from 'styled-components';

const StyledSidebarSection = styled.div`
  ${({ withTitle, isCollapsed, bottomFloat }) => {
    return css`
      position: relative;
      padding: 10px 0;
      margin: 0 15px;
      border-bottom: 1px solid #f0f0f0;
      ${!withTitle &&
      css`
        padding: 10px 0;
      `}
      ${bottomFloat &&
      css`
        display: flex;
        flex: 1;
        align-items: flex-end;
        border-bottom: none;
      `}

      .section-title {
        padding: 10px 20px 15px 20px;
        color: #bfbfbf;
        font-weight: 600;
        ${isCollapsed &&
        css`
          height: 0;
          padding: 0;
          margin-left: $sidebar-collapsed-width;
        `}
      }

      .section-content {
        width: 100%;
      }
    `;
  }}
`;

StyledSidebarSection.displayName = 'StyledSidebarSection';
export default StyledSidebarSection;
