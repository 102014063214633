export const breadcrumb = [
  {
    id: '0',
    label: 'CDD Scheduling',
  },
];

export const PROFILE_TYPES = {
  None: 0,
  FullProfile: 1,
  OnGoing: 2,
  Revalidation: 3,
};

export const FREQUENCIES = {
  0: 'Never',
  1: 'Every Day',
  2: 'Every Week',
  3: 'Every Month',
  4: 'Every Quarter',
  5: 'Every Six Months',
  6: 'Every Year',
  7: 'Every 18 Months',
};

export const ENTITY_TYPES = {
  individual: 'individual',
  entity: 'entity',
};

export const ENTITY_TYPES_LABELS = {
  [ENTITY_TYPES.individual]: 'Individual',
  [ENTITY_TYPES.entity]: 'Entity',
};

export const ENTITY_TYPES_KEYS = {
  [ENTITY_TYPES.individual]: 'individualProfile',
  [ENTITY_TYPES.entity]: 'entityProfile',
};
