import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Icon } from '../index';
import Spinner from '../Spinner';

import { SIZE_TYPES, POSITION_TYPES } from './constants';
import StyledDialog from './Dialog.style';

const Dialog = ({
  isLoading,
  children,
  dataTest,
  className,
  contentClassName,
  header,
  size,
  actionsPosition,
  actions,
  onCancel,
  onClose,
}) => {
  const closeIcon = <Icon type="mdi" name="close" className="modal__close-icon" onClick={onCancel} />;

  return (
    <StyledDialog
      isOpen
      centered
      size={size}
      data-test={dataTest}
      className="modal-dialog-scrollable"
      modalClassName={`modal ${className}`}
      contentClassName={`modal__content ${contentClassName}`}
      backdrop={!isLoading || 'static'}
      toggle={onCancel}
    >
      {isLoading && <Spinner visible overlay />}
      <ModalHeader close={closeIcon} tag="div" className="modal__header">
        <div className="modal__title">{header}</div>
      </ModalHeader>
      <ModalBody className="modal__body">{children}</ModalBody>
      {actions && (
        <ModalFooter className={`modal__footer position-${actionsPosition}`}>
          {typeof actions === 'function' ? actions(onClose) : actions}
        </ModalFooter>
      )}
    </StyledDialog>
  );
};

Dialog.defaultProps = {
  isLoading: false,
  children: null,
  dataTest: '',
  className: '',
  contentClassName: '',
  header: '',
  size: SIZE_TYPES.lg,
  actionsPosition: POSITION_TYPES.right,
  actions: '',
};

Dialog.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  header: PropTypes.string,
  size: PropTypes.oneOf([...Object.keys(SIZE_TYPES)]),
  actionsPosition: PropTypes.oneOf([...Object.keys(POSITION_TYPES)]),
  actions: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
