import types from './types';

export const setBreakpoint = (breakpoint) => ({
  type: types.SET_BREAKPOINT,
  breakpoint,
});

export const toggleDetailView = (isOpen) => ({
  type: types.TOGGLE_DETAIL_VIEW,
  isOpen,
});

export const collapseSideBar = (isCollapsed) => ({
  type: types.COLLAPSE_SIDEBAR,
  isCollapsed,
});

export const switchToView = (viewType) => ({
  type: types.SWITHC_TO_VIEW,
  viewType,
});

export const toggleSubmenu = (submenu) => ({
  type: types.TOGGLE_SUBMENU,
  submenu,
});

export const toggleClientView = ({ view }) => ({
  type: types.TOGGLE_CLIENT_VIEW,
  view,
});
