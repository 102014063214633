import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSearchValue } from 'modules/edd/actions';
import { getWorkItemPackages } from 'modules/edd/actions/get-work-item-packages';
import Search from './component';

const mapStateToProps = ({ edd }) => ({
  query: edd.query,
  statuses: edd.statuses,
  assignedTo: edd.assignedTo,
  currentTab: edd.currentTab,
  pageSize: edd.workItems?.pageSize || 10,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearchValue,
      getWorkItemPackages,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Search);
