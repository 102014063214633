import types from '../types';

export const getWorkItemPackagesStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: true,
  },
});

export const getWorkItemPackagesSuccess = (state, { data }) => ({
  ...state,
  query: data.query || '',
  workItems: data,
  statuses: data.statuses,
  assignedTo: data.assignedTo,
  currentTab: data.currentTab,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: false,
  },
});

export const getWorkItemPackagesFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItems: false,
  },
  error,
});

export default {
  [types.GET_WORK_ITEM_PACKAGES_START]: getWorkItemPackagesStart,
  [types.GET_WORK_ITEM_PACKAGES_SUCCESS]: getWorkItemPackagesSuccess,
  [types.GET_WORK_ITEM_PACKAGES_FAIL]: getWorkItemPackagesFail,
};
