import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const setConfigurationFrequency = createPrefixedAction(actionTypes.SET_CONFIGURATION_FREQUENCY);

export const getNotificationConfiguration = createPrefixedAction(actionTypes.GET_NOTIFICATION_CONFIGURATION_SAGA);
export const getNotificationConfigurationStore = createPrefixedAction(actionTypes.GET_NOTIFICATION_CONFIGURATION_STORE);

export const saveNotificationConfiguration = createPrefixedAction(actionTypes.SAVE_NOTIFICATION_CONFIGURATION_SAGA);
export const saveNotificationConfigurationStore = createPrefixedAction(
  actionTypes.SAVE_NOTIFICATION_CONFIGURATION_STORE
);
