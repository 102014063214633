import { createAction } from 'redux-actions';

const MODULE_NAME = 'INTERNAL_USER_MANAGEMENT';

export default {
  MODULE_NAME,
};

export const addPrefix = (type) => `${MODULE_NAME}/${type}`;

export const createPrefixedAction = (type) => createAction(addPrefix(type));
