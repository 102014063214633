import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import Icon from '../Icon';

export default class Tooltip extends Component {
  static propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    identifier: PropTypes.string,
    className: PropTypes.string,
    position: PropTypes.string,
    breakpoint: PropTypes.string,
    iconName: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    iconName: 'help-circle-outline',
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };
  }

  toggle = () =>
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });

  render() {
    const { tooltip, identifier, className, position, breakpoint, iconName } = this.props;
    const { tooltipOpen } = this.state;

    return (
      <div ref="tooltip" className={`tooltip ${className}`}>
        <div className="tooltip__button" id={`tooltip-${identifier}`}>
          <Icon
            className={`tooltip__icon ${tooltipOpen ? 'tooltip__icon--active' : ''}`}
            name={iconName}
          />
        </div>
        <UncontrolledPopover
          className="tooltip__content"
          trigger="hover"
          placement={position || 'right'}
          isOpen={tooltipOpen}
          toggle={this.toggle}
          hideArrow
          placementPrefix="tooltip__content-"
          target={`tooltip-${identifier}`}
        >
          <div ref="tooltipContent">
            <PopoverBody>
              <div
                className={`tooltip__body ${
                  breakpoint !== 'desktop' ? 'tooltip__body--mobile' : ''
                }`}
              >
                {tooltip}
              </div>
              <Icon className="tooltip__close-icon" onClick={this.toggle} name="close" />
            </PopoverBody>
          </div>
        </UncontrolledPopover>
      </div>
    );
  }
}
