import React from 'react';
import PropTypes from 'prop-types';

class Search extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    pageSize: PropTypes.number,
    query: PropTypes.string,
    status: PropTypes.array,
    currentTab: PropTypes.string,
  };

  search = (query) => {
    const { pageSize, assignedTo, statuses, getWorkItemPackages, currentTab } = this.props;
    getWorkItemPackages({
      pageNo: 0,
      pageSize,
      sortFilters: {},
      query,
      statuses,
      assignedTo,
      currentTab,
    });
  };

  handleOnInputChange = (event) => {
    const { value } = event.target;
    if (event.keyCode === 13) {
      this.search(value);
    }
  };

  handleBlur = (event) => {
    const { value } = event.currentTarget;
    this.search(value);
  };

  render() {
    const { handleBlur, handleOnInputChange } = this;
    const { customId, placeholder } = this.props;

    return (
      <div className="search-container">
        <label className="search-label" htmlFor="search-input">
          <i className="fa fa-search search-icon" />
          <input
            type="text"
            id={customId || 'search-input'}
            placeholder={placeholder}
            onKeyDown={handleOnInputChange}
            onBlur={handleBlur}
          />
        </label>
      </div>
    );
  }
}

export default Search;
