export const getWiredTransactionStatusColors = (val) => {
  return (
    {
      Pending: 'orange',
      PendingPayOpsApproval: 'orange',
      SubmittedToBank: 'orange',
      Failed: 'red',
      Rolledback: 'red',
      Completed: 'green',
    }[val] || 'orange'
  );
};

export const getWiredTransactionStatus = (val) => {
  return {
    Created: 'Created',
    CheckedBalance: 'Checked Balance',
    CheckedKYC: 'Checked KYC',
    CheckedAML: 'Checked AML',
    UpdatedAvailableAmount: 'Updated Available Amount',
    ACHGenerated: 'ACH Generated',
    ACHProcessing: 'ACH Processing',
    ACHProcessed: 'ACH Processed',
    Completed: 'Completed',
    Rolledback: 'Rolledback',
    Failure: 'Failure',
    ACHPosted: 'ACH Posted',
    ConvertedCryptoToFiat: 'Converted Crypto To Fiat',
    ConvertedFiatToCrypto: 'Converted Fiat To Crypto',
    MovedCryptoFromCryptoExchangeToStellarWallet: 'Moved Crypto From Crypto Exchange To Stellar Wallet',
    QueriedForCryptoExchange: 'Queried For Crypto Exchange',
    MovedCryptoFromStellarWalletToCryptoExchange: 'Moved Crypto From Stellar Wallet To Crypto Exchange',
    InitiatedAnchorTransfer: 'Initiated Anchor Transfer',
    ProcessedTransferOnAnchor: 'Processed Transfer On Anchor',
    FundsReleased: 'Funds Released',
    CompletedTransferOnAnchor: 'Completed Transfer On Anchor',
    InitiatedAnchorPayment: 'Initiated Anchor Payment',
    AnchorSubmittedPayment: 'Anchor Submitted Payment',
    PlacedFiatToCryptoOrderOnExchange: 'Placed Fiat To Crypto Order On Exchange',
    PlacedCryptoToFiatOrderOnExchange: 'Placed Crypto To Fiat Order On Exchange',
    ComputedFiatAmountEstimation: 'Computed Fiat Amount Estimation',
    AnchorCompletedPayment: 'Anchor Completed Payment',
    UnblockTransactionEstimatedAmount: 'Unblock Transaction Estimated Amount',
    BlockTransactionEstimatedAmount: 'Block Transaction Estimated Amount',
    BlockTransactionAmount: 'Block Transaction Amount',
    PaymentSentForInitiationOnAnchor: 31,
    UnblockTransactionAmount: 'Unblock Transaction Amount',
    CreateEscrowTransaction: 'Create Escrow Transaction',
    PendingConversionToNativeAssetOnAnchor: 'Pending Conversion To Native Asset On Anchor',
    AnchorConvertedToNativeAsset: 'Anchor Converted To Native Asset',
    PendingEstimateToNativeAssetOnAnchor: 'Pending Estimate To Native Asset On Anchor',
    MovedCryptoFromCryptoExchangeToStellarConversionWallet:
      'Moved Crypto From Crypto Exchange To Stellar Conversion Wallet',
    AnchorConvertedToAsset: 'Anchor Converted To Asset',
    UnderManualReview: 'Under Manual Review',
    AutomatedRollback: 'Automated Rollback',
    InsufficientFundsForRollback: 'Insufficient Funds For Rollback',
    PendingMultiDaySettlement: 'Pending Multi Day Settlement',
    CreatedLinkedTransaction: 'Created Linked Transaction',
    PendingACHFileGeneration: 'Pending ACH File Generation',
    LimitsChecked: 'Limits Checked',
    CheckedBalanceOnCounterParty: 'Checked Balance On Counter Party',
    PendingManualOTC: 'Pending Manual OTC',
    OTCTradeCompleted: 'OTC Trade Completed',
    PendingPayOpsApproval: 'Pending PayOps Approval',
    SubmittedToBank: 'Submitted to Bank',
  }[val];
};

export const getTransactionStatuses = (val) => {
  return {
    Created: 1,
    CheckedBalance: 2,
    CheckedKYC: 3,
    CheckedAML: 4,
    UpdatedAvailableAmount: 5,
    ACHGenerated: 6,
    ACHProcessing: 7,
    ACHProcessed: 8,
    Completed: 9,
    Rolledback: 10,
    Failure: 11,
    ACHPosted: 12,
    ConvertedCryptoToFiat: 13,
    ConvertedFiatToCrypto: 14,
    MovedCryptoFromCryptoExchangeToStellarWallet: 15,
    QueriedForCryptoExchange: 16,
    MovedCryptoFromStellarWalletToCryptoExchange: 17,
    InitiatedAnchorTransfer: 18,
    ProcessedTransferOnAnchor: 19,
    FundsReleased: 20,
    CompletedTransferOnAnchor: 21,
    InitiatedAnchorPayment: 22,
    AnchorSubmittedPayment: 23,
    PlacedFiatToCryptoOrderOnExchange: 24,
    PlacedCryptoToFiatOrderOnExchange: 25,
    ComputedFiatAmountEstimation: 26,
    AnchorCompletedPayment: 27,
    UnblockTransactionEstimatedAmount: 28,
    BlockTransactionEstimatedAmount: 29,
    BlockTransactionAmount: 30,
    PaymentSentForInitiationOnAnchor: 31,
    UnblockTransactionAmount: 32,
    CreateEscrowTransaction: 33,
    PendingConversionToNativeAssetOnAnchor: 34,
    AnchorConvertedToNativeAsset: 35,
    PendingEstimateToNativeAssetOnAnchor: 36,
    MovedCryptoFromCryptoExchangeToStellarConversionWallet: 37,
    AnchorConvertedToAsset: 38,
    UnderManualReview: 39,
    AutomatedRollback: 40,
    InsufficientFundsForRollback: 41,
    PendingMultiDaySettlement: 43,
    CreatedLinkedTransaction: 44,
    PendingACHFileGeneration: 45,
    LimitsChecked: 46,
    CheckedBalanceOnCounterParty: 47,
    PendingManualOTC: 48,
    OTCTradeCompleted: 49,
    PendingPayOpsApproval: 50,
    SubmittedToBank: 51,
  }[val];
};
