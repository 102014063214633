export const workItemOrder = (productID) => {
  return (
    {
      OverviewAndOrganizationSummary: 1,
      BeneficialOwnersReview: 2,
      RiskScoreProofScore: 3,
      OrganizationEddDetails: 4,
      SanctionsAndGlobalWatchlists: 5,
      AdverseSocialMedia: 6,
      AdverseMediaInternet: 7,
      WealthAnalysis: 8,
      LegalActionsAnalysis: 9,
      DarkDeepWeb: 10,
      BespokeInvestigationsAndLocalSourceCommentary: 11,
      FinalEddPdfReport: 12,
    }[productID] || Number.MAX_SAFE_INTEGER
  );
};
