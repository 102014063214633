import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@finclusive/utils';
import {
  toggleNotificationsOverlay,
  toggleChannelOverlay,
  setSelectedCustomer,
  setIsDirtyState,
  handleUpdateFormData,
  addFormDestinations,
  removeFormDestinations,
  resetNotificationsForm,
  deleteNotificationChannel,
  getAllCustomersStore,
  getConfigurationOptionsStore,
  getConfigurationGroupsStore,
  saveCustomerConfigurationStore,
  resetState,
  setActiveTab,
} from '../actions';
import EMPTY_DESTINATION from './constants';
import initialState from './initialState';

const setActiveTabHandler = [
  setActiveTab,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      activeTab: payload,
    };
  },
];

const toggleNotificationsOverlayHandler = [
  toggleNotificationsOverlay,
  (state, action) => {
    return {
      ...state,
      isNotificationsOverlayVisible: action.payload,
      activeTab: R.clone(initialState.activeTab),
    };
  },
];

const toggleChannelOverlayHandler = [
  toggleChannelOverlay,
  (state, action) => {
    return {
      ...state,
      isChannelOverlayVisible: action.payload,
      isDirty: false,
    };
  },
];

const setSelectedCustomerHandler = [
  setSelectedCustomer,
  (state, action) => {
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        ...action.payload,
      },
    };
  },
];

const setIsDirtyStateHandler = [
  setIsDirtyState,
  (state, action) => {
    return {
      ...state,
      isDirty: action.payload,
    };
  },
];

const handleUpdateFormDataHandler = [
  handleUpdateFormData,
  (state, action) => {
    return {
      ...state,
      selectedChannel: {
        ...state.selectedChannel,
        ...action.payload,
      },
    };
  },
];

const addFormDestinationsHandler = [
  addFormDestinations,
  (state) => {
    return {
      ...state,
      selectedChannel: {
        ...state.selectedChannel,
        destinations: {
          ...state.selectedChannel.destinations,
          [Object.keys(state.selectedChannel.destinations).length]: {
            ...EMPTY_DESTINATION,
          },
        },
      },
    };
  },
];

const removeFormDestinationsHandler = [
  removeFormDestinations,
  (state, action) => {
    const oldDestinations = R.clone(state.selectedChannel.destinations);
    delete oldDestinations[action.payload];

    return {
      ...state,
      selectedChannel: {
        ...state.selectedChannel,
        destinations: R.clone(oldDestinations),
      },
    };
  },
];

const resetNotificationsFormHandler = [
  resetNotificationsForm,
  (state) => {
    const allKnownNotificationTypes = R.path(['options', 'allKnownNotificationTypes'], state);
    const allKnownNotificationTypesList =
      allKnownNotificationTypes &&
      allKnownNotificationTypes.reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {});

    return {
      ...state,
      selectedChannel: {
        ...R.clone(initialState.selectedChannel),
        ...allKnownNotificationTypesList,
      },
    };
  },
];

const deleteNotificationChannelHandler = [
  deleteNotificationChannel,
  (state, action) => {
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        channels: R.reject(({ id }) => id === action.payload, state.selectedCustomer.channels),
      },
    };
  },
];

const getAllCustomersStoresHandler = [
  getAllCustomersStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    const { selectedCustomer } = state;

    return {
      ...state,
      isLoading: false,
      customers: payload,
      selectedCustomer: selectedCustomer.id ? R.find(R.propEq('id', selectedCustomer.id))(payload) : selectedCustomer,
    };
  },
];

const getConfigurationOptionsStoreHandler = [
  getConfigurationOptionsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        options: {
          ...state.options,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        options: {
          ...state.options,
          isLoading: false,
        },
        error,
      };
    }

    const allKnownNotificationTypes = R.prop('allKnownNotificationTypes', payload);
    const allKnownNotificationTypesList =
      !isBlank(allKnownNotificationTypes) &&
      allKnownNotificationTypes.reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {});

    return {
      ...state,
      options: {
        ...state.options,
        isLoading: false,
        ...payload,
      },
      selectedChannel: {
        ...state.selectedChannel,
        ...allKnownNotificationTypesList,
      },
    };
  },
];

const getConfigurationGroupsStoreHandler = [
  getConfigurationGroupsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        groups: {
          ...state.groups,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        groups: {
          ...state.groups,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
        data: payload,
      },
    };
  },
];

const saveCustomerConfigurationStoreHandler = [
  saveCustomerConfigurationStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isSubmitting: true,
      };
    }

    if (error) {
      return {
        ...state,
        isSubmitting: false,
        error,
      };
    }

    return {
      ...state,
      isSubmitting: false,
    };
  },
];

const resetStateHandler = [resetState, () => R.clone(initialState)];

const reducer = handleActions(
  new Map([
    setActiveTabHandler,
    toggleNotificationsOverlayHandler,
    toggleChannelOverlayHandler,
    setSelectedCustomerHandler,

    setIsDirtyStateHandler,
    handleUpdateFormDataHandler,
    addFormDestinationsHandler,
    removeFormDestinationsHandler,
    resetNotificationsFormHandler,
    deleteNotificationChannelHandler,

    getAllCustomersStoresHandler,
    getConfigurationOptionsStoreHandler,
    getConfigurationGroupsStoreHandler,
    saveCustomerConfigurationStoreHandler,

    resetStateHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
