import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { stringSanitize } from '@finclusive/utils';
import TextArea from '../TextArea';

class TextGroup extends React.Component {
  add = () => {
    const { defaultValue, onChange, name } = this.props;
    defaultValue.push({
      id: uuidv4(),
      value: '',
    });
    onChange({
      target: {
        value: defaultValue,
        name,
      },
    });
  };

  remove = (index) => {
    const { defaultValue, onChange, name } = this.props;
    defaultValue.splice(index, 1);
    onChange({
      target: {
        value: defaultValue,
        name,
      },
    });
  };

  onChange = (e, index) => {
    const { defaultValue, onChange, name } = this.props;
    defaultValue.splice(index, 1, {
      ...defaultValue[index],
      value: stringSanitize(e.target.value),
    });
    onChange({
      target: {
        value: defaultValue,
        name,
      },
    });
  };

  render() {
    const { defaultValue } = this.props;
    return (
      <>
        {defaultValue?.map((defVal, i) => (
          <React.Fragment key={defVal.id}>
            <br />
            <div className="flex-row mt-1">
              <TextArea
                className="input--full-width"
                onChange={(e) => this.onChange(e, i)}
                defaultValue={defVal.value}
              />
              <button className="input__group-add-button cursor ml-1" onClick={() => this.remove(i)}>
                X
              </button>
            </div>
          </React.Fragment>
        ))}
        <button className="input__group-add-button cursor" onClick={this.add}>
          Add
        </button>
      </>
    );
  }
}

export default TextGroup;
