import ROUTE_PATHS from 'routes/routePaths';

const getMainMenuItems = ({ permissions, currentOpenSubmenu, toggleSubmenu }) => {
  const {
    canViewRegistration,
    canViewStellarTransactions,
    canViewGenerateReportView,
    canViewTransactionLimits,
    canViewUsWiresTransactions,
    canViewAMLTransactions,
    canViewAMLRules,
    canViewBilling,
    canViewCustomerNotifications,
    canViewPayOpsCreateTransaction,
  } = permissions;
  const isTradeOpsMenuVisible =
    canViewStellarTransactions || canViewGenerateReportView || canViewTransactionLimits || canViewUsWiresTransactions;
  const isTransactionMenuVisible = canViewAMLTransactions || canViewAMLRules;

  return {
    dashboard: {
      label: 'Dashboard',
      icon: 'barChar',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.DASHBOARD,
      isVisible: true,
    },
    registration: {
      label: 'Registration',
      icon: 'personAdd',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.REGISTRATION,
      isVisible: canViewRegistration,
    },
    resources: {
      label: 'Resources',
      icon: 'book',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.RESOURCES,
      isVisible: true,
    },
    feedback: {
      label: 'Feedback',
      icon: 'thumbsUp',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.FEEDBACK,
      isVisible: true,
    },
    cddMonitoring: {
      label: 'CDD Scheduling',
      icon: 'file',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.CDD_MONITORING,
      isVisible: true,
    },
    tradeOps: {
      label: 'TradeOps',
      icon: 'personCheck',
      rightSideIcon: currentOpenSubmenu === 'tradeOps' ? 'arrowDoubleDown' : 'arrowDoubleRight',
      isVisible: isTradeOpsMenuVisible,
      onClick: () => toggleSubmenu('tradeOps'),
      subMenu: {
        blockchain: {
          subMenuLabel: 'Blockchain',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.STELLAR_TRANSACTIONS,
          isSubMenuVisible: canViewStellarTransactions,
        },
        wires: {
          subMenuLabel: 'Wires',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.WIRED_TRANSACTIONS,
          isSubMenuVisible: canViewUsWiresTransactions,
        },
        limitsManagement: {
          subMenuLabel: 'Limits Management',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.MANAGEMENT_LIMITS,
          isSubMenuVisible: canViewTransactionLimits,
        },
        blockchainReport: {
          subMenuLabel: 'Blockchain Report',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.STELLAR_REPORT,
          isSubMenuVisible: canViewGenerateReportView,
        },
        createTransaction: {
          subMenuLabel: 'Create Transaction',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.CREATE_TRANSACTION,
          isSubMenuVisible: canViewPayOpsCreateTransaction,
        },
      },
    },
    transactionMonitoring: {
      label: 'Transaction Monitoring',
      icon: 'monitorContent',
      rightSideIcon: currentOpenSubmenu === 'transactionMonitoring' ? 'arrowDoubleDown' : 'arrowDoubleRight',
      isVisible: isTransactionMenuVisible,
      onClick: () => toggleSubmenu('transactionMonitoring'),
      subMenu: {
        transactions: {
          subMenuLabel: 'Transactions',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.TRANSACTION_MONITORING_TRANSACTIONS,
          isSubMenuVisible: canViewAMLTransactions,
        },
        amlRules: {
          subMenuLabel: 'AML Rules',
          subMenuIcon: 'reverseKeyboardReturn',
          subMenuRoutePath: ROUTE_PATHS.TRANSACTION_MONITORING_AML_RULES,
          isSubMenuVisible: canViewAMLTransactions,
        },
      },
    },
    billing: {
      label: 'Billing',
      icon: 'billing',
      rightSideIcon: currentOpenSubmenu === 'billing' ? 'arrowDoubleDown' : 'arrowDoubleRight',
      isVisible: canViewBilling,
      onClick: () => toggleSubmenu('billing'),
      subMenu: {
        customers: {
          subMenuLabel: 'Customers',
          subMenuIcon: 'people',
          subMenuRoutePath: ROUTE_PATHS.BILLING_CUSTOMERS,
          isSubMenuVisible: true,
        },
      },
    },
    customerNotifications: {
      label: 'Customer Notifications',
      icon: 'bell',
      rightSideIcon: '',
      routePath: ROUTE_PATHS.CUSTOMER_NOTIFICATIONS,
      isVisible: canViewCustomerNotifications,
    },
  };
};

export default getMainMenuItems;
