import types from './types';

const initialState = {
  breakpoint: 'desktop',
  detailViewIsOpen: false,
  sidebarIsCollapsed: false,
  currentView: 'Dashboard',
  currentOpenSubmenu: '',
  showClientView: false,
};

const setBreakpoint = (state, { breakpoint }) => ({
  ...state,
  breakpoint,
});

const collapseSidebar = (state, { isCollapsed }) => ({
  ...state,
  sidebarIsCollapsed: isCollapsed,
});

export const toggleDetailView = (state, { isOpen }) => ({
  ...state,
  detailViewIsOpen: isOpen,
});

const toggleSubmenu = (state, { submenu }) => ({
  ...state,
  currentOpenSubmenu: state.currentOpenSubmenu !== submenu ? submenu : '',
});

const toggleClientView = (state, { view }) => ({
  ...state,
  showClientView: view,
});

const reducer = {
  [types.SET_BREAKPOINT]: setBreakpoint,
  [types.TOGGLE_DETAIL_VIEW]: toggleDetailView,
  [types.COLLAPSE_SIDEBAR]: collapseSidebar,
  [types.TOGGLE_SUBMENU]: toggleSubmenu,
  [types.TOGGLE_CLIENT_VIEW]: toggleClientView,
};

export default (state = initialState, action = {}) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
