import { call, put, takeLatest } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_FINALIZE_WORK_ITEM_PACKAGE as ENDPOINT } from 'env-config';
import { putRequest } from 'modules/utils';
import {
  finalizeWorkItemPackageFail,
  finalizeWorkItemPackageStart,
  finalizeWorkItemPackageSuccess,
} from '../actions/finalize-work-item-package';
import types from '../types';

export const httpCall = (data) => putRequest({ route: ENDPOINT, data });

export function* finalizeWorkItemPackage({ workItemPackageID, isAccepted, reason, notes }) {
  try {
    yield put(finalizeWorkItemPackageStart());

    const payload = {
      workItemPackageID,
      isAccepted,
      reason,
      notes,
    };
    const result = yield call(httpCall, payload);

    yield put(finalizeWorkItemPackageSuccess(result));
  } catch (error) {
    yield put(finalizeWorkItemPackageFail(error));
    ToastNotification('error', error.message || 'There was an error trying to finalize work item package');
  }
}

export default function* finalizeWorkItemPackageSaga() {
  yield takeLatest(types.FINALIZE_WORK_ITEM_PACKAGE, finalizeWorkItemPackage);
}
