import types from '../types';

export const settleTransactionStart = () => ({
  type: types.SETTLE_TRANSACTION_START,
});

export const settleTransaction = ({ transactionID }) => ({
  type: types.SETTLE_TRANSACTION,
  transactionID,
});

export const settleTransactionSuccess = () => ({
  type: types.SETTLE_TRANSACTION_SUCCESS,
});

export const settleTransactionFail = (error) => ({
  type: types.SETTLE_TRANSACTION_FAIL,
  error,
});
