import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Input from 'components/library/Input';
import Navigation from './components/Navigation';

class TablePagination extends Component {
  constructor(props) {
    super(props);
    const { data, pageLimits } = this.props;
    const { currentPage, pageCount, pageSize } = data;
    this.state = {
      currentPage: currentPage || 1,
      pageLimit: pageSize || pageLimits[0],
      totalPages: pageCount,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    const { currentPage, pageCount, pageSize } = data;
    if (
      prevProps.data.pageCount !== pageCount ||
      prevProps.data.currentPage !== currentPage ||
      prevProps.data.pageSize !== pageSize
    ) {
      this.setState({
        totalPages: pageCount,
        currentPage,
        pageLimit: pageSize,
      });
    }
  };

  updatePagination = (page, pageLimit) => {
    const { onPageChanged, data, sortOrder, filterValue } = this.props;
    const { rowCount } = data;
    this.setState({
      currentPage: page,
      pageLimit,
      totalPages: Math.ceil(rowCount / pageLimit),
    });
    onPageChanged(page, pageLimit, sortOrder, filterValue);
  };

  fetchPageNumbers = () => {
    const { currentPage, totalPages } = this.state;
    const pageNumbers = [...Array(totalPages)].map((_1, i) => i + 1);

    if (currentPage <= 3 || totalPages <= 5) {
      return pageNumbers.slice(0, 5);
    }
    if (currentPage > 3 && currentPage <= totalPages - 3) return pageNumbers.slice(currentPage - 3, currentPage + 2);
    if (currentPage > 3 && currentPage >= totalPages - 3) return pageNumbers.slice(totalPages - 5, totalPages);

    return false;
  };

  handleClick = (page) => (event) => {
    event.preventDefault();
    const { pageLimit } = this.state;
    this.updatePagination(page, pageLimit);
  };

  render() {
    const { currentPage, pageLimit, totalPages } = this.state;
    const { isLoading, dataTest, data, pageLimits } = this.props;
    const { results } = data;

    return (
      <div className="pagination" data-test={dataTest}>
        <div className="pagination__information" data-test={`${dataTest}-info`}>
          {currentPage} of {totalPages} pages ({results.length} {results.length > 1 ? 'items' : 'item'})
        </div>
        <div className="pagination__wrapper">
          <Navigation
            icon="chevron-left"
            className="pagination__icon--start"
            dataTest={`${dataTest}-start`}
            isDisabled={isLoading || currentPage === 1}
            pageNumber={1}
            onClick={this.handleClick}
          />
          <Navigation
            icon="chevron-left"
            dataTest={`${dataTest}-previous`}
            isDisabled={isLoading || currentPage === 1}
            pageNumber={currentPage - 1}
            onClick={this.handleClick}
          />
          {this.fetchPageNumbers().map((page, index) => (
            <div
              key={`pagination__item-${index}`}
              className={cls('pagination__item', {
                'pagination__item--active': currentPage === page,
                'pagination__item--disabled': isLoading,
              })}
              data-test={`${dataTest}-page-${page}`}
              onClick={this.handleClick(page)}
            >
              {page}
            </div>
          ))}
          <Navigation
            icon="chevron-right"
            dataTest={`${dataTest}-next`}
            isDisabled={isLoading || currentPage === totalPages}
            pageNumber={currentPage + 1}
            onClick={this.handleClick}
          />
          <Navigation
            icon="chevron-right"
            className="pagination__icon--end"
            dataTest={`${dataTest}-end`}
            isDisabled={isLoading || currentPage === totalPages}
            pageNumber={totalPages}
            onClick={this.handleClick}
          />
        </div>
        <div className="pagination__limit-selector">
          <div className="pagination__limit-selector-label">Show</div>
          <Input
            fullWidth
            type="select"
            className="pagination__limit-selector-input"
            data-test={`${dataTest}-showPerPage`}
            disabled={isLoading}
            value={pageLimit}
            requiresValidation={false}
            onChange={({ target }) => this.updatePagination(1, target.value)}
          >
            {pageLimits.map((option, index) => (
              <option key={`pagination__limit-selector-input-${index}`} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </div>
      </div>
    );
  }
}

TablePagination.defaultProps = {
  isLoading: false,
  dataTest: '',
  filterValue: '',
  sortOrder: '',
};

TablePagination.propTypes = {
  isLoading: PropTypes.bool,
  dataTest: PropTypes.string,
  data: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    pageCount: PropTypes.number,
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rowCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    pageSize: PropTypes.number,
  }).isRequired,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  pageLimits: PropTypes.array.isRequired,
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onPageChanged: PropTypes.func.isRequired,
};

export default TablePagination;
