import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose, { withPermissions, PERMISSION_TYPES } from 'hocs';
import userManager from 'modules/userManager/components/UserManager';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { StyledSidebarOffset, StyledLayout } from './Layout.style';

const Layout = ({ permissions, children, isSidebarCollapsed, userName, userData, actions }) => {
  const isAuthenticated = userManager.isAuthenticated();

  return (
    <StyledSidebarOffset isSidebarCollapsed={isSidebarCollapsed}>
      {isAuthenticated && (
        <div className="layout-header">
          <TopBar
            companyName={userData.emailAddress}
            userName={userName}
            push={actions.push}
            permissions={permissions}
          />
          <Sidebar />
        </div>
      )}
      <StyledLayout>
        <div className="layout-content" id="app-container-content">
          {children}
        </div>
      </StyledLayout>
    </StyledSidebarOffset>
  );
};

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    emailAddress: PropTypes.string,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ router, browser, userManager }) => ({
  isSidebarCollapsed: browser.sidebarIsCollapsed,
  userName: `${userManager.firstName} ${userManager.lastName}`,
  userData: userManager,
  pathname: router.location.pathname, // used for routing purposes. TODO: must replace this
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
    },
    dispatch
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions({
    canViewInternalUsers: [PERMISSION_TYPES.VIEW_INTERNAL_USERS],
  })
)(Layout);
