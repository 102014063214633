import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Icon from 'components/library/Icon';

const TableHeaderCell = ({ className = '', dataTest, label, columnSort, sortable, style, sortOrder, sort }) => {
  let sortIcon = 'sort';
  let selectedClass = sortable ? 'clickable ' : '';
  const sortColumnRegExp = /\(([^)]+)\)/.exec(sortOrder);
  const sortByColumn = sortOrder && sortColumnRegExp && sortColumnRegExp[1];
  if (sortOrder && sortByColumn === columnSort) {
    sortIcon = sortOrder.indexOf('Desc(') ? 'sort-ascending' : 'sort-descending';
    selectedClass += 'selected';
  }

  return (
    <th
      className={cls('table__cell', 'table__cell--header', className)}
      data-test={dataTest}
      style={style}
      onClick={sortable ? () => sort(columnSort) : undefined}
    >
      {label} {sortable && <Icon name={sortIcon} />}
    </th>
  );
};

TableHeaderCell.defaultProps = {
  className: '',
  label: '',
  columnSort: '',
  sortable: false,
  style: {},
  sort: () => {},
};

TableHeaderCell.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  columnSort: PropTypes.string,
  sortable: PropTypes.bool,
  style: PropTypes.shape({}),
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  sort: PropTypes.func,
};

export default TableHeaderCell;
