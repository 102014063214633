import { call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_EDD_ATTACHMENT as ENDPOINT } from 'env-config';
import { ToastNotification } from 'components/library';
import { upload } from 'modules/utils';
import { uploadDocumentStart, uploadDocumentSuccess, uploadDocumentFail } from '../actions/upload-document';
import types from '../types';

export const httpCall = (data) => upload({ route: ENDPOINT, data });

export function* uploadDocument({ fileMeta, file }) {
  try {
    yield put(uploadDocumentStart());

    const formData = new FormData();

    formData.append('', file);
    formData.append('fileMeta', JSON.stringify(fileMeta));

    const result = yield call(httpCall, formData);
    yield put(uploadDocumentSuccess(result));
  } catch (error) {
    yield put(uploadDocumentFail(error));
    ToastNotification('error', 'There was an issue with uploading your file.');
  }
}

export default function* uploadDocumentSaga() {
  yield takeLatest(types.UPLOAD_DOCUMENT, uploadDocument);
}
