import React from 'react';
import * as R from 'ramda';
import { BackButton, HomeButton, LogoutButton, ReloadButton } from 'components/library';

const ERRORS = {
  generic: {
    title: 'Error',
    description: 'Something went wrong',
    children: (
      <>
        <ReloadButton />
        <LogoutButton />
      </>
    ),
  },
  404: {
    title: '404',
    description: 'Page not found',
    children: <HomeButton />,
  },
  403: {
    title: '403',
    subtitle: 'Access Denied',
    description: "You don't have permission to access this page.",
    children: (
      <>
        <BackButton />
        <HomeButton />
      </>
    ),
  },
};

const getError = (match) => R.prop(R.path(['params', 'code'], match), ERRORS) || ERRORS.generic;

export default getError;
