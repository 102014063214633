import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const setActiveTab = createPrefixedAction(actionTypes.SET_ACTIVE_TAB);
export const setSelectedCustomer = createPrefixedAction(actionTypes.SET_SELECTED_CUSTOMER);
export const toggleNotificationsOverlay = createPrefixedAction(actionTypes.TOGGLE_NOTIFICATIONS_OVERLAY);
export const toggleChannelOverlay = createPrefixedAction(actionTypes.TOGGLE_CHANNEL_OVERLAY);

export const setIsDirtyState = createPrefixedAction(actionTypes.SET_IS_DIRTY_STATE);
export const handleUpdateFormData = createPrefixedAction(actionTypes.HANDLE_UPDATE_FORM_DATA);
export const addFormDestinations = createPrefixedAction(actionTypes.ADD_FORM_DESTINATIONS);
export const removeFormDestinations = createPrefixedAction(actionTypes.REMOVE_FORM_DESTINATIONS);
export const resetNotificationsForm = createPrefixedAction(actionTypes.RESET_NOTIFICATION_FORM);

export const getAllCustomers = createPrefixedAction(actionTypes.GET_ALL_CUSTOMERS_SAGA);
export const getAllCustomersStore = createPrefixedAction(actionTypes.GET_ALL_CUSTOMERS_STORE);

export const getConfigurationOptions = createPrefixedAction(actionTypes.GET_CONFIGURATION_OPTIONS_SAGA);
export const getConfigurationOptionsStore = createPrefixedAction(actionTypes.GET_CONFIGURATION_OPTIONS_STORE);

export const getConfigurationGroups = createPrefixedAction(actionTypes.GET_CONFIGURATION_GROUPS_SAGA);
export const getConfigurationGroupsStore = createPrefixedAction(actionTypes.GET_CONFIGURATION_GROUPS_STORE);

export const saveCustomerConfiguration = createPrefixedAction(actionTypes.SAVE_CUSTOMER_CONFIGURATION_SAGA);
export const saveCustomerConfigurationStore = createPrefixedAction(actionTypes.SAVE_CUSTOMER_CONFIGURATION_STORE);

export const deleteNotificationChannel = createPrefixedAction(actionTypes.DELETE_NOTIFICATION_CHANNEL);

export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
