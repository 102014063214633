import styled, { css } from 'styled-components';
import { TOP_BAR_HEIGHT } from '../Layout/Layout.style';
import { sidebarSpacing } from '../Sidebar/Sidebar.style';

const StyledPageOverlay = styled.div`
  ${({ theme, isVisible, isTransitioning, isSidebarCollapsed, isLoading, withBorder }) => {
    const { palette } = theme;

    return css`
      display: flex;
      flex-direction: column;
      position: fixed;
      top: ${TOP_BAR_HEIGHT};
      right: 0;
      bottom: 0;
      width: calc(100% - ${isSidebarCollapsed ? sidebarSpacing.collapsedWidth : sidebarSpacing.width});
      background: #fbfbfb;
      transform: translateX(100%);
      transition: width 0.2s ease, transform 0.7s ease;
      ${isVisible &&
      css`
        transform: translateX(0);
      `}
      ${isTransitioning &&
      css`
        border-left: 1px solid #ced4da;
      `}

      .page-overlay-form {
        display: flex;
        flex-direction: column;
        height: 100%;

        .overlay-header {
          display: flex;
          flex-direction: column;
          ${withBorder &&
          css`
            border-bottom: 0.1rem solid ${palette.gray.xxGrayLightest};
          `}

          padding-bottom: 1.6rem;

          > * {
            padding-left: 5rem;
          }

          ${isLoading &&
          css`
            pointer-events: none;
            opacity: 0.3;
          `}
        }

        .overlay-title {
          position: relative;
        }

        .overlay-icon {
          position: absolute;
          left: 0;
          top: calc(50% - 0.8rem);
          cursor: pointer;
        }

        .crumbs {
          margin: 0;
          color: ${palette.gray.grayDarkMedium};
          font-size: 1.2rem;
        }

        .title {
          margin: 0;
          color: #222222;
          font-weight: 300;
        }

        .subtitle {
          margin-top: 1rem;
          color: ${palette.gray.grayDarkMedium};
        }

        .overlay-content {
          flex: 1;
          padding-top: 3.6rem;
        }

        .overlay-footer {
          display: flex;
          align-items: center;
          justify-content: center;
          position: sticky;
          min-height: 9rem;
          border-top: 0.1rem solid ${palette.gray.xxGrayLightest};
          background: #fbfbfb;
          > button:not(:last-child) {
            margin-right: 2.4rem;
          }
        }
      }
    `;
  }}
`;

StyledPageOverlay.displayName = 'StyledPageOverlay';
export default StyledPageOverlay;
