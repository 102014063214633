import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  clearAccount,
  createTransactionStore,
  getAccountStore,
  getTransactionsStore,
  setAccountNumber,
  setActiveTab,
  setOriginatorField,
  setReceiverField,
  setTransactionDetailErrors,
  setTransactionField,
  setTransactionPageFilters,
} from '../actions';
import initialState from './initialState';

const setAccountNumberHandler = [
  setAccountNumber,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      account: {
        ...state.account,
        accountNumber: payload,
        error: '',
      },
    };
  },
];

const getAccountStoreHandler = [
  getAccountStore,
  (state, action) => {
    const { loading, error, payload } = action.payload;

    if (loading) {
      return {
        ...state,
        account: {
          ...state.account,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        account: {
          ...state.account,
          isLoading: false,
          error,
        },
      };
    }

    return {
      ...state,
      account: {
        ...state.account,
        isLoading: false,
        data: payload,
      },
      transaction: {
        ...state.transaction,
        receiver: {
          ...state.transaction.receiver,
          receiverAccountNumber: R.prop('accountNumber', state.account),
        },
      },
    };
  },
];

const clearAccountHandler = [
  clearAccount,
  () => {
    return {
      ...R.clone(initialState),
    };
  },
];

const setTransactionFieldHandler = [
  setTransactionField,
  (state, action) => {
    const {
      payload: { name, value },
    } = action;
    const transactionErrors = R.path(['transaction', 'errors', 'data'], state);
    return {
      ...state,
      transaction: {
        ...state.transaction,
        data: {
          ...state.transaction.data,
          [name]: value,
        },
        errors: {
          ...state.transaction.errors,
          data: {
            ...transactionErrors,
            [name]: '',
          },
        },
      },
    };
  },
];

const setOriginatorFieldHandler = [
  setOriginatorField,
  (state, action) => {
    const {
      payload: { name, value },
    } = action;
    return {
      ...state,
      transaction: {
        ...state.transaction,
        originator: {
          ...state.transaction.originator,
          [name]: value,
        },
      },
    };
  },
];

const setReceiverFieldHandler = [
  setReceiverField,
  (state, action) => {
    const {
      payload: { name, value },
    } = action;
    return {
      ...state,
      transaction: {
        ...state.transaction,
        receiver: {
          ...state.transaction.receiver,
          [name]: value,
        },
      },
    };
  },
];

const createTransactionStoreHandler = [
  createTransactionStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      transaction: {
        ...R.clone(initialState.transaction),
        isLoading: false,
        receiver: {
          receiverAccountNumber: R.prop('accountNumber', state.account),
        },
      },
    };
  },
];

const setTransactionDetailErrorsHandler = [
  setTransactionDetailErrors,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      transaction: {
        ...state.transaction,
        errors: {
          data: payload,
        },
      },
    };
  },
];

const setActiveTabHandler = [
  setActiveTab,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      activeTab: payload,
    };
  },
];

const getTransactionsStoreHandler = [
  getTransactionsStore,
  (state, action) => {
    const { loading, error, payload } = action.payload;

    if (loading) {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      transactions: {
        ...state.transactions,
        isLoading: false,
        data: R.omit(['results'], payload),
        results: R.path(['results'], payload),
      },
    };
  },
];

const setTransactionPageFiltersHandler = [
  setTransactionPageFilters,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      transactions: {
        ...state.transactions,
        pageFilters: payload,
      },
    };
  },
];

const reducer = handleActions(
  new Map([
    setAccountNumberHandler,
    getAccountStoreHandler,
    clearAccountHandler,
    setTransactionFieldHandler,
    setOriginatorFieldHandler,
    setReceiverFieldHandler,
    createTransactionStoreHandler,
    setTransactionDetailErrorsHandler,
    setActiveTabHandler,
    getTransactionsStoreHandler,
    setTransactionPageFiltersHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
