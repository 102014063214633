import types from '../types';

export const assignWorkItemStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: true,
  },
});

export const assignWorkItemSuccess = (state, { success }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: false,
  },
  success,
});

export const assignWorkItemFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: false,
  },
  error,
});

export default {
  [types.ASSIGN_WORK_ITEM_START]: assignWorkItemStart,
  [types.ASSIGN_WORK_ITEM_SUCCESS]: assignWorkItemSuccess,
  [types.ASSIGN_WORK_ITEM_FAIL]: assignWorkItemFail,
};
