import { put, call, takeLatest } from 'redux-saga/effects';
import { REACT_APP_OTC_TRADE_URL } from 'env-config';
import { get } from 'modules/utils';
import types from '../types';
import { getOtcDataStart, getOtcDataSuccess, getOtcDataFail } from '../actions/get-otc-data';

export const callToGetOtcData = ({ transactionID }) => get(`${REACT_APP_OTC_TRADE_URL}/${transactionID}`);

export function* getOtcData({ transactionID }) {
  try {
    yield put(getOtcDataStart());

    const otcData = yield call(callToGetOtcData, { transactionID });

    yield put(getOtcDataSuccess(otcData));
  } catch (error) {
    yield put(getOtcDataFail(error));
  }
}

export default function* getOtcDataSaga() {
  yield takeLatest(types.GET_OTC_DATA, getOtcData);
}
