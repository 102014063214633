import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { FIELD_TYPES } from './constants';
import { isNaturalNumber } from 'utilities';
import { ENTITIES } from '../../../../../../constants';

export const getTabsConfig = (alerts) => {
  if (isBlank(alerts)) {
    return [];
  }
  const keys = R.intersection(
    R.keys(ENTITIES),
    R.map(({ entityType }) => `${entityType}`, alerts)
  );
  return R.map((key) => ({ title: ENTITIES[key].label, key }), keys);
};

export const validateRule = (rule, fieldConfig) => {
  const validationErrors = [];
  if (isBlank(rule.severity)) {
    validationErrors.push('Alert Severity is a required field.');
  }
  if (isBlank(rule.alertMessage)) {
    validationErrors.push('Alert Message is a required field.');
  }

  R.forEach((key) => {
    const { triggerConfig, entityType } = rule.alerts[key];
    R.forEach((fieldKey) => {
      const [fieldLabel, fieldType] = R.compose(
        R.props(['displayLabel', 'type']),
        R.find(R.propEq('name', fieldKey))
      )(fieldConfig);
      const entityLabel = R.path([entityType, 'label'], ENTITIES);
      const fieldName = `${entityLabel ? `${entityLabel} - ` : ''}${isBlank(fieldLabel) ? fieldKey : fieldLabel}`;
      const value = triggerConfig[fieldKey];
      if (isBlank(value)) {
        validationErrors.push(`${fieldName} is a required field.`);
      } else if (fieldType === FIELD_TYPES.number && !isNaturalNumber(value)) {
        validationErrors.push(`${fieldName} must be be a natural number.`);
      }
    }, R.keys(R.omit(['typeDiscriminator'], triggerConfig)));
  }, R.keys(rule.alerts));

  return validationErrors;
};
