const initialState = {
  editDefaultConfigurationOverlayVisible: false,
  editCustomerConfigurationOverlayVisible: false,
  customers: null,
  loaders: {
    isLoadingCustomers: false,
    isLoadingGlobalConfig: false,
    isLoadingCustomerConfig: false,
    isSubmittingGlobalConfig: false,
    isSubmittingCustomerConfig: false,
  },
  globalLimits: {
    id: '',
    configEdit: {},
    selectedBank: '',
  },
  customerLimits: {
    id: '',
    customerFinClusiveID: '',
    customerName: '',
    configEdit: {},
    selectedBank: '',
  },
  customersFilter: {
    filterBy: '',
    input: null,
  },
};

export default initialState;
