import qs from 'qs';
import * as R from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ToastNotification } from '../../../components/library';
import API from '../../../config/api';
import { get, post } from '../../utils/axios-wrapper';
import { showErrorMessage } from '../../utils/saga-errors';
import { hideDialog } from '../../../actions/global';
import { addPrefix } from '../config';
import actionTypes from './types';
import { createTransactionStore, getAccountStore, getTransactionsStore, setActiveTab } from '.';
import { TAB_KEYS } from '../components/constants';
import { filterEmptyKeys, isBlank } from '@finclusive/utils';

function* getAccountSagaCallback({ payload: accountNumber }) {
  try {
    yield put(getAccountStore({ loading: true }));
    const result = yield call(get, API.createTransaction.getAccount(accountNumber));

    yield put(getAccountStore({ loading: false, payload: result }));
    yield put(setActiveTab(TAB_KEYS.account));
  } catch (error) {
    ToastNotification('error', `The account data for account number ${accountNumber} could not be retrieved.`);
    yield put(
      getAccountStore({
        loading: false,
        error: `The account data could not be retrieved for this account number.`,
      })
    );
  }
}

function* getAccountSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_ACCOUNT_SAGA), getAccountSagaCallback);
}

function* createTransactionSagaCallback() {
  try {
    yield put(createTransactionStore({ loading: true }));

    const createTransaction = yield select((state) => R.path(['createTransaction'], state));
    const { data: transaction, originator, receiver } = R.path(['transaction'], createTransaction);
    const accountId = R.path(['account', 'data', 'accountId'], createTransaction);
    const response = yield call(post, {
      route: API.createTransaction.transaction,
      data: {
        ...transaction,
        method: R.path(['method', 'value'], transaction),
        secCode: R.path(['secCode', 'value'], transaction),
        type: R.path(['type', 'value'], transaction),
        accountID: accountId,
        counterParty: {
          isRecurringCounterParty: false,
        },
        originator: {
          ...originator,
          originatorCountryCode: R.path(['originatorCountryCode', 'value'], originator),
        },
        receiver: {
          ...receiver,
        },
      },
    });

    yield put(createTransactionStore({ loading: false }));
    yield put(hideDialog());
    ToastNotification('success', response || 'Transaction has been submitted.');
  } catch (error) {
    showErrorMessage(error);
    yield put(
      createTransactionStore({
        loading: false,
        error,
      })
    );
  }
}

function* createTransactionSaga() {
  yield takeLatest(addPrefix(actionTypes.CREATE_TRANSACTION_SAGA), createTransactionSagaCallback);
}

function* getTransactionsSagaCallback() {
  try {
    yield put(getTransactionsStore({ loading: true }));

    const createTransaction = yield select((state) => R.path(['createTransaction'], state));
    const accountNumber = R.path(['account', 'accountNumber'], createTransaction);
    const pageFilters = R.path(['transactions', 'pageFilters'], createTransaction);

    const params = qs.stringify(
      filterEmptyKeys({
        ...pageFilters,
        accountNumber,
      })
    );
    const result = yield call(get, `${API.createTransaction.transaction}?${params}`);
    const formattedResult = {
      ...result,
      currentPage: parseInt(result.currentPage, 10),
      firstRowOnPage: parseInt(result.firstRowOnPage, 10),
      lastRowOnPage: parseInt(result.lastRowOnPage, 10),
      pageCount: parseInt(result.pageCount, 10),
      pageSize: parseInt(result.pageSize, 10),
      rowCount: parseInt(result.rowCount, 10),
    };
    yield put(getTransactionsStore({ loading: false, payload: formattedResult }));
  } catch (error) {
    showErrorMessage(error || 'The list of transactions could not be retrieved.');
  }
}

function* getCreatedTransactionsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_TRANSACTIONS_SAGA), getTransactionsSagaCallback);
}

const sagas = [call(getAccountSaga), call(createTransactionSaga), call(getCreatedTransactionsSaga)];

export default sagas;
