import types from '../types';

export const getOtcDataStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingOtcData: true,
  },
});

export const getOtcDataSuccess = (state, { otcData }) => ({
  ...state,
  selectedRow: {
    ...state.selectedRow,
    otcData: {
      ...otcData,
      executionPrice: otcData.executionPrice && otcData.executionPrice.toFixed(2),
      fees: otcData.fees && otcData.fees.toFixed(2),
    },
  },
  loaders: {
    ...state.loaders,
    isLoadingOtcData: false,
  },
});

export const getOtcDataFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingOtcData: false,
  },
  error,
});

export default {
  [types.GET_OTC_DATA_START]: getOtcDataStart,
  [types.GET_OTC_DATA_SUCCESS]: getOtcDataSuccess,
  [types.GET_OTC_DATA_FAIL]: getOtcDataFail,
};
