import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@finclusive/utils';
import {
  approvePendingRegistrationCurrentStore,
  approvePendingRegistrationSecondaryStore,
  archiveRegistrationStore,
  clearSelectedPendingRegistration,
  exportRegistrationReportStore,
  getPendingRegistrationsStore,
  getRegistrationReportStore,
  setPendingRegistrationValidationErrors,
  setSelectedPendingRegistration,
  setSelectedPendingRegistrationField,
  updatePendingRegistrationStore,
} from '../actions';
import initialState from './initialState';

const getPendingRegistrationsStoreHandler = [
  getPendingRegistrationsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          list: {
            ...state.pendingRegistrations.list,
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          list: {
            ...state.pendingRegistrations.list,
            isLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        currentEnvironmentName: payload.currentEnvironmentName,
        secondaryEnvironmentName: payload.secondaryEnvironmentName,
        list: {
          data: payload.registrationApplicationList,
          isLoading: false,
        },
      },
    };
  },
];

const setSelectedPendingRegistrationHandler = [
  setSelectedPendingRegistration,
  (state, { payload }) => ({
    ...state,
    pendingRegistrations: {
      ...state.pendingRegistrations,
      selectedRegistration: {
        ...state.pendingRegistrations.selectedRegistration,
        isOverlayVisible: true,
        initialData: R.clone(payload),
        data: payload,
      },
    },
  }),
];

const clearSelectedPendingRegistrationHandler = [
  clearSelectedPendingRegistration,
  (state) => ({
    ...state,
    pendingRegistrations: {
      ...state.pendingRegistrations,
      selectedRegistration: R.clone(initialState).pendingRegistrations.selectedRegistration,
    },
  }),
];

const setPendingRegistrationValidationErrorsHandler = [
  setPendingRegistrationValidationErrors,
  (state, { payload }) => ({
    ...state,
    pendingRegistrations: {
      ...state.pendingRegistrations,
      selectedRegistration: {
        ...state.pendingRegistrations.selectedRegistration,
        validationErrors: payload,
      },
    },
  }),
];

const setSelectedPendingRegistrationFieldHandler = [
  setSelectedPendingRegistrationField,
  (state, { payload }) => {
    const { path, value, connectedFields } = payload;
    let newPendingRegistration = R.clone(state.pendingRegistrations.selectedRegistration.data);

    newPendingRegistration = R.set(R.lensPath(path), value, newPendingRegistration);

    if (!isBlank(connectedFields)) {
      connectedFields.forEach((field) => {
        newPendingRegistration = R.set(R.lensPath(field.path), field.value, newPendingRegistration);
      });
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        selectedRegistration: {
          ...state.pendingRegistrations.selectedRegistration,
          data: newPendingRegistration,
        },
      },
    };
  },
];

const getRegistrationReportStoreHandler = [
  getRegistrationReportStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        registrationReport: {
          ...state.registrationReport,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        registrationReport: {
          ...state.registrationReport,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      registrationReport: {
        ...state.registrationReport,
        data: payload,
        isLoading: false,
      },
    };
  },
];

const updatePendingRegistrationStoreHandler = [
  updatePendingRegistrationStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isUpdating: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isUpdating: false,
          },
        },
      };
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        selectedRegistration: {
          ...state.pendingRegistrations.selectedRegistration,
          isUpdating: false,
        },
      },
    };
  },
];

const approvePendingRegistrationCurrentStoreHandler = [
  approvePendingRegistrationCurrentStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isApproving: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isApproving: false,
          },
        },
      };
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        selectedRegistration: {
          ...state.pendingRegistrations.selectedRegistration,
          isApproving: false,
        },
      },
    };
  },
];

const approvePendingRegistrationSecondaryStoreHandler = [
  approvePendingRegistrationSecondaryStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isApproving: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          selectedRegistration: {
            ...state.pendingRegistrations.selectedRegistration,
            isApproving: false,
          },
        },
      };
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        selectedRegistration: {
          ...state.pendingRegistrations.selectedRegistration,
          isApproving: false,
        },
      },
    };
  },
];

const exportRegistrationReportStoreHandler = [
  exportRegistrationReportStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        registrationReport: {
          ...state.registrationReport,
          isExporting: true,
          hasExportError: false,
        },
      };
    }

    if (error) {
      return {
        ...state,
        registrationReport: {
          ...state.registrationReport,
          isExporting: false,
          hasExportError: true,
        },
      };
    }

    return {
      ...state,
      registrationReport: {
        ...state.registrationReport,
        isExporting: false,
        hasExportError: false,
      },
    };
  },
];

const archiveRegistrationStoreHandler = [
  archiveRegistrationStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          list: {
            ...state.pendingRegistrations.list,
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        pendingRegistrations: {
          ...state.pendingRegistrations,
          list: {
            ...state.pendingRegistrations.list,
            isLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      pendingRegistrations: {
        ...state.pendingRegistrations,
        list: {
          ...state.pendingRegistrations.list,
          isLoading: false,
        },
      },
    };
  },
];

const reducer = handleActions(
  new Map([
    getPendingRegistrationsStoreHandler,
    getRegistrationReportStoreHandler,
    setSelectedPendingRegistrationHandler,
    clearSelectedPendingRegistrationHandler,
    setSelectedPendingRegistrationFieldHandler,
    exportRegistrationReportStoreHandler,
    updatePendingRegistrationStoreHandler,
    approvePendingRegistrationCurrentStoreHandler,
    approvePendingRegistrationSecondaryStoreHandler,
    setPendingRegistrationValidationErrorsHandler,
    archiveRegistrationStoreHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
