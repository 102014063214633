import { createPrefixedAction } from '../config';
import actionTypes from './types';

const {
  SET_TRANSACTION_PAGE_FILTERS,
  SET_SELECTED_TRANSACTION,
  CLEAR_SELECTED_TRANSACTION,
  SET_ALERT_NOTE,
  SET_TRANSACTION_NOTE,
  SET_ALERT_EXPANDED,
  SET_ALERT_COLLAPSED,
  SET_DETAIL_VIEW_ACTIVE_TAB,
  TOGGLE_DETAIL_VIEW,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_STORE,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_STORE,
  GET_TRANSACTION_ALERTS,
  GET_TRANSACTION_ALERTS_STORE,
  CLEAR_ALERT,
  CLEAR_ALERT_STORE,
  CLAIM_TRANSACTION,
  CLAIM_TRANSACTION_STORE,
  CLEAR_TRANSACTION,
  CLEAR_TRANSACTION_STORE,
  GET_HISTORICAL_TRANSACTIONS,
  GET_HISTORICAL_TRANSACTIONS_STORE,
  SET_TRANSACTION_FILTERS,
} = actionTypes;

export const setTransactionPageFilters = createPrefixedAction(SET_TRANSACTION_PAGE_FILTERS);
export const setSelectedTransaction = createPrefixedAction(SET_SELECTED_TRANSACTION);
export const clearSelectedTransaction = createPrefixedAction(CLEAR_SELECTED_TRANSACTION);
export const setAlertNote = createPrefixedAction(SET_ALERT_NOTE);
export const setTransactionNote = createPrefixedAction(SET_TRANSACTION_NOTE);
export const setAlertExpanded = createPrefixedAction(SET_ALERT_EXPANDED);
export const setAlertCollapsed = createPrefixedAction(SET_ALERT_COLLAPSED);
export const setDetailViewActiveTab = createPrefixedAction(SET_DETAIL_VIEW_ACTIVE_TAB);
export const toggleDetailView = createPrefixedAction(TOGGLE_DETAIL_VIEW);

export const getTransactions = createPrefixedAction(GET_TRANSACTIONS);
export const getTransactionsStore = createPrefixedAction(GET_TRANSACTIONS_STORE);

export const getTransactionDetails = createPrefixedAction(GET_TRANSACTION_DETAILS);
export const getTransactionDetailsStore = createPrefixedAction(GET_TRANSACTION_DETAILS_STORE);

export const getTransactionAlerts = createPrefixedAction(GET_TRANSACTION_ALERTS);
export const getTransactionAlertsStore = createPrefixedAction(GET_TRANSACTION_ALERTS_STORE);

export const clearAlert = createPrefixedAction(CLEAR_ALERT);
export const clearAlertStore = createPrefixedAction(CLEAR_ALERT_STORE);

export const claimTransaction = createPrefixedAction(CLAIM_TRANSACTION);
export const claimTransactionStore = createPrefixedAction(CLAIM_TRANSACTION_STORE);

export const clearTransaction = createPrefixedAction(CLEAR_TRANSACTION);
export const clearTransactionStore = createPrefixedAction(CLEAR_TRANSACTION_STORE);

export const getHistoricalTransactions = createPrefixedAction(GET_HISTORICAL_TRANSACTIONS);
export const getHistoricalTransactionsStore = createPrefixedAction(GET_HISTORICAL_TRANSACTIONS_STORE);

export const setTransactionFilters = createPrefixedAction(SET_TRANSACTION_FILTERS);
