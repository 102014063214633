import React from 'react';
import { ErrorPage, HomeButton } from 'components/library';

const NotFound = () => (
  <ErrorPage title="404" description="Page not found">
    <HomeButton />
  </ErrorPage>
);

export default NotFound;
