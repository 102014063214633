import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@finclusive/utils';
import {
  setActiveTab,
  getTriggerConfigsStart,
  getTriggerConfigsSuccess,
  getTriggerConfigsFail,
  getGlobalRulesStart,
  getGlobalRulesSuccess,
  getGlobalRulesFail,
  editGlobalRuleStart,
  editGlobalRuleSuccess,
  editGlobalRuleFail,
  getGlobalRuleDetails,
  getGlobalRuleDetailsStart,
  getGlobalRuleDetailsSuccess,
  getGlobalRuleDetailsFail,
  toggleGlobalRuleFormOverlay,
  updateGlobalRule,
  getOverridenRulesStart,
  getOverridenRulesSuccess,
  getOverridenRulesFail,
  setOverridenRulesPageData,
  toggleApplyOverridenRulesFormOverlay,
  setOverrideFormData,
  addOverrideFormRule,
  removeOverrideFormRule,
  updateOverrideFormRule,
  getOverridenEntityRules,
  getOverridenEntityRulesStart,
  getOverridenEntityRulesSuccess,
  getOverridenEntityRulesFail,
  updateOverrideFormRuleData,
  updateOverrideFormRuleAlertData,
  addOverridenEntityRuleStart,
  addOverridenEntityRuleSuccess,
  addOverridenEntityRuleFail,
  deleteOverridenEntityRuleStart,
  deleteOverridenEntityRuleFail,
  deleteOverridenEntityRuleSuccess,
} from '../actions';
import initialState from './initialState';

export const setActiveTabHandler = [
  setActiveTab,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      activeTab: payload,
    };
  },
];

const getTriggerConfigsStartHandler = [
  getTriggerConfigsStart,
  (state) => ({
    ...state,
    triggerConfigs: {
      ...state.triggerConfigs,
      isLoading: true,
    },
  }),
];

const getTriggerConfigsSuccessHandler = [
  getTriggerConfigsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      triggerConfigs: {
        ...state.triggerConfigs,
        isLoading: false,
        data: payload,
      },
    };
  },
];

const getTriggerConfigsFailHandler = [
  getTriggerConfigsFail,
  (state) => ({
    ...state,
    triggerConfigs: {
      ...state.triggerConfigs,
      isLoading: false,
    },
  }),
];

const getGlobalRulesStartHandler = [
  getGlobalRulesStart,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      list: {
        ...state.global.list,
        isLoading: true,
      },
    },
  }),
];

const getGlobalRulesSuccessHandler = [
  getGlobalRulesSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      global: {
        ...state.global,
        list: {
          isLoading: false,
          data: payload,
        },
      },
    };
  },
];

const getGlobalRulesFailHandler = [
  getGlobalRulesFail,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      list: {
        ...state.global.list,
        isLoading: false,
      },
    },
  }),
];

const editGlobalRuleStartHandler = [
  editGlobalRuleStart,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      form: {
        ...state.global.form,
        isLoading: true,
      },
    },
  }),
];

const editGlobalRuleSuccessHandler = [
  editGlobalRuleSuccess,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      form: {
        ...state.global.form,
        isLoading: false,
      },
    },
  }),
];

const editGlobalRuleFailHandler = [
  editGlobalRuleFail,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      form: {
        ...state.global.form,
        isLoading: false,
      },
    },
  }),
];

const toggleGlobalRuleFormOverlayHandler = [
  toggleGlobalRuleFormOverlay,
  (state, action) => {
    const { payload } = action;
    const clonedState = R.clone(initialState.global.form);
    const {
      global: { form },
    } = state;
    return {
      ...state,
      global: {
        ...state.global,
        form: {
          ...state.global.form,
          isOverlayVisible: payload,
          ruleId: !payload ? clonedState.ruleId : form.ruleId,
          rule: !payload ? clonedState.rule : form.rule,
        },
      },
    };
  },
];

const updateGlobalRuleHandler = [
  updateGlobalRule,
  (state, action) => {
    const {
      payload: { key, value, alertId },
    } = action;

    let newRuleObj = R.clone(state.global.form.rule);

    if (isBlank(alertId)) {
      newRuleObj = {
        ...newRuleObj,
        [key]: value,
      };
    } else {
      const newAlertsObj = newRuleObj.alerts;
      const alertIndex = R.findIndex(({ id }) => id === alertId)(newAlertsObj);
      newRuleObj.alerts[alertIndex] = {
        ...newAlertsObj[alertIndex],
        triggerConfig: {
          ...newAlertsObj[alertIndex].triggerConfig,
          [key]: value,
        },
      };
    }

    return {
      ...state,
      global: {
        ...state.global,
        form: {
          ...state.global.form,
          rule: {
            ...newRuleObj,
          },
        },
      },
    };
  },
];

const getGlobalRuleDetailsHandler = [
  getGlobalRuleDetails,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      global: {
        ...state.global,
        form: {
          ...state.global.form,
          ruleId: payload,
        },
      },
    };
  },
];

const getGlobalRuleDetailsStartHandler = [
  getGlobalRuleDetailsStart,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      form: {
        ...state.global.form,
        isLoading: true,
      },
    },
  }),
];

const getGlobalRuleDetailsSuccessHandler = [
  getGlobalRuleDetailsSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      global: {
        ...state.global,
        form: {
          ...state.global.form,
          isLoading: false,
          rule: payload,
        },
      },
    };
  },
];

const getGlobalRuleDetailsFailHandler = [
  getGlobalRuleDetailsFail,
  (state) => ({
    ...state,
    global: {
      ...state.global,
      form: {
        ...state.global.form,
        isLoading: false,
      },
    },
  }),
];

const getOverridenRulesStartHandler = [
  getOverridenRulesStart,
  (state) => ({
    ...state,
    overriden: {
      ...state.overriden,
      list: {
        ...state.overriden.list,
        isLoading: true,
        data: [],
        pageData: {},
      },
    },
  }),
];

const getOverridenRulesSuccessHandler = [
  getOverridenRulesSuccess,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        list: {
          ...state.overriden.list,
          isLoading: false,
          data: R.path(['results'], payload),
          pageData: R.omit(['results'], payload),
        },
      },
    };
  },
];

const getOverridenRulesFailHandler = [
  getOverridenRulesFail,
  (state) => ({
    ...state,
    overriden: {
      ...state.overriden,
      list: {
        ...state.overriden.list,
        isLoading: false,
      },
    },
  }),
];

const setOverridenRulesPageDataHandler = [
  setOverridenRulesPageData,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        list: {
          ...state.overriden.list,
          pageFilters: payload,
        },
      },
    };
  },
];

const toggleApplyOverridenRulesFormOverlayHandler = [
  toggleApplyOverridenRulesFormOverlay,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: payload
          ? {
              ...state.overriden.form,
              isOverlayVisible: payload,
            }
          : R.clone(initialState.overriden.form),
      },
    };
  },
];

const setOverrideFormDataHandler = [
  setOverrideFormData,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          data: {
            ...state.overriden.form.data,
            [payload.name]: payload.value,
          },
        },
      },
    };
  },
];

export const addOverrideFormRuleHandler = [
  addOverrideFormRule,
  (state, action) => {
    const {
      payload: { ruleId },
    } = action;
    const rule = R.find((rule) => rule.id === ruleId)(state.global.list.data);
    const ruleToAdd = {
      ...rule,
      alerts: R.map((alert) => ({ ...R.omit(['id'], alert) }), rule.alerts),
    };
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          rules: [...state.overriden.form.rules, ruleToAdd],
        },
      },
    };
  },
];

const removeOverrideFormRuleHandler = [
  removeOverrideFormRule,
  (state, action) => {
    const {
      payload: { ruleId, entityType },
    } = action;
    const rule = R.find((rule) => rule.id === ruleId)(state.overriden.form.rules);
    const alert = R.find((alert) => `${alert.entityType}` === `${entityType}`)(rule.alerts);
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          removedRules: isBlank(alert.id)
            ? state.overriden.form.removedRules
            : [...state.overriden.form.removedRules, alert.id],
          rules: R.filter((rule) => rule.id !== ruleId, state.overriden.form.rules),
        },
      },
    };
  },
];

const updateOverrideFormRuleHandler = [
  updateOverrideFormRule,
  (state, action) => {
    const {
      payload: { previousRuleId, currentRuleId, entityType },
    } = action;
    const selectedRules = state.overriden.form.rules;
    const currentRule = R.find((rule) => rule.id === currentRuleId)(state.global.list.data);
    const ruleToAdd = {
      ...currentRule,
      alerts: R.map((alert) => ({ ...alert, id: '' }), currentRule.alerts),
    };
    const previousRuleIndex = R.findIndex((rule) => rule.id === previousRuleId)(selectedRules);
    const previousRule = R.find((rule) => rule.id === previousRuleId)(selectedRules);
    const previousAlertId = R.prop('id', R.find((alert) => `${alert.entityType}` === `${entityType}`)(previousRule.alerts));
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          rules: R.update(previousRuleIndex, ruleToAdd, selectedRules),
          removedRules: isBlank(previousAlertId) ?
            R.path(['overriden', 'form', 'removedRules'], state) :
            R.append(
              previousAlertId,
              R.path(['overriden', 'form', 'removedRules'], state)
            )
        },
      },
    };
  },
];

const getOverridenEntityRulesHandler = [
  getOverridenEntityRules,
  (state, action) => {
    const { payload: config } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isOverlayVisible: true,
          configDetails: {
            ...state.overriden.form.configDetails,
            entityType: config.entityType,
            entityId: config.rule.entityID,
            channel: config.rule.channel,
            initialRule: config.rule,
          },
        },
      },
    };
  },
];

const getOverridenEntityRulesStartHandler = [
  getOverridenEntityRulesStart,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isLoading: true,
        },
      },
    };
  },
];

const getOverridenEntityRulesSuccessHandler = [
  getOverridenEntityRulesSuccess,
  (state, action) => {
    const { payload: data } = action;
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isLoading: false,
          rules: data,
        },
      },
    };
  },
];

const getOverridenEntityRulesFailHandler = [
  getOverridenEntityRulesFail,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isLoading: false,
        },
      },
    };
  },
];

const updateOverrideFormRuleDataHandler = [
  updateOverrideFormRuleData,
  (state, action) => {
    const {
      payload: { ruleId, fieldName, value },
    } = action;
    const selectedRules = state.overriden.form.rules;
    const ruleIndex = R.findIndex((rule) => rule.id === ruleId)(selectedRules);
    const rule = R.path([ruleIndex], selectedRules);
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          rules: R.update(ruleIndex, { ...rule, [fieldName]: value }, selectedRules),
        },
      },
    };
  },
];

const updateOverrideFormRuleAlertDataHandler = [
  updateOverrideFormRuleAlertData,
  (state, action) => {
    const {
      payload: { ruleId, entityType, name, value },
    } = action;
    const selectedRules = state.overriden.form.rules;
    const ruleIndex = R.findIndex((rule) => rule.id === ruleId)(selectedRules);
    const rule = R.path([ruleIndex], selectedRules);
    const alertIndex = R.findIndex((alert) => `${alert.entityType}` === `${entityType}`)(
      rule.alerts
    );
    const selectedAlert = R.path([alertIndex], rule.alerts);
    const updatedRule = {
      ...rule,
      alerts: R.update(
        alertIndex,
        {
          ...selectedAlert,
          triggerConfig: {
            ...selectedAlert.triggerConfig,
            [name]: value,
          },
        },
        rule.alerts
      ),
    };
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          rules: R.update(ruleIndex, updatedRule, selectedRules),
        },
      },
    };
  },
];

const addOverridenEntityRuleStartHandler = [
  addOverridenEntityRuleStart,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isSubmitting: true,
        },
      },
    };
  },
];

const addOverridenEntityRuleSuccessHandler = [
  addOverridenEntityRuleSuccess,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isSubmitting: false,
        },
      },
    };
  },
];

const addOverridenEntityRuleFailHandler = [
  addOverridenEntityRuleFail,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        form: {
          ...state.overriden.form,
          isSubmitting: false,
        },
      },
    };
  },
];

export const deleteOverridenEntityRulesStartHandler = [
  deleteOverridenEntityRuleStart,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        list: {
          ...state.overriden.list,
          isDeleteLoading: true,
        },
      },
    };
  },
];

export const deleteOverridenEntityRulesSuccessHandler = [
  deleteOverridenEntityRuleSuccess,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        list: {
          ...state.overriden.list,
          isDeleteLoading: false,
        },
      },
    };
  },
];

export const deleteOverridenEntityRulesFailHandler = [
  deleteOverridenEntityRuleFail,
  (state) => {
    return {
      ...state,
      overriden: {
        ...state.overriden,
        list: {
          ...state.overriden.list,
          isDeleteLoading: false,
        },
      },
    };
  },
];

const reducer = handleActions(
  new Map([
    setActiveTabHandler,
    getTriggerConfigsStartHandler,
    getTriggerConfigsSuccessHandler,
    getTriggerConfigsFailHandler,
    getGlobalRulesStartHandler,
    getGlobalRulesSuccessHandler,
    getGlobalRulesFailHandler,
    editGlobalRuleStartHandler,
    editGlobalRuleSuccessHandler,
    editGlobalRuleFailHandler,
    toggleGlobalRuleFormOverlayHandler,
    updateGlobalRuleHandler,
    getGlobalRuleDetailsHandler,
    getGlobalRuleDetailsStartHandler,
    getGlobalRuleDetailsSuccessHandler,
    getGlobalRuleDetailsFailHandler,
    getOverridenRulesStartHandler,
    getOverridenRulesSuccessHandler,
    getOverridenRulesFailHandler,
    setOverridenRulesPageDataHandler,
    toggleApplyOverridenRulesFormOverlayHandler,
    setOverrideFormDataHandler,
    addOverrideFormRuleHandler,
    removeOverrideFormRuleHandler,
    updateOverrideFormRuleHandler,
    getOverridenEntityRulesHandler,
    getOverridenEntityRulesStartHandler,
    getOverridenEntityRulesSuccessHandler,
    getOverridenEntityRulesFailHandler,
    updateOverrideFormRuleDataHandler,
    updateOverrideFormRuleAlertDataHandler,
    addOverridenEntityRuleStartHandler,
    addOverridenEntityRuleSuccessHandler,
    addOverridenEntityRuleFailHandler,
    deleteOverridenEntityRulesStartHandler,
    deleteOverridenEntityRulesSuccessHandler,
    deleteOverridenEntityRulesFailHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
