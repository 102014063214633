import * as R from "ramda";

export const addBodyBlobRequestInterceptor = async (axiosInstance, options) => {
  const { data, contentType } = options;
  let bodyBlob;

  if (contentType === 'multipart/form-data') {
    bodyBlob = data;
  } else {
    bodyBlob = new Blob(
      [
        JSON.stringify(
          data && typeof data === 'object' && !Array.isArray(data) ? { ...R.omit(['skipAccessToken'], data) } : data
        ),
      ],
      { type: contentType || 'application/json' }
    );
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      config.data = bodyBlob;
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};
