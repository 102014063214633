import styled, { css } from 'styled-components';

export const sidebarSpacing = {
  width: '25rem',
  collapsedWidth: '6rem',
};

const StyledSidebar = styled.div`
  ${({ theme, isCollapsed }) => {
    const { palette } = theme;

    return css`
      display: flex;
      flex-direction: column;
      flex: 1 1 ${sidebarSpacing.width};
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      max-width: ${sidebarSpacing.width};
      padding-bottom: 2rem;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.1);
      background: ${palette.basic.white};
      z-index: 2;
      transition: all 0.2s ease;

      ${isCollapsed &&
      css`
        flex: 1 1 ${sidebarSpacing.collapsedWidth};
        max-width: ${sidebarSpacing.collapsedWidth};
        overflow: hidden;
      `}
    `;
  }}
`;

StyledSidebar.displayName = 'StyledSidebar';
export default StyledSidebar;
