import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Icon } from '../index';

const toastIcons = {
  success: 'check-circle',
  warning: 'alert-circle',
  error: 'alert-circle',
  info: 'information',
};

const toastContent = (type, content, title) => (
  <>
    <Icon
      className={`toast-notification__icon toast-notification__icon--${type}`}
      name={toastIcons[type]}
    />
    <div className="toast-notification__content">
      <div className="toast-notification__title">{title || type}</div>
      {R.is(String, content) ? (
        <div className="toast-notification__text">{content}</div>
      ) : (
        <ul className="toast-notification__list">
          {content.map((item, i) => (
            <li key={`toast-notification-${i}`} className="toast-notification__list-item">
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  </>
);

const ToastNotification = (type, content, title) =>
  toast[type](toastContent(type, content, title), {
    className: `toast-notification toast-notification--${type}`,
    bodyClassName: 'toast-notification__body',
    progressClassName: `toast-notification__progress toast-notification__progress--${type}`,
    closeButton: <Icon className="toast-notification__close-icon" name="close" />,
  });

ToastNotification.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
};

export default ToastNotification;
