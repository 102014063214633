import React from 'react';
import * as R from 'ramda';
import TypeHeader from './components/TypeHeader';

export const CLAIMED_SELECT_OPTIONS = [
  {
    label: 'All',
    value: 'false',
  },
  {
    label: 'Claimed by me',
    value: 'true',
  },
];

export const SORT_KEYS = {
  sortBy: 'sortBy',
  sortType: 'sortType',
};

export const SORT_BY_VALUES = {
  date: '0',
  sender: '2',
  senderBankName: '3',
  receiver: '4',
  receiverBankName: '5',
  customerName: '6',
};

export const SORT_TYPE_VALUES = {
  ascending: '0',
  descending: '1',
};

export const TAB_KEYS = {
  all: 'all',
  pending: 'pending',
  closed: 'closed',
};

export const TABS_CONFIG = {
  [TAB_KEYS.all]: {
    title: 'Transactions',
    value: 0,
  },
  [TAB_KEYS.pending]: {
    title: 'Need Action',
    value: 1,
  },
  [TAB_KEYS.closed]: {
    title: 'Closed',
    value: 2,
  },
};

export const ALERT_LEVELS = {
  0: 'Customer',
  1: 'Client',
  2: 'Account',
  3: 'Transaction',
};

export const AML_TRANSACTION_STATUS = {
  new: 0,
  pending: 1,
  accepted: 2,
  rejected: 3,
  error: 4,
  automaticallyRejected: 5,
  pausedForManualReview: 6,
};

export const AML_TRANSACTION_STATUS_TAG = {
  [AML_TRANSACTION_STATUS.new]: {
    type: 'warning',
    label: 'New',
  },
  [AML_TRANSACTION_STATUS.accepted]: {
    type: 'success',
    label: 'Accepted',
  },
  [AML_TRANSACTION_STATUS.rejected]: {
    type: 'error',
    label: 'Rejected',
  },
  [AML_TRANSACTION_STATUS.pending]: {
    type: 'warning',
    label: 'Pending',
  },
  [AML_TRANSACTION_STATUS.automaticallyRejected]: {
    type: 'error',
    label: 'Automatically Rejected',
  },
  [AML_TRANSACTION_STATUS.pausedForManualReview]: {
    type: 'warning',
    label: 'Paused For Manual Review',
  },
  [AML_TRANSACTION_STATUS.error]: {
    type: 'error',
    label: 'Error',
  },
};

export const FUNDS_DIRECTION = {
  in: 1,
  out: 2,
  internalTransfer: 3,
};

export const CLEARED_AML_TRANSACTION_MONITORING_STATUSES = [
  AML_TRANSACTION_STATUS.accepted,
  AML_TRANSACTION_STATUS.rejected,
  AML_TRANSACTION_STATUS.error,
  AML_TRANSACTION_STATUS.automaticallyRejected,
];

export const AML_TRANSACTION_MONITORING_STATUS = {
  pendingSanctionsCheck: 0,
  pendingSanctionManualReview: 1,
  pendingAMLRulesExecution: 2,
  pendingManualAMLEvaluation: 3,
  automaticallyCleared: 4,
  manuallyAccepted: 5,
  manuallyRejected: 6,
  failedSanctions: 7,
  error: 8,
  automaticallyFailedSanctions: 9,
};

export const AUTOMATICALLY_CLEARED_AML_TRANSACTION_MONITORING_STATUS_STEPS = [
  AML_TRANSACTION_MONITORING_STATUS.automaticallyCleared,
  AML_TRANSACTION_MONITORING_STATUS.failedSanctions,
  AML_TRANSACTION_MONITORING_STATUS.error,
  AML_TRANSACTION_MONITORING_STATUS.automaticallyFailedSanctions,
];

export const AML_TRANSACTION_MONITORING_STATUS_STEP_LABEL = {
  [AML_TRANSACTION_MONITORING_STATUS.pendingSanctionsCheck]: 'Pending sanctions check',
  [AML_TRANSACTION_MONITORING_STATUS.pendingSanctionManualReview]: 'Pending sanctions manual review',
  [AML_TRANSACTION_MONITORING_STATUS.pendingAMLRulesExecution]: 'Pending AML Rules execution',
  [AML_TRANSACTION_MONITORING_STATUS.pendingManualAMLEvaluation]: 'Pending Manual AML evaluation',
  [AML_TRANSACTION_MONITORING_STATUS.automaticallyCleared]: 'Automatically cleared',
  [AML_TRANSACTION_MONITORING_STATUS.manuallyAccepted]: 'Manually accepted',
  [AML_TRANSACTION_MONITORING_STATUS.manuallyRejected]: 'Manually rejected',
  [AML_TRANSACTION_MONITORING_STATUS.failedSanctions]: 'Failed sanctions',
  [AML_TRANSACTION_MONITORING_STATUS.error]: 'Error',
  [AML_TRANSACTION_MONITORING_STATUS.automaticallyFailedSanctions]: 'Automatically failed sanctions',
};

export const TRANSACTION_MONITORING_HEADING = 'Transaction Monitoring';

export const BREADCRUMB_ITEMS = [
  {
    label: TRANSACTION_MONITORING_HEADING,
  },
];

export const COLUMNS_KEYS = {
  date: 'date',
  customerName: 'customerName',
  sender: 'sender',
  senderBankName: 'senderBankName',
  receiver: 'receiver',
  receiverBankName: 'receiverBankName',
  type: 'type',
  amount: 'amount',
  systemRisk: 'systemRisk',
  claimedBy: 'claimedBy',
  closedBy: 'closedBy',
  status: 'status',
  systemStatus: 'systemStatus',
  customerStatus: 'customerStatus',
};

export const PAGE_LIMITS = [10, 25, 50];

export const ALERT_STATUS = {
  pending: 0,
  accepted: 1,
  rejected: 2,
  pendingManualInterventionInOrderToContinue: 3,
};

export const COLUMNS_CONFIG = {
  all: [
    {
      columnKey: COLUMNS_KEYS.date,
      label: 'Date',
      columnSort: SORT_BY_VALUES.date,
      sortable: true,
      className: 'transaction-table__date',
    },
    {
      columnKey: COLUMNS_KEYS.customerName,
      label: 'Customer',
      columnSort: SORT_BY_VALUES.customerName,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.sender,
      label: 'Sender',
      columnSort: SORT_BY_VALUES.sender,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.senderBankName,
      columnSort: SORT_BY_VALUES.senderBankName,
      label: 'Sender Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiver,
      label: 'Receiver',
      columnSort: SORT_BY_VALUES.receiver,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiverBankName,
      columnSort: SORT_BY_VALUES.receiverBankName,
      label: 'Receiver Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.type,
      label: <TypeHeader />,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.amount,
      label: 'Amount',
      className: 'transaction-table__amount',
    },
    {
      columnKey: COLUMNS_KEYS.status,
      label: 'Status',
    },
  ],
  pending: [
    {
      columnKey: COLUMNS_KEYS.date,
      label: 'Date',
      columnSort: SORT_BY_VALUES.date,
      sortable: true,
      className: 'transaction-table__date',
    },
    {
      columnKey: COLUMNS_KEYS.customerName,
      label: 'Customer',
      columnSort: SORT_BY_VALUES.customerName,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.sender,
      label: 'Sender',
      columnSort: SORT_BY_VALUES.sender,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.senderBankName,
      columnSort: SORT_BY_VALUES.senderBankName,
      label: 'Sender Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiver,
      label: 'Receiver',
      columnSort: SORT_BY_VALUES.receiver,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiverBankName,
      columnSort: SORT_BY_VALUES.receiverBankName,
      label: 'Receiver Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.type,
      label: <TypeHeader />,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.claimedBy,
      label: 'Claimed By',
    },
    {
      columnKey: COLUMNS_KEYS.amount,
      label: 'Amount',
      className: 'transaction-table__amount',
    },
    {
      columnKey: COLUMNS_KEYS.systemRisk,
      label: 'System Risk',
    },
  ],
  closed: [
    {
      columnKey: COLUMNS_KEYS.date,
      label: 'Date',
      columnSort: SORT_BY_VALUES.date,
      sortable: true,
      className: 'transaction-table__date',
    },
    {
      columnKey: COLUMNS_KEYS.customerName,
      label: 'Customer',
      columnSort: SORT_BY_VALUES.customerName,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.sender,
      label: 'Sender',
      columnSort: SORT_BY_VALUES.sender,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.senderBankName,
      columnSort: SORT_BY_VALUES.senderBankName,
      label: 'Sender Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiver,
      label: 'Receiver',
      columnSort: SORT_BY_VALUES.receiver,
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.receiverBankName,
      columnSort: SORT_BY_VALUES.receiverBankName,
      label: 'Receiver Bank',
      sortable: true,
      className: 'transaction-table__value',
    },
    {
      columnKey: COLUMNS_KEYS.closedBy,
      label: 'Closed By',
    },
    {
      columnKey: COLUMNS_KEYS.amount,
      label: 'Amount',
      className: 'transaction-table__amount',
    },
    {
      columnKey: COLUMNS_KEYS.systemStatus,
      label: 'System Status',
    },
    {
      columnKey: COLUMNS_KEYS.customerStatus,
      label: 'Customer Status',
    },
  ],
};

export const TRANSACTION_TYPES = {
  0: 'Not Specified',
  1: 'ACH Credit',
  2: 'Stellar SEP24 Deposit',
  3: 'Stellar SEP24 Withdrawal',
  4: 'Stellar SEP31 Receive',
  5: 'Stellar SEP31 Send',
  6: 'ACH Debit',
  7: 'External ACH Credit',
  8: 'External ACH Debit',
  9: 'Domestic Wire',
  10: 'Celo Cash In',
  11: 'Celo Cash Out',
  12: 'ACH Debit for Cash In',
  13: 'ACH Credit for Cash Out',
  14: 'International Wire',
  15: 'SEPA Send',
  16: 'Internal Transfer',
  17: 'SEPA Receive',
  18: 'External Crypto',
  19: 'External Domestic Wire',
};

export const UNSUPPORTED_TRANSACTION_TYPE = 0;
export const EXTERNAL_CRYPTO_TRANSACTION_TYPE = 18;

export const CURRENCY_TYPES = {
  fiat: 0,
  crypto: 1,
};

export const CURRENCY_PROP_NAME = {
  [CURRENCY_TYPES.fiat]: 'approximateUSDAmount',
  [CURRENCY_TYPES.crypto]: 'assetAmount',
};

export const FILTERS = {
  main: 'main',
  customerName: 'customerName',
  senderName: 'senderName',
  senderBankName: 'senderBankName',
  receiverName: 'receiverName',
  receiverBankName: 'receiverBankName',
  claimedBy: 'claimedBy',
};

export const DETAILED_FILTER_KEYS = R.keys(
  R.pick(['customerName', 'senderName', 'senderBankName', 'receiverName', 'receiverBankName'], FILTERS)
);

export const DETAILED_FILTER_DEFAULT_VALUES = {
  customerName: '',
  senderName: '',
  senderBankName: '',
  receiverName: '',
  receiverBankName: '',
};
