export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const resolveObjectDepth = (obj, path) => {
  // eslint-disable-next-line no-param-reassign
  path = path.split('.');
  let current = obj;
  while (path.length) {
    if (typeof current !== 'object') return undefined;
    current = current[path.shift()];
  }
  return current;
};

export const toggleBodyScroll = (bool) => {
  document.body.style.overflow = bool ? 'overlay' : 'hidden';
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function callable(...args) {
    const context = this;
    const later = function laterCallable() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
