import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createReducer, createSaga } from '../reducers';

export const history = createBrowserHistory();

export function configureStore(initialState = {}) {
  const isProdEnv = process.env.NODE_ENV === 'production';

  const composeEnhancers = (!isProdEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [routerMiddleware(history), sagaMiddleware];

  const store = createStore(
    createReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  const rootSaga = function* rootSaga() {
    yield all([...createSaga()]);
  };

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureStore();

export default store;
