const actionTypes = {
  TOGGLE_PLAN_OVERLAY: 'TOGGLE_PLAN_OVERLAY',

  GET_DEFAULT_PLANS_SAGA: 'GET_DEFAULT_PLANS_SAGA',
  GET_DEFAULT_PLANS: 'GET_DEFAULT_PLANS',

  GET_DEFAULT_PLAN_SAGA: 'GET_DEFAULT_PLAN_SAGA',
  GET_DEFAULT_PLAN: 'GET_DEFAULT_PLAN',

  CREATE_DEFAULT_PLAN_SAGA: 'CREATE_DEFAULT_PLAN_SAGA',
  CREATE_DEFAULT_PLAN: 'CREATE_DEFAULT_PLAN',

  PUT_DEFAULT_PLAN_SAGA: 'PUT_DEFAULT_PLAN_SAGA',
  PUT_DEFAULT_PLAN: 'PUT_DEFAULT_PLAN',

  DELETE_DEFAULT_PLAN_SAGA: 'DELETE_DEFAULT_PLAN_SAGA',
  DELETE_DEFAULT_PLAN: 'DELETE_DEFAULT_PLAN',

  GET_PLAN_SKUSS_SAGA: 'GET_PLAN_SKUSS_SAGA',
  GET_PLAN_SKUS: 'GET_PLAN_SKUS',

  SET_DEFAULT_PLAN_NAME: 'SET_DEFAULT_PLAN_NAME',
  SET_DEFAULT_PLAN_SKUS: 'SET_DEFAULT_PLAN_SKUS',
  RESET_STATE: 'RESET_STATE',
};

export default actionTypes;
