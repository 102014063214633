import React from 'react';
import PropTypes from 'prop-types';

const ColourIndicator = ({ className, colour, label, displayLabelWithin }) => (
  <div
    className={`colour-indicator ${
      displayLabelWithin ? 'colour-indicator--label-within' : ''
    } ${className}`}
  >
    <div
      className={`colour-indicator__bulb colour-indicator__bulb--${colour} ${
        displayLabelWithin ? 'colour-indicator__bulb--label-within' : ''
      }`}
    >
      {displayLabelWithin && label}
    </div>
    {!displayLabelWithin && label}
  </div>
);

ColourIndicator.propTypes = {
  colour: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  displayLabelWithin: PropTypes.bool,
};
ColourIndicator.defaultProps = {
  className: '',
  displayLabelWithin: false,
};

export default ColourIndicator;
