import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  toggleVendorsOverlay,
  toggleVendorPricingOverlay,
  updateVendorSkuList,
  getDefaultVendorsStore,
  getVendorSkusStore,
  getDefaultVendorPlanStore,
  saveDefaultVendorPlanStore,
  resetState,
} from '../actions';
import initialState from './initialState';

const toggleVendorsOverlayHandler = [
  toggleVendorsOverlay,
  (state, action) => {
    return {
      ...state,
      isOverlayVisible: action.payload,
    };
  },
];

const toggleVendorPricingOverlayHandler = [
  toggleVendorPricingOverlay,
  (state, action) => {
    return {
      ...state,
      details: {
        isOverlayVisible: action.payload,
        ...(!action.payload && R.clone(initialState.details)),
      },
    };
  },
];

const updateVendorSkuListHandler = [
  updateVendorSkuList,
  (state, action) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details.data,
          thirdPartySKUsMetadata: action.payload,
        },
      },
    };
  },
];

const getDefaultVendorsStoreHandler = [
  getDefaultVendorsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
        list: [],
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      ...state,
      isLoading: false,
      list: payload,
    };
  },
];

const getVendorSkusStoreHandler = [
  getVendorSkusStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...payload,
          },
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          data: {},
        },
      };
    }

    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details.data,
          ...payload,
        },
        isLoading: false,
      },
    };
  },
];

const getDefaultVendorPlanStoreHandler = [
  getDefaultVendorPlanStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: true,
          data: {
            ...payload,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          data: {},
        },
      };
    }

    return {
      ...state,
      details: {
        ...state.details,
        isLoading: false,
        data: {
          ...state.details.data,
          ...payload,
        },
      },
    };
  },
];

const saveDefaultVendorPlanStoreHandler = [
  saveDefaultVendorPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      details: {
        ...state.details,
        isLoading: false,
      },
    };
  },
];

const resetStateHandler = [resetState, () => R.clone(initialState)];

const reducer = handleActions(
  new Map([
    toggleVendorsOverlayHandler,
    toggleVendorPricingOverlayHandler,
    updateVendorSkuListHandler,

    getDefaultVendorsStoreHandler,
    getVendorSkusStoreHandler,
    getDefaultVendorPlanStoreHandler,
    saveDefaultVendorPlanStoreHandler,

    resetStateHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
