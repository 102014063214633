const actionTypes = {
  SET_CONFIGURATION_FREQUENCY: 'SET_CONFIGURATION_FREQUENCY',

  GET_NOTIFICATION_CONFIGURATION_SAGA: 'GET_NOTIFICATION_CONFIGURATION_SAGA',
  GET_NOTIFICATION_CONFIGURATION_STORE: 'GET_NOTIFICATION_CONFIGURATION_STORE',

  SAVE_NOTIFICATION_CONFIGURATION_SAGA: 'SAVE_NOTIFICATION_CONFIGURATION_SAGA',
  SAVE_NOTIFICATION_CONFIGURATION_STORE: 'SAVE_NOTIFICATION_CONFIGURATION_STORE',
};

export default actionTypes;
