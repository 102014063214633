import styled, { css } from 'styled-components';
import { Modal } from 'reactstrap';

const StyledDialog = styled(Modal)`
  ${() => {
    return css`
      .modal__footer {
        padding: 1.6rem 3rem;
        &.position-left {
          justify-content: flex-start;
        }
        &.position-center {
          justify-content: center;
        }
        &.position-right {
          justify-content: flex-end;
        }
      }
    `;
  }}
`;

StyledDialog.displayName = 'StyledDialog';
export default StyledDialog;
