import { call, put, select, takeLatest } from 'redux-saga/effects';
import { path } from 'ramda';
import { get } from 'modules/utils';
import { REACT_APP_GET_WIRED_TRANSACTIONS_URL } from 'env-config';
import userTypes from '../types';
import {
  getWiredTransactionsStart,
  getWiredTransactionsSuccess,
  getWiredTransactionsFail,
} from '../actions/get-wired-transactions';
import { setSelectedRow } from '../actions';

export const httpCall = ({ clientId }) => get(`${REACT_APP_GET_WIRED_TRANSACTIONS_URL}?clientId=${clientId}`);

export function* getWiredTransactions({ clientId }) {
  try {
    yield put(getWiredTransactionsStart());

    const result = yield call(httpCall, { clientId });

    yield put(getWiredTransactionsSuccess(result));
    const selectedRow = yield select((state) => path(['transactions', 'selectedRow'], state));
    if (selectedRow.index || selectedRow.index === 0) {
      const newSelectedRowIndex = result.findIndex((row) => row.transactionID === selectedRow.rowDetails.transactionID);
      yield put(setSelectedRow(newSelectedRowIndex, result[newSelectedRowIndex]));
    }
  } catch (error) {
    yield put(getWiredTransactionsFail(error));
  }
}

export default function* getWiredTransactionsSaga() {
  yield takeLatest(userTypes.GET_WIRED_TRANSACTIONS, getWiredTransactions);
}
