import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { noop } from '@finclusive/utils';
import GlobalDialog from 'modules/globalDialog/components';
import FeatureToggleLoader from 'modules/featureToggle/components/FeatureToggleLoader';
import { withAuthentication } from 'hocs';
import { ErrorBoundary, Layout } from 'components/library';
import AppRoutes from 'routes';

class App extends Component {
  componentDidMount = () => {
    window.addEventListener('touchstart', noop, true); // To prevent gray highlighting on tap when using safari on ios
    toast.configure();
  };

  componentWillUnmount() {
    window.removeEventListener('touchstart', noop, true);
  }

  render() {
    return (
      <ErrorBoundary>
        <GlobalDialog />
        <FeatureToggleLoader />

        <Layout>
          <AppRoutes />
        </Layout>
      </ErrorBoundary>
    );
  }
}

export default withAuthentication(App);
