import { put, takeLatest, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { saveAs } from 'file-saver';
import { isBlank } from '@finclusive/utils';
import { get, post, putRequest, deleteRequest } from 'modules/utils';
import { ToastNotification } from 'components/library';
import API from 'config/api';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  getPendingRegistrations,
  setSelectedPendingRegistration,
  clearSelectedPendingRegistration,
  getPendingRegistrationsStore,
  getRegistrationReportStore,
  updatePendingRegistrationStore,
  approvePendingRegistrationCurrentStore,
  approvePendingRegistrationSecondaryStore,
  exportRegistrationReportStore,
  archiveRegistrationStore,
} from '.';

function* getPendingRegistrationsSagaCallback({ payload: silent }) {
  try {
    if (silent) {
      yield put(getPendingRegistrationsStore({ loading: true }));
    }

    const payload = yield call(get, API.registration.pendingRegistrations);

    yield put(getPendingRegistrationsStore({ loading: false, payload }));
  } catch (error) {
    yield put(getPendingRegistrationsStore({ loading: false, error }));
    ToastNotification('error', error.message || 'There was an error trying to get the Pending Registrations.');
  }
}

function* getPendingRegistrationsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_PENDING_REGISTRATIONS_SAGA), getPendingRegistrationsSagaCallback);
}

function* getRegistrationReportSagaCallback() {
  try {
    yield put(getRegistrationReportStore({ loading: true }));

    const payload = yield call(get, API.registration.report);

    yield put(getRegistrationReportStore({ loading: false, payload }));
  } catch (error) {
    yield put(getRegistrationReportStore({ loading: false, error }));
    ToastNotification('error', error.message || 'There was an error trying to get the Registration report.');
  }
}

function* getRegistrationReportSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_REGISTRATION_REPORT_SAGA), getRegistrationReportSagaCallback);
}

function* exportRegistrationReportSagaCallback() {
  try {
    yield put(exportRegistrationReportStore({ loading: true }));

    const response = yield call(get, {
      route: `${API.registration.reportExport}/excel`,
      responseType: 'blob',
      returnFullResponse: true,
    });

    const filename = response.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();

    saveAs(response.data, filename);

    yield put(exportRegistrationReportStore({ loading: false }));
  } catch (error) {
    yield put(exportRegistrationReportStore({ loading: false, error }));
    ToastNotification('error', error.message || 'There was an error trying to export the report.');
  }
}

function* exportRegistrationReportSaga() {
  yield takeLatest(addPrefix(actionTypes.EXPORT_REGISTRATION_REPORT_SAGA), exportRegistrationReportSagaCallback);
}

function* updatePendingRegistrationSagaCallback({ payload: data }) {
  try {
    yield put(updatePendingRegistrationStore({ loading: true }));

    const isTechContactCompleted = !isBlank(R.path(['firstName'], R.path(['techContactInfo'], data)));
    if (!isTechContactCompleted) {
      data = R.set(R.lensPath(['techContactInfo']), null, data);
    }
    yield call(putRequest, {
      route: API.registration.update,
      data,
    });

    yield put(updatePendingRegistrationStore({ loading: false }));
    ToastNotification('success', 'Pending registration was updated.');
    yield put(setSelectedPendingRegistration(data));
    yield put(getPendingRegistrations(true));
  } catch (error) {
    yield put(updatePendingRegistrationStore({ loading: false, error }));
    const errors = R.path(['response', 'data', 'reasons'], error);

    if (!isBlank(errors)) {
      errors.forEach((item) => ToastNotification('error', item));
    } else {
      ToastNotification('error', error.message || 'There was an error trying to update the Pending Registration.');
    }
  }
}

function* updatePendingRegistrationSaga() {
  yield takeLatest(addPrefix(actionTypes.UPDATE_PENDING_REGISTRATION_SAGA), updatePendingRegistrationSagaCallback);
}

function* approvePendingRegistrationCurrentSagaCallback({ payload: id }) {
  try {
    yield put(approvePendingRegistrationCurrentStore({ loading: true }));
    yield call(post, { route: `${API.registration.approveCurrent}/${id}` });

    yield put(approvePendingRegistrationCurrentStore({ loading: false }));
    ToastNotification('success', 'Pending registration was approved.');
    yield put(clearSelectedPendingRegistration());
    yield put(getPendingRegistrations(true));
  } catch (error) {
    yield put(approvePendingRegistrationCurrentStore({ loading: false, error }));
    const errors = R.path(['response', 'data', 'reasons'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => {
        ToastNotification('error', item);
      });
    } else {
      ToastNotification('error', error.message || 'There was an error trying to approve the Pending Registration.');
    }
  }
}

function* approvePendingRegistrationCurrentSaga() {
  yield takeLatest(
    addPrefix(actionTypes.APPROVE_PENDING_REGISTRATION_CURRENT_SAGA),
    approvePendingRegistrationCurrentSagaCallback
  );
}

function* approvePendingRegistrationSecondarySagaCallback({ payload: id }) {
  try {
    yield put(approvePendingRegistrationSecondaryStore({ loading: true }));
    yield call(post, { route: `${API.registration.approveSecondary}/${id}` });

    yield put(approvePendingRegistrationSecondaryStore({ loading: false }));
    ToastNotification('success', 'Pending registration was approved.');
    yield put(clearSelectedPendingRegistration());
    yield put(getPendingRegistrations(true));
  } catch (error) {
    yield put(approvePendingRegistrationSecondaryStore({ loading: false, error }));
    const errors = R.path(['response', 'data', 'reasons'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => {
        ToastNotification('error', item);
      });
    } else {
      ToastNotification('error', error.message || 'There was an error trying to approve the Pending Registration.');
    }
  }
}

function* approvePendingRegistrationSecondarySaga() {
  yield takeLatest(
    addPrefix(actionTypes.APPROVE_PENDING_REGISTRATION_SECONDARY_SAGA),
    approvePendingRegistrationSecondarySagaCallback
  );
}

function* archiveRegistrationSagaCallback({ payload: id }) {
  try {
    yield put(archiveRegistrationStore({ loading: true }));
    yield call(deleteRequest, {
      route: `${API.registration.delete}/${id}`,
    });

    yield put(archiveRegistrationStore({ loading: false }));
    ToastNotification('success', 'Registration was archived.');
    yield put(getPendingRegistrations(true));
  } catch (error) {
    yield put(archiveRegistrationStore({ loading: false, error }));
    const errors = R.path(['response', 'data', 'reasons'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => {
        ToastNotification('error', item);
      });
    } else {
      ToastNotification('error', error.message || 'There was an error trying to archive the Registration.');
    }
  }
}

function* archiveRegistrationSaga() {
  yield takeLatest(addPrefix(actionTypes.ARCHIVE_REGISTRATION_SAGA), archiveRegistrationSagaCallback);
}

const sagas = [
  call(getPendingRegistrationsSaga),
  call(getRegistrationReportSaga),
  call(exportRegistrationReportSaga),
  call(updatePendingRegistrationSaga),
  call(approvePendingRegistrationCurrentSaga),
  call(approvePendingRegistrationSecondarySaga),
  call(archiveRegistrationSaga),
];

export default sagas;
