import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Spinner from 'components/library/Spinner';
import NotFound from './static/NotFound';
import { privateRoutes, publicRoutes } from './config';
import PrivateRoute from './PrivateRoute';

const AppRoutes = (props) => {
  return (
    <Suspense fallback={<Spinner visible />}>
      <Switch>
        {props.privateRoutes.map((route) => (
          <PrivateRoute key={route.id} {...route} />
        ))}

        {props.publicRoutes.map((route) => (
          <Route key={route.id} {...route} />
        ))}

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

AppRoutes.defaultProps = {
  privateRoutes,
  publicRoutes,
};

AppRoutes.propTypes = {
  privateRoutes: PropTypes.arrayOf(PropTypes.shape({})),
  publicRoutes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AppRoutes;
