import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'components/library';

export default class ViewModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    bodyClassName: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, bodyClassName, isLoading, toggle, handleConfirm, base64 } = this.props;

    return (
      <Modal
        title="View File"
        isOpen={isOpen}
        toggle={toggle}
        primaryButton={{ label: 'Done', onClick: handleConfirm }}
      >
        <div className={bodyClassName}>
          {isLoading ? (
            <div className="min-height-6 max-height-10">
              <Spinner visible={isLoading} />
            </div>
          ) : (
            <object data={base64} width="100%" height="100%" className="min-height-6 max-height-10">
              <p>This file cannot seem to be viewed. Try downloading it instead.</p>
            </object>
          )}
        </div>
      </Modal>
    );
  }
}
