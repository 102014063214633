export const getParams = (filter, payload) => {
  const { pageSize, pageNo } = payload ?? { pageSize: 10, pageNo: 1 };
  const { searchValue } = filter ?? { searchValue: '' };

  return {
    filter: searchValue,
    currentPage: pageNo,
    pageSize 
  };
};
