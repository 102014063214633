import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ButtonComplex, Icon } from '../index';
import Spinner from '../Spinner';

export default class Modal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    spinnerVisible: PropTypes.bool,
    primaryButton: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      submit: PropTypes.bool,
    }),
    secondaryButton: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    toggle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }

  onSubmit = (e) => {
    e && e.preventDefault();
    const { primaryButton } = this.props;
    primaryButton.onClick();
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  render() {
    const { isOpen, toggle, className, title, children, primaryButton, secondaryButton, spinnerVisible } = this.props;
    const closeBtn = <Icon type="mdi" name="close" className="modal__close-icon" onClick={toggle} />;

    return (
      <ReactModal
        modalClassName={`modal ${className}`}
        contentClassName="modal__content"
        backdrop={!spinnerVisible || 'static'}
        className="modal-dialog-scrollable"
        isOpen={isOpen}
        toggle={toggle}
        centered
        size="lg"
      >
        {spinnerVisible ? <Spinner visible overlay /> : ''}
        <ModalHeader close={closeBtn} tag="div" className="modal__header">
          <div className="modal__title">{title}</div>
        </ModalHeader>
        <ModalBody className="modal__body">{children}</ModalBody>
        <ModalFooter className="modal__footer">
          {secondaryButton && (
            <ButtonComplex type="text" disabled={secondaryButton.disabled} onClick={secondaryButton.onClick}>
              {secondaryButton.label}
            </ButtonComplex>
          )}
          {primaryButton && <ButtonComplex {...primaryButton}>{primaryButton.label}</ButtonComplex>}
        </ModalFooter>
      </ReactModal>
    );
  }
}
