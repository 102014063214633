import types from '../types';

export const viewDocument = ({ documentUniqueIdentifier, name }) => ({
  type: types.GET_DOCUMENT,
  documentUniqueIdentifier,
  name,
  isDownload: false,
});

export const downloadDocument = ({ documentUniqueIdentifier, name }) => ({
  type: types.GET_DOCUMENT,
  documentUniqueIdentifier,
  name,
  isDownload: true,
});

export const getDocumentStart = () => ({
  type: types.GET_DOCUMENT_START,
});

export const getDocumentSuccess = (data) => ({
  type: types.GET_DOCUMENT_SUCCESS,
  data,
});

export const getDocumentFail = (error) => ({
  type: types.GET_DOCUMENT_FAIL,
  error,
});

export const clearDocument = () => ({
  type: types.CLEAR_DOCUMENT,
});
