import { combineReducers } from 'redux';
import amlRulesReducer from '../components/AMLRules/reducer';
import transactionsReducer from '../components/Transactions/reducer';
import internalNotificationConfiguratorReducer from '../components/InternalNotificationConfigurator/reducer';
import customerNotificationConfiguratorReducer from '../components/CustomerNotificationConfigurator/reducer';

export default combineReducers({
  amlRules: amlRulesReducer,
  transactions: transactionsReducer,
  internalNotificationConfigurator: internalNotificationConfiguratorReducer,
  customerNotificationConfigurator: customerNotificationConfiguratorReducer,
});
