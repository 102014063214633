import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Input as ReactInput } from 'reactstrap';
import CleaveInput from 'cleave.js/react';
import { omit } from 'ramda';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { isBlank } from '@finclusive/utils';
import { emailCheck } from 'utilities';

class DateField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: this.checkIfValid(props.value),
      dirty: false,
      autoFormatOptions: this.formatValue(),
      showCalendar: false,
      value: '',
    };
  }

  componentDidMount() {
    const { resetToDefault, defaultValue, name, value, onChange } = this.props;
    if (resetToDefault && value === undefined) {
      onChange({
        target: {
          value: defaultValue,
          name,
        },
      });
    }
  }

  formatValue = () => {
    const { autoFormat, name } = this.props;
    if (autoFormat) {
      if (name === 'amount') {
        return {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          prefix: '$ ',
        };
      }
      if (name === 'crypto') {
        return {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 7,
        };
      }
      if (name === 'BankRoutingNumber') {
        return { numericOnly: true };
      }
    }
    return {};
  };

  checkIfValid = (value) => {
    const { type, name, required, requiresValidation } = this.props;
    if (!requiresValidation) return null;
    if (value && value.trim()) {
      if (type === 'email' || name === 'emailAddress') return emailCheck(value);
      return !!value;
    }
    if (required) return false;
    return null;
  };

  toggleCalendar = () => {
    this.setState({ showCalendar: !this.state.showCalendar });
  };

  onDateChange = (value) => {
    const { name, onChange } = this.props;
    const formattedValue = dayjs(value).format('MM/DD/YYYY');
    onChange({ target: { name, value: formattedValue } });
    this.toggleCalendar();
  };

  render() {
    const { className, icon, label, disabled, autoFormat, value, maxLength } = this.props;
    const { valid, dirty, autoFormatOptions, showCalendar } = this.state;
    const InputType = autoFormat ? CleaveInput : ReactInput;

    return (
      <>
        <InputType
          {...omit(['requiresValidation', 'autoFormat', 'resetToDefault'], this.props)}
          onBlur={this.onBlur}
          value={value || ''}
          onClick={this.toggleCalendar}
          autoComplete="invalid"
          options={autoFormatOptions}
          type="text"
          readOnly
          className={cls('input__field', 'form-control', className, {
            'input__field--with-addon': icon,
            'input__field--invalid': valid === false && dirty,
            'input__field--disabled': disabled,
            'input__field--full-width': !label,
          })}
        />
        {maxLength && (
          <span className="input__max-length">{`${(value && value.length) || 0} / ${maxLength} Characters`}</span>
        )}
        {showCalendar && (
          <Calendar
            className="date-field-calendar"
            value={!isBlank(value) ? dayjs(value).toDate() : null}
            onChange={this.onDateChange}
          />
        )}
      </>
    );
  }
}

DateField.defaultProps = {
  className: '',
  type: '',
  placeholder: '',
  label: '',
  icon: '',
  value: '',
  defaultValue: '',
  name: '',
  maxLength: null,
  required: false,
  requiresValidation: false,
  autoFormat: false,
  disabled: false,
  resetToDefault: false,
  onChange: () => {},
};

DateField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  requiresValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  required: PropTypes.bool,
  autoFormat: PropTypes.bool,
  disabled: PropTypes.bool,
  resetToDefault: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default DateField;
