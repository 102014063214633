export default {
  TOGGLE_TRANSACTION_SIDEBAR: 'transactions/TOGGLE_TRANSACTION_SIDEBAR',
  SET_SELECTED_ROW: 'transactions/SET_SELECTED_ROW',
  SET_TRANSACTION_FILTER: 'transactions/SET_TRANSACTION_FILTER',
  SET_TRANSACTION_FILTER_INPUT: 'transactions/SET_TRANSACTION_FILTER_INPUT',
  SET_OTC_DATA: 'transactions/SET_OTC_DATA',
  SET_REPORT_DATE_INTERVAL: 'transactions/SET_REPORT_DATE_INTERVAL',

  GET_TRANSACTIONS_START: 'transactions/GET_TRANSACTIONS_START',
  GET_TRANSACTIONS: 'transactions/GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS: 'transactions/GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAIL: 'transactions/GET_TRANSACTIONS_FAIL',

  SETTLE_TRANSACTION_START: 'transactions/SETTLE_TRANSACTION_START',
  SETTLE_TRANSACTION: 'transactions/SETTLE_TRANSACTION',
  SETTLE_TRANSACTION_SUCCESS: 'transactions/SETTLE_TRANSACTION_SUCCESS',
  SETTLE_TRANSACTION_FAIL: 'transactions/SETTLE_TRANSACTION_FAIL',

  ROLLBACK_TRANSACTION_START: 'transactions/ROLLBACK_TRANSACTION_START',
  ROLLBACK_TRANSACTION: 'transactions/ROLLBACK_TRANSACTION',
  ROLLBACK_TRANSACTION_SUCCESS: 'transactions/ROLLBACK_TRANSACTION_SUCCESS',
  ROLLBACK_TRANSACTION_FAIL: 'transactions/ROLLBACK_TRANSACTION_FAIL',

  GET_OTC_DATA_START: 'transactions/GET_OTC_DATA_START',
  GET_OTC_DATA: 'transactions/GET_OTC_DATA',
  GET_OTC_DATA_SUCCESS: 'transactions/GET_OTC_DATA_SUCCESS',
  GET_OTC_DATA_FAIL: 'transactions/GET_OTC_DATA_FAIL',

  UPDATE_OTC_DATA_START: 'transactions/UPDATE_OTC_DATA_START',
  UPDATE_OTC_DATA: 'transactions/UPDATE_OTC_DATA',
  UPDATE_OTC_DATA_SUCCESS: 'transactions/UPDATE_OTC_DATA_SUCCESS',
  UPDATE_OTC_DATA_FAIL: 'transactions/UPDATE_OTC_DATA_FAIL',

  GENERATE_REPORT_START: 'transactions/GENERATE_REPORT_START',
  GENERATE_REPORT: 'transactions/GENERATE_REPORT',
  GENERATE_REPORT_SUCCESS: 'transactions/GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAIL: 'transactions/GENERATE_REPORT_FAIL',

  GET_WIRED_TRANSACTIONS: 'transactions/GET_WIRED_TRANSACTIONS',
  GET_WIRED_TRANSACTIONS_START: 'transactions/GET_WIRED_TRANSACTIONS_START',
  GET_WIRED_TRANSACTIONS_SUCCESS: 'transactions/GET_WIRED_TRANSACTIONS_SUCCESS',
  GET_WIRED_TRANSACTIONS_FAIL: 'transactions/GET_WIRED_TRANSACTIONS_FAIL',

  TOGGLE_WIRES_STATUS_MODAL: 'transactions/TOGGLE_WIRES_STATUS_MODAL',
  RESET_WIRES_STATUS_INFO: 'transactions/RESET_WIRES_STATUS_INFO',
  SET_WIRES_STATUS_INFO: 'transactions/SET_WIRES_STATUS_INFO',

  SETTLE_WIRE: 'transactions/SETTLE_WIRE',
  SETTLE_WIRE_START: 'transactions/SETTLE_WIRE_START',
  SETTLE_WIRE_SUCCESS: 'transactions/SETTLE_WIRE_SUCCESS',
  SETTLE_WIRE_FAIL: 'transactions/SETTLE_WIRE_FAIL',

  ROLLBACK_WIRE: 'transactions/ROLLBACK_WIRE',
  ROLLBACK_WIRE_START: 'transactions/ROLLBACK_WIRE_START',
  ROLLBACK_WIRE_SUCCESS: 'transactions/ROLLBACK_WIRE_SUCCESS',
  ROLLBACK_WIRE_FAIL: 'transactions/ROLLBACK_WIRE_FAIL',

  UPDATE_WIRE: 'transactions/UPDATE_WIRE',
  UPDATE_WIRE_START: 'transactions/UPDATE_WIRE_START',
  UPDATE_WIRE_SUCCESS: 'transactions/UPDATE_WIRE_SUCCESS',
  UPDATE_WIRE_FAIL: 'transactions/UPDATE_WIRE_FAIL',
};
