import React, { useState, useRef } from 'react';

import Chevron from './components/chevron';
import { ReactComponent as Check } from './components/check.svg';
import { ReactComponent as EmptyCheck } from './components/empty-check.svg';

const Accordion = ({ isComplete, title, content }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : '1000px');
    setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
  };

  return (
    <div className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        {isComplete ? <Check /> : <EmptyCheck />}
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill="#666666" />
      </button>
      <div ref={contentRef} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
        <div className="accordion__text">{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
