import React from 'react';

export const BROKERS = {
  JST: 'JST',
  B2C2: 'B2C2',
  FalconX: 'FalconX',
  GenesisOTCDesk: 'GenesisOTCDesk',
  AnchorUSD: 'AnchorUSD',
  Coinbase: 'Coinbase',
  CoinList: 'CoinList',
  OkCoin: 'OkCoin',
};

export const BROKERS_LIST = Object.keys(BROKERS).map((broker) => (
  <option key={broker} value={broker}>
    {BROKERS[broker]}
  </option>
));
