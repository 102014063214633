import * as R from 'ramda';
import { isBlank, stringSanitize } from '@finclusive/utils';
import { ENTITY_TYPES_KEYS, ENTITY_TYPES_LABELS, FREQUENCIES, PROFILE_TYPES } from './constants';

export const mapAcuantProfilesToOptions = (acuantProfiles) =>
  R.map((item) => {
    const name = R.prop('name', item);
    return {
      label: name,
      value: name,
    };
  }, acuantProfiles);

const getProfileEntityValue = (profileType, data) => {
  const profile = R.find((it) => R.prop('profileType', it) === profileType)(data);

  if (isBlank(profile)) {
    return null;
  }

  return profile;
};

const getSelectValue = (value) => (isBlank(value) ? null : { value, label: value });

const getUpdatedProfile = (value, profile, profileType, key) => {
  const updatedProfile = isBlank(profile) ? { profileType } : profile;
  return {
    ...updatedProfile,
    [key]: stringSanitize(value),
  };
};

const getFrequencySelectValue = (value) => (isBlank(value) ? null : { value, label: FREQUENCIES[value] });

export const getFrequencySelectFieldsConfig = (data, onChange) => {
  const onGoing = getProfileEntityValue(PROFILE_TYPES.OnGoing, data);
  const revalidation = getProfileEntityValue(PROFILE_TYPES.Revalidation, data);
  const frequencyOptions = R.map((key) => ({ label: FREQUENCIES[key], value: parseFloat(key) }), R.keys(FREQUENCIES));
  const onGoingFrequency = R.prop('frequencyCheck', onGoing);
  const revalidationFrequency = R.prop('frequencyCheck', revalidation);
  return [
    {
      type: 'select',
      title: 'Ongoing Monitoring Frequency',
      name: 'ongoingMonitoringFrequency',
      options: frequencyOptions,
      value: getFrequencySelectValue(onGoingFrequency),
      onChange: ({
        target: {
          value: { value },
        },
      }) => onChange(getUpdatedProfile(value, onGoing, PROFILE_TYPES.OnGoing, 'frequencyCheck')),
    },
    {
      type: 'select',
      title: 'Revalidation Frequency',
      name: 'revalidationFrequency',
      options: frequencyOptions,
      value: getFrequencySelectValue(revalidationFrequency),
      onChange: ({
        target: {
          value: { value },
        },
      }) => onChange(getUpdatedProfile(value, revalidation, PROFILE_TYPES.Revalidation, 'frequencyCheck')),
    },
  ];
};

export const getCompanyTypeSelectFieldsConfig = (data, entityType, acuantProfileOptions, onChange) => {
  const fullProfile = getProfileEntityValue(PROFILE_TYPES.FullProfile, data);
  const onGoing = getProfileEntityValue(PROFILE_TYPES.OnGoing, data);
  const revalidation = getProfileEntityValue(PROFILE_TYPES.Revalidation, data);
  const entityTypeKey = ENTITY_TYPES_KEYS[entityType];
  return {
    title: ENTITY_TYPES_LABELS[entityType],
    fields: [
      {
        type: 'select',
        title: 'Full Profile',
        name: 'fullProfile',
        options: acuantProfileOptions,
        value: getSelectValue(R.prop(entityTypeKey, fullProfile)),
        onChange: ({
          target: {
            value: { value },
          },
        }) => onChange(getUpdatedProfile(value, fullProfile, PROFILE_TYPES.FullProfile, entityTypeKey)),
      },
      {
        type: 'select',
        title: 'Ongoing Monitoring Profile',
        name: 'ongoingMonitoringProfile',
        options: acuantProfileOptions,
        value: getSelectValue(R.prop(entityTypeKey, onGoing)),
        onChange: ({
          target: {
            value: { value },
          },
        }) => onChange(getUpdatedProfile(value, onGoing, PROFILE_TYPES.OnGoing, entityTypeKey)),
      },
      {
        type: 'select',
        title: 'Revalidation Profile',
        name: 'revalidationProfile',
        options: acuantProfileOptions,
        value: getSelectValue(R.prop(entityTypeKey, revalidation)),
        onChange: ({
          target: {
            value: { value },
          },
        }) => onChange(getUpdatedProfile(value, revalidation, PROFILE_TYPES.Revalidation, entityTypeKey)),
      },
    ],
  };
};

export const checkIfSameConfiguration = (configuration, initialConfiguration) => {
  if (initialConfiguration.length !== configuration.length) {
    return false;
  }
  const checks = R.map((config) => {
    const profileType = R.prop('profileType', config);
    const updatedProfile = R.find((it) => R.prop('profileType', it) === profileType)(configuration);
    return R.equals(config, updatedProfile);
  }, initialConfiguration);
  return isBlank(checks.filter((it) => !it));
};

export const validateConfiguration = (configuration) => {
  const checks = R.map((config) => {
    const { individualProfile, entityProfile, profileType, frequencyCheck } = config;
    if (profileType === PROFILE_TYPES.FullProfile) {
      return !(isBlank(entityProfile) || isBlank(individualProfile));
    }
    return !(isBlank(entityProfile) || isBlank(individualProfile) || isBlank(frequencyCheck));
  }, configuration);
  return isBlank(checks.filter((it) => !it));
};

export const getProfilesToSave = (configuration, initialConfiguration) => {
  if (isBlank(configuration)) return [];
  const profiles = configuration.filter((config) => {
    const initialConfig = R.find((it) => R.prop('profileType', it) === R.prop('profileType', config))(
      initialConfiguration
    );
    return !R.equals(config, initialConfig);
  });
  return profiles;
};
