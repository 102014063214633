import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkBreakpoint, collapseSideBar, setBreakpoint } from 'modules/browser';
import SidebarHeader from './components/sidebarHeader';
import SidebarFooter from './components/sidebarFooter';
import MainSection from './components/mainSection';
import StyledSidebar from './Sidebar.style';

class Sidebar extends Component {
  componentDidMount() {
    this.setBrowserBreakpoint();
    window.addEventListener('resize', this.setBrowserBreakpoint);
  }

  componentDidUpdate = (prevProps) => {
    const { isNarrow, actions } = this.props;
    if (!prevProps.isNarrow && isNarrow) {
      actions.collapseSideBar(true);
    }
    if (prevProps.isNarrow && !isNarrow) {
      actions.collapseSideBar(false);
    }
  };

  componentWillUnmount() {
    this.setBrowserBreakpoint();
    window.removeEventListener('resize', this.setBrowserBreakpoint);
  }

  setBrowserBreakpoint = () => {
    const { breakpoint, actions } = this.props;
    const currentBreakpoint = checkBreakpoint();
    if (breakpoint !== currentBreakpoint) {
      actions.setBreakpoint(currentBreakpoint);
    }
  };

  render() {
    const { isCollapsed, isNarrow, actions } = this.props;

    return (
      <StyledSidebar isCollapsed={isCollapsed} data-test="sidebar-container">
        <SidebarHeader
          isCollapsed={isCollapsed}
          isNarrow={isNarrow}
          onSidebarCollapse={() => actions.collapseSideBar(!isCollapsed)}
        />
        <MainSection />
        <SidebarFooter />
      </StyledSidebar>
    );
  }
}

Sidebar.propTypes = {
  isNarrow: PropTypes.bool.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    collapseSideBar: PropTypes.func,
    setBreakpoint: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ browser }) => {
  return {
    isCollapsed: browser.sidebarIsCollapsed,
    isNarrow: ['tablet', 'stablet', 'phablet'].includes(browser.breakpoint),
    breakpoint: browser.breakpoint,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      collapseSideBar,
      setBreakpoint,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
