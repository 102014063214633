const actionTypes = {
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_STORE: 'GET_CUSTOMERS_STORE',
  SET_CUSTOMERS_PAGE_FILTERS: 'SET_CUSTOMERS_PAGE_FILTERS',

  GET_CUSTOMER_ACUANT_PROFILES: 'GET_CUSTOMER_ACUANT_PROFILES',
  GET_CUSTOMER_ACUANT_PROFILES_STORE: 'GET_CUSTOMER_ACUANT_PROFILES_STORE',

  GET_CUSTOMER_CONFIGURATION: 'GET_CUSTOMER_CONFIGURATION',
  GET_CUSTOMER_CONFIGURATION_STORE: 'GET_CUSTOMER_CONFIGURATION_STORE',

  RESET_CUSTOMER_CONFIGURATION_STORE: 'RESET_CUSTOMER_CONFIGURATION_STORE',
  SET_CUSTOMER_CONFIGURATION: 'SET_CUSTOMER_CONFIGURATION',

  SAVE_CUSTOMER_CONFIGURATION: 'SAVE_CUSTOMER_CONFIGURATION',
  SAVE_CUSTOMER_CONFIGURATION_STORE: 'SAVE_CUSTOMER_CONFIGURATION_STORE',
};

export default actionTypes;
