import { call, put, takeLatest } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_WORKFLOW_ITEM_PACKAGE as ENDPOINT } from 'env-config';
import { post } from 'modules/utils';
import { searchInValue, getEddStageForBackend } from 'utilities';
import {
  getWorkItemPackagesFail,
  getWorkItemPackagesStart,
  getWorkItemPackagesSuccess,
} from '../actions/get-work-item-packages';
import types from '../types';

export const httpCall = (data) => post({ route: ENDPOINT, data });

export function* getWorkItemPackages({ pageNo, pageSize, sortFilters, query, currentTab, statuses, assignedTo }) {
  try {
    yield put(getWorkItemPackagesStart());

    const pageIndex = pageNo > 1 ? pageNo - 1 : 0; // pageNo and pageIndex are two different things
    const payload = {
      pageFilter: { pageIndex, pageSize: parseInt(pageSize, 10) },
      statuses,
    };

    if (!(statuses && statuses.length)) {
      payload.statuses = getEddStageForBackend(currentTab);
    }

    if (assignedTo && assignedTo.length) {
      payload.assignedTo = { iDs: assignedTo };
    }

    if (Object.keys(sortFilters).length) {
      payload.sortFilters = [sortFilters];
    }

    if (query) {
      const keywords = searchInValue(query).split(' ');
      payload.customer = { keywords };
      payload.client = { keywords };
    }

    const result = yield call(httpCall, payload);

    yield put(
      getWorkItemPackagesSuccess({
        ...result,
        currentPage: result.pageIndex,
        pageSize: result.pageSize,
        pageCount: result.totalNumberOfPages,
        rowCount: result.pageSize * result.totalNumberOfPages,
        query,
        currentTab,
        statuses,
        assignedTo,
      })
    );
  } catch (error) {
    yield put(getWorkItemPackagesFail(error));
    ToastNotification('error', error.message || 'There was an error trying to load work items');
  }
}

export default function* getWorkItemPackagesSaga() {
  yield takeLatest(types.GET_WORK_ITEM_PACKAGES, getWorkItemPackages);
}
