import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  clearSearchFilters,
  setPageFilters,
  setSearchFilters,
  toggleCustomerPlansOverlay,
  setFormData,
  setSelectedCustomer,
  setCustomerPlanSkus,
  getCustomersPlansStore,
  getCustomerPlanStore,
  createCustomerPlanStore,
  updateCustomerPlanStore,
  getCustomerVendorsStore,
  toggleVendorPricingOverlay,
  getCustomerVendorPlanStore,
  updateVendorSkuList,
  saveCustomerVendorPlanStore,
  deleteCustomerVendorPlanStore,
  resetState,
} from '../actions';
import initialState from './initialState';

const clearSearchFiltersHandler = [
  clearSearchFilters,
  (state) => {
    return {
      ...state,
      searchFilters: R.path(['searchFilters'], R.clone(initialState)),
    };
  },
];

const setPageFiltersHandler = [
  setPageFilters,
  (state, action) => {
    return {
      ...state,
      pageFilters: {
        ...state.pageFilters,
        ...action.payload,
      },
    };
  },
];

const setSearchFiltersHandler = [
  setSearchFilters,
  (state, action) => {
    return {
      ...state,
      searchFilters: { ...action.payload },
    };
  },
];

const toggleCustomerPlansOverlayHandler = [
  toggleCustomerPlansOverlay,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isVisible: action.payload,
        isDirty: false,
        ...(!action.payload && R.clone(initialState.planOverlay)),
      },
    };
  },
];

const setFormDataHandler = [
  setFormData,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isDirty: true,
        formData: {
          ...state.planOverlay.formData,
          ...action.payload,
        },
      },
    };
  },
];

const setSelectedCustomerHandler = [
  setSelectedCustomer,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        customer: {
          ...state.planOverlay.customer,
          ...action.payload,
        },
      },
    };
  },
];

const setCustomerPlanSkusHandler = [
  setCustomerPlanSkus,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isDirty: true,
        customer: {
          ...state.planOverlay.customer,
          finClusiveSKUs: action.payload,
        },
      },
    };
  },
];

const getCustomersPlansStoreHandler = [
  getCustomersPlansStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    return {
      ...state,
      isLoading: false,
      data: R.omit(['results'], payload),
      list: R.path(['results'], payload),
    };
  },
];

const getCustomerPlanStoreHandler = [
  getCustomerPlanStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isLoading: false,
        customer: {
          ...state.planOverlay.customer,
          ...R.omit(['createdAt', 'finClusiveSKUsMetadata', 'updatedAt'], payload),
          finClusiveSKUs: payload.finClusiveSKUsMetadata,
        },
      },
    };
  },
];

const createCustomerPlanStoreHandler = [
  createCustomerPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isLoading: false,
        isDirty: false,
      },
    };
  },
];

const updateCustomerPlanStoreHandler = [
  updateCustomerPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          isLoading: false,
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        isLoading: false,
        isDirty: false,
      },
    };
  },
];

const getCustomerVendorsStoreHandler = [
  getCustomerVendorsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            isLoading: false,
          },
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          isLoading: false,
          list: payload,
        },
      },
    };
  },
];

const toggleVendorPricingOverlayHandler = [
  toggleVendorPricingOverlay,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          details: {
            ...state.planOverlay.vendors.details,
            isOverlayVisible: action.payload,
            ...(!action.payload && R.clone(initialState.details)),
          },
        },
      },
    };
  },
];

const getCustomerVendorPlanStoreHandler = [
  getCustomerVendorPlanStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: true,
              data: {
                ...state.planOverlay.vendors.details.data,
                ...payload,
              },
            },
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: false,
            },
          },
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          details: {
            ...state.planOverlay.vendors.details,
            isLoading: false,
            isCustom: payload.hasPlanAssigned,
            data: {
              ...state.planOverlay.vendors.details.data,
              ...payload.data,
            },
          },
        },
      },
    };
  },
];

const updateVendorSkuListHandler = [
  updateVendorSkuList,
  (state, action) => {
    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          details: {
            ...state.planOverlay.vendors.details,
            data: {
              ...state.planOverlay.vendors.details.data,
              thirdPartySKUsMetadata: action.payload,
            },
          },
        },
      },
    };
  },
];

const saveCustomerVendorPlanStoreHandler = [
  saveCustomerVendorPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: true,
            },
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: false,
            },
          },
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          details: {
            ...state.planOverlay.vendors.details,
            isLoading: false,
          },
        },
      },
    };
  },
];

const deleteCustomerVendorPlanStoreHandler = [
  deleteCustomerVendorPlanStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            isLoading: true,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: true,
            },
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        planOverlay: {
          ...state.planOverlay,
          vendors: {
            ...state.planOverlay.vendors,
            isLoading: false,
            details: {
              ...state.planOverlay.vendors.details,
              isLoading: false,
            },
          },
        },
        error,
      };
    }

    return {
      ...state,
      planOverlay: {
        ...state.planOverlay,
        vendors: {
          ...state.planOverlay.vendors,
          isLoading: false,
          details: {
            ...state.planOverlay.vendors.details,
            isLoading: false,
          },
        },
      },
    };
  },
];

const resetStateHandler = [resetState, () => R.clone(initialState)];

const reducer = handleActions(
  new Map([
    clearSearchFiltersHandler,
    setPageFiltersHandler,
    setSearchFiltersHandler,
    toggleCustomerPlansOverlayHandler,
    toggleVendorPricingOverlayHandler,

    setFormDataHandler,
    setSelectedCustomerHandler,
    setCustomerPlanSkusHandler,

    getCustomersPlansStoreHandler,
    getCustomerPlanStoreHandler,
    createCustomerPlanStoreHandler,
    updateCustomerPlanStoreHandler,
    getCustomerVendorsStoreHandler,
    getCustomerVendorPlanStoreHandler,
    updateVendorSkuListHandler,
    saveCustomerVendorPlanStoreHandler,
    deleteCustomerVendorPlanStoreHandler,

    resetStateHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
