import {
  REACT_APP_AAD_CLIENT_ID,
  REACT_APP_AAD_AUTHORITY,
  REACT_APP_AAD_TOKEN_SCOPE,
  REACT_APP_HOSTNAME,
} from 'env-config';
import ROUTE_PATHS from 'routes/routePaths';

export const SCOPES = [REACT_APP_AAD_TOKEN_SCOPE];

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AAD_CLIENT_ID,
    authority: REACT_APP_AAD_AUTHORITY,
    postLogoutRedirectUri: `${REACT_APP_HOSTNAME}${ROUTE_PATHS.LOGIN}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  redirectUri: `${REACT_APP_HOSTNAME}${ROUTE_PATHS.LOGIN_REDIRECT}`,
  scopes: SCOPES,
};

export const tokenRequest = {
  scopes: SCOPES,
  forceRefresh: false,
};
