export const getEddStageType = (type) => {
  return (
    {
      0: 'New',
      1: 'Claimed',
      2: 'In Review',
      3: 'Final Approval',
      1000: 'Completed',
      1001: 'Accepted',
      1002: 'Rejected',
    }[type] || 'Unknown'
  );
};

export const getEddStageForBackend = (type) => {
  return (
    {
      new: [0, 1],
      review: [2, 3],
      completed: [1000, 1001, 1002],
    }[type] || []
  );
};

export const getEddStageByTabOptions = (tab) => {
  return (
    {
      new: [
        { label: 'New', value: 0 },
        { label: 'Claimed', value: 1 },
      ],
      review: [
        { label: 'In Review', value: 2 },
        { label: 'Final Approval', value: 3 },
      ],
      completed: [
        { label: 'Completed', value: 1000 },
        { label: 'Accepted', value: 1001 },
        { label: 'Rejected', value: 1002 },
      ],
    }[tab] || []
  );
};
