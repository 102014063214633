import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { Button, H2, Icon, Loader } from '@finclusive/library';
import { isBlank, noop } from '@finclusive/utils';
import { toggleBodyScroll } from 'utilities';
import Tools from '../ViewContainer/Tools';
import { StyledLayout } from '../Layout/Layout.style';
import StyledPageOverlay from './PageOverlay.style';

const PageOverlay = ({
  visible,
  onExit,
  dataTest,
  className,
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  togglePageOverlay,
  children,
  spinnerVisible: isLoading,
  hasFormWithValidation,
  parent,
  sidebarCollapsed,
  form,
  customTools,
  withBorder,
  footer,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const overlayElement = useMemo(() => document.createElement('div'), []);

  const openOverlay = () => {
    const rootElement = document.getElementById('app-container-content');
    rootElement.appendChild(overlayElement);
    toggleBodyScroll(false);
    setTimeout(() => setIsOpened(true), 10);
  };

  const closeOverlay = () => {
    if (isOpened) {
      onExit();
      setIsOpened(false);
      setTimeout(() => {
        const rootElement = document.getElementById('app-container-content');
        if (rootElement.contains(overlayElement)) {
          rootElement.removeChild(overlayElement);
        }
        toggleBodyScroll(true);
      }, 500);
    }
  };

  useEffect(() => {
    return () => {
      togglePageOverlay(false);
      const rootElement = document.getElementById('app-container-content');
      if (rootElement.contains(overlayElement)) {
        rootElement.removeChild(overlayElement);
      }
      toggleBodyScroll(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsTransitioning(true);
    visible ? openOverlay() : closeOverlay();
    const timer = setTimeout(() => setIsTransitioning(false), 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onSubmit = (e) => {
    e && e.preventDefault();
    if (hasFormWithValidation && e.target.id === form) {
      primaryButton.action();
    }
  };

  const isFooterVisible = !isBlank(R.prop('label')(primaryButton)) || !isBlank(R.prop('label')(secondaryButton));

  return ReactDOM.createPortal(
    <StyledPageOverlay
      isLoading={isLoading}
      isVisible={isOpened}
      isTransitioning={isTransitioning}
      isSidebarCollapsed={sidebarCollapsed}
      withBorder={withBorder}
      className={className}
    >
      {isOpened && (
        <Form id={form} className="page-overlay-form" data-test={dataTest} onSubmit={onSubmit}>
          <StyledLayout>
            <div className="overlay-header">
              {parent && (
                <div className="crumbs" data-test={`${dataTest}-parent`}>
                  {parent}
                </div>
              )}

              <div className="overlay-title" data-test={`${dataTest}-title`}>
                <Icon name="arrowLeft" className="overlay-icon" onClick={() => togglePageOverlay(false)} />
                <H2 className="title">{title}</H2>
              </div>

              {subTitle && (
                <div className="subtitle" data-test={`${dataTest}-sub-title`}>
                  {subTitle}
                </div>
              )}

              <Tools isLoading={isLoading} customTools={customTools} dataTest={`${dataTest}-tools`} />
            </div>

            <div id="overlay-top-scroll" className="overlay-content">
              {isLoading ? <Loader className="loader" size="medium" /> : children}
            </div>
          </StyledLayout>

          {isFooterVisible && (
            <div className="overlay-footer">
              {!isBlank(secondaryButton) && (
                <Button
                  color={secondaryButton.color || 'secondary'}
                  variant={secondaryButton.variant || 'contained'}
                  disabled={isLoading || secondaryButton.disabled}
                  dataTest={`${dataTest}-secondaryButton`}
                  action={secondaryButton.action}
                >
                  {secondaryButton.label}
                </Button>
              )}
              {!isBlank(primaryButton) && (
                <Button
                  color={primaryButton.color || 'primary'}
                  variant={primaryButton.variant || 'contained'}
                  disabled={isLoading || primaryButton.disabled}
                  dataTest={`${dataTest}-primaryButton`}
                  action={primaryButton.action}
                  submit={hasFormWithValidation}
                  submitForm={form}
                >
                  {primaryButton.label}
                </Button>
              )}
            </div>
          )}

          {!isBlank(footer) && <div className="overlay-footer">{footer}</div>}
        </Form>
      )}
    </StyledPageOverlay>,
    overlayElement
  );
};

PageOverlay.defaultProps = {
  children: null,
  visible: false,
  className: '',
  spinnerVisible: false,
  hasFormWithValidation: false,
  form: '',
  parent: '',
  subTitle: '',
  customTools: [],
  primaryButton: {},
  secondaryButton: {},
  dataTest: '',
  onExit: noop,
  withBorder: true,
  footer: null,
};

PageOverlay.propTypes = {
  visible: PropTypes.bool,
  onExit: PropTypes.func,
  togglePageOverlay: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  className: PropTypes.string,
  spinnerVisible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  primaryButton: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.func,
  }),
  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.func,
  }),
  children: PropTypes.node,
  parent: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  hasFormWithValidation: PropTypes.bool,
  form: PropTypes.string,
  customTools: PropTypes.arrayOf(PropTypes.shape({})),
  dataTest: PropTypes.string,
  withBorder: PropTypes.bool,
  footer: PropTypes.node,
};

const mapStateToProps = ({ browser }) => ({
  sidebarCollapsed: browser.sidebarIsCollapsed,
});

export default connect(mapStateToProps)(PageOverlay);
