const initialState = {
  isLoading: false,
  isDeleting: false,
  searchInput: '',
  filterBy: '',
  list: [],
  planOverlay: {
    isVisible: false,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    data: {
      planName: '',
      finClusiveSKUs: [],
    },
  },
  error: null,
};

export default initialState;
