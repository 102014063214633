import qs from 'qs';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { filterEmptyKeys } from '@finclusive/utils';
import API from 'config/api';
import { get, patch } from 'modules/utils';
import { showErrorMessage } from 'modules/utils/saga-errors';
import { toggleDetailView } from '../../browser';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  getInternalUsersStart,
  getInternalUsersSuccess,
  getInternalUsersFail,
  getInternalUserDetailsStart,
  getInternalUserDetailsSuccess,
  getInternalUserDetailsFail,
  patchInternalUserDetailsStart,
  patchInternalUserDetailsSuccess,
  patchInternalUserDetailsFail,
  getInternalPermissionsStart,
  getInternalPermissionsSuccess,
  getInternalPermissionsFail,
} from '.';
import { getParams } from './helpers';

const { GET_INTERNAL_USERS, GET_INTERNAL_USER_DETAILS, PATCH_INTERNAL_USER_DETAILS, GET_INTERNAL_PERMISSIONS } =
  actionTypes;

function* getInternalUsersSagaCallback({ payload }) {
  try {
    yield put(toggleDetailView(false));
    yield put(getInternalUsersStart());

    const filter = yield select(({ internalUserManagement }) => internalUserManagement.filter);
    const params = qs.stringify(filterEmptyKeys(getParams(filter, payload)));
    const uri = API.internalUserManagement.getInternalUsers(params);
    const result = yield call(get, uri);

    yield put(getInternalUsersSuccess(result));
  } catch (error) {
    yield put(getInternalUsersFail(error));
    showErrorMessage(error, 'There was an error trying to fetch Internal Users.');
  }
}

function* getInternalUsersSaga() {
  yield takeLatest(addPrefix(GET_INTERNAL_USERS), getInternalUsersSagaCallback);
}

function* getInternalUserDetailsSagaCallback({ payload }) {
  try {
    yield put(getInternalUserDetailsStart());

    const url = API.internalUserManagement.getInternalUserDetails(payload);
    const result = yield call(get, url);

    yield put(getInternalUserDetailsSuccess(result));
  } catch (error) {
    yield put(getInternalUserDetailsFail(error));
    showErrorMessage(error, 'There was an error trying to fetch a Internal User.');
  }
}

function* getInternalUserDetails() {
  yield takeLatest(addPrefix(GET_INTERNAL_USER_DETAILS), getInternalUserDetailsSagaCallback);
}

function* getInternalPermissionsSagaCallback() {
  try {
    yield put(getInternalPermissionsStart());

    const url = API.internalUserManagement.getInternalPermissions();
    const result = yield call(get, url);

    yield put(getInternalPermissionsSuccess(result));
  } catch (error) {
    yield put(getInternalPermissionsFail(error));
    showErrorMessage(error, 'There was an error trying to fetch a Internal Permissions.');
  }
}

function* getInternalPermissionsSaga() {
  yield takeLatest(addPrefix(GET_INTERNAL_PERMISSIONS), getInternalPermissionsSagaCallback);
}

function* patchInternalUserSagaCallback({ payload }) {
  try {
    yield put(patchInternalUserDetailsStart());

    const result = yield call(patch, {
      route: API.internalUserManagement.updateInternalUser(),
      data: payload,
    });

    yield put(patchInternalUserDetailsSuccess(result));
  } catch (error) {
    yield put(patchInternalUserDetailsFail(error));
    showErrorMessage(error, 'There was an error when trying to update Internal User.');
  }
}

function* patchInternalUserSaga() {
  yield takeLatest(addPrefix(PATCH_INTERNAL_USER_DETAILS), patchInternalUserSagaCallback);
}

export const internalUserManagementSagas = [
  call(getInternalUsersSaga),
  call(getInternalUserDetails),
  call(patchInternalUserSaga),
  call(getInternalPermissionsSaga),
];
