import { lazy } from 'react';
import { PERMISSION_TYPES } from '../hocs';
import Login from '../modules/userManager/components/components/login';
import LoginRedirect from '../modules/userManager/components/components/loginRedirect';
import Logout from '../modules/userManager/components/components/logout';
import Error from '../modules/error/components/Error';
import ROUTE_PATHS from './routePaths';

const DashboardRoot = lazy(() => import('../components/views/pages/Dashboard'));
const RegistrationDashboardRoot = lazy(() => import('../modules/registration'));
const ResourcesRoot = lazy(() => import('../components/views/pages/Resources'));
const FeedbackRoot = lazy(() => import('../components/views/pages/Feedback'));
const TransactionsRoot = lazy(() => import('../components/views/Transactions'));
const WiredTransactionsRoot = lazy(() => import('../components/views/WiredTransactions'));
const LimitsManagementRoot = lazy(() => import('../modules/limitsManagement/components'));
const StellarReportRoot = lazy(() => import('../components/views/Management/Report'));
const TransactionMonitoringList = lazy(() =>
  import('../modules/transactionMonitoring/components/Transactions/components')
);
const TransactionMonitoringRules = lazy(() =>
  import('../modules/transactionMonitoring/components/AMLRules/components')
);
const BillingCustomerPlans = lazy(() => import('../modules/billing/components/CustomerPlans/components/CustomerPlans'));
const BillingDefaultPlans = lazy(() => import('../modules/billing/components/DefaultPlans/components/DefaultPlans'));
const CustomerNotifications = lazy(() => import('../modules/customerNotifications/components/CustomerNotifications'));
const CDDMonitoring = lazy(() => import('../modules/cddMonitoring/components/CDDMonitoring'));
const CddMonitoringProfile = lazy(() => import('../modules/cddMonitoring/components/CDDMonitoringProfile'));
const CreateTransaction = lazy(() => import('../modules/createTransaction/components'));

const InternalUserManagement = lazy(() => import('../modules/internalUserManagement/components/index'));

const InternalUserManagementEdit = lazy(() =>
  import('../modules/internalUserManagement/components/components/EditView/index')
);

export const privateRoutes = [
  {
    id: ROUTE_PATHS.DASHBOARD,
    path: ROUTE_PATHS.DASHBOARD,
    component: DashboardRoot,
    title: 'Dashboard',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.REGISTRATION,
    path: ROUTE_PATHS.REGISTRATION,
    component: RegistrationDashboardRoot,
    title: 'Registration',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.RESOURCES,
    path: ROUTE_PATHS.RESOURCES,
    component: ResourcesRoot,
    title: 'Resources',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.FEEDBACK,
    path: ROUTE_PATHS.FEEDBACK,
    component: FeedbackRoot,
    title: 'Feedback',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.STELLAR_TRANSACTIONS,
    path: ROUTE_PATHS.STELLAR_TRANSACTIONS,
    component: TransactionsRoot,
    title: 'Stellar Transactions',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.WIRED_TRANSACTIONS,
    path: ROUTE_PATHS.WIRED_TRANSACTIONS,
    component: WiredTransactionsRoot,
    title: 'Wired Transactions',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.MANAGEMENT_LIMITS,
    path: ROUTE_PATHS.MANAGEMENT_LIMITS,
    component: LimitsManagementRoot,
    title: 'Limits Management',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.STELLAR_REPORT,
    path: ROUTE_PATHS.STELLAR_REPORT,
    component: StellarReportRoot,
    title: 'Stellar Report',
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.TRANSACTION_MONITORING_TRANSACTIONS,
    path: ROUTE_PATHS.TRANSACTION_MONITORING_TRANSACTIONS,
    component: TransactionMonitoringList,
    title: 'Transactions - Transaction Monitoring',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_AML_TRANSACTIONS],
  },
  {
    id: ROUTE_PATHS.TRANSACTION_MONITORING_AML_RULES,
    path: ROUTE_PATHS.TRANSACTION_MONITORING_AML_RULES,
    component: TransactionMonitoringRules,
    title: 'AML Rules - Transaction Monitoring',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_AML_RULES],
  },
  {
    id: ROUTE_PATHS.BILLING_CUSTOMERS,
    path: ROUTE_PATHS.BILLING_CUSTOMERS,
    component: BillingCustomerPlans,
    title: 'Plans - Billing',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_BILLING],
  },
  {
    id: ROUTE_PATHS.BILLING_DEFAULT_PLANS,
    path: ROUTE_PATHS.BILLING_DEFAULT_PLANS,
    component: BillingDefaultPlans,
    title: 'Default Plans - Billing',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_BILLING],
  },
  {
    id: ROUTE_PATHS.CUSTOMER_NOTIFICATIONS,
    path: ROUTE_PATHS.CUSTOMER_NOTIFICATIONS,
    component: CustomerNotifications,
    title: 'Customer Notifications',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_CUSTOMER_NOTIFICATIONS, PERMISSION_TYPES.VIEW_TM_NOTIFICATIONS],
  },
  {
    id: ROUTE_PATHS.INTERNAL_USER_MANAGEMENT,
    path: ROUTE_PATHS.INTERNAL_USER_MANAGEMENT,
    component: InternalUserManagement,
    title: 'Internal User Management',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_INTERNAL_USERS],
  },
  {
    id: ROUTE_PATHS.INTERNAL_USER_MANAGEMENT_EDIT,
    path: ROUTE_PATHS.INTERNAL_USER_MANAGEMENT_EDIT,
    component: InternalUserManagementEdit,
    title: 'Internal User Management Edit',
    exact: true,
    permissions: [PERMISSION_TYPES.EDIT_INTERNAL_USERS],
  },
  {
    id: ROUTE_PATHS.CDD_MONITORING,
    path: ROUTE_PATHS.CDD_MONITORING,
    component: CDDMonitoring,
    title: 'CDD Monitoring',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_CDD_MONITORING],
  },
  {
    id: ROUTE_PATHS.CDD_MONITORING_CONFIGURATION,
    path: ROUTE_PATHS.CDD_MONITORING_CONFIGURATION,
    component: CddMonitoringProfile,
    title: 'CDD Monitoring Profile',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_CDD_MONITORING],
  },
  {
    id: `${ROUTE_PATHS.CREATE_TRANSACTION}/:accountNumber?`,
    path: `${ROUTE_PATHS.CREATE_TRANSACTION}/:accountNumber?`,
    component: CreateTransaction,
    title: 'Create Transaction',
    exact: true,
    permissions: [PERMISSION_TYPES.VIEW_PAYOPS_ACCOUNT],
  },
  {
    id: ROUTE_PATHS.ERROR,
    path: ROUTE_PATHS.ERROR,
    component: Error,
    title: 'Error',
    exact: true,
    permissions: [],
  },
  {
    id: `${ROUTE_PATHS.ERROR}/:code`,
    path: `${ROUTE_PATHS.ERROR}/:code`,
    component: Error,
    title: 'Error',
    exact: true,
    permissions: [],
  },
];

export const publicRoutes = [
  {
    id: ROUTE_PATHS.LOGIN,
    path: ROUTE_PATHS.LOGIN,
    component: Login,
    title: 'Login',
    exact: true,
  },
  {
    id: ROUTE_PATHS.LOGIN_REDIRECT,
    path: ROUTE_PATHS.LOGIN_REDIRECT,
    component: LoginRedirect,
    exact: true,
    permissions: [],
  },
  {
    id: ROUTE_PATHS.LOGOUT,
    path: ROUTE_PATHS.LOGOUT,
    component: Logout,
    title: 'Logout',
    exact: true,
  },
];
