import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { noop } from '@finclusive/utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideDialog } from 'actions/global';
import { Dialog } from 'components/library';
import { SIZE_TYPES, POSITION_TYPES } from 'components/library/Dialog/constants';

const GlobalDialog = ({ dialogData, actions }) => {
  return (
    <>
      {dialogData.isVisible ? (
        <Dialog
          header={dialogData.header}
          onClose={R.compose(actions.hideDialog, dialogData.onClose || noop)}
          onCancel={actions.hideDialog}
          actions={dialogData.actions}
          {...dialogData.options}
        >
          {dialogData.body}
        </Dialog>
      ) : null}
    </>
  );
};

GlobalDialog.defaultProps = {};

GlobalDialog.propTypes = {
  dialogData: PropTypes.shape({
    isVisible: PropTypes.bool,
    header: PropTypes.node,
    body: PropTypes.node,
    options: PropTypes.shape({
      isLoading: PropTypes.bool,
      size: PropTypes.oneOf([...Object.keys(SIZE_TYPES)]),
      dataTest: PropTypes.string,
      actionsPosition: PropTypes.oneOf([...Object.keys(POSITION_TYPES)]),
    }),
    onClose: PropTypes.func,
    actions: PropTypes.node,
  }).isRequired,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ global }) => ({
  dialogData: global.dialog,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideDialog,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);
