import { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

const useQueryParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => {
    const urlParams = new URLSearchParams(search.substring(search.indexOf('?')));
    return Object.fromEntries(urlParams);
  }, [search]);

  const setSearchParams = useCallback(
    (params) => {
      const parsedParams = qs.stringify(params);
      history.push({ search: parsedParams });
    },
    [history]
  );

  const setQueryParams = useCallback(
    (params) => {
      const newParams = R.reject(R.equals(''))(params);
      setSearchParams(newParams);

      return newParams;
    },
    [setSearchParams]
  );

  const mergeQueryParams = useCallback(
    (params) => {
      const newParams = R.mergeRight(queryParams, params);

      return setQueryParams(newParams);
    },
    [setQueryParams, queryParams]
  );

  const setQueryParam = useCallback(
    (name, value) => setQueryParams(R.assoc(name, value, queryParams)),
    [setQueryParams, queryParams]
  );

  return {
    queryParams,
    setQueryParam,
    setQueryParams,
    mergeQueryParams,
  };
};

export default useQueryParams;
