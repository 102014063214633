import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/library/Checkbox';

const CheckboxGroup = ({ children, className }) => {
  return (
    <div className={`checkbox-group ${className}`}>
      {children && children.map ? (
        children.map(({ props }) => {
          return <Checkbox {...props} key={props.label} alignLeft className="checkbox-group__item" />;
        })
      ) : (
        <Checkbox {...children.props} alignLeft className="checkbox-group__item" />
      )}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  className: '',
  children: {},
};

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
};

export default CheckboxGroup;
