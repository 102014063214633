import dayjs from 'dayjs';

const initialState = {
  activeTab: '',
  account: {
    accountNumber: '',
    error: '',
    isLoading: false,
    data: {},
  },
  transaction: {
    isLoading: false,
    isValid: true,
    data: {
      effectiveEntryDate: dayjs().toDate(),
      settlementDate: dayjs().toDate(),
    },
    originator: {},
    receiver: {},
  },
  transactions: {
    isLoading: false,
    data: {},
    results: [],
    pageFilters: {
      pageNo: 1,
      pageSize: 25,
    },
  },
};

export default initialState;
