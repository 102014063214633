import React from 'react';
import Lottie from 'react-lottie';
import animationData from './data.json';

export default class Spinner extends React.Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Lottie
        options={defaultOptions}
        // height={800}
        width={400}
        isClickToPauseDisabled
      />
    );
  }
}
