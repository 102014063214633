export default {
  ASSIGN_WORK_ITEM_START: 'edd/ASSIGN_WORK_ITEM_START',
  ASSIGN_WORK_ITEM: 'edd/ASSIGN_WORK_ITEM',
  ASSIGN_WORK_ITEM_SUCCESS: 'edd/ASSIGN_WORK_ITEM_SUCCESS',
  ASSIGN_WORK_ITEM_FAIL: 'edd/ASSIGN_WORK_ITEM_FAIL',

  ASSIGN_WORK_ITEM_PACKAGE_START: 'edd/ASSIGN_WORK_ITEM_PACKAGE_START',
  ASSIGN_WORK_ITEM_PACKAGE: 'edd/ASSIGN_WORK_ITEM_PACKAGE',
  ASSIGN_WORK_ITEM_PACKAGE_SUCCESS: 'edd/ASSIGN_WORK_ITEM_PACKAGE_SUCCESS',
  ASSIGN_WORK_ITEM_PACKAGE_FAIL: 'edd/ASSIGN_WORK_ITEM_PACKAGE_FAIL',

  FINALIZE_WORK_ITEM_PACKAGE_START: 'edd/FINALIZE_WORK_ITEM_PACKAGE_START',
  FINALIZE_WORK_ITEM_PACKAGE: 'edd/FINALIZE_WORK_ITEM_PACKAGE',
  FINALIZE_WORK_ITEM_PACKAGE_SUCCESS: 'edd/ASSIGN_WORK_ITEM_SUCCESS',
  FINALIZE_WORK_ITEM_PACKAGE_FAIL: 'edd/FINALIZE_WORK_ITEM_PACKAGE_FAIL',

  GET_WORK_ITEM_START: 'edd/GET_WORK_ITEM_START',
  GET_WORK_ITEM: 'edd/GET_WORK_ITEM',
  GET_WORK_ITEM_SUCCESS: 'edd/GET_WORK_ITEM_SUCCESS',
  GET_WORK_ITEM_FAIL: 'edd/GET_WORK_ITEM_FAIL',

  GET_WORK_ITEM_PACKAGE_START: 'edd/GET_WORK_ITEM_PACKAGE_START',
  GET_WORK_ITEM_PACKAGE: 'edd/GET_WORK_ITEM_PACKAGE',
  GET_WORK_ITEM_PACKAGE_SUCCESS: 'edd/GET_WORK_ITEM_PACKAGE_SUCCESS',
  GET_WORK_ITEM_PACKAGE_FAIL: 'edd/GET_WORK_ITEM_PACKAGE_FAIL',

  GET_WORK_ITEM_PACKAGES_START: 'edd/GET_WORK_ITEM_PACKAGES_START',
  GET_WORK_ITEM_PACKAGES: 'edd/GET_WORK_ITEM_PACKAGES',
  GET_WORK_ITEM_PACKAGES_SUCCESS: 'edd/GET_WORK_ITEM_PACKAGES_SUCCESS',
  GET_WORK_ITEM_PACKAGES_FAIL: 'edd/GET_WORK_ITEM_PACKAGES_FAIL',

  UPDATE_WORK_ITEM_PACKAGE_STATUS_START: 'edd/UPDATE_WORK_ITEM_PACKAGE_STATUS_START',
  UPDATE_WORK_ITEM_PACKAGE_STATUS: 'edd/UPDATE_WORK_ITEM_PACKAGE_STATUS',
  UPDATE_WORK_ITEM_PACKAGE_STATUS_SUCCESS: 'edd/UPDATE_WORK_ITEM_PACKAGE_STATUS_SUCCESS',
  UPDATE_WORK_ITEM_PACKAGE_STATUS_FAIL: 'edd/UPDATE_WORK_ITEM_PACKAGE_STATUS_FAIL',

  UPDATE_ACTION_ITEM_START: 'edd/UPDATE_ACTION_ITEM_START',
  UPDATE_ACTION_ITEM: 'edd/UPDATE_ACTION_ITEM',
  UPDATE_ACTION_ITEM_SUCCESS: 'edd/UPDATE_ACTION_ITEM_SUCCESS',
  UPDATE_ACTION_ITEM_FAIL: 'edd/UPDATE_ACTION_ITEM_FAIL',

  UPLOAD_DOCUMENT: 'edd/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_START: 'edd/UPLOAD_DOCUMENT_START',
  UPLOAD_DOCUMENT_SUCCESS: 'edd/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAIL: 'edd/UPLOAD_DOCUMENT_FAIL',

  GET_DOCUMENT: 'edd/GET_DOCUMENT',
  GET_DOCUMENT_START: 'edd/GET_DOCUMENT_START',
  GET_DOCUMENT_SUCCESS: 'edd/GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FAIL: 'edd/GET_DOCUMENT_FAIL',

  CANCEL_ACTION_ITEMS: 'edd/CANCEL_ACTION_ITEMS',
  CANCEL_ACTION_ITEMS_SUCCESS: 'edd/CANCEL_ACTION_ITEMS_SUCCESS',
  CHANGE_ACTION_ITEM: 'edd/CHANGE_ACTION_ITEM',

  SET_SEARCH_WORK_ITEM: 'edd/SET_SEARCH_WORK_ITEM',
  REMOVE_CURRENT_CLIENT: 'edd/REMOVE_CURRENT_CLIENT',
  REMOVE_CURRENT_WORK_ITEMS: 'edd/REMOVE_CURRENT_WORK_ITEMS',
  SET_SELECTED_ROW: 'edd/SET_SELECTED_ROW',
  SET_SELECTED_PAGE: 'edd/SET_SELECTED_PAGE',
  SET_CURRENT_PAGE: 'edd/SET_CURRENT_PAGE',
  SET_SORT_ORDER: 'edd/SET_SORT_ORDER',
  TOGGLE_EDD_OVERLAY: 'edd/TOGGLE_EDD_OVERLAY',
  SELECT_WORK_ITEMS_TAB: 'edd/SELECT_WORK_ITEMS_TAB',
  CLEAR_DOCUMENT: 'edd/CLEAR_DOCUMENT',

  GET_EDD_REPORT: 'edd/GET_EDD_REPORT',
  GET_EDD_REPORT_START: 'edd/GET_EDD_REPORT_START',
  GET_EDD_REPORT_SUCCESS: 'edd/GET_EDD_REPORT_SUCCESS',
  GET_EDD_REPORT_FAIL: 'edd/GET_EDD_REPORT_FAIL',
};
