import types from '../types';

export const getWiredTransactionsStart = () => ({
  type: types.GET_WIRED_TRANSACTIONS_START,
});

export const getWiredTransactions = (clientId = 'default') => ({
  type: types.GET_WIRED_TRANSACTIONS,
  clientId,
});

export const getWiredTransactionsSuccess = (wiredTransactions) => ({
  type: types.GET_WIRED_TRANSACTIONS_SUCCESS,
  wiredTransactions,
});

export const getWiredTransactionsFail = (err) => ({
  type: types.GET_WIRED_TRANSACTIONS_FAIL,
  err,
});
