import React from 'react';
import { stringSanitize } from '@finclusive/utils';
import TextArea from '../TextArea';

class TabularData extends React.Component {
  add = () => {
    const { data, headers, onChange, name } = this.props;
    delete data.isEmpty;
    data.rows.push({
      cells: headers.map(() => ({
        rawValue: '',
        type: 0,
      })),
    });
    onChange({
      target: {
        value: data,
        name,
      },
    });
  };

  remove = (index) => {
    const { data, onChange, name } = this.props;
    data.rows.splice(index, 1);
    onChange({
      target: {
        value: data,
        name,
      },
    });
  };

  onChange = (row, cell, e) => {
    const { data, onChange, name } = this.props;
    data.rows[row].cells[cell] = {
      rawValue: stringSanitize(e.target.value),
      type: 0,
    };

    onChange({
      target: {
        value: data,
        name,
      },
    });
  };

  render() {
    const { data, headers } = this.props;
    return (
      <>
        <table>
          <thead>
            <tr>
              {data.rows?.length !== 0 && headers?.map((header, i) => <th key={i}>{header}</th>)}
              <th />
            </tr>
          </thead>
          <tbody>
            {data.rows?.map((row, i) => (
              <tr>
                {row.cells?.map((cell, j) => (
                  <td>
                    <TextArea defaultValue={cell.rawValue} onChange={(e) => this.onChange(i, j, e)} />
                  </td>
                ))}
                <td>
                  <button className="input__group-add-button cursor" onClick={() => this.remove(i)}>
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="input__group-add-button cursor" onClick={this.add}>
          Add
        </button>
      </>
    );
  }
}

export default TabularData;
