import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import compose, { withSignedIn } from 'hocs';
import { getGlobalFeatureFlags } from '../actions';

const FeatureToggleLoader = ({ actions }) => {
  useEffect(() => {
    actions.getGlobalFeatureFlags();
  }, [actions]);

  return null;
};

FeatureToggleLoader.propTypes = {
  actions: PropTypes.shape({
    getGlobalFeatureFlags: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getGlobalFeatureFlags,
    },
    dispatch
  ),
});

export default compose(connect(null, mapDispatchToProps), withSignedIn)(FeatureToggleLoader);
