import { call, put, takeLatest } from 'redux-saga/effects';
import { REACT_APP_EDD_DOCUMENT } from 'env-config';
import { get } from 'modules/utils';
import { getDocumentStart, getDocumentSuccess, getDocumentFail, clearDocument } from '../actions/get-document';
import types from '../types';

export const getDoc = (docId) => get({ route: `${REACT_APP_EDD_DOCUMENT}/${docId}`, responseType: 'blob' });

const revokeUrl = (url) => {
  setTimeout(() => {
    URL.revokeObjectURL(url);
    clearDocument();
  }, 15000);
};

export function* getDocument({ documentUniqueIdentifier, name, isDownload = false }) {
  try {
    yield put(getDocumentStart());

    const documentBlob = yield call(getDoc, documentUniqueIdentifier);
    const exportUrl = URL.createObjectURL(documentBlob);

    yield put(getDocumentSuccess({ exportUrl }));

    if (isDownload) {
      const downloadLink = document.createElement('a');
      downloadLink.href = exportUrl;
      downloadLink.download = name;
      downloadLink.click();
    }
    revokeUrl(exportUrl);
  } catch (error) {
    yield put(getDocumentFail(error));
  }
}

export default function* getDocumentSaga() {
  yield takeLatest(types.GET_DOCUMENT, getDocument);
}
