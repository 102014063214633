import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cls from 'classnames';

class TabsComplex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { selectedTab } = this.props;
    const firstTab = this.getVisibleTabs()[0];
    if (R.isNil(R.prop('index', selectedTab))) {
      this.selectTab(0, firstTab.title);
    }
    if (!R.isNil(R.prop('index', selectedTab))) {
      this.selectTab(selectedTab.index, selectedTab.title);
    }
  }

  getVisibleTabs = () => {
    const { tabs } = this.props;
    const visibleTabs = [];
    tabs.forEach((tab) => {
      if (!R.includes('hidden', R.keys(tab)) || !tab.hidden) {
        visibleTabs.push(tab);
      }
    });
    return visibleTabs;
  };

  selectTab = (index, title) => {
    const { setSelectedTab } = this.props;
    setSelectedTab && setSelectedTab(index, title);
    this.setState({ activeTab: index });
  };

  TabButton = ({ index, title }) => {
    const { activeTab } = this.state;
    return (
      <div
        className={cls('tabs__tab-button', {
          'tabs__tab-button--active': activeTab === index,
        })}
        onClick={() => this.selectTab(index, title)}
      >
        {title}
      </div>
    );
  };

  TabContent = ({ index, content, displayTabButtons }) => {
    const { activeTab } = this.state;
    return (
      <div
        className={cls('tabs__tab-content', {
          'tabs__tab-content--active': activeTab === index,
          'tabs__tab-content--without-buttons': !displayTabButtons,
        })}
      >
        {content}
      </div>
    );
  };

  render() {
    const { className } = this.props;
    const { TabButton, TabContent, getVisibleTabs } = this;
    const displayTabButtons = getVisibleTabs().length > 1;

    return (
      <div className={`tabs ${className}`}>
        {displayTabButtons && (
          <div className="tabs__tab-buttons">
            {getVisibleTabs().map((tab, i) => (
              <TabButton {...tab} key={`tab-button-${i}`} index={i} />
            ))}
          </div>
        )}
        {getVisibleTabs().map((tab, i) => (
          <TabContent
            {...tab}
            key={`tab-content-${i}`}
            index={i}
            displayTabButtons={displayTabButtons}
          />
        ))}
      </div>
    );
  }
}

TabsComplex.defaultProps = {
  className: '',
};

TabsComplex.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.object,
};

export default TabsComplex;
