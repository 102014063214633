import types from '../types';

export const updateActionItemStart = () => ({
  type: types.UPDATE_ACTION_ITEM_START,
});

export const updateActionItem = (actionItem, workItemIndex, actionItemIndex) => ({
  type: types.UPDATE_ACTION_ITEM,
  actionItem,
  workItemIndex,
  actionItemIndex,
});

export const updateActionItemSuccess = (data) => ({
  type: types.UPDATE_ACTION_ITEM_SUCCESS,
  data,
});

export const updateActionItemFail = (error) => ({
  type: types.UPDATE_ACTION_ITEM_FAIL,
  error,
});
