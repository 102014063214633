import styled, { css } from 'styled-components';

const StyledErrorPage = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 4.8rem 0;
      .error-page-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        .page-error-title {
          margin: 0;
          margin-bottom: 2.4rem;
          color: ${palette.accent.blueDark};
          font-size: 6rem;
          font-weight: 600;
          text-align: center;
        }
        .page-error-subtitle {
          margin: 0;
          font-size: 1.8rem;
          font-weight: 600;
          text-align: center;
        }
        .page-error-description {
          margin-top: 0;
          margin-bottom: 3.2rem;
        }
        .page-error-body {
          display: flex;

          button {
            margin-right: 1.8rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    `;
  }}
`;

StyledErrorPage.displayName = 'StyledErrorPage';
export default StyledErrorPage;
