import types from '../types';

export const getWorkItemStart = () => ({
  type: types.GET_WORK_ITEM_START,
});

export const getWorkItem = (workItemID, index) => ({
  type: types.GET_WORK_ITEM,
  workItemID,
  index,
});

export const getWorkItemSuccess = (data) => ({
  type: types.GET_WORK_ITEM_SUCCESS,
  data,
});

export const getWorkItemFail = (error) => ({
  type: types.GET_WORK_ITEM_FAIL,
  error,
});
