import { put, call, takeLatest } from 'redux-saga/effects';
import { get } from 'modules/utils';
import { REACT_APP_GET_STELLAR_TRANSACTIONS_URL } from 'env-config';
import types from '../types';
import { getTransactionsStart, getTransactionsSuccess, getTransactionsFail } from '../actions/get-transactions';

export const callToGetTransactions = () => get(`${REACT_APP_GET_STELLAR_TRANSACTIONS_URL}`);

export function* getTransactions() {
  try {
    yield put(getTransactionsStart());

    const transactions = yield call(callToGetTransactions);

    const pendingSettlementTransactionsSorted = transactions
      .filter((trans) => trans.status === 'PendingMultiDaySettlement' || trans.status === 'PendingManualOTC')
      .sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
    const notPendingSettlementTransactionsSorted = transactions
      .filter((trans) => trans.status !== 'PendingMultiDaySettlement' && trans.status !== 'PendingManualOTC')
      .sort((a, b) => {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      });

    const sortedList = pendingSettlementTransactionsSorted.concat(notPendingSettlementTransactionsSorted);

    yield put(getTransactionsSuccess(sortedList));
  } catch (error) {
    yield put(getTransactionsFail(error));
  }
}

export default function* getTransactionsSaga() {
  yield takeLatest(types.GET_TRANSACTIONS, getTransactions);
}
