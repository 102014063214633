export const SIZE_TYPES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const POSITION_TYPES = {
  left: 'left',
  center: 'center',
  right: 'right',
};
