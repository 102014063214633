import React, { PureComponent } from 'react';
import { isBlank } from '@finclusive/utils';
import userManager from 'modules/userManager/components/UserManager';

const withSignedIn = (ChildComponent) => {
  if (isBlank(ChildComponent)) {
    throw new Error('No component provided to HOC');
  }

  const SignInWrapper = (props) => {
    const isAuthenticated = userManager.isAuthenticated();
    return isAuthenticated ? <ChildComponent {...props} /> : null;
  };

  return SignInWrapper;
};

export default withSignedIn;
