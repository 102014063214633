import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DetailTabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        content: PropTypes.node.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  componentDidUpdate() {
    const { tabs } = this.props;
    const { activeTab } = this.state;

    if (!tabs[activeTab]) {
      this.resetActiveTab();
    }
  }

  resetActiveTab = () => this.setState({ activeTab: 0 });

  render() {
    const { className, tabs } = this.props;
    const { activeTab } = this.state;

    return (
      <div className={`detail-view__tab-container ${className}`}>
        <div className="detail-view__tab-buttons">
          {tabs.map(({ title }, i) => (
            <div
              key={`detail-tab-button-${i}`}
              className={`detail-view__tab-button ${
                activeTab === i ? 'detail-view__tab-button--active' : ''
              }`}
              onClick={() => this.setState({ activeTab: i })}
            >
              {title}
            </div>
          ))}
        </div>

        {tabs.map(({ content }, i) => (
          <div
            key={`detail-tab-${i}`}
            className={`detail-view__tab ${activeTab === i ? 'detail-view__tab--active' : ''}`}
          >
            {content}
          </div>
        ))}
      </div>
    );
  }
}
