// eslint-disable-next-line no-underscore-dangle
const ENV = window._env_ || process.env;

const BASE_API = ENV.REACT_APP_FE_GATEWAY;

const user = {
  getUserMe: `${BASE_API}/user/user/me`,
};

const billing = {
  skus: {
    finclusiveSkus: `${BASE_API}/billing/SKU/finclusive`,
    thirdPartySkus: (planType) => `${BASE_API}/billing/SKU/thirdparty/${planType}`,
  },
  defaultPlans: {
    finclusivePlans: `${BASE_API}/billing/plan/default/finclusive`,
    finclusivePlan: (planId = '') => `${BASE_API}/billing/plan/default/finclusive/${planId}`,
    thirdParties: `${BASE_API}/billing/plan/default/thirdparties`,
    thirdParty: (planType) => `${BASE_API}/billing/plan/default/thirdparty/${planType}`,
  },
  customerPlans: {
    customers: `${BASE_API}/customer/internal/billing/customers`,
    customer: (finclusiveId) => `${BASE_API}/billing/plan/customer/finclusive/${finclusiveId}`,
    thirdParties: (finclusiveId) => `${BASE_API}/billing/plan/customer/thirdparties/${finclusiveId}`,
    thirdParty: (thirdPartyId, finclusiveId) =>
      `${BASE_API}/billing/plan/customer/thirdparty/${thirdPartyId}/customer/${finclusiveId}`,
  },
};

const cddMonitoring = {
  customersList: (params) => `${BASE_API}/customer/internal/CDDConfiguration/customers?${params}`,
  acuantProfiles: (customerFinClusiveId) =>
    `${BASE_API}/compliance/CDDConfiguration/customers/${customerFinClusiveId}/acuant/profiles`,
  customerConfiguration: (customerFinClusiveId) =>
    `${BASE_API}/compliance/CDDConfiguration/customers/${customerFinClusiveId}`,
};

const customerNotifications = {
  getAll: `${BASE_API}/notification/Configuration/all`,
  saveNotifications: `${BASE_API}/notification/Configuration/save`,
  getOptions: `${BASE_API}/notification/Configuration/options`,
  getGroups: `${BASE_API}/notification/template/allknownnotificationtypes`,
};

const transactionMonitoring = {
  transaction: `${BASE_API}/amlv2/AMLTransaction`,
  transactionAlerts: `${BASE_API}/amlv2/Alert`,
  historicalTransactions: `${BASE_API}/amlv2/Alert/transactionHistory`,
  globalRules: `${BASE_API}/amlv2/Rule/global`,
  overridenRules: `${BASE_API}/amlv2/Rule/override`,
  overridenRulesPreview: `${BASE_API}/amlv2/Rule/override/preview`,
  overridenRulesConfigs: `${BASE_API}/amlv2/Rule/override/configs`,
  triggerConfig: `${BASE_API}/amlv2//TriggerConfig`,
  notification: {
    internalConfig: `${BASE_API}/amlv2/AMLNotificationConfig/internal`,
    customerConfig: (customerFinClusiveId) =>
      `${BASE_API}/amlv2/AMLNotificationConfig/customer/${customerFinClusiveId}`,
  },
};

const createTransaction = {
  getAccount: (accountId) => `${BASE_API}/BankingCore/internal/payops/account/${accountId}`,
  transaction: `${BASE_API}/BankingCore/internal/payops/transaction`,
};

const internalUserManagement = {
  getInternalUsers: (params) => `${BASE_API}/user/user/internal?${params}`,
  updateInternalUser: () => `${BASE_API}/user/user/internal`,
  getInternalUserDetails: (objectId) => `${BASE_API}/user/user/internal/${objectId}`,
  getInternalPermissions: () => `${BASE_API}/user/permission/internal`,
};

const registration = {
  pendingRegistrations: `${BASE_API}/registration/registration/pending`,
  report: `${BASE_API}/registration/report`,
  reportExport: `${BASE_API}/registration/report/export`,
  update: `${BASE_API}/registration/registration/update`,
  approveCurrent: `${BASE_API}/registration/registration/approve`,
  approveSecondary: `${BASE_API}/registration/registration/approveInSecondaryEnvironment`,
  delete: `${BASE_API}/registration/registration/delete`,
};

const API = {
  user,
  featureFlagsGlobal: `${BASE_API}/featureflags/global`,
  featureFlagsEntity: `${BASE_API}/featureflags/entity`,
  billing,
  cddMonitoring,
  customerNotifications,
  createTransaction,
  transactionMonitoring,
  internalUserManagement,
  registration,
};

export default API;
