const initialState = {
  pendingRegistrations: {
    currentEnvironmentName: '',
    secondaryEnvironmentName: '',
    list: {
      isLoading: false,
      data: [],
    },
    selectedRegistration: {
      isOverlayVisible: false,
      isUpdating: false,
      isApproving: false,
      initialData: {},
      data: {},
      validationErrors: [],
    },
  },
  registrationReport: {
    data: {
      prospects: [],
    },
    isLoading: false,
    isExporting: false,
    hasExportError: false,
  },
};

export default initialState;
