import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const toggleEditDefaultConfigurationOverlayVisible = createPrefixedAction(
  actionTypes.EDIT_DEFAULT_CONFIGURATION_OVERLAY_VISIBLE
);
export const toggleEditCustomerConfigurationOverlayVisible = createPrefixedAction(
  actionTypes.EDIT_CUSTOMER_CONFIGURATION_OVERLAY_VISIBLE
);
export const setGlobalLimitsSelectedBank = createPrefixedAction(
  actionTypes.SET_GLOBAL_LIMITS_SELECTED_BANK
);
export const setGlobalLimitsData = createPrefixedAction(actionTypes.SET_GLOBAL_LIMITS_DATA);
export const setCustomerLimitsSelectedBank = createPrefixedAction(
  actionTypes.SET_CUSTOMER_LIMITS_SELECTED_BANK
);
export const setCustomerLimitsData = createPrefixedAction(actionTypes.SET_CUSTOMER_LIMITS_DATA);
export const setCustomersFilter = createPrefixedAction(actionTypes.SET_CUSTOMERS_FILTER);
export const setCustomersFilterInput = createPrefixedAction(actionTypes.SET_CUSTOMERS_FILTER_INPUT);

export const getCustomers = createPrefixedAction(actionTypes.GET_CUSTOMERS);
export const getCustomersStart = createPrefixedAction(actionTypes.GET_CUSTOMERS_START);
export const getCustomersSuccess = createPrefixedAction(actionTypes.GET_CUSTOMERS_SUCCESS);
export const getCustomersFail = createPrefixedAction(actionTypes.GET_CUSTOMERS_FAIL);

export const getCustomerConfig = createPrefixedAction(actionTypes.GET_CUSTOMER_CONFIG);
export const getCustomerConfigStart = createPrefixedAction(actionTypes.GET_CUSTOMER_CONFIG_START);
export const getCustomerConfigSuccess = createPrefixedAction(
  actionTypes.GET_CUSTOMER_CONFIG_SUCCESS
);
export const getCustomerConfigFail = createPrefixedAction(actionTypes.GET_CUSTOMER_CONFIG_FAIL);

export const setCustomerConfig = createPrefixedAction(actionTypes.SET_CUSTOMER_CONFIG);
export const setCustomerConfigStart = createPrefixedAction(actionTypes.SET_CUSTOMER_CONFIG_START);
export const setCustomerConfigSuccess = createPrefixedAction(
  actionTypes.SET_CUSTOMER_CONFIG_SUCCESS
);
export const setCustomerConfigFail = createPrefixedAction(actionTypes.SET_CUSTOMER_CONFIG_FAIL);

export const getGlobalConfig = createPrefixedAction(actionTypes.GET_GLOBAL_CONFIG);
export const getGlobalConfigStart = createPrefixedAction(actionTypes.GET_GLOBAL_CONFIG_START);
export const getGlobalConfigSuccess = createPrefixedAction(actionTypes.GET_GLOBAL_CONFIG_SUCCESS);
export const getGlobalConfigFail = createPrefixedAction(actionTypes.GET_GLOBAL_CONFIG_FAIL);

export const setGlobalConfig = createPrefixedAction(actionTypes.SET_GLOBAL_CONFIG);
export const setGlobalConfigStart = createPrefixedAction(actionTypes.SET_GLOBAL_CONFIG_START);
export const setGlobalConfigSuccess = createPrefixedAction(actionTypes.SET_GLOBAL_CONFIG_SUCCESS);
export const setGlobalConfigFail = createPrefixedAction(actionTypes.SET_GLOBAL_CONFIG_FAIL);
