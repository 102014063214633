import types from '../types';

export const finalizeWorkItemPackageStart = () => ({
  type: types.FINALIZE_WORK_ITEM_PACKAGE_START,
});

export const finalizeWorkItemPackage = (workItemPackageID, isAccepted, reason, notes) => ({
  type: types.FINALIZE_WORK_ITEM_PACKAGE,
  workItemPackageID,
  isAccepted,
  reason,
  notes,
});

export const finalizeWorkItemPackageSuccess = (data) => ({
  type: types.FINALIZE_WORK_ITEM_PACKAGE_SUCCESS,
  data,
});

export const finalizeWorkItemPackageFail = (error) => ({
  type: types.FINALIZE_WORK_ITEM_PACKAGE_FAIL,
  error,
});
