import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { ALERT_SEVERITIES } from './constants';

export const getSeverityTag = (value) => {
  if (isBlank(value)) {
    return '';
  }
  const [type, label] = R.paths(
    [
      [value, 'type'],
      [value, 'label'],
    ],
    ALERT_SEVERITIES
  );
  return { type, label };
};
