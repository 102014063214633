import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { putRequest } from 'modules/utils';
import { toggleDetailView } from 'modules/browser/actions';
import { ToastNotification } from 'components/library';
import { REACT_APP_OTC_TRADE_URL } from 'env-config';
import { updateOtcDataStart, updateOtcDataSuccess, updateOtcDataFail } from '../actions/update-otc-data';
import { getOtcData } from '../actions/get-otc-data';
import types from '../types';
import { getTransactions } from '../actions/get-transactions';
import { setSelectedRow } from '../actions';

export const callToUpdateOtcData = ({ otcData }) =>
  putRequest({ route: REACT_APP_OTC_TRADE_URL, data: { ...otcData } });

export function* updateOtcData() {
  try {
    yield put(updateOtcDataStart());

    const otcData = yield select((state) => R.path(['transactions', 'selectedRow', 'otcData'], state));
    const { transactionID } = otcData;
    yield call(callToUpdateOtcData, { otcData });

    yield put(getOtcData(transactionID));

    yield put(getTransactions());

    yield put(setSelectedRow(null, {}, null));

    yield put(toggleDetailView(false));

    ToastNotification('success', 'OTC data has been successfully updated');

    yield put(updateOtcDataSuccess());
  } catch (error) {
    yield put(updateOtcDataFail(error));
    if (R.path(['response', 'data', 'errors'], error) && !isBlank(error.response.data.errors)) {
      error.response.data.errors.forEach((item) => ToastNotification('error', item));
      return;
    }

    if (R.path(['response', 'data', 'message'], error)) {
      ToastNotification('error', error.response.data.message);
      return;
    }

    yield put(getTransactions());

    yield put(toggleDetailView(false));

    yield put(setSelectedRow(null, {}, null));

    ToastNotification('error', 'Something went wrong when we were trying to process the action');
  }
}

export default function* updateOtcDataSaga() {
  yield takeLatest(types.UPDATE_OTC_DATA, updateOtcData);
}
