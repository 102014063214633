import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  getNotificationConfigurationStore,
  saveNotificationConfigurationStore,
  setConfigurationFrequency,
} from '../actions';
import initialState from './initialState';

const setConfigurationFrequencyHandler = [
  setConfigurationFrequency,
  (state, action) => {
    const {
      payload: { index, value },
    } = action;
    const updatedConfiguration = [...state.configuration];
    updatedConfiguration[index] = {
      ...updatedConfiguration[index],
      frequency: value,
    };
    return {
      ...state,
      configuration: updatedConfiguration,
    };
  },
];

const getNotificationConfigurationStoreHandler = [
  getNotificationConfigurationStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      isLoading: false,
      initialConfiguration: payload,
      configuration: payload,
    };
  },
];

const saveNotificationConfigurationStoreHandler = [
  saveNotificationConfigurationStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        isSaving: true,
      };
    }

    if (error) {
      return {
        ...state,
        isSaving: false,
      };
    }

    return {
      ...state,
      isSaving: false,
    };
  },
];

const reducer = handleActions(
  new Map([
    getNotificationConfigurationStoreHandler,
    setConfigurationFrequencyHandler,
    saveNotificationConfigurationStoreHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
