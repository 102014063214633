import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const getPendingRegistrations = createPrefixedAction(
  actionTypes.GET_PENDING_REGISTRATIONS_SAGA
);
export const getPendingRegistrationsStore = createPrefixedAction(
  actionTypes.GET_PENDING_REGISTRATIONS_STORE
);

export const setSelectedPendingRegistration = createPrefixedAction(
  actionTypes.SET_SELECTED_PENDING_REGISTRATION
);
export const clearSelectedPendingRegistration = createPrefixedAction(
  actionTypes.CLEAR_SELECTED_PENDING_REGISTRATION
);

export const setSelectedPendingRegistrationField = createPrefixedAction(
  actionTypes.SET_SELECTED_PENDING_REGISTRATION_FIELD
);

export const setPendingRegistrationValidationErrors = createPrefixedAction(
  actionTypes.SET_PENDING_REGISTRATION_VALIDATION_ERRORS
);

export const getRegistrationReport = createPrefixedAction(actionTypes.GET_REGISTRATION_REPORT_SAGA);

export const getRegistrationReportStore = createPrefixedAction(
  actionTypes.GET_REGISTRATION_REPORT_STORE
);

export const exportRegistrationReport = createPrefixedAction(
  actionTypes.EXPORT_REGISTRATION_REPORT_SAGA
);

export const exportRegistrationReportStore = createPrefixedAction(
  actionTypes.EXPORT_REGISTRATION_REPORT_STORE
);

export const updatePendingRegistration = createPrefixedAction(
  actionTypes.UPDATE_PENDING_REGISTRATION_SAGA
);

export const updatePendingRegistrationStore = createPrefixedAction(
  actionTypes.UPDATE_PENDING_REGISTRATION_STORE
);

export const approvePendingRegistrationCurrent = createPrefixedAction(
  actionTypes.APPROVE_PENDING_REGISTRATION_CURRENT_SAGA
);

export const approvePendingRegistrationCurrentStore = createPrefixedAction(
  actionTypes.APPROVE_PENDING_REGISTRATION_CURRENT_STORE
);

export const approvePendingRegistrationSecondary = createPrefixedAction(
  actionTypes.APPROVE_PENDING_REGISTRATION_SECONDARY_SAGA
);

export const approvePendingRegistrationSecondaryStore = createPrefixedAction(
  actionTypes.APPROVE_PENDING_REGISTRATION_SECONDARY_STORE
);

export const archiveRegistration = createPrefixedAction(actionTypes.ARCHIVE_REGISTRATION_SAGA);

export const archiveRegistrationStore = createPrefixedAction(
  actionTypes.ARCHIVE_REGISTRATION_STORE
);
