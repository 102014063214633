import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/library';

export default class ViewModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    bodyClassName: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, bodyClassName, toggle, handleConfirm, title } = this.props;
    return (
      <Modal
        title="Delete File?"
        isOpen={isOpen}
        toggle={toggle}
        primaryButton={{ label: 'Delete', onClick: handleConfirm }}
        secondaryButton={{ label: 'Cancel', onClick: toggle }}
      >
        <div className={bodyClassName}>Do you want to delete {title}?</div>
      </Modal>
    );
  }
}
