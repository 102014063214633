import sagas from '../components/AMLRules/actions/sagas';
import transactionsSagas from '../components/Transactions/actions/sagas';
import internalNotificationConfiguratorSagas from '../components/InternalNotificationConfigurator/actions/sagas';
import customerNotificationConfiguratorSagas from '../components/CustomerNotificationConfigurator/actions/sagas';

const transactionMonitoringSagas = [
  ...sagas,
  ...transactionsSagas,
  ...internalNotificationConfiguratorSagas,
  ...customerNotificationConfiguratorSagas,
];

export default transactionMonitoringSagas;
