import { createPrefixedAction } from '../config';
import actionTypes from './types';

const {
  GET_GLOBAL_FEATURE_FLAGS,
  GET_GLOBAL_FEATURE_FLAGS_START,
  GET_GLOBAL_FEATURE_FLAGS_SUCCESS,
  GET_GLOBAL_FEATURE_FLAGS_FAIL,
  GET_ENTITY_FEATURE_FLAGS,
  GET_ENTITY_FEATURE_FLAGS_START,
  GET_ENTITY_FEATURE_FLAGS_SUCCESS,
  GET_ENTITY_FEATURE_FLAGS_FAIL,
} = actionTypes;

export const getGlobalFeatureFlags = createPrefixedAction(GET_GLOBAL_FEATURE_FLAGS);
export const getGlobalFeatureFlagsStart = createPrefixedAction(GET_GLOBAL_FEATURE_FLAGS_START);
export const getGlobalFeatureFlagsSuccess = createPrefixedAction(GET_GLOBAL_FEATURE_FLAGS_SUCCESS);
export const getGlobalFeatureFlagsFail = createPrefixedAction(GET_GLOBAL_FEATURE_FLAGS_FAIL);

export const getEntityFeatureFlags = createPrefixedAction(GET_ENTITY_FEATURE_FLAGS);
export const getEntityFeatureFlagsStart = createPrefixedAction(GET_ENTITY_FEATURE_FLAGS_START);
export const getEntityFeatureFlagsSuccess = createPrefixedAction(GET_ENTITY_FEATURE_FLAGS_SUCCESS);
export const getEntityFeatureFlagsFail = createPrefixedAction(GET_ENTITY_FEATURE_FLAGS_FAIL);
