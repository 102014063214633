import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { ToastNotification } from 'components/library';
import { handleValidationErrors } from './handle-validation-errors';

const buildErrorMessage = (error, alternativeMessage) => {
  const { data } = error.response;

  if (R.path(['error'], data)) {
    return R.path(['error'], data);
  }

  if (!isBlank(R.path(['errors'], data))) {
    return R.path(['errors'], data);
  }

  if (!isBlank(R.path(['Errors'], data))) {
    return R.path(['Errors'], data);
  }

  if (!isBlank(R.path(['reasons'], data))) {
    return R.path(['reasons'], data);
  }

  if (!isBlank(R.path(['message'], data))) {
    return R.path(['message'], data);
  }

  if (!isBlank(data) && Array.isArray(data)) {
    return data;
  }

  if (error.message) {
    return error.message;
  }

  return alternativeMessage;
};

export const showErrorMessage = (
  error,
  alternativeMessage = 'There was an error trying to receive the order results'
) => {
  if (handleValidationErrors(error)) return;

  const message = buildErrorMessage(error, alternativeMessage);
  ToastNotification('error', message);
};
