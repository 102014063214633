import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cls from 'classnames';
import { Input as ReactInput } from 'reactstrap';
import CleaveInput from 'cleave.js/react';
import { emailCheck, integerCheck, amountCheck } from 'utilities';

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: this.checkIfValid(props.value),
      dirty: false,
      autoFormatOptions: this.formatValue(),
      rendered: true,
    };
  }

  componentDidMount() {
    const { resetToDefault, defaultValue, name, value } = this.props;
    if (resetToDefault && value === undefined) {
      this.onChange({ target: { value: defaultValue, name } });
    }
  }

  onChange = (e) => {
    const { onChange, requiresValidation } = this.props;
    const valid = this.checkIfValid(e.target.value);
    requiresValidation && this.setState({ dirty: true, valid });
    onChange && onChange(e);
  };

  onBlur = ({ target }) => {
    const { resetToDefault, defaultValue, value } = this.props;
    if (resetToDefault && value === '') {
      this.onChange({ target: { ...target, value: defaultValue } });
      return;
    }
    if (value) {
      this.onChange({
        target: { ...target, name: target.name, value: value.trim() },
      });
    }
  };

  formatValue = () => {
    const { autoFormat, name } = this.props;

    if (autoFormat) {
      if (name === 'amount') return { numeral: true, numeralThousandsGroupStyle: 'thousand', prefix: '$ ' };
      if (name === 'crypto') return { numeral: true, numeralThousandsGroupStyle: 'thousand', numeralDecimalScale: 7 };
      if (name === 'BankRoutingNumber') return { numericOnly: true };
    }

    return {};
  };

  checkIfValid = (value) => {
    const { type, name, required, requiresValidation, min, max } = this.props;
    if (!requiresValidation) return null;
    if (value && value.trim()) {
      if (type === 'email' || name === 'emailAddress') return emailCheck(value);
      if (name === 'amount') return amountCheck(value);
      if (name === 'integerNumber') return integerCheck(value, min, max);

      return !!value;
    }
    if (required) return false;
    return null;
  };

  render() {
    const { className, icon, label, disabled, autoFormat, value, maxLength } = this.props;
    const { valid, dirty, rendered, autoFormatOptions } = this.state;
    const InputType = autoFormat ? CleaveInput : ReactInput;

    return (
      rendered && (
        <>
          <InputType
            {...R.omit(['requiresValidation', 'autoFormat', 'resetToDefault', 'tooltipIdentifier'], this.props)}
            onBlur={this.onBlur}
            value={value || ''}
            onChange={this.onChange}
            autoComplete="invalid"
            options={autoFormatOptions}
            className={cls('input__field', 'form-control', className, {
              'input__field--with-addon': icon,
              'input__field--invalid': valid === false && dirty,
              'input__field--full-width': !label,
              'input__field--disabled': disabled,
            })}
          />
          {maxLength ? (
            <span className="input__max-length">{`${(value && value.length) || 0} / ${maxLength} Characters`}</span>
          ) : (
            ''
          )}
        </>
      )
    );
  }
}

Text.defaultProps = {
  autoFormat: false,
};

Text.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  requiresValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  autoFormat: PropTypes.bool,
  disabled: PropTypes.bool,
  resetToDefault: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Text;
