import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { toggleDetailView } from 'modules/browser/actions';

import DetailViewComponent from './component';
import DetailSection from './DetailSection';
import DetailItem from './DetailItem';
import DetailHighlight from './DetailHighlight';
import DetailHighlightItem from './DetailHighlightItem';
import DetailTabs from './DetailTabs';

const mapStateToProps = ({ browser }) => ({
  isOpen: browser.detailViewIsOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      toggleDetailView,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DetailViewComponent);
export { DetailSection, DetailItem, DetailHighlight, DetailHighlightItem, DetailTabs };
