import userManager from 'modules/userManager/components/UserManager';

const getBottomMenu = () => ({
  signOut: {
    label: 'Sign Out',
    icon: 'exit-to-app',
    rightSideIcon: '',
    isVisible: true,
    onClick: userManager.logout,
  },
});

export default getBottomMenu;
