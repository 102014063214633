import types from '../types';

export const getWorkItemPackageStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItemPackage: true,
  },
});

export const getWorkItemPackageSuccess = (state, { data }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItemPackage: false,
  },
  currentClient: data,
  currentWorkItems: data.workItems,
});

export const getWorkItemPackageFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingWorkItemPackage: false,
  },
  error,
});

export default {
  [types.GET_WORK_ITEM_PACKAGE_START]: getWorkItemPackageStart,
  [types.GET_WORK_ITEM_PACKAGE_SUCCESS]: getWorkItemPackageSuccess,
  [types.GET_WORK_ITEM_PACKAGE_FAIL]: getWorkItemPackageFail,
};
