export default () => {
  const viewWidth = window.innerWidth || window.outerWidth;

  if (viewWidth < 1024 && viewWidth > 768) return 'ltablet';
  if (viewWidth <= 768 && viewWidth > 640) return 'tablet';
  if (viewWidth <= 640 && viewWidth > 480) return 'stablet';
  if (viewWidth <= 480) return 'phablet';

  return 'desktop';
};
