import { call, put, takeLatest } from 'redux-saga/effects';
import { patch, showErrorMessage } from 'modules/utils';
import { REACT_APP_ROLLBACK_US_WIRE, REACT_APP_ROLLBACK_INTERNATIONAL_WIRE } from 'env-config';
import ToastNotification from 'components/library/ToastNotification';
import { INTERNATIONAL_WIRE, DOMESTIC_WIRE } from 'components/views/WiredTransactions/constants';
import { rollbackWireStart, rollbackWireSuccess, rollbackWireFail } from '../actions/rollback-wire';
import userTypes from '../types';
import { getWiredTransactions } from './get-wired-transactions';

const API_MAPPING = {
  [DOMESTIC_WIRE]: REACT_APP_ROLLBACK_US_WIRE,
  [INTERNATIONAL_WIRE]: REACT_APP_ROLLBACK_INTERNATIONAL_WIRE,
};

export const httpCall = (payload, transactionMethod) =>
  patch({
    route: `${API_MAPPING[transactionMethod]}?clientId=default`,
    data: payload,
  });

export function* rollbackWire({ payload, transactionMethod }) {
  try {
    yield put(rollbackWireStart());

    const result = yield call(httpCall, payload, transactionMethod);

    yield put(rollbackWireSuccess(result));
    ToastNotification('success', 'Transaction rolled back');
    yield* getWiredTransactions('default');
  } catch (error) {
    showErrorMessage(error);
    yield put(rollbackWireFail(error));
  }
}

export default function* rollbackWireSaga() {
  yield takeLatest(userTypes.ROLLBACK_WIRE, rollbackWire);
}
