const actionTypes = {
  GET_GLOBAL_FEATURE_FLAGS: 'GET_GLOBAL_FEATURE_FLAGS',
  GET_GLOBAL_FEATURE_FLAGS_START: 'GET_GLOBAL_FEATURE_FLAGS_START',
  GET_GLOBAL_FEATURE_FLAGS_SUCCESS: 'GET_GLOBAL_FEATURE_FLAGS_SUCCESS',
  GET_GLOBAL_FEATURE_FLAGS_FAIL: 'GET_GLOBAL_FEATURE_FLAGS_FAIL',
  GET_ENTITY_FEATURE_FLAGS: 'GET_ENTITY_FEATURE_FLAGS',
  GET_ENTITY_FEATURE_FLAGS_START: 'GET_ENTITY_FEATURE_FLAGS_START',
  GET_ENTITY_FEATURE_FLAGS_SUCCESS: 'GET_ENTITY_FEATURE_FLAGS_SUCCESS',
  GET_ENTITY_FEATURE_FLAGS_FAIL: 'GET_ENTITY_FEATURE_FLAGS_FAIL',
};

export default actionTypes;
