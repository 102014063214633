import types from '../types';

export const assignWorkItemPackageStart = () => ({
  type: types.ASSIGN_WORK_ITEM_PACKAGE_START,
});

export const assignWorkItemPackage = (workItemPackageID, dataAnalystID, paginationInfo) => ({
  type: types.ASSIGN_WORK_ITEM_PACKAGE,
  workItemPackageID,
  dataAnalystID,
  paginationInfo,
});

export const assignWorkItemPackageSuccess = (data) => ({
  type: types.ASSIGN_WORK_ITEM_PACKAGE_SUCCESS,
  data,
});

export const assignWorkItemPackageFail = (error) => ({
  type: types.ASSIGN_WORK_ITEM_PACKAGE_FAIL,
  error,
});
