import types from '../types';

export const settleWireStart = () => ({
  type: types.SETTLE_WIRE_START,
});

export const settleWire = (payload, transactionMethod) => ({
  type: types.SETTLE_WIRE,
  payload,
  transactionMethod,
});

export const settleWireSuccess = (data) => ({
  type: types.SETTLE_WIRE_SUCCESS,
  data,
});

export const settleWireFail = (err) => ({
  type: types.SETTLE_WIRE_FAIL,
  err,
});
