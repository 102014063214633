import styled, { css } from 'styled-components';

const StyledTooltipComponent = styled.div`
  ${() => css`
    display: flex;
    .tooltip-icon {
      cursor: help;
    }
  `}
`;

StyledTooltipComponent.displayName = 'StyledTooltipComponent';
export default StyledTooltipComponent;
