import { call, put, takeEvery } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_GET_WORK_ITEM as ENDPOINT } from 'env-config';
import { get } from 'modules/utils';
import { getWorkItemFail, getWorkItemStart, getWorkItemSuccess } from '../actions/get-work-item';
import types from '../types';

export const httpCall = (data, workItemID) => get({ route: `${ENDPOINT}/${workItemID}`, data });

export function* getWorkItem({ workItemID }) {
  try {
    yield put(getWorkItemStart());

    const payload = {};
    const result = yield call(httpCall, payload, workItemID);

    yield put(getWorkItemSuccess(result));
  } catch (error) {
    yield put(getWorkItemFail(error));
    ToastNotification('error', error.message || 'There was an error trying to get work item');
  }
}

export default function* getWorkItemSaga() {
  yield takeEvery(types.GET_WORK_ITEM, getWorkItem);
}
