import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button, H2, H3 } from '@finclusive/library';
import { isBlank } from '@finclusive/utils';
import Icon from '../Icon';
import Tools from './Tools';
import StyledViewContainer from './ViewContainer.style';

const ViewContainer = ({
  children,
  className,
  isLoading,
  readOnly,
  parent,
  heading,
  headingSubSection,
  contentClassName,
  customTools,
  footer,
  dataTest,
  onBackButtonClick,
}) => {
  const primaryButton = R.prop('primaryButton', footer);
  const secondaryButton = R.prop('secondaryButton', footer);
  const isFooterVisible =
    !isBlank(R.prop('label')(primaryButton)) || !isBlank(R.prop('label')(secondaryButton));

  const showHeader =
    !isBlank(parent) || !isBlank(heading) || !isBlank(headingSubSection) || !isBlank(customTools);

  return (
    <StyledViewContainer className={className} data-test={dataTest}>
      {showHeader && (
        <div className="view-container-heading">
          <div className="heading-group">
            {!isBlank(parent) && (
              <H3 className="heading-crumb" dataTest={`${dataTest}-parent`}>
                {parent}
              </H3>
            )}
            <H2 className="heading-title" dataTest={`${dataTest}-title`}>
              {onBackButtonClick && (
                <div className="heading-title-back-button" onClick={onBackButtonClick}>
                  <Icon name="arrow-left" />
                </div>
              )}
              {heading}
            </H2>
            {headingSubSection && (
              <div className="heading-subtitle" data-test={`${dataTest}-sub-section`}>
                {headingSubSection}
              </div>
            )}
          </div>

          {!readOnly && !isBlank(customTools) && (
            <Tools isLoading={isLoading} customTools={customTools} dataTest={`${dataTest}-tools`} />
          )}
        </div>
      )}

      <div className={`view-container-content ${contentClassName}`}>{children}</div>

      {isFooterVisible && (
        <div className="view-container-footer">
          {!isBlank(secondaryButton) && (
            <Button
              color={secondaryButton.color || 'secondary'}
              variant={secondaryButton.variant || 'contained'}
              disabled={secondaryButton.disabled}
              dataTest={`${dataTest}-secondaryButton`}
              action={secondaryButton.action}
            >
              {secondaryButton.label}
            </Button>
          )}
          {!isBlank(primaryButton) && (
            <Button
              color={primaryButton.color || 'primary'}
              variant={primaryButton.variant || 'contained'}
              disabled={primaryButton.disabled}
              dataTest={`${dataTest}-primaryButton`}
              action={primaryButton.action}
              {...primaryButton.otherProps}
            >
              {primaryButton.label}
            </Button>
          )}
        </div>
      )}
    </StyledViewContainer>
  );
};

ViewContainer.defaultProps = {
  children: null,
  dataTest: '',
  className: '',
  readOnly: false,
  isLoading: false,
  contentClassName: '',
  heading: null,
  headingSubSection: null,
  parent: null,
  customTools: [],
  footer: {},
};

ViewContainer.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  contentClassName: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  headingSubSection: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  parent: PropTypes.node,
  customTools: PropTypes.arrayOf(PropTypes.shape({})),
  footer: PropTypes.shape({
    primaryAction: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func,
      otherProps: PropTypes.shape({
        submit: PropTypes.bool,
        submitForm: PropTypes.string,
      }),
    }),
    secondaryAction: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func,
    }),
  }),
  onBackButtonClick: PropTypes.func,
};

export default ViewContainer;
