import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StepProgressBar extends Component {
  static propTypes = {
    className: PropTypes.string,
    steps: PropTypes.array.isRequired,
    activeStep: PropTypes.number,
  };

  static defaultProps = {
    activeStep: 0,
    className: '',
  };

  render() {
    const { steps, activeStep, className } = this.props;

    return (
      steps && (
        <div className={`step-progress-bar ${className}`}>
          <div className="step-progress-bar__container">
            <ul className="step-progress-bar__progressbar">
              {steps &&
                steps.map((step, index) => {
                  let stepClassName = '';
                  if (index < activeStep) {
                    stepClassName = 'completed';
                  }

                  if (activeStep === index) {
                    stepClassName = 'active';
                  }
                  return (
                    <li key={index} className={stepClassName}>
                      {index > 0 && <div className={`step-progress-bar__arrow ${stepClassName}`} />}
                      {step.title}
                      <div className="step-progress-bar__subtitle">{step.subtitle}</div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )
    );
  }
}
