import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import SidebarSection from '../common/sidebarSection';
import SidebarItem from '../common/sidebarItem';
import getBottomMenu from './helpers';

const SidebarFooter = ({ isCollapsed, location, actions }) => {
  const bottomMenu = getBottomMenu();

  return (
    <SidebarSection bottomFloat isCollapsed={isCollapsed} dataTest="sidebar-bottom-section">
      {R.map((key) => {
        const { label, icon, rightSideIcon, isVisible, onClick } = R.prop(key, bottomMenu);
        if (!isVisible) return null;

        return (
          <SidebarItem
            key={key}
            isCollapsed={isCollapsed}
            label={label}
            icon={icon}
            rightSideIcon={rightSideIcon}
            location={location}
            dataTest={`sidebar-${key}`}
            push={actions.push}
            onClick={onClick}
          />
        );
      }, R.keys(bottomMenu))}
    </SidebarSection>
  );
};

SidebarFooter.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ browser, router }) => {
  return {
    isCollapsed: browser.sidebarIsCollapsed,
    location: router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFooter);
