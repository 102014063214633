import types from '../types';

export const updateWireStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: true,
  },
});

export const updateWireSuccess = (state, { data }) => ({
  ...state,
  data,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
});

export const updateWireFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
  error,
});

export default {
  [types.UPDATE_WIRE_START]: updateWireStart,
  [types.UPDATE_WIRE_FAIL]: updateWireFail,
  [types.UPDATE_WIRE_SUCCESS]: updateWireSuccess,
};
