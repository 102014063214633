import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig, loginRequest, tokenRequest } from './constants';

class UserManager {
  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);

    const accounts = this.msalInstance.getAllAccounts();
    if (R.length(accounts) > 0) {
      this.msalInstance.setActiveAccount(R.head(accounts));
    }

    this.msalInstance.addEventCallback(
      (event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const { account } = event.payload;
          this.msalInstance.setActiveAccount(account);
        }
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    );
  }

  handleRedirectPromise = async () => {
    await this.msalInstance.handleRedirectPromise();
  };

  getAccount = () => {
    return this.msalInstance.getActiveAccount();
  };

  isAuthenticated = () => {
    const account = this.msalInstance.getActiveAccount();
    return !isBlank(account);
  };

  getToken = () => {
    const accessTokenRequest = { ...tokenRequest, account: this.getAccount() };
    const promise = this.msalInstance.acquireTokenSilent(accessTokenRequest).catch((error) => {
      // fallback to interaction when silent call fails
      if (error instanceof InteractionRequiredAuthError) {
        this.msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    });
    return promise;
  };

  login = () => {
    const account = this.msalInstance.getActiveAccount();
    if (!account) {
      this.msalInstance.loginRedirect(loginRequest);
    }
  };

  logout = () => {
    this.clearData();
    this.msalInstance.logoutRedirect().catch((event) => {
      // eslint-disable-next-line no-console
      console.error(event);
    });
  };

  clearData = () => {
    sessionStorage.clear();
  };
}

const userManager = new UserManager();

export default userManager;
