import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { post } from 'modules/utils';
import { ToastNotification } from 'components/library';
import { REACT_APP_GENERATE_REPORT_URL } from 'env-config';
import types from '../types';
import { generateReportStart, generateReportSuccess, generateReportFail } from '../actions/generate-report';

export const callToGenerateReport = ({ interval }) =>
  post({
    route: REACT_APP_GENERATE_REPORT_URL,
    data: {
      startDate: interval[0].toString().slice(0, 24),
      endDate: interval[1].toString().slice(0, 24),
    },
  });

export function* generateReport() {
  try {
    yield put(generateReportStart());

    const interval = yield select((state) => R.path(['transactions', 'reportDateInterval'], state));

    yield call(callToGenerateReport, { interval });

    ToastNotification('success', 'Report has been successfully generated');

    yield put(generateReportSuccess());
  } catch (error) {
    yield put(generateReportFail(error));
    if (R.path(['response', 'data', 'errors'], error) && !isBlank(error.response.data.errors)) {
      error.response.data.errors.forEach((item) => ToastNotification('error', item));
      return;
    }

    if (R.path(['response', 'data', 'message'], error)) {
      ToastNotification('error', error.response.data.message);
      return;
    }

    ToastNotification('error', 'Something went wrong when we were trying to process the action');
  }
}

export default function* generateReportSaga() {
  yield takeLatest(types.GENERATE_REPORT, generateReport);
}
