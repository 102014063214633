import types from '../types';

export const getWorkItemPackagesStart = () => ({
  type: types.GET_WORK_ITEM_PACKAGES_START,
});

export const getWorkItemPackages = ({
  pageNo,
  pageSize,
  sortFilters,
  query,
  currentTab,
  statuses,
  assignedTo,
}) => ({
  type: types.GET_WORK_ITEM_PACKAGES,
  pageNo,
  pageSize,
  sortFilters,
  query,
  currentTab,
  statuses,
  assignedTo,
});

export const getWorkItemPackagesSuccess = (data) => ({
  type: types.GET_WORK_ITEM_PACKAGES_SUCCESS,
  data,
});

export const getWorkItemPackagesFail = (error) => ({
  type: types.GET_WORK_ITEM_PACKAGES_FAIL,
  error,
});
