import { put, takeLatest, call, select } from 'redux-saga/effects';
import qs from 'qs';
import * as R from 'ramda';
import { isBlank, filterEmptyKeys } from '@finclusive/utils';
import API from 'config/api';
import { get, patch } from 'modules/utils';
import { ToastNotification } from 'components/library';
import {
  getTransactions,
  getTransactionAlerts,
  getTransactionDetails,
  setAlertCollapsed,
  toggleDetailView,
  getTransactionsStore,
  getTransactionDetailsStore,
  getTransactionAlertsStore,
  clearAlertStore,
  claimTransactionStore,
  clearTransactionStore,
  getHistoricalTransactionsStore,
  clearSelectedTransaction,
} from '.';
import { addPrefix } from '../config';
import actionTypes from './types';
import { FILTERS, SORT_KEYS, TABS_CONFIG, TAB_KEYS } from '../components/constants';

function* getTransactionsSagaCallback({ payload: filters }) {
  try {
    yield put(getTransactionsStore({ loading: true }));

    const transactions = yield select((state) => R.path(['transactionMonitoring', 'transactions'], state));
    const pageFilters = R.path(['list', 'pageFilters'], transactions);
    const activeTabKey = R.prop(FILTERS.main, filters);

    const params = qs.stringify(
      filterEmptyKeys({
        ...pageFilters,
        transactionFilter: R.path([activeTabKey, 'value'], TABS_CONFIG),
        showOnlyClaimedByUser: activeTabKey === TAB_KEYS.pending ? R.prop(FILTERS.claimedBy, filters) : false,
        customerNameFilter: R.prop(FILTERS.customerName, filters),
        senderNameFilter: R.prop(FILTERS.senderName, filters),
        senderBankNameFilter: R.prop(FILTERS.senderBankName, filters),
        receiverNameFilter: R.prop(FILTERS.receiverName, filters),
        receiverBankNameFilter: R.prop(FILTERS.receiverBankName, filters),
        orderByField: R.prop(SORT_KEYS.sortBy, filters),
        orderByDirection: R.prop(SORT_KEYS.sortType, filters),
      })
    );
    const result = yield call(get, `${API.transactionMonitoring.transaction}?${params}`);

    yield put(getTransactionsStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getTransactionsStore({ error }));
  }
}

function* getTMTransactionsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_TRANSACTIONS), getTransactionsSagaCallback);
}

function* getTransactionDetailsSagaCallback({ payload: transactionId }) {
  try {
    yield put(getTransactionDetailsStore({ loading: true }));

    const result = yield call(get, `${API.transactionMonitoring.transaction}/${transactionId}`);

    yield put(getTransactionDetailsStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getTransactionDetailsStore({ error }));
    yield put(toggleDetailView(false));
    ToastNotification('error', error.message || 'There was an error trying to get the transaction details.');
  }
}

function* getTransactionDetailsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_TRANSACTION_DETAILS), getTransactionDetailsSagaCallback);
}

function* getTransactionAlertsSagaCallback({ payload: transactionId }) {
  try {
    yield put(getTransactionAlertsStore({ loading: true }));

    const result = yield call(get, `${API.transactionMonitoring.transactionAlerts}/${transactionId}`);

    yield put(getTransactionAlertsStore({ loading: false, payload: result }));
  } catch (error) {
    yield put(getTransactionAlertsStore({ error }));
    yield put(toggleDetailView(false));
    ToastNotification('error', error.message || 'There was an error trying to get the transaction alerts.');
  }
}

function* getTransactionAlertsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_TRANSACTION_ALERTS), getTransactionAlertsSagaCallback);
}

function* clearAlertSagaCallback({ payload: { notes, status, alertId } }) {
  try {
    yield put(clearAlertStore({ loading: true }));

    yield call(patch, {
      route: `${API.transactionMonitoring.transactionAlerts}/${alertId}`,
      data: { notes, status },
    });
    const transactionId = yield select((state) =>
      R.path(['transactionMonitoring', 'transactions', 'detailView', 'transactionDetail', 'data', 'id'], state)
    );

    yield put(getTransactionAlerts(transactionId));
    yield put(clearAlertStore({ loading: false }));
    yield put(setAlertCollapsed(alertId));
    ToastNotification('success', 'Alert cleared successfully.');
  } catch (error) {
    yield put(clearAlertStore({ error }));
    const errors = R.path(['response', 'data', 'errors'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => ToastNotification('error', item));
      return;
    }
    ToastNotification('error', error.message || 'There was an error trying to clear the alert.');
  }
}

function* clearAlertSaga() {
  yield takeLatest(addPrefix(actionTypes.CLEAR_ALERT), clearAlertSagaCallback);
}

function* claimTransactionSagaCallback() {
  try {
    yield put(claimTransactionStore({ loading: true }));

    const transactionId = yield select((state) =>
      R.path(['transactionMonitoring', 'transactions', 'detailView', 'transactionDetail', 'data', 'id'], state)
    );

    yield call(patch, { route: `${API.transactionMonitoring.transaction}/claim/${transactionId}` });

    yield put(getTransactionDetails(transactionId));
    yield put(claimTransactionStore({ loading: false }));
    ToastNotification('success', 'Transaction claimed successfully.');
  } catch (error) {
    yield put(claimTransactionStore({ error }));
    const errors = R.path(['response', 'data', 'errors'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => ToastNotification('error', item));
      return;
    }
    ToastNotification('error', error.message || 'There was an error trying to claim the transaction.');
  }
}

function* claimTransactionSaga() {
  yield takeLatest(addPrefix(actionTypes.CLAIM_TRANSACTION), claimTransactionSagaCallback);
}

function* clearTransactionSagaCallback({ payload }) {
  try {
    yield put(clearTransactionStore({ loading: true }));

    const transactionId = yield select((state) =>
      R.path(['transactionMonitoring', 'transactions', 'detailView', 'transactionDetail', 'data', 'id'], state)
    );
    const transactionNote = yield select((state) =>
      R.path(['transactionMonitoring', 'transactions', 'detailView', 'transactionDetail', 'note'], state)
    );
    const { internalStatus, customerStatus, filters } = payload;
    yield call(patch, {
      route: `${API.transactionMonitoring.transaction}/${transactionId}`,
      data: {
        internalAMLStatus: internalStatus,
        internalNotes: transactionNote,
        customerAMLStatus: customerStatus,
      },
    });

    yield put(getTransactions(filters));
    yield put(toggleDetailView(false));
    yield put(clearSelectedTransaction());
    yield put(clearTransactionStore({ loading: false }));
    ToastNotification('success', 'Transaction cleared successfully.');
  } catch (error) {
    yield put(clearTransactionStore({ error }));
    const errors = R.path(['response', 'data', 'errors'], error);
    if (!isBlank(errors)) {
      errors.forEach((item) => ToastNotification('error', item));
      return;
    }
    ToastNotification('error', error.message || 'There was an error trying to clear the transaction.');
  }
}

function* clearTransactionSaga() {
  yield takeLatest(addPrefix(actionTypes.CLEAR_TRANSACTION), clearTransactionSagaCallback);
}

function* getHistoricalTransactionsSagaCallback({ payload: { alertId, pageNo, pageSize } }) {
  try {
    yield put(getHistoricalTransactionsStore({ loading: true, payload: alertId }));

    const params = qs.stringify(
      filterEmptyKeys({
        pageNo,
        alertId,
        pageSize,
      })
    );
    const result = yield call(get, `${API.transactionMonitoring.historicalTransactions}?${params}`);

    yield put(getHistoricalTransactionsStore({ loading: false, payload: { alertId, data: result } }));
  } catch (error) {
    yield put(getHistoricalTransactionsStore({ error }));
  }
}

function* getHistoricalTransactionsSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_HISTORICAL_TRANSACTIONS), getHistoricalTransactionsSagaCallback);
}

const sagas = [
  call(getTMTransactionsSaga),
  call(getTransactionDetailsSaga),
  call(getTransactionAlertsSaga),
  call(clearAlertSaga),
  call(claimTransactionSaga),
  call(clearTransactionSaga),
  call(getHistoricalTransactionsSaga),
];

export default sagas;
