import { call, put, takeLatest } from 'redux-saga/effects';
import ToastNotification from 'components/library/ToastNotification';
import { REACT_APP_UPDATE_WORK_ITEM_PACKAGE_STATUS as ENDPOINT } from 'env-config';
import { post } from 'modules/utils';
import {
  updateWorkItemPackageStatusFail,
  updateWorkItemPackageStatusStart,
  updateWorkItemPackageStatusSuccess,
} from '../actions/update-work-item-package-status';
import types from '../types';
import { getWorkItemPackage } from './get-work-item-package';
import { getWorkItemPackages } from './get-work-item-packages';

export const httpCall = (data) => post({ route: ENDPOINT, data });

export function* updateWorkItemPackageStatus({ workItemPackageID, desiredStatus, paginationInfo }) {
  try {
    yield put(updateWorkItemPackageStatusStart());

    const payload = {
      workItemPackageID,
      desiredStatus,
    };
    const result = yield call(httpCall, payload);
    yield put(updateWorkItemPackageStatusSuccess(result));

    yield getWorkItemPackage({ workItemPackageID });
    yield getWorkItemPackages(paginationInfo);
    ToastNotification('success', 'You have successfully submitted the report');
  } catch (error) {
    yield put(updateWorkItemPackageStatusFail(error));
    ToastNotification('error', error.message || 'There was an error trying to update work item package status');
  }
}

export default function* updateWorkItemPackageStatusSaga() {
  yield takeLatest(types.UPDATE_WORK_ITEM_PACKAGE_STATUS, updateWorkItemPackageStatus);
}
