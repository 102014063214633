import styled, { css } from 'styled-components';

const StyledRuleForm = styled.div`
  ${({ theme }) => {
    const { palette } = theme;
    return css`
      .rule-form__tabs .tabs__tab-buttons {
        justify-content: center;
        margin-left: 3rem;
      }
      .form-control {
        margin-bottom: 2.4rem;
      }
      .tooltip__icon {
        background-color: transparent;
      }
      .input__field--select {
        opacity: 1;
      }
      .rule-form__section-header {
        font-size: 1.4rem;
        letter-spacing: 0.02rem;
        color: ${palette.gray.grayDevil};
        border-bottom: 0.1rem solid ${palette.gray.xxGrayLightest};
        padding: 2.4rem 0 1.6rem;
        margin-bottom: 2.4rem;
        position: relative;
        margin-right: -3.5rem;

        > .tooltip {
          right: 0;
          top: 2.1rem;
        }
      }
    `;
  }}
`;

StyledRuleForm.displayName = 'StyledRuleForm';
export default StyledRuleForm;
