import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get, post } from 'modules/utils';
import API from 'config/api';
import { addPrefix } from '../config';
import { ENTITY_TYPES } from './constants';
import actionTypes from './types';
import {
  getGlobalFeatureFlagsStart,
  getGlobalFeatureFlagsSuccess,
  getGlobalFeatureFlagsFail,
  getEntityFeatureFlags,
  getEntityFeatureFlagsStart,
  getEntityFeatureFlagsSuccess,
  getEntityFeatureFlagsFail,
} from '.';

const { GET_GLOBAL_FEATURE_FLAGS, GET_ENTITY_FEATURE_FLAGS } = actionTypes;

export function* getGlobalFeatureFlagsSagaCallback() {
  try {
    const { linkedFinClusiveId } = yield select(({ userManager }) => userManager);

    yield put(getGlobalFeatureFlagsStart());
    const globalFeatureFlags = yield call(get, API.featureFlagsGlobal);
    yield put(getEntityFeatureFlags([{ entityId: linkedFinClusiveId, entityType: ENTITY_TYPES.customer }]));
    yield put(getGlobalFeatureFlagsSuccess(globalFeatureFlags));
  } catch (error) {
    yield put(getGlobalFeatureFlagsFail(error));
  }
}

function* getGlobalFeatureFlagsSaga() {
  yield takeLatest(addPrefix(GET_GLOBAL_FEATURE_FLAGS), getGlobalFeatureFlagsSagaCallback);
}

export function* getEntityFeatureFlagsSagaCallback({ payload }) {
  try {
    yield put(getEntityFeatureFlagsStart());
    const entityFeatureFlags = yield call(post, {
      route: API.featureFlagsEntity,
      data: { data: payload },
    });
    yield put(getEntityFeatureFlagsSuccess(entityFeatureFlags));
  } catch (error) {
    yield put(getEntityFeatureFlagsFail(error));
  }
}

function* getEntityFeatureFlagsSaga() {
  yield takeLatest(addPrefix(GET_ENTITY_FEATURE_FLAGS), getEntityFeatureFlagsSagaCallback);
}

const sagas = [call(getGlobalFeatureFlagsSaga), call(getEntityFeatureFlagsSaga)];

export default sagas;
