import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from '@finclusive/library';

const HomeButton = ({ history }) => {
  return (
    <Button color="primary" action={() => history.push('/')}>
      Home
    </Button>
  );
};

HomeButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(memo(HomeButton));
