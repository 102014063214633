export const getTransactionMethods = (val) => {
  const statuses = {
    ACHCredit: 'ACH Credit',
    ACHDebit: 'ACH Debit',
    InternalTransfer: 'Internal Transfer',
    NotSupported: 'Not Supported',
    StellarSend: 'Stellar Send',
    StellarReceive: 'Stellar Receive',
    DomesticWire: 'Domestic Wire',
    InternationalWire: 'International Wire',
    CeloCashin: 'Celo Cash In',
    CeloCashout: 'Celo Cash Out',
  };

  return statuses[val] || 'Unknown';
};
