import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as R from 'ramda';
import { deleteRequest, get, post, putRequest } from 'modules/utils';
import { ToastNotification } from 'components/library';
import API from 'config/api';
import { addPrefix } from '../config';
import actionTypes from './types';
import {
  getDefaultPlans,
  getDefaultPlansStore,
  getDefaultPlanStore,
  createDefaultPlanStore,
  putDefaultPlanStore,
  deleteDefaultPlanStore,
  getPlanSkusStore,
  togglePlanOverlay,
} from '.';

function* getDefaultPlansSagaCallback() {
  try {
    yield put(getDefaultPlansStore({ loading: true }));
    const response = yield call(get, API.billing.defaultPlans.finclusivePlans);
    yield put(getDefaultPlansStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(getDefaultPlansStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to get the default plans.');
  }
}

function* getDefaultPlansSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_DEFAULT_PLANS_SAGA), getDefaultPlansSagaCallback);
}

function* getDefaultPlanSagaCallback({ payload: planId }) {
  try {
    yield put(getDefaultPlanStore({ loading: true }));
    const response = yield call(get, API.billing.defaultPlans.finclusivePlan(planId));
    yield put(getDefaultPlanStore({ loading: false, payload: { ...response, planId } }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(togglePlanOverlay(false));
    yield put(getDefaultPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to get the default plan.');
  }
}

function* getDefaultPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_DEFAULT_PLAN_SAGA), getDefaultPlanSagaCallback);
}

function* createDefaultPlanSagaCallback() {
  try {
    yield put(createDefaultPlanStore({ loading: true }));
    const data = yield select((state) => R.path(['billing', 'defaultPlans', 'planOverlay', 'data'], state));
    yield call(post, { route: API.billing.defaultPlans.finclusivePlans, data });

    yield put(createDefaultPlanStore({ loading: false }));
    ToastNotification('success', 'Default plan was created successfully');
    yield put(togglePlanOverlay(false));
    yield put(getDefaultPlans());
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(createDefaultPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to save the default plan.');
  }
}

function* createDefaultPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.CREATE_DEFAULT_PLAN_SAGA), createDefaultPlanSagaCallback);
}

function* putDefaultPlanSagaCallback() {
  try {
    yield put(putDefaultPlanStore({ loading: true }));
    const data = yield select((state) => R.path(['billing', 'defaultPlans', 'planOverlay', 'data'], state));
    yield call(putRequest, {
      route: API.billing.defaultPlans.finclusivePlan(R.prop('planId', data)),
      data,
    });

    yield put(putDefaultPlanStore({ loading: false }));
    ToastNotification('success', 'Default plan was updated successfully');
    yield put(togglePlanOverlay(false));
    yield put(getDefaultPlans());
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(putDefaultPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to update the default plan.');
  }
}

function* putDefaultPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.PUT_DEFAULT_PLAN_SAGA), putDefaultPlanSagaCallback);
}

function* deleteDefaultPlanSagaCallback({ payload: planId }) {
  try {
    yield put(deleteDefaultPlanStore({ loading: true }));
    yield call(deleteRequest, { route: API.billing.defaultPlans.finclusivePlan(planId) });
    yield put(deleteDefaultPlanStore({ loading: false }));
    ToastNotification('success', 'Default plan was deleted successfully');
    yield put(togglePlanOverlay(false));
    yield put(getDefaultPlans());
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(deleteDefaultPlanStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to delete the default plan.');
  }
}

function* deleteDefaultPlanSaga() {
  yield takeLatest(addPrefix(actionTypes.DELETE_DEFAULT_PLAN_SAGA), deleteDefaultPlanSagaCallback);
}

function* getPlanSkusSagaCallback() {
  try {
    yield put(getPlanSkusStore({ loading: true }));
    const response = yield call(get, API.billing.skus.finclusiveSkus);
    yield put(getPlanSkusStore({ loading: false, payload: response }));
  } catch (errors) {
    const error = R.path(['response', 'data', 'errors'], errors);
    yield put(togglePlanOverlay(false));
    yield put(getPlanSkusStore({ loading: false, error }));
    ToastNotification('error', error || 'There was an error trying to get the plan skus.');
  }
}

function* getPlanSkusSaga() {
  yield takeLatest(addPrefix(actionTypes.GET_PLAN_SKUSS_SAGA), getPlanSkusSagaCallback);
}

export const defaultPlansSagas = [
  call(getDefaultPlansSaga),
  call(getPlanSkusSaga),
  call(getDefaultPlanSaga),
  call(createDefaultPlanSaga),
  call(putDefaultPlanSaga),
  call(deleteDefaultPlanSaga),
];
