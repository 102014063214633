import types from '../types';

export const settleWireStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: true,
  },
});

export const settleWireSuccess = (state, { data }) => ({
  ...state,
  data,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
});

export const settleWireFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingWiresStatus: false,
  },
  error,
});

export default {
  [types.SETTLE_WIRE_START]: settleWireStart,
  [types.SETTLE_WIRE_FAIL]: settleWireFail,
  [types.SETTLE_WIRE_SUCCESS]: settleWireSuccess,
};
