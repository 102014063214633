import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ROUTE_PATHS from 'routes/routePaths';
import userManager from '../../UserManager';

const Login = ({ history }) => {
  const login = useCallback(async () => {
    await userManager.handleRedirectPromise();
    const isAuthenticated = userManager.isAuthenticated();
    if (isAuthenticated) {
      history.push(ROUTE_PATHS.LOGIN_REDIRECT);
      return;
    }
    userManager.login();
  }, [history]);

  useEffect(() => {
    login();
  }, [login]);

  return null;
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Login;
