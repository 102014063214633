import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@finclusive/library';
import StyledTooltipComponent from './Tooltip.style';

const TooltipComponent = ({ id, text, className, position }) => (
  <StyledTooltipComponent className={className}>
    <Icon name="helpOutline" className="tooltip-icon" data-for={`tooltip-${id}`} data-tip />
    <Tooltip id={`tooltip-${id}`} type="dark" place={position} isFloating>
      {text}
    </Tooltip>
  </StyledTooltipComponent>
);

TooltipComponent.defaultProps = {
  className: '',
  position: 'left',
};

TooltipComponent.prototypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
};

export default TooltipComponent;
