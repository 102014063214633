import { call, put, takeLatest } from 'redux-saga/effects';
import { patch, showErrorMessage } from 'modules/utils';
import { REACT_APP_SETTLE_US_WIRE, REACT_APP_SETTLE_INTERNATIONAL_WIRE } from 'env-config';
import ToastNotification from 'components/library/ToastNotification';
import { INTERNATIONAL_WIRE, DOMESTIC_WIRE } from 'components/views/WiredTransactions/constants';
import { settleWireStart, settleWireSuccess, settleWireFail } from '../actions/settle-wire';
import userTypes from '../types';
import { getWiredTransactions } from './get-wired-transactions';

const API_MAPPING = {
  [DOMESTIC_WIRE]: REACT_APP_SETTLE_US_WIRE,
  [INTERNATIONAL_WIRE]: REACT_APP_SETTLE_INTERNATIONAL_WIRE,
};

export const httpCall = (payload, transactionMethod) =>
  patch({
    route: `${API_MAPPING[transactionMethod]}?clientId=default`,
    data: payload,
  });

export function* settleWire({ payload, transactionMethod }) {
  try {
    yield put(settleWireStart());

    const result = yield call(httpCall, payload, transactionMethod);

    yield put(settleWireSuccess(result));
    ToastNotification('success', 'Transaction completed');
    yield* getWiredTransactions('default');
  } catch (error) {
    showErrorMessage(error);
    yield put(settleWireFail(error));
  }
}

export default function* settleWireSaga() {
  yield takeLatest(userTypes.SETTLE_WIRE, settleWire);
}
