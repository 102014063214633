const initialState = {
  isLoading: false,
  data: {},
  list: [],
  pageFilters: {
    pageNo: 1,
    pageSize: 10,
  },
  searchFilters: {
    searchValue: '',
    filterOption: null,
  },
  planOverlay: {
    isVisible: false,
    isLoading: false,
    isDirty: false,
    formData: {
      selectedPlan: null,
      selectedStartDate: null,
    },
    customer: {
      assignedPlan: null,
      customerFinClusiveID: '',
      customerName: '',
      finClusiveSKUs: [],
      hasFinClusivePlan: false,
      planStartDate: null,
    },
    vendors: {
      isLoading: false,
      list: [],
      details: {
        isOverlayVisible: false,
        isLoading: false,
        isCustom: false,
        data: {},
      },
    },
  },
  error: null,
};

export default initialState;
