export const COLUMNS_KEYS = {
  name: 'name',
  parties: 'parties',
  severity: 'severity',
  edit: 'edit',
};

export const COLUMNS_CONFIG = [
  {
    columnKey: COLUMNS_KEYS.name,
    label: 'AML Rules',
  },
  {
    columnKey: COLUMNS_KEYS.parties,
    label: 'Parties',
  },
  {
    columnKey: COLUMNS_KEYS.severity,
    label: 'Severity',
  },
  {
    columnKey: COLUMNS_KEYS.edit,
    label: '',
  },
];
