import types from './types';
import getTransactionsReducer from './reducer/get-transactions';
import settleTransactionReducer from './reducer/settle-transaction';
import rollbackTransactionReducer from './reducer/rollback-transaction';
import updateOtcDataSaga from './reducer/update-otc-data';
import getOtcDataSaga from './reducer/get-otc-data';
import generateReportSaga from './reducer/generate-report';
import getWiredTransactionsReducer from './reducer/get-wired-transactions';
import settleUsWireReducer from './reducer/settle-wire';
import rollbackUsWireReducer from './reducer/rollback-wire';
import updateWireReducer from './reducer/update-wire';

const initialState = {
  loaders: {
    isLoading: true,
    isInteractingWithTransaction: false,
    isLoadingOtcData: false,
    isSubmittingReportRequest: false,
    IsLoadingWiredTransactions: false,
    isSubmittingWiresStatus: false,
  },
  modals: {
    showWiresStatusModal: false,
  },
  transactions: null,
  wiredTransactions: [],
  transactionFilter: {
    filterBy: '',
    input: null,
  },
  transactionSiderbarItemIsOpen: false,
  selectedRow: {
    index: null,
    rowDetails: {},
    otcData: {},
  },
  reportDateInterval: [new Date(Date.now()), new Date(Date.now())],
  wiresStatusModalInfo: {},
};

const toggleTransactionSidebar = (state, { isOpen }) => ({
  ...state,
  transactionSiderbarItemIsOpen: isOpen,
});

const setSelectedRow = (state, { index, rowDetails, otcData }) => ({
  ...state,
  selectedRow: {
    index,
    rowDetails,
    otcData,
  },
});

const setOtcData = (state, { key, value }) => ({
  ...state,
  selectedRow: {
    ...state.selectedRow,
    otcData: {
      ...state.selectedRow.otcData,
      [key]: value,
    },
  },
});

const setWiresStatusInfo = (state, { key, value }) => ({
  ...state,
  wiresStatusModalInfo: {
    ...state.wiresStatusModalInfo,
    [key]: value,
  },
});

const setReportDateInterval = (state, { dateInterval }) => ({
  ...state,
  reportDateInterval: dateInterval,
});

const setTransactionFilter = (state, { filterBy }) => ({
  ...state,
  transactionFilter: {
    ...state.transactionFilter,
    filterBy,
  },
});

const setTransactionFilterInput = (state, { input }) => ({
  ...state,
  transactionFilter: {
    ...state.transactionFilter,
    input,
  },
});

const toggleWiresStatusModal = (state, { isOpen }) => ({
  ...state,
  modals: {
    ...state.modals,
    showWiresStatusModal: isOpen,
  },
});

const resetWiresStatusInfo = (state) => ({
  ...state,
  wiresStatusModalInfo: initialState.wiresStatusModalInfo,
});

const reducer = {
  ...getTransactionsReducer,
  ...settleTransactionReducer,
  ...rollbackTransactionReducer,
  ...updateOtcDataSaga,
  ...getOtcDataSaga,
  ...generateReportSaga,
  [types.SET_REPORT_DATE_INTERVAL]: setReportDateInterval,
  [types.TOGGLE_TRANSACTION_SIDEBAR]: toggleTransactionSidebar,
  [types.SET_SELECTED_ROW]: setSelectedRow,
  [types.SET_TRANSACTION_FILTER]: setTransactionFilter,
  [types.SET_TRANSACTION_FILTER_INPUT]: setTransactionFilterInput,
  [types.SET_OTC_DATA]: setOtcData,
  [types.TOGGLE_WIRES_STATUS_MODAL]: toggleWiresStatusModal,
  [types.RESET_WIRES_STATUS_INFO]: resetWiresStatusInfo,
  [types.SET_WIRES_STATUS_INFO]: setWiresStatusInfo,
  ...getWiredTransactionsReducer,
  ...settleUsWireReducer,
  ...rollbackUsWireReducer,
  ...updateWireReducer,
};

export default (state = initialState, action = {}) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
