import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const getAccount = createPrefixedAction(actionTypes.GET_ACCOUNT_SAGA);
export const getAccountStore = createPrefixedAction(actionTypes.GET_ACCOUNT_STORE);
export const setAccountNumber = createPrefixedAction(actionTypes.SET_ACCOUNT_NUMBER);
export const clearAccount = createPrefixedAction(actionTypes.CLEAR_ACCOUNT);
export const setTransactionField = createPrefixedAction(actionTypes.SET_TRANSACTION_FIELD);
export const setOriginatorField = createPrefixedAction(actionTypes.SET_ORIGINATOR_FIELD);
export const setReceiverField = createPrefixedAction(actionTypes.SET_RECEIVER_FIELD);
export const createTransaction = createPrefixedAction(actionTypes.CREATE_TRANSACTION_SAGA);
export const createTransactionStore = createPrefixedAction(actionTypes.CREATE_TRANSACTION_STORE);
export const setTransactionDetailErrors = createPrefixedAction(actionTypes.SET_TRANSACTION_DETAIL_ERRORS);
export const setActiveTab = createPrefixedAction(actionTypes.SET_ACTIVE_TAB);
export const getTransactions = createPrefixedAction(actionTypes.GET_TRANSACTIONS_SAGA);
export const getTransactionsStore = createPrefixedAction(actionTypes.GET_TRANSACTIONS_STORE);
export const setTransactionPageFilters = createPrefixedAction(actionTypes.SET_TRANSACTION_PAGE_FILTERS);
