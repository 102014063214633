import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../index';

export default class Button extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    submit: PropTypes.bool,
    form: PropTypes.bool,
    submitForm: PropTypes.string,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    type: 'primary',
    disabled: false,
    className: '',
    buttonClassName: '',
    submit: false,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    const { submit, onClick } = this.props;

    // eslint-disable-next-line
    !submit && !onClick && console.error('You need either submit:true or an onClick function.');
    // eslint-disable-next-line
    submit && onClick && console.error('Do not use both submit and onClick at the same time.');
  }

  render() {
    const { children, onClick, type, disabled, className, buttonClassName, submit, submitForm, isLoading } = this.props;

    return (
      <div className={`button-wrapper ${className}`}>
        <button
          type={submit ? 'submit' : 'button'}
          form={submitForm}
          className={`
            button ${type ? `button--${type}` : ''} 
            ${buttonClassName} 
            ${disabled ? `button--${type}-disabled` : ''}
          `}
          onClick={!disabled && !submit ? (e) => onClick && onClick(e) : undefined}
        >
          <Spinner visible={isLoading} tiny className="button--spinner" />
          {children}
        </button>
      </div>
    );
  }
}
