import { createAction } from 'redux-actions';

const MODULE_NAME = 'TRANSACTION_MONITORING/INTERNAL_NOTIFICATION_CONFIGURATOR';

export default {
  MODULE_NAME,
};

export const addPrefix = (type) => `${MODULE_NAME}/${type}`;

export const createPrefixedAction = (type) => createAction(addPrefix(type));
