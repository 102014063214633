import { call } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import browserReducer from '../modules/browser';
import globalReducer from './globalReducer';
import eddReducer, {
  assignWorkItemSaga,
  assignWorkItemPackageSaga,
  getWorkItemSaga,
  getWorkItemPackageSaga,
  getWorkItemPackagesSaga,
  updateActionItemSaga,
  finalizeWorkItemPackageSaga,
  updateWorkItemPackageStatusSaga,
  uploadDocumentSaga,
  getDocumentSaga,
  getEDDReportSaga,
} from '../modules/edd';
import feedbackReducer from '../modules/feedback';
import transactionsReducer, {
  getTransactionsSaga,
  settleTransactionSaga,
  rollbackTransactionSaga,
  updateOtcDataSaga,
  getOtcDataSaga,
  generateReportSaga,
  getWiredTransactionsSaga,
  settleWireSaga,
  rollbackWireSaga,
  updateWireSaga,
} from '../modules/transactions';
import {
  getCustomersSaga,
  getCustomerConfigSaga,
  setCustomerConfigSaga,
  getGlobalConfigSaga,
  setGlobalConfigSaga,
} from '../modules/limitsManagement/actions/sagas';
import limitsManagementReducer from '../modules/limitsManagement/reducer';

import billingSagas from '../modules/billing/actions/sagas';
import billingReducer from '../modules/billing/reducer';
import cddMonitoringSagas from '../modules/cddMonitoring/actions/sagas';
import cddMonitoringReducer from '../modules/cddMonitoring/reducer';
import createTransactionReducer from '../modules/createTransaction/reducer';
import createTransactionSagas from '../modules/createTransaction/actions/sagas';
import customerNotificationsSagas from '../modules/customerNotifications/actions/sagas';
import customerNotificationsReducer from '../modules/customerNotifications/reducer';
import featureToggleSagas from '../modules/featureToggle/actions/sagas';
import featureToggleReducer from '../modules/featureToggle/reducer';
import registrationSagas from '../modules/registration/actions/sagas';
import registrationReducer from '../modules/registration/reducer';
import transactionMonitoringSagas from '../modules/transactionMonitoring/actions/sagas';
import transactionMonitoringReducer from '../modules/transactionMonitoring/reducer';
import userManagerSagas from '../modules/userManager/actions/sagas';
import userManagerReducer from '../modules/userManager/reducer';

import internalUserManagement from '../modules/internalUserManagement/reducer';
import { internalUserManagementSagas } from '../modules/internalUserManagement/actions/sagas';

const rootReducers = {
  global: globalReducer,
  billing: billingReducer,
  cddMonitoring: cddMonitoringReducer,
  createTransaction: createTransactionReducer,
  customerNotifications: customerNotificationsReducer,
  edd: eddReducer,
  featureToggle: featureToggleReducer,
  feedback: feedbackReducer,
  limits: limitsManagementReducer,
  registration: registrationReducer,
  transactions: transactionsReducer,
  transactionMonitoring: transactionMonitoringReducer,
  userManager: userManagerReducer,
  browser: browserReducer,
  internalUserManagement,
};

const rootSagas = [
  ...billingSagas,
  ...cddMonitoringSagas,
  ...customerNotificationsSagas,
  ...featureToggleSagas,
  ...registrationSagas,
  ...transactionMonitoringSagas,
  ...userManagerSagas,
  ...createTransactionSagas,
  ...internalUserManagementSagas,

  call(assignWorkItemSaga),
  call(assignWorkItemPackageSaga),
  call(getWorkItemSaga),
  call(getWorkItemPackageSaga),
  call(getWorkItemPackagesSaga),
  call(updateActionItemSaga),
  call(finalizeWorkItemPackageSaga),
  call(updateWorkItemPackageStatusSaga),
  call(uploadDocumentSaga),
  call(getDocumentSaga),
  call(getEDDReportSaga),

  call(getTransactionsSaga),
  call(settleTransactionSaga),
  call(rollbackTransactionSaga),
  call(updateOtcDataSaga),
  call(getOtcDataSaga),
  call(generateReportSaga),

  call(getCustomersSaga),
  call(getCustomerConfigSaga),
  call(setCustomerConfigSaga),
  call(getGlobalConfigSaga),
  call(setGlobalConfigSaga),

  call(getWiredTransactionsSaga),
  call(settleWireSaga),
  call(rollbackWireSaga),
  call(updateWireSaga),
];

const createReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducers,
  });

const createSaga = () => rootSagas;

export { createReducer, createSaga };
