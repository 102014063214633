const actionTypes = {
  EDIT_DEFAULT_CONFIGURATION_OVERLAY_VISIBLE: 'EDIT_DEFAULT_CONFIGURATION_OVERLAY_VISIBLE',
  EDIT_CUSTOMER_CONFIGURATION_OVERLAY_VISIBLE: 'EDIT_CUSTOMER_CONFIGURATION_OVERLAY_VISIBLE',
  SET_GLOBAL_LIMITS_SELECTED_BANK: 'SET_DEFAULT_LIMITS_SELECTED_BANK',
  SET_GLOBAL_LIMITS_DATA: 'SET_DEFAULT_LIMITS_DATA',
  SET_CUSTOMER_LIMITS_SELECTED_BANK: 'SET_CUSTOMER_LIMITS_SELECTED_BANK',
  SET_CUSTOMER_LIMITS_DATA: 'SET_CUSTOMER_LIMITS_DATA',
  SET_CUSTOMERS_FILTER: 'SET_CUSTOMERS_FILTER',
  SET_CUSTOMERS_FILTER_INPUT: 'SET_CUSTOMERS_FILTER_INPUT',

  GET_CUSTOMER_CONFIG_START: 'GET_CUSTOMER_CONFIG_START',
  GET_CUSTOMER_CONFIG: 'GET_CUSTOMER_CONFIG',
  GET_CUSTOMER_CONFIG_SUCCESS: 'GET_CUSTOMER_CONFIG_SUCCESS',
  GET_CUSTOMER_CONFIG_FAIL: 'GET_CUSTOMER_CONFIG_FAIL',

  GET_CUSTOMERS_START: 'GET_CUSTOMERS_START',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAIL: 'GET_CUSTOMERS_FAIL',

  GET_GLOBAL_CONFIG_START: 'GET_GLOBAL_CONFIG_START',
  GET_GLOBAL_CONFIG: 'GET_GLOBAL_CONFIG',
  GET_GLOBAL_CONFIG_SUCCESS: 'GET_GLOBAL_CONFIG_SUCCESS',
  GET_GLOBAL_CONFIG_FAIL: 'GET_GLOBAL_CONFIG_FAIL',

  SET_CUSTOMER_CONFIG_START: 'SET_CUSTOMER_CONFIG_START',
  SET_CUSTOMER_CONFIG: 'SET_CUSTOMER_CONFIG',
  SET_CUSTOMER_CONFIG_SUCCESS: 'SET_CUSTOMER_CONFIG_SUCCESS',
  SET_CUSTOMER_CONFIG_FAIL: 'SET_CUSTOMER_CONFIG_FAIL',

  SET_GLOBAL_CONFIG_START: 'SET_GLOBAL_CONFIG_START',
  SET_GLOBAL_CONFIG: 'SET_GLOBAL_CONFIG',
  SET_GLOBAL_CONFIG_SUCCESS: 'SET_GLOBAL_CONFIG_SUCCESS',
  SET_GLOBAL_CONFIG_FAIL: 'SET_GLOBAL_CONFIG_FAIL',
};

export default actionTypes;
