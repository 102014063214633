import React, { memo } from 'react';
import { Button } from '@finclusive/library';

const ReloadButton = () => (
  <Button
    color="secondary"
    action={() => {
      window.location.replace(window.location.origin);
    }}
  >
    Reload
  </Button>
);

export default memo(ReloadButton);
