import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@finclusive/library';
import { toUpper } from '@finclusive/utils';
import userManager from 'modules/userManager/components/UserManager';
import ROUTE_PATHS from 'routes/routePaths';

const TopBar = ({ push, companyName, userName, permissions }) => {
  const initialLetter = toUpper(userName.charAt(0));
  const { canViewInternalUsers } = permissions;

  return (
    <div className="topbar" data-test="header-container">
      <div className="topbar__topbar-menu">
        <div className="topbar__topbar-menu__dropdown" data-test="header-menu">
          <div className="topbar__topbar-menu__dropdown__avatar">
            <span className="initials">{initialLetter}</span>
          </div>
          <div className="topbar__topbar-menu__dropdown__display-name">
            <span className="topbar__topbar-menu__dropdown__display-name__username">{userName}</span>
            <span className="topbar__topbar-menu__dropdown__display-name__company-name">{companyName}</span>
          </div>
          <Icon className="topbar__chevron" name="angleDown" />
          <div className="topbar__topbar-menu__dropdown-content">
            {canViewInternalUsers ? (
              <p
                data-test="header-internal-user-management"
                onClick={() => push(`${ROUTE_PATHS.INTERNAL_USER_MANAGEMENT}`)}
              >
                <Icon dataTest="header-internal-user-management-icon" name="account-supervisor-circle" />
                <span data-test="header-internal-user-management-text">Internal Users</span>
              </p>
            ) : null}
            <p data-test="header-sign-out" onClick={userManager.logout}>
              <Icon className="topbar__inner" data-test="header-sign-out-icon" name="exit" />
              <span data-test="header-sign-out-text">Sign Out</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  companyName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canViewInternalUsers: PropTypes.bool,
  }).isRequired,
};

export default TopBar;
