import React, { memo } from 'react';
import { Button } from '@finclusive/library';
import userManager from 'modules/userManager/components/UserManager';

const LogoutButton = () => {
  return (
    <Button color="primary" action={userManager.logout}>
      Logout
    </Button>
  );
};

export default memo(LogoutButton);
