import types from '../types';

export const getOtcDataStart = () => ({
  type: types.GET_OTC_DATA_START,
});

export const getOtcData = (transactionID) => ({
  type: types.GET_OTC_DATA,
  transactionID,
});

export const getOtcDataSuccess = (otcData) => ({
  type: types.GET_OTC_DATA_SUCCESS,
  otcData,
});

export const getOtcDataFail = (error) => ({
  type: types.GET_OTC_DATA_FAIL,
  error,
});
