import types from '../types';

export const generateReportStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingReportRequest: true,
  },
});

export const generateReportSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingReportRequest: false,
  },
});

export const generateReportFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isSubmittingReportRequest: false,
  },
  error,
});

export default {
  [types.GENERATE_REPORT_START]: generateReportStart,
  [types.GENERATE_REPORT_SUCCESS]: generateReportSuccess,
  [types.GENERATE_REPORT_FAIL]: generateReportFail,
};
