import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../SpinnerComplex';

const DetailHighlight = ({ className, children, loading }) => (
  <div className={`detail-view__highlight ${className} ${loading ? 'detail-view__highlight--loading' : ''}`}>
    {loading ? <Spinner visible overlay /> : ''}
    {children}
  </div>
);

DetailHighlight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};
DetailHighlight.defaultProps = {
  className: '',
  loading: false,
};

export default DetailHighlight;
