import React from 'react';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { Icon, Tag } from '@finclusive/library';
import { COLUMNS_CONFIG, COLUMNS_KEYS } from './constants';
import { PARTIES } from '../../../../constants';
import { getSeverityTag } from '../../../../helpers';

const getPartyLabel = (value) => {
  if (isBlank(value)) {
    return PARTIES.unset.label;
  }
  const foundPartyKey = R.find((key) => PARTIES[key].value === value)(R.keys(PARTIES));
  return isBlank(foundPartyKey) ? PARTIES.unset.label : PARTIES[foundPartyKey].label;
};

export const getTableData = (list, onEditClick) =>
  !isBlank(list)
    ? list.map((rule) => {
        const { name, parties, severity } = rule;
        const partyLabel = getPartyLabel(parties);
        const { label: severityLabel, type: severityType } = getSeverityTag(severity);

        return {
          onClick: () => onEditClick(rule),
          cells: {
            [COLUMNS_KEYS.name]: name,
            [COLUMNS_KEYS.parties]: partyLabel,
            [COLUMNS_KEYS.severity]: (
              <Tag variant="bullet" type={severityType}>
                {severityLabel}
              </Tag>
            ),
            edit: <Icon name="pencil" />,
          },
        };
      })
    : [];

export const getTableConfig = (isEditable) =>
  isEditable ? COLUMNS_CONFIG : R.filter((item) => item.key === COLUMNS_KEYS.edit, COLUMNS_CONFIG);
