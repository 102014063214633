import { createPrefixedAction } from '../config';
import actionTypes from './types';

const {
  GET_INTERNAL_USERS,
  GET_INTERNAL_USERS_START,
  GET_INTERNAL_USERS_SUCCESS,
  GET_INTERNAL_USERS_FAIL,
  SET_FILTER,
  SET_SELECTED_ROW,
  GET_INTERNAL_USER_DETAILS,
  GET_INTERNAL_USER_DETAILS_START,
  GET_INTERNAL_USER_DETAILS_SUCCESS,
  GET_INTERNAL_USER_DETAILS_FAIL,
  PATCH_INTERNAL_USER_DETAILS,
  PATCH_INTERNAL_USER_DETAILS_START,
  PATCH_INTERNAL_USER_DETAILS_SUCCESS,
  PATCH_INTERNAL_USER_DETAILS_FAIL,
  GET_INTERNAL_PERMISSIONS,
  GET_INTERNAL_PERMISSIONS_START,
  GET_INTERNAL_PERMISSIONS_SUCCESS,
  GET_INTERNAL_PERMISSIONS_FAIL,
} = actionTypes;

export const getInternalUsers = createPrefixedAction(GET_INTERNAL_USERS);
export const getInternalUsersStart = createPrefixedAction(GET_INTERNAL_USERS_START);
export const getInternalUsersSuccess = createPrefixedAction(GET_INTERNAL_USERS_SUCCESS);
export const getInternalUsersFail = createPrefixedAction(GET_INTERNAL_USERS_FAIL);

export const setFilter = createPrefixedAction(SET_FILTER);
export const setSelectedRow = createPrefixedAction(SET_SELECTED_ROW);

export const getInternalUserDetails = createPrefixedAction(GET_INTERNAL_USER_DETAILS);
export const getInternalUserDetailsStart = createPrefixedAction(GET_INTERNAL_USER_DETAILS_START);
export const getInternalUserDetailsSuccess = createPrefixedAction(
  GET_INTERNAL_USER_DETAILS_SUCCESS
);
export const getInternalUserDetailsFail = createPrefixedAction(GET_INTERNAL_USER_DETAILS_FAIL);

export const patchInternalUserDetails = createPrefixedAction(PATCH_INTERNAL_USER_DETAILS);
export const patchInternalUserDetailsStart = createPrefixedAction(PATCH_INTERNAL_USER_DETAILS_START);
export const patchInternalUserDetailsSuccess = createPrefixedAction(
  PATCH_INTERNAL_USER_DETAILS_SUCCESS
);
export const patchInternalUserDetailsFail = createPrefixedAction(PATCH_INTERNAL_USER_DETAILS_FAIL);

export const getInternalPermissions = createPrefixedAction(GET_INTERNAL_PERMISSIONS);
export const getInternalPermissionsStart = createPrefixedAction(GET_INTERNAL_PERMISSIONS_START);
export const getInternalPermissionsSuccess = createPrefixedAction(GET_INTERNAL_PERMISSIONS_SUCCESS);
export const getInternalPermissionsFail = createPrefixedAction(GET_INTERNAL_PERMISSIONS_FAIL);
