import axios from 'axios';
import { addAuthorizationHeaderRequestInterceptor } from './axios-interceptors/authorization-request-interceptor';
import { addBodyBlobRequestInterceptor } from './axios-interceptors/blob-data-request-interceptor';

const applicationJson = 'application/json';

export const apiAxios = async (options) => {
  const { responseType, headers, contentType } = options;

  const instance = axios.create({
    responseType: responseType === applicationJson ? 'json' : responseType,
    headers: {
      ...headers,
      Accept: contentType || applicationJson,
      'Content-Type': contentType || applicationJson,
      'Cache-Control': 'no-cache, no-store, must-revalidate, private, max-age=0',
    },
  });

  await addAuthorizationHeaderRequestInterceptor(instance, options);
  await addBodyBlobRequestInterceptor(instance, options);

  return instance;
};
