import types from '../types';

export const assignWorkItemPackageStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: true,
  },
});

export const assignWorkItemPackageSuccess = (state, { success }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: false,
  },
  success,
});

export const assignWorkItemPackageFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isAssigningWorkItem: false,
  },
  error,
});

export default {
  [types.ASSIGN_WORK_ITEM_PACKAGE_START]: assignWorkItemPackageStart,
  [types.ASSIGN_WORK_ITEM_PACKAGE_SUCCESS]: assignWorkItemPackageSuccess,
  [types.ASSIGN_WORK_ITEM_PACKAGE_FAIL]: assignWorkItemPackageFail,
};
