import React from 'react';
import PropTypes from 'prop-types';

const Column = ({ children, className, width, leftOffset, rightOffset, offset }) => (
  <>
    {leftOffset && <div className="column__offset" style={{ flex: leftOffset }} />}
    {offset && <div className="column__offset" style={{ flex: offset }} />}
    <div className={`column ${className}`} style={{ flex: width }}>
      {children}
    </div>
    {offset && <div className="column__offset" style={{ flex: offset }} />}
    {rightOffset && <div className="column__offset" style={{ flex: rightOffset }} />}
  </>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  offset: PropTypes.number,
};
Column.defaultProps = {
  className: '',
  width: 1,
};

export default Column;
