import types from '../types';

export const getDocumentStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingDocument: true,
  },
});

export const getDocumentSuccess = (state, { data }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingDocument: false,
  },
  exportUrl: data.exportUrl,
});

export const getDocumentFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoadingDocument: false,
  },
  error,
});

export default {
  [types.GET_DOCUMENT_START]: getDocumentStart,
  [types.GET_DOCUMENT_SUCCESS]: getDocumentSuccess,
  [types.GET_DOCUMENT_FAIL]: getDocumentFail,
};
