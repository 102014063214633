import { call, put, takeLatest } from 'redux-saga/effects';
import { patch, showErrorMessage } from 'modules/utils';
import { REACT_APP_UPDATE_US_WIRE, REACT_APP_UPDATE_INTERNATIONAL_WIRE } from 'env-config';
import ToastNotification from 'components/library/ToastNotification';
import { INTERNATIONAL_WIRE, DOMESTIC_WIRE } from 'components/views/WiredTransactions/constants';
import { updateWireStart, updateWireSuccess, updateWireFail } from '../actions/update-wire';
import userTypes from '../types';
import { getWiredTransactions } from './get-wired-transactions';

const API_MAPPING = {
  [DOMESTIC_WIRE]: REACT_APP_UPDATE_US_WIRE,
  [INTERNATIONAL_WIRE]: REACT_APP_UPDATE_INTERNATIONAL_WIRE,
};

export const httpCall = (payload, transactionMethod) =>
  patch({
    route: `${API_MAPPING[transactionMethod]}?clientId=default`,
    data: payload,
  });

export function* updateWire({ payload, transactionMethod }) {
  try {
    yield put(updateWireStart());

    const result = yield call(httpCall, payload, transactionMethod);

    yield put(updateWireSuccess(result));
    ToastNotification('success', 'Transaction submitted to back');
    yield* getWiredTransactions('default');
  } catch (error) {
    showErrorMessage(error);
    yield put(updateWireFail(error));
  }
}

export default function* updateWireSaga() {
  yield takeLatest(userTypes.UPDATE_WIRE, updateWire);
}
