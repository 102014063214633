import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { isBlank } from '@finclusive/utils';

const withPermissions = (watchedPermissions) => (WrappedComponent) => {
  if (isBlank(WrappedComponent)) {
    throw new Error('No component provided to HOC');
  }

  if (!Array.isArray(watchedPermissions) && !R.is(Object, watchedPermissions)) {
    throw new Error('The passed argument is neither an Array nor an Object.');
  }

  const PermissionsWrapper = ({ userPermissions, ...restProps }) => {
    const isArray = Array.isArray(watchedPermissions);
    const [permissions, setPermissions] = useState(isArray ? false : {});

    const checkPermissions = useCallback(
      (list) =>
        list.find((permissionName) => R.find(R.propEq('name', permissionName), userPermissions)),
      [userPermissions]
    );

    useEffect(() => {
      if (isArray) {
        const hasPermission = !!checkPermissions(watchedPermissions);
        setPermissions(hasPermission);
        return;
      }

      const permisionList = R.reduce(
        (acc, key) => ({
          ...acc,
          [key]: !!checkPermissions(watchedPermissions[key]),
        }),
        {},
        R.keys(watchedPermissions)
      );

      setPermissions(permisionList);
    }, [isArray, userPermissions, checkPermissions]);

    return <WrappedComponent permissions={permissions} {...restProps} />;
  };

  PermissionsWrapper.propTypes = {
    userPermissions: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ])
    ).isRequired,
  };

  const mapStateToProps = ({ userManager }) => ({
    userPermissions: userManager.permissions,
  });

  return connect(mapStateToProps)(PermissionsWrapper);
};

export default withPermissions;
