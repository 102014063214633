import styled, { css } from 'styled-components';

const StyledOverridenRules = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      .table-icon {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: ${palette.primary};
        }
      }
      .table {
        .table__cell {
          &.rule-table-icon {
            padding: 0;
            width: 5rem;
          }
          &:nth-last-child(2) {
            padding-right: 1rem;
            width: 6rem;
          }
        }
        th:last-child,
        th:nth-last-child(2),
        .table__selected-icon {
          display: none;
        }
      }
    `;
  }}
`;

StyledOverridenRules.displayName = 'StyledOverridenRules';
export default StyledOverridenRules;
