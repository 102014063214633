import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '@finclusive/utils';
import Table from '../Table';
import TablePagination from './components/TablePagination';

const TableWithPagination = ({
  isLoading,
  enableHover,
  className,
  dataTest,
  data,
  columns,
  selected,
  selectedIcon,
  noDataFoundMessage,
  filterValue,
  pageLimits,
  sortOrder,
  sort,
  getPagedData,
}) => {
  const { results, rowCount } = data;

  const onPageChanged = (currentPage, pageLimit, sortOrder, filterValueItem) => {
    getPagedData(currentPage, pageLimit, sortOrder, filterValueItem);
  };

  return (
    <div className="table-with-pagination">
      <div className="table-with-pagination__wrapper">
        <Table
          isLoading={isLoading}
          enableHover={enableHover}
          className={`table--with-pagination ${className}`}
          dataTest={dataTest}
          data={results}
          columns={columns}
          selected={selected}
          selectedIcon={selectedIcon}
          noDataFoundMessage={noDataFoundMessage}
          sort={sort}
        />
        {rowCount > 0 && (
          <TablePagination
            isLoading={isLoading}
            dataTest={`${dataTest}-pagination`}
            data={data}
            filterValue={filterValue}
            pageLimits={pageLimits}
            sortOrder={sortOrder}
            onPageChanged={onPageChanged}
          />
        )}
      </div>
    </div>
  );
};

TableWithPagination.defaultProps = {
  isLoading: false,
  enableHover: false,
  className: '',
  dataTest: '',
  selected: null,
  selectedIcon: '',
  noDataFoundMessage: 'No data found.',
  filterValue: '',
  sortOrder: '',
  sort: noop,
};

TableWithPagination.propTypes = {
  isLoading: PropTypes.bool,
  enableHover: PropTypes.bool,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  data: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowCount: PropTypes.number,
  }).isRequired,
  columns: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  selectedIcon: PropTypes.string,
  noDataFoundMessage: PropTypes.string,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  pageLimits: PropTypes.array.isRequired,
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  sort: PropTypes.func,
  getPagedData: PropTypes.func.isRequired,
};

export default TableWithPagination;
