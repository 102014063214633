import types from './types';

const initialState = {
  body: '',
};

const setFeedback = (state, { feedback }) => ({
  ...state,
  body: feedback,
});

const reducer = {
  [types.SET_FEEDBACK]: setFeedback,
};

export default (state = initialState, action = {}) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
