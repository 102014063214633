import { apiAxios } from './api-axios';

const requestWrapper = async (method, options) => {
  if (!options)
    throw new Error('Route has not been set. If you added a new route in the .env file, restart the project.');

  try {
    const { returnFullResponse } = options;

    const instance = await apiAxios(options);

    const response = await instance.request({
      method,
      url: options.route,
    });

    /* eslint-disable consistent-return */
    if (returnFullResponse) {
      return response;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get = async (data) => {
  const options = typeof data === 'string' ? { route: data } : data;
  return requestWrapper('get', options);
};
export const post = async (data) => await requestWrapper('post', data);
export const deleteRequest = async (data) => await requestWrapper('delete', data);
export const putRequest = async (data) => await requestWrapper('put', data);
export const patch = async (data) => await requestWrapper('patch', data);
export const upload = async (data) =>
  await requestWrapper('post', {
    ...data,
    contentType: 'multipart/form-data',
  });
