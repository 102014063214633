export const PAGE_LIMITS = [10, 25, 50];

export const COLUMNS_KEYS = {
  customerName: 'customerName',
  finclusiveId: 'finclusiveId',
  channels: 'channels',
};

export const COLUMNS_CONFIG = [
  {
    columnKey: COLUMNS_KEYS.customerName,
    label: 'Customer Name',
  },
  {
    columnKey: COLUMNS_KEYS.finclusiveId,
    label: 'FinClusive ID',
  },
  {
    columnKey: COLUMNS_KEYS.channels,
    label: 'Channels',
  },
];

export const CHANNELS = {
  0: 'Email',
  1: 'WebHook',
};

export const TAB_LABELS = {
  compliance: 'Compliance',
  transactionMonitoring: 'Transaction Monitoring',
};
