import styled, { css } from 'styled-components';

const StyledSidebarHeader = styled.a`
  ${({ theme, isCollapsed }) => {
    const { palette, media } = theme;
    const sidebarWidth = '25rem';
    const sidebarCollapsedWidth = '6rem';

    return css`
      display: flex;
      align-items: center;
      position: relative;
      height: 4rem;
      min-width: ${sidebarWidth};
      padding: 2rem 2rem 2rem 2rem;
      border-bottom: 0.1rem solid ${palette.gray.xxGrayLightest};
      ${media.sm &&
      css`
        height: 6rem;
      `}
  
      .top-icon-menu,
      .top-icon-back {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0.6rem;
        left: 2rem;
        width: 3rem;
        height: 3rem;
        color: #ced4da;
        font-size: 2.4rem;
        opacity: 0;
        border-radius: 1.5rem;
        transform: rotate(270deg);
        z-index: 1;
        &:hover {
          cursor: pointer;
          background-color: ${palette.gray.xxGrayLightest};
        }
        ${media.sm &&
        css`
          top: 1.5rem;
        `}
      }
  
      .top-icon-menu {
        left: 1.5rem;
        transform: rotate(270deg);
        ${isCollapsed &&
        css`
          opacity: 1;
          transform: rotate(0deg);
          z-index: 2;
        `}
      }
  
      .top-icon-back {
        transform: rotate(-270deg);
        ${!isCollapsed &&
        css`
          opacity: 1;
          transform: rotate(0deg);
          z-index: 2;
        `}
      }
      .logo {
        height: 1.5rem;
        margin-left: 4rem;
        ${isCollapsed &&
        css`
          margin-left: ${sidebarCollapsedWidth};
        `}
      }
    `;
  }}
`;

StyledSidebarHeader.displayName = 'StyledSidebarHeader';
export default StyledSidebarHeader;
