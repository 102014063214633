import types from '../types';

export const getTransactionsStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoading: true,
  },
});

export const getTransactionsSuccess = (state, { transactionData }) => ({
  ...state,
  transactions: {
    ...state.transactions,
    transactionList: transactionData,
  },
  loaders: {
    ...state.loaders,
    isLoading: false,
  },
});

export const getTransactionsFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isLoading: false,
  },
  error,
});

export default {
  [types.GET_TRANSACTIONS_START]: getTransactionsStart,
  [types.GET_TRANSACTIONS_SUCCESS]: getTransactionsSuccess,
  [types.GET_TRANSACTIONS_FAIL]: getTransactionsFail,
};
