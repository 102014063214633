import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@finclusive/library';
import StyledSidebarItem from './SidebarItem.style';

const SidebarItem = ({
  isChildItem,
  isCollapsed,
  dataTest,
  label,
  icon,
  rightSideIcon,
  routePath,
  location,
  search,
  push,
  onClick,
}) => {
  const isActive = routePath && location.indexOf(routePath) !== -1;

  return (
    <StyledSidebarItem
      data-test={dataTest}
      isActive={isActive}
      isCollapsed={isCollapsed}
      isChildItem={isChildItem}
      onClick={
        onClick ||
        (() => push(isActive && location === routePath ? `${routePath}${search}` : routePath, { replace: true }))
      }
    >
      <div className="link-name-wrapper">
        <Icon className="link-left-icon" name={icon} />
        <div className="link-name">{label}</div>
      </div>
      {rightSideIcon && <Icon className="link-right-icon" name={rightSideIcon} />}
    </StyledSidebarItem>
  );
};

SidebarItem.defaultProps = {
  isChildItem: false,
  isCollapsed: false,
  dataTest: '',
  rightSideIcon: '',
  routePath: '',
  onClick: null,
};

SidebarItem.propTypes = {
  isChildItem: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  dataTest: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  rightSideIcon: PropTypes.string,
  routePath: PropTypes.string,
  location: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  push: PropTypes.func.isRequired,
};

export default SidebarItem;
