import React from 'react';
import Tooltip from 'modules/transactionMonitoring/components/Tooltip';

const TypeHeader = () => (
  <div className="transaction-table__type">
    <span>Type</span>
    <Tooltip
      id="type"
      text="Prefix denotes “Internal” if a transaction was processed using FinClusive Gateway or “External”  if a transaction was processed by a customer outside of FinClusive.  Suffix denotes the transaction type / channel of the transaction."
      className="transaction-table__tooltip"
    />
  </div>
);

export default TypeHeader;
