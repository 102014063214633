import { TAB_LABELS } from '../components/constants';
import EMPTY_DESTINATION from './constants';

const initialState = {
  isLoading: false,
  isSubmitting: false,
  isNotificationsOverlayVisible: false,
  isChannelOverlayVisible: false,
  isDirty: false,
  customers: [],
  activeTab: TAB_LABELS.compliance,
  options: {
    isLoading: false,
    allNotificationChannelTypes: [],
  },
  groups: {
    isLoading: false,
    data: [],
  },
  selectedCustomer: {
    id: '',
    attributes: null,
    channels: [],
    customerLegalName: '',
    customerID: '',
    customerFinClusiveID: '',
    customerTaxIdNumber: '',
    customerContactAddress: '',
    customerIncorporationAddress: '',
    customerBusinessContact: '',
    customerPrimaryContact: '',
    ownerID: '',
  },
  selectedChannel: {
    title: '',
    CddExternalStatusChange: false,
    CddInternalStatusChange: false,
    channelType: 0,
    ClientComplianceStatusChange: false,
    destinations: {
      0: EMPTY_DESTINATION,
    },
    EddAnalystAssigned: false,
    EddAnalystCompleted: false,
    EddComponentCompleted: false,
    EddReportCompleted: false,
    EddReportFullRevalidationTriggered: false,
    EddReportInProgress: false,
    EddReportOngoingMonitoringTriggered: false,
    EddReportOrdered: false,
  },
};

export default initialState;
