import React from 'react';
import { ReactComponent as Back } from 'assets/img/icons/back.svg';
import './index.scss';

export default class SlideView extends React.Component {
  render() {
    const { displayName, children, handler } = this.props;
    return (
      <div className="slide-view">
        <div className="slide-view-title">
          <Back className="cursor" onClick={handler} />
          {displayName}
        </div>
        <div className="slide-view-content">{children}</div>
      </div>
    );
  }
}
