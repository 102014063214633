import types from '../types';

export const getWorkItemPackageStart = () => ({
  type: types.GET_WORK_ITEM_PACKAGE_START,
});

export const getWorkItemPackage = ({ workItemPackageID, index }) => ({
  type: types.GET_WORK_ITEM_PACKAGE,
  workItemPackageID,
  index,
});

export const getWorkItemPackageSuccess = (data) => ({
  type: types.GET_WORK_ITEM_PACKAGE_SUCCESS,
  data,
});

export const getWorkItemPackageFail = (error) => ({
  type: types.GET_WORK_ITEM_PACKAGE_FAIL,
  error,
});
