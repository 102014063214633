import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

import { Icon, Spinner } from '../index';

export default class DetailView extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    contentClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    silentLoading: PropTypes.bool,
    toggleDetailView: PropTypes.func.isRequired,
    toggleEditOverlay: PropTypes.func,
    isOpen: PropTypes.bool,
    hasEditButton: PropTypes.bool,
    onClose: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    className: '',
    contentClassName: '',
    isLoading: false,
    hasEditButton: true,
    width: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      transitioning: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { isOpen, isLoading } = this.props;
    if (prevProps.isOpen !== isOpen || prevProps.isLoading !== isLoading) {
      this.setState({ transitioning: true });
      !isOpen && this.toggle(false);
      setTimeout(() => this.setState({ transitioning: false }), 500);
    }
  };

  setWidth = () => {
    const { width } = this.props;
    const { visible } = this.state;
    return { minWidth: visible ? width : '', maxWidth: width };
  };

  toggle = (bool) => {
    const { toggleDetailView, onClose } = this.props;
    const { visible } = this.state;

    !bool && onClose && onClose();
    toggleDetailView(bool);
    this.setState({ visible: typeof bool === 'boolean' ? bool : !visible });
  };

  render() {
    const { setWidth } = this;
    const { className, children, contentClassName, hasEditButton, tools, isLoading, toggleEditOverlay, silentLoading } =
      this.props;
    const { visible, transitioning } = this.state;

    return (
      <div style={setWidth()} className={`detail-view ${className} ${visible ? 'detail-view--visible' : ''}`}>
        <div className="detail-view__toolbar">
          <div className="detail-view__button-container">
            {tools &&
              tools.map((tool, i) => (
                <div
                  onClick={tool.onClick}
                  key={`tool-button-${i}`}
                  className={`detail-view__tool-button ${isLoading ? 'detail-view__edit-button--disabled' : ''} ${
                    tool.className
                  }`}
                >
                  {tool.label}
                </div>
              ))}
            {hasEditButton && (
              <Icon
                className={`detail-view__tool-button ${isLoading ? 'detail-view__edit-button--disabled' : ''}`}
                onClick={toggleEditOverlay}
                name="pencil-outline"
              />
            )}
            <Icon
              className="detail-view__tool-button detail-view__tool-button--close"
              onClick={() => this.toggle(false)}
              name="close"
            />
          </div>
          {silentLoading && <LinearProgress className="detail-view__silent-loader" />}
        </div>

        <div className={`detail-view__inner ${transitioning ? 'detail-view__inner--transitioning' : ''}`}>
          <div
            ref={(c) => {
              this.contentElement = c;
            }}
            className={`detail-view__content ${contentClassName}`}
          >
            {!silentLoading && <Spinner visible={isLoading} subLoader solidOverlay className="detail-view__spinner" />}
            {children}
          </div>
        </div>
      </div>
    );
  }
}
