import types from '../types';

export const uploadDocument = ({ fileMeta, file }) => ({
  type: types.UPLOAD_DOCUMENT,
  fileMeta,
  file,
});

export const uploadDocumentStart = () => ({
  type: types.UPLOAD_DOCUMENT_START,
});

export const uploadDocumentSuccess = (data) => ({
  type: types.UPLOAD_DOCUMENT_SUCCESS,
  data,
});

export const uploadDocumentFail = (error) => ({
  type: types.UPLOAD_DOCUMENT_FAIL,
  error,
});
