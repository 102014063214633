import { createPrefixedAction } from '../config';
import actionTypes from './types';

export const setPageFilters = createPrefixedAction(actionTypes.SET_PAGE_FILTERS);
export const setSearchFilters = createPrefixedAction(actionTypes.SET_SEARCH_FILTERS);
export const clearSearchFilters = createPrefixedAction(actionTypes.CLEAR_SEARCH_FILTERS);
export const toggleCustomerPlansOverlay = createPrefixedAction(
  actionTypes.TOGGLE_CUSTOMER_PLANS_OVERLAY
);
export const toggleVendorPricingOverlay = createPrefixedAction(
  actionTypes.TOGGLE_VENDOR_PRICING_OVERLAY
);

export const setFormData = createPrefixedAction(actionTypes.SET_FORM_DATA);
export const setSelectedCustomer = createPrefixedAction(actionTypes.SET_SELECTED_CUSTOMER);
export const setCustomerPlanSkus = createPrefixedAction(actionTypes.SET_CUSTOMER_PLAN_SKUS);

export const getCustomersPlans = createPrefixedAction(actionTypes.GET_CUSTOMERS_PLANS_SAGA);
export const getCustomersPlansStore = createPrefixedAction(actionTypes.GET_CUSTOMERS_PLANS);

export const getCustomerPlan = createPrefixedAction(actionTypes.GET_CUSTOMER_PLAN_SAGA);
export const getCustomerPlanStore = createPrefixedAction(actionTypes.GET_CUSTOMER_PLAN);

export const createCustomerPlan = createPrefixedAction(actionTypes.SET_CUSTOMER_PLAN_SAGA);
export const createCustomerPlanStore = createPrefixedAction(actionTypes.SET_CUSTOMER_PLAN);

export const updateCustomerPlan = createPrefixedAction(actionTypes.UPDATE_CUSTOMER_PLAN_SAGA);
export const updateCustomerPlanStore = createPrefixedAction(actionTypes.UPDATE_CUSTOMER_PLAN);

export const getCustomerVendors = createPrefixedAction(actionTypes.GET_CUSTOMER_VENDORS_SAGA);
export const getCustomerVendorsStore = createPrefixedAction(actionTypes.GET_CUSTOMER_VENDORS);

export const getCustomerVendorPlan = createPrefixedAction(
  actionTypes.GET_CUSTOMER_VENDOR_PLAN_SAGA
);
export const getCustomerVendorPlanStore = createPrefixedAction(
  actionTypes.GET_CUSTOMER_VENDOR_PLAN
);

export const updateVendorSkuList = createPrefixedAction(actionTypes.UDPATE_VENDOR_SKU_LIST);

export const saveCustomerVendorPlan = createPrefixedAction(
  actionTypes.SAVE_CUSTOMER_VENDOR_PLAN_SAGA
);
export const saveCustomerVendorPlanStore = createPrefixedAction(
  actionTypes.SAVE_CUSTOMER_VENDOR_PLAN
);

export const deleteCustomerVendorPlan = createPrefixedAction(
  actionTypes.DELETE_CUSTOMER_VENDOR_PLAN_SAGA
);
export const deleteCustomerVendorPlanStore = createPrefixedAction(
  actionTypes.DELETE_CUSTOMER_VENDOR_PLAN
);

export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
