const actionTypes = {
  SET_PAGE_FILTERS: 'SET_PAGE_FILTERS',
  SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
  CLEAR_SEARCH_FILTERS: 'CLEAR_SEARCH_FILTERS',
  TOGGLE_CUSTOMER_PLANS_OVERLAY: 'TOGGLE_CUSTOMER_PLANS_OVERLAY',

  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_SELECTED_CUSTOMER: 'SET_SELECTED_CUSTOMER',
  SET_CUSTOMER_PLAN_SKUS: 'SET_CUSTOMER_PLAN_SKUS',

  GET_CUSTOMERS_PLANS_SAGA: 'GET_CUSTOMERS_PLANS_SAGA',
  GET_CUSTOMERS_PLANS: 'GET_CUSTOMERS_PLANS',

  GET_CUSTOMER_PLAN_SAGA: 'GET_CUSTOMER_PLAN_SAGA',
  GET_CUSTOMER_PLAN: 'GET_CUSTOMER_PLAN',

  SET_CUSTOMER_PLAN_SAGA: 'SET_CUSTOMER_PLAN_SAGA',
  SET_CUSTOMER_PLAN: 'SET_CUSTOMER_PLAN',

  UPDATE_CUSTOMER_PLAN_SAGA: 'UPDATE_CUSTOMER_PLAN_SAGA',
  UPDATE_CUSTOMER_PLAN: 'UPDATE_CUSTOMER_PLAN',

  GET_CUSTOMER_VENDORS_SAGA: 'GET_CUSTOMER_VENDORS_SAGA',
  GET_CUSTOMER_VENDORS: 'GET_CUSTOMER_VENDORS',

  TOGGLE_VENDOR_PRICING_OVERLAY: 'TOGGLE_VENDOR_PRICING_OVERLAY',

  GET_CUSTOMER_VENDOR_PLAN_SAGA: 'GET_CUSTOMER_VENDOR_PLAN_SAGA',
  GET_CUSTOMER_VENDOR_PLAN: 'GET_CUSTOMER_VENDOR_PLAN',

  UDPATE_VENDOR_SKU_LIST: 'UDPATE_VENDOR_SKU_LIST',

  SAVE_CUSTOMER_VENDOR_PLAN_SAGA: 'SAVE_CUSTOMER_VENDOR_PLAN_SAGA',
  SAVE_CUSTOMER_VENDOR_PLAN: 'SAVE_CUSTOMER_VENDOR_PLAN',

  DELETE_CUSTOMER_VENDOR_PLAN_SAGA: 'DELETE_CUSTOMER_VENDOR_PLAN_SAGA',
  DELETE_CUSTOMER_VENDOR_PLAN: 'DELETE_CUSTOMER_VENDOR_PLAN',

  RESET_STATE: 'RESET_STATE',
};

export default actionTypes;
