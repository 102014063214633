import * as R from 'ramda';
import { CHANNELS, ENTITIES } from '../../../constants';
import { TABS_CONFIG_KEYS } from '../components/constants';
import { PAGE_LIMITS } from '../components/OverridenRules/constants';

const initialState = {
  activeTab: TABS_CONFIG_KEYS.global,
  triggerConfigs: {
    isLoading: false,
    data: [],
  },
  global: {
    list: {
      isLoading: false,
      data: [],
    },
    form: {
      isOverlayVisible: false,
      isLoading: false,
      ruleId: '',
      rule: {
        name: '',
        description: '',
        parties: 0,
        alerts: [],
      },
    },
  },
  overriden: {
    list: {
      isLoading: false,
      isDeleteLoading: false,
      data: [],
      pageData: {},
      pageFilters: {
        pageNo: 1,
        pageSize: PAGE_LIMITS[0],
      },
    },
    form: {
      isOverlayVisible: false,
      isLoading: false,
      isSubmitting: false,
      rules: [],
      removedRules: [],
      data: {
        entityId: '',
        entityType: R.keys(ENTITIES)[0],
        channel: CHANNELS[R.keys(CHANNELS)[0]].value,
      },
      configDetails: {
        isLoading: false,
        entityId: '',
        channel: '',
        entityType: '',
        initialRule: {},
      },
    },
  },
};

export default initialState;
