import types from '../types';

export const rollbackTransactionStart = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: true,
  },
});

export const rollbackTransactionSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
});

export const rollbackTransactionFail = (state, { error }) => ({
  ...state,
  loaders: {
    ...state.loaders,
    isInteractingWithTransaction: false,
  },
  error,
});

export default {
  [types.ROLLBACK_TRANSACTION_START]: rollbackTransactionStart,
  [types.ROLLBACK_TRANSACTION_SUCCESS]: rollbackTransactionSuccess,
  [types.ROLLBACK_TRANSACTION_FAIL]: rollbackTransactionFail,
};
