const initialState = {
  isLoading: false,
  isUsersLoading: false,
  isSubmitting: false,
  isSubmitted: false,
  emailAddress: '',
  users: {
    currentPage: 1,
    firstRowOnPage: 1,
    lastRowOnPage: 10,
    pageCount: 2,
    pageSize: 10,
    rowCount: 0,
    results: [],
  },
  permissions: [],
  error: null,
};

export default initialState;
