import React from 'react';
import { ENTITIES } from '../../../constants';
import GlobalRules from './GlobalRules';
import OverridenRules from './OverridenRules';

export const TABS_CONFIG_KEYS = {
  global: 'global',
  customer: ENTITIES[0].value,
  client: ENTITIES[1].value,
  account: ENTITIES[2].value,
};

export const TABS_CONFIG = {
  [TABS_CONFIG_KEYS.global]: {
    title: 'Global',
    component: ({ canEditAMLRules }) => <GlobalRules canEditAMLRules={canEditAMLRules} />,
  },
  [TABS_CONFIG_KEYS.customer]: {
    title: ENTITIES[0].label,
    component: ({ onRuleDelete, canEditAMLRules }) => (
      <OverridenRules
        entityType={0}
        canEditAMLRules={canEditAMLRules}
        onRuleDelete={onRuleDelete}
      />
    ),
  },
  [TABS_CONFIG_KEYS.client]: {
    title: ENTITIES[1].label,
    component: ({ onRuleDelete, canEditAMLRules }) => (
      <OverridenRules
        entityType={1}
        canEditAMLRules={canEditAMLRules}
        onRuleDelete={onRuleDelete}
      />
    ),
  },
  [TABS_CONFIG_KEYS.account]: {
    title: ENTITIES[2].label,
    component: ({ onRuleDelete, canEditAMLRules }) => (
      <OverridenRules
        entityType={2}
        canEditAMLRules={canEditAMLRules}
        onRuleDelete={onRuleDelete}
      />
    ),
  },
};

export const AML_RULES_HEADING = 'AML Rules';
