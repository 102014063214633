import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  setTransactionPageFilters,
  setSelectedTransaction,
  setAlertNote,
  setTransactionNote,
  clearSelectedTransaction,
  setDetailViewActiveTab,
  toggleDetailView,
  setAlertExpanded,
  setAlertCollapsed,
  getTransactionsStore,
  getTransactionDetailsStore,
  getTransactionAlertsStore,
  clearAlertStore,
  claimTransactionStore,
  clearTransactionStore,
  getHistoricalTransactionsStore,
  setTransactionFilters,
} from '../actions';
import initialState from './initialState';

const setTransactionPageFiltersHandler = [
  setTransactionPageFilters,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      list: {
        ...state.list,
        pageFilters: payload,
      },
    };
  },
];

const setSelectedTransactionHandler = [
  setSelectedTransaction,
  (state, action) => {
    const {
      payload: { index, transaction },
    } = action;
    return {
      ...state,
      list: {
        ...state.list,
        selectedRow: {
          index,
        },
      },
      detailView: {
        ...state.detailView,
        selectedTransaction: transaction,
      },
    };
  },
];

const clearSelectedTransactionHandler = [
  clearSelectedTransaction,
  (state) => ({
    ...state,
    list: {
      ...state.list,
      selectedRow: R.clone(initialState.list.selectedRow),
    },
  }),
];

const setAlertNoteHandler = [
  setAlertNote,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          notes: {
            ...state.detailView.transactionAlerts.notes,
            [payload.alertId]: payload.value,
          },
        },
      },
    };
  },
];

const setTransactionNoteHandler = [
  setTransactionNote,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionDetail: {
          ...state.detailView.transactionDetail,
          note: payload,
        },
      },
    };
  },
];

const setDetailViewActiveTabHandler = [
  setDetailViewActiveTab,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        activeTab: payload,
      },
    };
  },
];

const toggleDetailViewHandler = [
  toggleDetailView,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        ...(!payload ? R.clone(initialState.detailView) : {}),
        isVisible: payload,
      },
    };
  },
];

const setAlertExpandedHandler = [
  setAlertExpanded,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          expandedAlerts: [payload],
        },
      },
    };
  },
];

const setAlertCollapsedHandler = [
  setAlertCollapsed,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          expandedAlerts: [...state.detailView.transactionAlerts.expandedAlerts.filter((it) => it !== payload)],
        },
      },
    };
  },
];

const getTransactionsStoreHandler = [
  getTransactionsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      };
    }

    if (error) {
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
        },
      };
    }

    return {
      ...state,
      list: {
        ...state.list,
        isLoading: false,
        data: R.omit(['results'], payload),
        results: R.path(['results'], payload),
      },
    };
  },
];

const getTransactionDetailsStoreHandler = [
  getTransactionDetailsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionDetail: {
          ...state.detailView.transactionDetail,
          isLoading: false,
          data: payload,
          note: '',
        },
      },
    };
  },
];

const getTransactionAlertsStoreHandler = [
  getTransactionAlertsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            isLoading: true,
            list: [],
            notes: {},
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            isLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          isLoading: false,
          list: payload,
          notes: {},
        },
      },
    };
  },
];

const clearAlertStoreHandler = [
  clearAlertStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            isClearAlertLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            isClearAlertLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          isClearAlertLoading: false,
        },
      },
    };
  },
];

const claimTransactionStoreHandler = [
  claimTransactionStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isClaimTransactionLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isClaimTransactionLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionDetail: {
          ...state.detailView.transactionDetail,
          isClaimTransactionLoading: false,
        },
      },
    };
  },
];

const clearTransactionStoreHandler = [
  clearTransactionStore,
  (state, action) => {
    const { loading, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isClearTransactionLoading: true,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionDetail: {
            ...state.detailView.transactionDetail,
            isClearTransactionLoading: false,
          },
        },
      };
    }

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionDetail: {
          ...state.detailView.transactionDetail,
          isClearTransactionLoading: false,
        },
      },
    };
  },
];

const getHistoricalTransactionsStoreHandler = [
  getHistoricalTransactionsStore,
  (state, action) => {
    const { loading, payload, error } = action.payload;

    if (loading) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            loadingAlert: payload,
          },
        },
      };
    }

    if (error) {
      return {
        ...state,
        detailView: {
          ...state.detailView,
          transactionAlerts: {
            ...state.detailView.transactionAlerts,
            loadingAlert: R.clone(initialState.detailView.transactionAlerts.loadingAlert),
          },
        },
      };
    }

    const { alertId, data } = payload;
    const alertsList = R.clone(state.detailView.transactionAlerts.list);
    const alertIndex = R.findIndex((alert) => alert.id === alertId)(alertsList);
    alertsList.splice(alertIndex, 1, { ...alertsList[alertIndex], historicalTransactions: data });

    return {
      ...state,
      detailView: {
        ...state.detailView,
        transactionAlerts: {
          ...state.detailView.transactionAlerts,
          list: [...alertsList],
          loadingAlert: R.clone(initialState.detailView.transactionAlerts.loadingAlert),
        },
      },
    };
  },
];

const setTransactionFiltersHandler = [
  setTransactionFilters,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      filters: payload,
    };
  },
];

const reducer = handleActions(
  new Map([
    setTransactionPageFiltersHandler,
    setSelectedTransactionHandler,
    clearSelectedTransactionHandler,
    setAlertNoteHandler,
    setTransactionNoteHandler,
    setDetailViewActiveTabHandler,
    toggleDetailViewHandler,
    setAlertExpandedHandler,
    setAlertCollapsedHandler,
    getTransactionsStoreHandler,
    getTransactionDetailsStoreHandler,
    getTransactionAlertsStoreHandler,
    clearAlertStoreHandler,
    claimTransactionStoreHandler,
    clearTransactionStoreHandler,
    getHistoricalTransactionsStoreHandler,
    setTransactionFiltersHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
