import styled, { css } from 'styled-components';

const StyledSidebarItem = styled.div`
  ${({ theme, isActive, isCollapsed, isChildItem }) => {
    const { palette } = theme;
    const sidebarCollapsedWidth = '6rem';

    return css`
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 3rem;
      margin: 0 -1.6rem;
      color: ${palette.gray.grayDarkMedium};
      font-weight: 600;
      transition: all 0.2s ease;
      &:hover {
        cursor: pointer;
        background: ${palette.gray.xxGrayLightest};
      }
      ${isActive &&
      css`
        color: ${palette.primary};
        box-shadow: inset 0.4rem 0px 0px 0px ${palette.primary};
      `}
      ${isCollapsed &&
      css`
        padding: 1rem 2.2rem;
      `}
      ${!isCollapsed &&
      isChildItem &&
      css`
        padding-left: 6.4rem;
      `}

      .link-name-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        .link-left-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          margin-right: 2rem;
          fill: ${palette.gray.grayDarkMedium};
          font-size: 1.6rem;
          ${isActive &&
          css`
            color: ${palette.primary};
          `}
        }
        .link-name {
          opacity: 1;
          white-space: nowrap;
          ${isCollapsed &&
          css`
            margin-left: ${sidebarCollapsedWidth};
            opacity: 0;
          `}
        }
      }

      .link-right-icon {
        align-self: center;
        width: 0.8rem;
        height: 0.8rem;
        fill: ${palette.gray.grayDarkMedium};
      }
    `;
  }}
`;

StyledSidebarItem.displayName = 'StyledSidebarItem';
export default StyledSidebarItem;
