import types from '../types';

export const getTransactionsStart = () => ({
  type: types.GET_TRANSACTIONS_START,
});

export const getTransactions = () => ({
  type: types.GET_TRANSACTIONS,
});

export const getTransactionsSuccess = (transactionData) => ({
  type: types.GET_TRANSACTIONS_SUCCESS,
  transactionData,
});

export const getTransactionsFail = (error) => ({
  type: types.GET_TRANSACTIONS_FAIL,
  error,
});
