import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from '@finclusive/theme';

const ThemeManager = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

ThemeManager.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeManager;
