import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { isBlank } from '@finclusive/utils';
import { Loader } from '@finclusive/library';
import { useQueryParams } from 'hooks';

const withDefaultQueryParams =
  (defaultParams = {}) =>
  (ChildComponent) => {
    if (isBlank(ChildComponent)) {
      throw new Error('No component provided to HOC');
    }

    const DefaultQueryParamsWrapper = (props) => {
      const [isLoaded, setIsLoaded] = useState(false);
      const { queryParams, setQueryParams } = useQueryParams();

      useEffect(() => {
        setQueryParams(R.mergeLeft(queryParams, defaultParams));
        setIsLoaded(true);

        // eslint-disable-next-line
      }, []);

      return isLoaded ? <ChildComponent {...props} /> : <Loader size="medium" />;
    };

    return DefaultQueryParamsWrapper;
  };

export default withDefaultQueryParams;
