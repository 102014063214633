import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import pretty from 'prettysize';
import { isBlank } from '@finclusive/utils';
import Icon from 'components/library/Icon';
import Tooltip from 'components/library/Tooltip';

const TableCell = ({
  className,
  dataTest,
  isDownload,
  name,
  value,
  icon,
  size,
  id,
  fileName,
  tooltip,
  rowIndex,
  colIndex,
  getDocument,
}) => {
  return (
    <td
      className={cls('table__cell', {
        [`table__cell--${className[value]}`]: !isBlank(className),
        'table__cell--with-icon': !isBlank(icon),
        'table__cell--with-tooltip': !isBlank(tooltip),
      })}
      data-test={dataTest}
    >
      {tooltip && <Tooltip tooltip={tooltip} identifier={`${rowIndex}-${colIndex}`} className="table__tooltip" />}
      {icon && <Icon className="table__cell-icon" name={icon[value]} />}
      {isDownload && (
        <span className="table__cell-download">
          <span className="table__cell-download-button" onClick={() => getDocument({ name, value, id, fileName })}>
            <Icon className="table__cell-icon table__cell-icon--download" name="download" />
            Download
          </span>
          <span className="table__cell-download-size">{size && `(${pretty(size, true)})`}</span>
        </span>
      )}
      {!isDownload && value}
    </td>
  );
};

TableCell.defaultProps = {
  className: '',
  dataTest: '',
  isDownload: false,
  name: '',
  value: '',
  icon: '',
  size: null,
  id: '',
  fileName: '',
  tooltip: '',
  rowIndex: null,
  colIndex: null,
  getDocument: () => {},
};

TableCell.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  isDownload: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  icon: PropTypes.string,
  size: PropTypes.number,
  id: PropTypes.string,
  fileName: PropTypes.string,
  tooltip: PropTypes.string,
  rowIndex: PropTypes.number,
  colIndex: PropTypes.number,
  getDocument: PropTypes.func,
};

export default TableCell;
