import types from '../types';

export const assignWorkItemStart = () => ({
  type: types.ASSIGN_WORK_ITEM_START,
});

export const assignWorkItem = (workItemPackageID, dataAnalystID, paginationInfo) => ({
  type: types.ASSIGN_WORK_ITEM,
  workItemPackageID,
  dataAnalystID,
  paginationInfo,
});

export const assignWorkItemSuccess = (data) => ({
  type: types.ASSIGN_WORK_ITEM_SUCCESS,
  data,
});

export const assignWorkItemFail = (error) => ({
  type: types.ASSIGN_WORK_ITEM_FAIL,
  error,
});
