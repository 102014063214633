import styled, { css } from 'styled-components';

const StyledTableBody = styled.tbody`
  ${() => {
    return css`
      position: relative;
      .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `;
  }}
`;

StyledTableBody.displayName = 'StyledTableBody';
export default StyledTableBody;
