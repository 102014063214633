const actionTypes = {
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_SELECTED_CUSTOMER: 'SET_SELECTED_CUSTOMER',
  TOGGLE_NOTIFICATIONS_OVERLAY: 'TOGGLE_NOTIFICATIONS_OVERLAY',
  TOGGLE_CHANNEL_OVERLAY: 'TOGGLE_CHANNEL_OVERLAY',

  SET_IS_DIRTY_STATE: 'SET_IS_DIRTY_STATE',
  HANDLE_UPDATE_FORM_DATA: 'HANDLE_UPDATE_FORM_DATA',
  ADD_FORM_DESTINATIONS: 'ADD_FORM_DESTINATIONS',
  REMOVE_FORM_DESTINATIONS: 'REMOVE_FORM_DESTINATIONS',
  RESET_NOTIFICATION_FORM: 'RESET_NOTIFICATION_FORM',
  DELETE_NOTIFICATION_CHANNEL: 'DELETE_NOTIFICATION_CHANNEL',

  GET_ALL_CUSTOMERS_SAGA: 'GET_ALL_CUSTOMERS_SAGA',
  GET_ALL_CUSTOMERS_STORE: 'GET_ALL_CUSTOMERS_STORE',

  GET_CONFIGURATION_OPTIONS_SAGA: 'GET_CONFIGURATION_OPTIONS_SAGA',
  GET_CONFIGURATION_OPTIONS_STORE: 'GET_CONFIGURATION_OPTIONS_STORE',

  GET_CONFIGURATION_GROUPS_SAGA: 'GET_CONFIGURATION_GROUPS_SAGA',
  GET_CONFIGURATION_GROUPS_STORE: 'GET_CONFIGURATION_GROUPS_STORE',

  SAVE_CUSTOMER_CONFIGURATION_SAGA: 'SAVE_CUSTOMER_CONFIGURATION_SAGA',
  SAVE_CUSTOMER_CONFIGURATION_STORE: 'SAVE_CUSTOMER_CONFIGURATION_STORE',

  RESET_STATE: 'RESET_STATE',
};

export default actionTypes;
