import { call, put, takeLatest } from 'redux-saga/effects';
import { ToastNotification } from 'components/library';
import { REACT_APP_ASSIGN_WORK_ITEM as ENDPOINT } from 'env-config';

import { post } from 'modules/utils';
import { assignWorkItemFail, assignWorkItemStart, assignWorkItemSuccess } from '../actions/assign-work-item';
import types from '../types';

export const httpCall = (data) => post({ route: ENDPOINT, data });

export function* assignWorkItem({ workItemID, dataAnalystID }) {
  try {
    yield put(assignWorkItemStart());

    const result = yield call(httpCall, { workItemID, dataAnalystID });

    yield put(assignWorkItemSuccess(result));

    ToastNotification('success', 'You have successfully assigned the work item');
  } catch (error) {
    yield put(assignWorkItemFail(error));
    ToastNotification('error', error.message || 'There was an error trying to assign work item');
  }
}

export default function* assignWorkItemSaga() {
  yield takeLatest(types.ASSIGN_WORK_ITEM, assignWorkItem);
}
