import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { createAction } from 'redux-actions';
import { isBlank } from '@finclusive/utils';

const MODULE_NAME = 'CUSTOMER_NOTIFICATIONS';

export default {
  MODULE_NAME,
};

export const addPrefix = (type) => `${MODULE_NAME}/${type}`;

export const createPrefixedAction = (type) => createAction(addPrefix(type));

export const getBreadcrumb = ({ crumbs } = {}) => {
  const baseCrumbs = [
    {
      id: '0',
      label: 'Customer Notifications',
    },
  ];

  if (isBlank(crumbs)) return baseCrumbs;

  return R.compose(
    R.concat(baseCrumbs),
    R.map((crumb) => {
      return {
        id: uuidv4(),
        label: crumb.label,
        href: crumb.href || null,
      };
    })
  )(crumbs);
};
