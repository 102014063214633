import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { isBlank } from '@finclusive/utils';
import { FEATURE_FLAGS_VALUES } from 'modules/featureToggle/config/flags';

const withFeatureToggle = (watchedFlags) => (WrappedComponent) => {
  if (isBlank(WrappedComponent)) {
    throw new Error('No component provided to HOC');
  }

  const FeatureToggleWrapper = ({ featureFlags, ...restProps }) => {
    const [featureQueryMatch, setFeatureQueryMatch] = useState({});

    useEffect(() => {
      const matches = {};

      R.forEach((flag) => {
        const featureFlagName = FEATURE_FLAGS_VALUES[flag];
        const index = R.findIndex(R.propEq('featureName', featureFlagName), featureFlags);
        matches[flag] = index > -1 ? R.path([index, 'featureToggle'], featureFlags) : false;
      }, watchedFlags);

      setFeatureQueryMatch(matches);
    }, [featureFlags]);

    return !isBlank(featureQueryMatch) && <WrappedComponent {...restProps} featureQueryMatch={featureQueryMatch} />;
  };

  FeatureToggleWrapper.propTypes = {
    featureFlags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  const mapStateToProps = ({ featureToggle }) => ({
    featureFlags: featureToggle.featureFlags,
  });

  return connect(mapStateToProps)(FeatureToggleWrapper);
};

export default withFeatureToggle;
